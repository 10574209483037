import React from 'react'
import ReactLoading from "react-loading";
const LoadingAnimation = () => {
  return (
    <div className="d-flex flex-row justify-content-center align-items-center my-5">
    <ReactLoading
      type={"spinningBubbles"}
      color={"blue"}
      height={50}
      width={50}
    />
  </div>
  )
}

export default LoadingAnimation