import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LogIn, setIsAuthenticated } from "../redux/authentication/AuthenticationSlice";
import { Link, useNavigate } from "react-router-dom";
import Company from "../Media/Brand.png";
import { useTranslation } from "react-i18next";
import { ChangeLanguage } from "../Language/ChangeLanguage";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import APIFormErrors from "../Utility/APIFormErrors";
import { http } from "../Utility/APIRequest";
import { SwalMessage } from "../Utility/SwalMessage";
import toast from "react-hot-toast";

import Cookies from "js-cookie";
import CustomCaptcha from "./CustomCaptcha";
import { generateCaptchaText } from "./generateCaptchaText";

function Login() {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [fieldErrors, setFieldErrors] = useState(null);
  const [credError, setCredError] = useState(null);

  const [captchaText, setCaptchaText] = useState(""); // Lift state to manage captchaText
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  // state to track whether the form is submitted
  // const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [resetCaptcha, setResetCaptcha] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  if(Cookies.get("userTokenCookie") && Cookies.get("userDataCookie")){
      sessionStorage.setItem("token", Cookies.get("userTokenCookie")); // Store token in sessionStorage
      sessionStorage.setItem("user", Cookies.get("userDataCookie")); 
      const T_data = {
        // user:Cookies.get("userDataCookie"),
        user:JSON.parse(Cookies.get("userDataCookie")),
        token:Cookies.get("userTokenCookie")
      };
      dispatch(setIsAuthenticated(T_data))
      navigator("/")
  }

  // const isAuthenticated = useSelector(
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated) navigator("/");
    setLoading(false)
    if (formSubmitted) {
      // Reset captcha after the form is submitted
      setResetCaptcha(true);
      setFormSubmitted(false);
    }
    
  }, [formSubmitted]);

  useEffect(() => {
    if (resetCaptcha) {
      // Reset captcha and set resetCaptcha back to false
      setCaptchaText(generateCaptchaText());
      setResetCaptcha(false);
    }
  }, [resetCaptcha]);

  const initialValues = {
    email: "",
    password: "",
    captcha: "",
  };
  // const loginHandler = (e) => {
  //   e.preventDefault();
  //   dispatch(LoginRequest());
  //   const user = { email: "javed.zalmay@gmail.com" };
  //   const message = "welcome to dashboard;";
  //   dispatch(LoginSuccess({ user, message }));
  //   Swal.fire({
  //     icon: "success",
  //     title: "Account Login",
  //     text: "You have been logged in successfully",
  //     showConfirmButton: false,
  //     timer: 1500,
  //   });
  //   dispatch(LoginSuccess({ user, message }));
  //   navigator("/");
  // };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("login.email_invalid_error"))
      .required(t("login.email_required_error")),
    password: Yup.string()
      .min(8, t("login.passwordLength"))
      .required(t("login.password_required_error")),
    // captcha: Yup.string().required("Captcha is required"),
  });


  return (
    !loading && (
      <Formik
        initialValues={initialValues}
        // initialValues={{ email: "", password: "", captcha: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setFieldValue }) => {
          http
            .get(`sanctum/csrf-cookie`)
            .then((res) => {
              http
                .post("api/auth/login", values)
                .then((response) => {
                  // console.log(response)
                  if (response.status === 200) {
                    const data = response.data;
                    const token = data.token;
                    const user = JSON.stringify(data.user);
                    sessionStorage.setItem("token", token); // Store token in sessionStorage
                    sessionStorage.setItem("user", user); 

                    if (values.rememberMe) {
                      setFieldValue("rememberMe", true); // Update the form field value
                      Cookies.set("userTokenCookie", token, { expires: 7 });
                      Cookies.set("userDataCookie", user, { expires: 7 });
                    } else {
                      setFieldValue("rememberMe", false); // Ensure the value is set to false if not checked
                    }

                    const T_data = {
                      user,
                      token
                    };

                    dispatch(setIsAuthenticated(T_data))
                    dispatch(LogIn(T_data));
                    SwalMessage(
                      t("login.authorizedUser"),
                      t("login.welcomeBack")+ `${response.data.user.firstName} ${response.data.user.lastName}`,
                      "success"
                    );
                    setSubmitting(false);

                    navigator("/");
                  }
                  
                })
                .catch((error) => {
                  // toast.error(error.message);
                  if (error.response) {
                    if (error.response.status === 422) {
                      toast.error(error.response.data.message);
                      setFieldErrors(error.response.data.errors);
                    } else if (error.response.status === 401) {
                      toast.error( t("login.anAuthorizedUser"));
                      setCredError(error.response.data.message);
                    }
                  }else{
                    toast.error(t("toast.apiOffline"));
                    setLoading(false); // Set loading to false
                  }
                })
                .finally((res) => {
                  setSubmitting(false);
                  // setFormSubmitted(true);
                });
            })
            .catch((error) => {
              toast.error(error.message);
              setSubmitting(false);
              // setFormSubmitted(true);
            });
            
        }}
      >
        {({ isSubmitting, errors, touched,values, setFieldValue }) => (
          <Form>
            <div
              className="d-flex justify-content-center align-items-center bg-body-tertiary"
              style={{ minHeight: "80vh" }}
            >
              
              <main className="login-form " >
                <div className="text-center" >
                  <img className="w-50 h-100" src={Company} alt="logo"/>
                </div>
                <h4 className=" mb-3 fw-semibold text-muted text-capitalize text-center">
                  {t("login.title")}
                </h4>
                {credError && (
                  // <div className="alert alert-danger p-3 my-2">{credError}</div>
                  <div className="alert alert-danger p-3 my-2">{t("login.invalidUsernaemOrPassword")}</div>
                )}
                {fieldErrors && <APIFormErrors errors={fieldErrors} />}
                <div className="form-floating">
                  <Field
                    type="email"
                    className={`form-control ${
                      errors.email && touched.email
                        ? "is-invalid"
                        : touched.email
                        ? "is-valid"
                        : ""
                    }`}
                    id="email"
                    name="email"
                    placeholder="name@example.com"
                  />
                  <label htmlFor="email">{t("login.email_label")}</label>
                </div>
                <div className="form-floating mt-2">
                  <Field
                    type="password"
                    className={`form-control ${
                      errors.password && touched.password
                        ? "is-invalid"
                        : touched.password
                        ? "is-valid"
                        : ""
                    }`}
                    id="password"
                    name="password"
                    placeholder="Password"
                  />
                  <label htmlFor="password">{t("login.password_label")}</label>
                </div>

                {/* <div className="form-check text-start my-3">
                 
                  <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  name="rememberMe"
                  checked={values.rememberMe} 
                  onChange={(e) => {
                    const { checked } = e.target;
                    setFieldValue("rememberMe", checked);
                  }}
                />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    {t("login.remember_me")}
                  </label>
                </div> */}

                {/* <div className="mb-3">
                  <Link to="/register" className="nav-link hover-link">
                    {t("login.registration_message")}
                  </Link>
                </div> */}

                {/* <CustomCaptcha onChange={(isValid) => setFieldValue("captcha", isValid ? captchaText : "")} touched={touched} errors={errors} /> */}
                <CustomCaptcha
                  onChange={(isValid) => {
                    setFieldValue("captcha", isValid ? captchaText : "");
                    setIsCaptchaValid(isValid);
                  }}
                  touched={touched}
                  errors={errors}
                  resetCaptcha={resetCaptcha}
                />
                
                {/* <button
                  className="btn btn-primary w-100 py-2 mt-2"
                  disabled={isSubmitting && true}
                  type="submit"
                > */}

              <button
                className="btn btn-primary w-100 py-2 mt-2"
                disabled={isSubmitting || !isCaptchaValid}
                type="submit"
                onClick={() =>{
                  setResetCaptcha(true)
                  setFormSubmitted(true);
                } 
                }
              >
                  {isSubmitting ? (
                    <>
                      <div
                        className="mx-3 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">{t("general_key_words.loading")}</span>
                      </div>
                      <span>{t("general_key_words.processingPleaseWait")}</span>
                    </>
                  ) : (
                    t("login.signin_button")
                  )}
                </button>

                <div className="d-flex flex-row justify-content-between mt-2 mb-3">
                  <p className="">© 2023-2026</p>
                  <div className="d-flex flex-row gap-3">
                    <span
                      className="hover-link"
                      onClick={(e) => ChangeLanguage("en")}
                    >
                      English
                    </span>
                    <span
                      className="hover-link"
                      onClick={(e) => ChangeLanguage("ar")}
                    >
                      پښتو
                    </span>
                    <span
                      className="hover-link"
                      onClick={(e) => ChangeLanguage("fa")}
                    >
                      دري
                    </span>
                  </div>
                </div>
              </main>
            </div>
          </Form>
        )}
      </Formik>
    )
  );
}

export default Login;