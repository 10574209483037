const FormDataHelper = (formikValues) => {
    const formData = new FormData();
  
    for (let key in formikValues) {
      formData.append(key, formikValues[key]);
    }
  
    return formData;
  };
  
  export default FormDataHelper;