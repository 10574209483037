import React from 'react'
import ReactLoading from "react-loading";
const InlineLoadingAnimation = () => {
  return (
    <div className="bi">
    <ReactLoading
      type={"bubbles"}
      color={"blue"}
      height={40}
      width={40}
    />
  </div>
  )
}

export default InlineLoadingAnimation