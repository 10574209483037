import React from "react";
import { Outlet } from "react-router-dom";
import UserLogNav from "./UserLogNav";

const UserLogLayout = () => {
  return (
    <div>
      <div className="mt-2 bg-white rounded-3 overflow-hidden shadow-sm">
        <UserLogNav />
      </div>
      <div className="">
        <Outlet />
      </div>
    </div>
  );
};

export default UserLogLayout;
