import React from "react";
import { Outlet } from "react-router-dom";
import FormHeader from "../../Utility/FormHeader";


const DriverLayout = () => {
  return (
    <div className="mt-2">
      <FormHeader province={"Kabul"} />
      <Outlet />
    </div>
  );
};

export default DriverLayout;
