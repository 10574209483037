import React from "react";
import { BsChevronDoubleRight } from "react-icons/bs";
const SearchHeader = ({ title }) => {
  return (
    <h6 className="mt-2 p-3 bg-light rounded-3 fw-semibold d-flex flex-row align-items-center gap-2 shadow-sm ">
      <BsChevronDoubleRight className="bi text-secondary" /> <span className="text-success">{title}</span>
    </h6>
  );
};

export default SearchHeader;
