import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
// import { useTranslation } from "react-i18next";

const CarNav = () => {
  const user = useSelector((state) => state.authentication.user);
  // const { t } = useTranslation("translation");
  return (
    <ul className="nav justify-content-start bg-white flex-column flex-lg-row">
      {user.role !=="approver" && (
      <li className="nav-item">
        <NavLink
          to="/view-list"
          className={({ isActive }) =>
            isActive ? "nav-link text-primary" : "nav-link text-black-50"
          }
          end
        >
            {t("vehicle_details_form.allRegisteredVehicle")}
        </NavLink>
      </li>
      )}

      <li className="nav-item">
        <NavLink
        //   to="view-list"
          to="/driver-no-waiting"
          className={({ isActive }) =>
            isActive ? "nav-link text-primary" : "nav-link text-black-50"
          }
          end
        >
          {t("general_key_words.pending")}
        </NavLink>
      </li>

      { (user.role !=="user" && user.staff !=="traffic staff") && (
      <li className="nav-item">
        <NavLink
        //   to="view-list"
          to="/driver-slip-approved"
          className={({ isActive }) =>
            isActive ? "nav-link text-primary" : "nav-link text-black-50"
          }
          end
        >
          {t("vehicle_table.approvedVehicles")}
        </NavLink>
      </li>
      )}
      {(user.role !=="approver" && user.role !=="user" && user.staff !=="traffic staff") && (
        <>
      <li className="nav-item">
        <NavLink
          to="/driver-waiting"
          className={({ isActive }) =>
            isActive ? "nav-link text-primary" : "nav-link text-black-50"
          }
          end
        >
            {t("dash_board_card.colorWaiting")}
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink
        //   to="view-list"
          to="/driver-rejected"
          className={({ isActive }) =>
            isActive ? "nav-link text-primary" : "nav-link text-black-50"
          }
          end
        >
          {t("general_key_words.rejected")}
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink
        //   to="view-list"
          to="/driver-approved"
          className={({ isActive }) =>
            isActive ? "nav-link text-primary" : "nav-link text-black-50"
          }
          end
        >
          {t("vehicle_details_form.inContrl")}
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink
        //   to="view-list"
          to="/driver-complate"
          className={({ isActive }) =>
            isActive ? "nav-link text-primary" : "nav-link text-black-50"
          }
          end
        >
          {t("dashboard_menu.complate-list")}
        </NavLink>
      </li>
        </>
      )}

    </ul>
  );
};

export default CarNav;
