import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { provinces, provincesDari } from "../../Data/provinces";
import { BsCloudUploadFill } from "react-icons/bs";
import FileField from "../../Utility/FileField";
import FormHeader from "../../Utility/FormHeader";
import DateConverter from "../../Utility/DateConverter";
import { perCarTypes } from "../../Data/statuses";

import i18next from "i18next";
import PageHeader from "../../Utility/PageHeader";
import APIFormErrors from "../../Utility/APIFormErrors";
import { useDispatch, useSelector } from "react-redux";
import { UploadDriverData } from "../../redux/vehicleRegistration/UploadDriverData";
import ProgressBar from "../../shared/ProgressBar";
import DateComponent from "../../Utility/DateConverter2";
import { useNavigate, useParams } from "react-router";
import { FetchRecordID } from "../../redux/vehicleRegistration/FetchRecordID";
import { UploadUpdateDriverData } from "../../redux/vehicleRegistration/UploadUpdateDriverData";
import { FetchBranchById } from "../../redux/branchSection/actions/FetchBranchById";
import { UploadBranchEdit } from "../../redux/branchSection/actions/UploadBranchEdit";

export default function BranchEdit() {
  const { t } = useTranslation("translation");
  const currentLanguage = i18next.language;
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);
  const loading = useSelector((state) => state.branch.loading);
  const progress = useSelector((state) => state.branch.progress);
  const errors = useSelector((state) => state.branch.APIErrors);
  const navigate = useNavigate();
  const update = useSelector((state) => state.branch.record);

  const { id } = useParams();
 
  const initialValues = {
    branchName: update ? update.branchName:"",
    province:update ? update.province: "",
    description:update ? update.description: "",
  };
  const validationSchema = Yup.object().shape({
    branchName: Yup.string().required(t("branch.branchName"))
    .min(3, t("validation_errors.branchNameLength"))
    .matches(/^[\p{L}0-9\s]+$/u, t("validation_errors.validCharacters")),
    // province: Yup.string().required(t("validation_errors.province")),
    description: Yup.string()
    .nullable()
    // .matches(/^[a-zA-Z0-9\s]+$/, t("validation_errors.validCharacters"))
    .matches(/^[\p{L}0-9\s]+$/u, t("validation_errors.validCharacters"))
    .max(30, t("validation_errors.branchDescription")),

  });

  // fetch the record for edit

  useEffect(() => {
    // console.log("use Effetct")
    if (id) {
      dispatch(FetchBranchById(token, id));
    }
  }, [id]);


  return (
    <>
      {/* <PageHeader title={t("vehicle_details_form.carDetailsRegForm")} /> */}
      <div className="">

        <PageHeader title={t("vehicle_details_form.carDetailsRegForm")} />
        {errors && (
          <div className=" bg-light rounded-3 mt-2">
            <div className="container-fluid">
              <APIFormErrors errors={errors} />
            </div>
          </div>
        )}
        <div className="mt-2 bg-white rounded-3 p-3">
          <Formik
            // initialValues={update}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            
            onSubmit={(values, { setSubmitting }) => {
            //   console.log("Value:::");
            //   console.log(values);
              dispatch(UploadBranchEdit(values, token, id));
            //   navigate("/view-list")
              setSubmitting(false);
              
            }}
          >
            {({ isSubmitting, handleReset, values }) => (
              <Form>
                <div className="row g-3">


                  <div className="col-sm-12">
                    <div className="row">
                      <label
                        htmlFor="branchName"
                        className="col-sm-2 col-form-label"
                      >
                        {t("branch.branchName")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-10">
                        <Field
                          type="text"
                          name="branchName"
                          id="branchName"
                          placeholder={t("place_holder.branchName")}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="branchName"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="row">
                      <label
                        htmlFor="province"
                        className="col-sm-2 col-form-label"
                      >
                        {t("vehicle_details_form.province")}
                        {/* <span className="text-danger"> *</span> */}
                      </label>
                      <div className="col-sm-10">
                        <Field
                          as="select"
                          id="province"
                          name="province"
                          className="form-select"
                        >
                          <option value="">{t("place_holder.province")}</option>
                          {provincesDari.map((pro, index) => (
                            <option value={pro} key={index}>
                              {pro}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="province"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div>

                  {/* account email and contact section*/}
                  <div className="col-sm-12">
                    <div className="row">
                      <label
                        htmlFor="description"
                        className="col-sm-2 col-form-label"
                      >
                        {t("branch.description")}
                        {/* <span className="text-danger"> *</span> */}
                      </label>
                      <div className="col-sm-10">
                        <Field
                          type="text"
                          name="description"
                          id="description"
                          placeholder={t("place_holder.branchDescription")}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div>

                  <hr className="mx-auto my-3 w-75" />
                  {loading && <ProgressBar progress={progress} />}

                  <button
                    className="btn btn-primary w-100 py-2"
                    disabled={loading && true}
                    type="submit"
                  >
                    {loading ? (
                      <>
                        <div
                          className="mx-3 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        <span>Processing, please wait</span>
                      </>
                    ) : (
                      t("general_key_words.updateRecord")
                    )}
                  </button>
                  {/* <button
                    className="w-100 btn btn-primary "
                    disabled={isSubmitting && true}
                    type="submit"
                  >
                    {t("vehicle_details_form.registerForm")}
                  </button> */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}


