import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: null,
};

const MessageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    SendStart: (state) => {
      state.loading = true;
    },
    SendSuccess: (state) => {
      state.loading = false;
    },
    SendError: (state) => {
      state.loading = false;
    },
  },
});

export const { SendError, SendSuccess, SendStart } = MessageSlice.actions;
export default MessageSlice.reducer;
