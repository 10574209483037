import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { provinces, provincesDari } from "../../Data/provinces";
import { BsCloudUploadFill } from "react-icons/bs";
import FileField from "../../Utility/FileField";
import FormHeader from "../../Utility/FormHeader";
import { acceptedFileTypes, engControlStatus, engInvoiceStatus } from "../../Data/statuses";
import DateConverter from "../../Utility/DateConverter";
import PageHeader from "../../Utility/PageHeader";
import { useLocation } from "react-router";
import APIFormErrors from "../../Utility/APIFormErrors";
import DriverSelectionMessage from "../traffic-control/DriverSelectionMessage";
import DriverDetails from "../traffic-control/DriverDetails";

export default function Invoice() {
  const { t } = useTranslation("translation");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const driverID = searchParams.get("driverID");

 const APIErrors = null;

  const initialValues = {
    trafficAgentName: "Trafic Agent",
    companyAgentName: "Company Agent",
    warrantyTime: "2 year",
    price: "4500",
    lotNumber: "lot123",
    // invGregorianDate: "",
    // invPersianDate: "",
    regGregorianDate: "",
    regPersianDate: "",
    // invHijriDate: "",
    carDetailsFkId: "1",
    trafficAgentRemark: "traffic agent remark here...",
    companyAgentRemark: "compnay agent remark herer...",
    invAttachement:"",
    invoiceStatus:""
  };

  const validationSchema = Yup.object().shape({
    trafficAgentName: Yup.string().required(t("validation_errors.trafficAgentNameRequired")),
    companyAgentName: Yup.string().required(t("validation_errors.companyAgentNameRequired")),
    warrantyTime: Yup.string().required("Warranty time is required"),
    price: Yup.number()
    .required("Price is required")
    .min(0, "Price must be greater than or equal to 0")
    .max(1000000, "Price must be less than or equal to 10000"),
    lotNumber: Yup.string().required("Lot number is required"),
    // invGregorianDate: Yup.string().required("Gregorian Date is required"),
    // invPersianDate: Yup.string().required("Shamsi Date is required"),
    // invHijriDate: Yup.string().required("Hijri Date is required"),
    regGregorianDate:  Yup.string().required(t("validation_errors.regGregorianDate")),
    regPersianDate: Yup.string().required(t("validation_errors.regPersianDate")),
    // carDetailsFkId: Yup.string().required("Car ID is required"),
    trafficAgentRemark: Yup.string().required("Traffic remark is required"),
    companyAgentRemark: Yup.string().required("Static Compmay remark is required"),
    invoiceStatus: Yup.string().required("Control Status is required"),
    invAttachement: Yup.mixed()
      .required("Invoce attachement file is required")
      .test("fileSize", "File size exceeds 1MB limit", (value) => {
        if (value) {
          return value.size <= 1 * 1024 * 1024;
        }
        return true;
      })
      // .test("fileFormat", "Only PDF file is allowed", (value) => {
        .test("fileType",t("validation_errors.imageType"),(value) => {
        if (value) {
          return acceptedFileTypes.includes(value.type);
          // return value.type === "application/pdf";
        }
        return true;
      }),

  });

  return (
    <div className="pt-2">
      {/* <div class="p-5 bg-light rounded-3">
        <div class="container-fluid">
          <h3 class="display-5 fw-bold">Vehicle registration form</h3>
          <p class="col-md-8 ">
            Islamic Emirate of afghanistan: <span className="d-block">Traffic </span>
          </p>
        </div>
      </div> */}
     
      {/* <FormHeader province={"Kabul"} title={t("page.invoice_registeration_form")}/> */}

      <PageHeader title={t("traffic_control.invoiceRegistrationForm")} />
      {driverID ? (
        <>
          <DriverSelectionMessage
            type={"success"}
            message={`driver has been selected with ${driverID}`}
          />
          <DriverDetails id={driverID} />
        </>
      ) : (
        <DriverSelectionMessage
          type={"danger"}
          message={`driver is not selected`}
        />
      )}
      {APIErrors && (
        <div class=" bg-light rounded-3 mt-2">
          <div class="container-fluid">
            <APIFormErrors errors={APIErrors} />
          </div>
        </div>
      )}
      <div className="mt-2 bg-white rounded-3 p-3">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            // console.log(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, handleReset, values }) => (
            <Form>
              <div className="row g-3">
                {/* account names section */}
                <div className="col-sm-12">
                  {/* <h4 className="text-muted">{t("traffic_control.trafficAgentName")}</h4> */}
                </div>
                <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="trafficAgentName"
                      className="col-sm-3 col-form-label"
                    >
                      {t("traffic_control.trafficAgentName")}
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <div className="col-sm-9">
                      <Field
                        type="text"
                        name="trafficAgentName"
                        id="trafficAgentName"
                        placeholder={t("traffic_control.trafficAgentName")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="trafficAgentName"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>
             
                <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="lastName"
                      className="col-sm-3 col-form-label"
                    >
                      {t("traffic_control.companyAgentName")}
                      {/* <span className="text-danger"> *</span> */}
                    </label>
                    <div className="col-sm-9">
                      <Field
                        type="text"
                        name="companyAgentName"
                        id="companyAgentName"
                        placeholder={t("traffic_control.companyAgentName")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="companyAgentName"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>

                {/* account email and contact section*/}
                <div className="col-sm-6">    
                
                  <div className="row">
                    <label
                      htmlFor="warrantyTime"
                      className="col-sm-3 col-form-label"
                    >
                      {t("invoice.warrantyTime")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                      <Field
                        type="text"
                        name="warrantyTime"
                        id="warrantyTime"
                        placeholder={t("invoice.warrantyTime")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="warrantyTime"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="price"
                      className="col-sm-3 col-form-label"
                    >
                      {t("invoice.price")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                      <Field
                        type="number"
                        name="price"
                        id="price"
                        placeholder={t("invoice.price")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="price"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>
                

                <DateConverter t={t} shamsiName="regPersianDate" miladiName="regGregorianDate"
                  shamsiId="regPersianDate" miladiId="regGregorianDate" />

                {/* <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="invGregorianDate"
                      className="col-sm-3 col-form-label"
                    >
                      {t("invoice.invGregorianDate")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                      <Field
                        type="date"
                        name="GreinvgorianDate"
                        id="invGregorianDate"
                        placeholder={t("invoice.invGregorianDate")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="activityType"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>
                       
                <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="invPersianDate"
                      className="col-sm-3 col-form-label"
                    >
                      
                      {t("invoice.invPersianDate")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                      <Field
                        type="date"
                        name="invPersianDate"
                        id="invPersianDate"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="invPersianDate"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="invHijriDate"
                      className="col-sm-3 col-form-label"
                    >
                      
                      {t("invoice.invHijriDate")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                      <Field
                        type="date"
                        name="invHijriDate"
                        id="invHijriDate"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="invHijriDate"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="carDetailsFkId"
                      className="col-sm-3 col-form-label"
                    >
                     {t("traffic_control.carDetailsFkId")}
                      
                      <span className="text-danger"> *</span>
                    </label>

                    <div className="col-sm-9">
                      <Field
                        type="number"
                        name="carDetailsFkId"
                        id="carDetailsFkId"
                        placeholder={t("traffic_control.carDetailsFkId")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="carDetailsFkId"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div> */}

                <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="nid"
                      className="col-sm-3 col-form-label"
                    >
                    
                      {t("invoice.lotNumber")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                      <Field
                        type="text"
                        name="lotNumber"
                        id="lotNumber"
                        placeholder={t("invoice.lotNumber")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="lotNumber"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="invoiceStatus"
                      className="col-sm-4 col-form-label"
                    >
                      Header: t("invoice.invoiceStatus"),
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-8">
                      <Field
                        as="select"
                        id="invoiceStatus"
                        name="invoiceStatus"
                        className="form-select"
                      >
                        <option value="">{t("general_key_words.choose")}</option>
                        {engInvoiceStatus.map((status, index) => (
                          <option value={status} key={index}>
                            {status}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="invoiceStatus"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="row">
                    <label
                      htmlFor="trafficAgentRemark"
                      className="col-sm-2 col-form-label"
                    >
                      {t("traffic_control.trafficAgentRemark")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-10">
                      <Field
                        as="textarea"
                        name="trafficAgentRemark"
                        id="trafficAgentRemark"
                        placeholder={t("traffic_control.trafficAgentRemark")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="trafficAgentRemark"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="row">
                    <label
                      htmlFor="companyAgentRemark"
                      className="col-sm-2 col-form-label"
                    >
                      
                      {t("traffic_control.companyAgentRemark")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-10">
                      <Field
                        as="textarea"
                        name="companyAgentRemark"
                        id="companyAgentRemark"
                        placeholder={t("traffic_control.companyAgentRemark")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="companyAgentRemark"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>

               
                <div className="col-sm-12 col-md-2"></div>
                <div className="col-sm-12 col-md-4">
                  <label
                    htmlFor="invAttachement"
                    className="form-label border shadow-sm text-muted rounded px-5 py-2 w-100 text-center"
                  >
                    {t("invoice.invAttachement")}
                    <span className="text-danger"> *</span>
                    <span
                      className={
                        values.invAttachement
                          ? "ms-3 fs-5 text-success"
                          : "ms-3 fs-5 text-danger"
                      }
                    >
                      <BsCloudUploadFill />
                    </span>
                  </label>
                  {values.invAttachement && (
                    <div className="text-muted">
                      <span className="text-primary">
                      {t("invoice.invAttachement")}
                      </span>:{" "}
                      {values.invAttachement.name}
                    </div>
                  )}
                  <FileField name={"invAttachement"} 
                  id={"invAttachement"} 
                  accept={"image/*"}
                  />
                  <ErrorMessage
                    name="invAttachement"
                    className="text-danger"
                    component="div"
                    
                  />
                </div>


                <hr className="mx-auto my-3 w-75" />

                <button
                  className="w-100 btn btn-primary "
                  disabled={isSubmitting && true}
                  type="submit"
                >{t("vehicle_details_form.registerForm")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}


