import React from 'react'
import { FaPrint } from 'react-icons/fa'

export const CompanyMessage = () => {
  return (
    <div className="panel" style={{margin:"30px", marginTop:"10px"}}>
        <h5>
                    <div  className={`alert alert-success text-center`}>
                        
                        {("افغان سافت گروپ")}
                        
                    <div className="print" style={{float:"right"}}
                    onClick={(e)=>window.print()}
                    ><FaPrint /></div>
                    </div>
                    </h5>
                    <div className="panel-body px-3" >
                        <div className='p-class' style={{ textAlign: 'justify' }}>
                            <p>افغان سافت گروپ افتخار دارد که از سال ۱۴۰۰ هجری به این طرف در بخش تکنالوژی
                            در خدمت همشهریان محترم بوده و با ساختن نرم افزارهای مختلف به ادارات دولتی
                            و غیر دولتی سهولت های هرچه بیشتر را به وطن داران عزیز مان فراهم نموده است.

                        </p>
                        <p>
                            این بار با ساختن سیستم مدیریتی  <b>تغییر رنگ وسایط نقلیه تکسی از رنگ زرد و سفید به رنگ فیروزه‌یی روشن و سفید </b>   
                        از طرف در اجرای پروسه کاری و طی مراحل رنگمالی موتر برای مالکین موتر سهولت ایجاد نموده و از طرف دیگر 
                        در بخش زیبایی شهر و امنیت همشهریان مان یک گام بزرگ و اساسی را برداشته ایم.


                        </p>
                        <p>
                            ما متعده به وطن و مردم خود بوده و همیشه در خدمت هم وطنان عزیز مان میباشیم
                            شما میتوانید با وتسپ نمبر 
                            &nbsp;
                            <b>0772709870</b>
                            &nbsp;
                            و یا &nbsp;
                            <b><a className='mail' href="mailto:mearajussin.haidary@gmail.com">با این ایمل</a></b>
                            &nbsp; آدرس
                            
                            با ما تماس گرفته و از خدمات ما استفاده نمایید.
                        </p>
                        <div className="text-center">
                            <p>
                                با احترام
                            </p>
                            <p>
                                تیم مسلکی و تخنیکی افغان سافت گروپ
                            </p>
                        </div>

                        </div>
    


                    </div>
                    
                </div>
  )
}
