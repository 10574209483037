import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import { UpdateRecords } from "./VehicleSlice";
import { DeleteError, DeleteSuccess, DeleteStart } from "../Util/DeleteSlice";

import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const AddToApproveBankSlip =
  (token, id, section) => async (dispatch, getState) => {
    // console.log(section)
    dispatch(DeleteStart(id));
    // http request to backend
    const data = {
        start_date : new Date().toDateString(),
        type:section
    }
    
    try {
      // const response = await http.post(`api/driver/gen/invoice/reject/${id}`,data, {
      const response = await http.post(`api/driver/approve/bank_slip/add/${id}`,data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // console.log("response:");
      // console.log(response.data.record);

      // // if (section === "table") {
      //   const currentState = getState();
      //   const updatedData = currentState.vehicle.data.map((record) => {
      //     if (record.id === id) {
      //       // Modify the record if it matches the updated one
      //       return response.data.record; // Assuming you have an updated record available
      //       // return record; // Assuming you have an updated record available
      //     }
      //     // return record;
      //   });
      //   dispatch(UpdateRecords(updatedData));
      // // }
      // console.log(response);

      if (section === "approve" || section === "disApprove") {
        const currentState = getState();

        const updatedData = currentState.vehicle.data.filter((record) => record.id != id) 
              
        dispatch(UpdateRecords(updatedData));
      }

      if(section==="approve"){
        toast.success(t("toast.BankSlipApproved"));
      }else if(section==="disApprove"){
        toast.success(t("toast.BankSlipDisApproved"));
      }
      
      dispatch(DeleteSuccess());
      if(section==="view"){
        window.location.reload();
      }
    } catch (error) {
      dispatch(DeleteError());
      if (error.response) {
        HandleHTTPRequestError(error.response.status, error, dispatch);
      } else {
        // Handle the case where the API is offline or unreachable
        console.error("API is offline or unreachable.");
          toast.error(t("toast.apiOffline"));
        //   toast.success(t("toast.BankSlipApproved"));
      }
    }
  };


