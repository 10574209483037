import i18next from "i18next";
import Swal from "sweetalert2";

export function ChangeLanguage(language) {
  i18next.changeLanguage(language);
  document.documentElement.setAttribute("dir", i18next.dir());
  localStorage.setItem("selectedLanguage", language);
  // console.log("Lanugagees:", language);

  const pageURL = window.location.href;

  if (language === "en") {
    Swal.fire(
      "Language Changed",
      `language has been changed to English`,
      "info"
    );
  } else if (language === "ar") {
    Swal.fire("ژبه بدل شو", `ژبه پشتو ته بدل شول`, "info");
  } else if (language === "fa") {
    Swal.fire("زبان تغیر کرد", `زبان به دری تبدیل شد`, "info");
  }

  // Reload the specific page URL
  window.location.href = pageURL;

  // console.log(language)
  // Swal.fire(
  //   "Language Changed",
  //   `language has been changed to ${language}`,
  //   "info"
  // );
}
