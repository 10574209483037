import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { t } from "i18next";

const LineChart = ({ data }) => {
  const domain = "http://localhost:3000/";

  const handleViewMore = (page) => {
    const navigateUrl = domain + page;
    window.open(navigateUrl, "_blank");
  };

  const chartRef = useRef(null);
  const chartInstanceRef1 = useRef(null);
  const chartInstanceRef2 = useRef(null);

  useEffect(() => {
    if (data) {
      // Extract the data you want to display in the pie charts
      const labels = [
        // t("dash_board_card.pendingInvoice")+ " :" + data.pendingInvoiceSum,
        // t("dash_board_card.receivedInvoice")+ " :" + data.receivedInvoiceSum,
        // t("invoice.generatedInvoice") + " :" + data.generatedInvoiceSum,
        // t("dash_board_card.registeredVehicle") + " :" + data.drivers,
        // t("dash_board_card.colorWaiting") + " :" + data.waiting,
        // t("dash_board_card.approvedControls") + " :" + data.controls,
        // t("dash_board_card.branches") + " :" + data.branches,


        t("dash_board_card.pendingInvoice"),
        t("dash_board_card.registeredVehicle"),
        t("dash_board_card.receivedInvoice"),
        t("dash_board_card.colorWaiting"),
        t("invoice.generatedInvoice"),
        t("dash_board_card.branches"),
        t("dash_board_card.approvedControls"),
        

        // t("setting.users") + ":" + data.users,
        // Add other data labels here
      ];
      const values = [
        // data.pendingInvoiceSum,
        // data.receivedInvoiceSum,
        // data.generatedInvoiceSum,
        // data.drivers,
        // data.waiting,
        // data.controls,
        // data.branches,
        // // data.users
        // // Add other data values here

        data.pendingInvoiceSum,
        data.drivers,
        data.receivedInvoiceSum,
        data.waiting,
        data.generatedInvoiceSum,
        data.branches,
        data.controls,
       
      ];

      // const invoiceValues = [
      //   data.pendingInvoiceSum,
      //   data.receivedInvoiceSum,
      //   data.generatedInvoiceSum,
      //   data.drivers,
      //   data.waiting,
      //   data.controls,
      //   data.branches,
      //   // data.users
      //   // Add other data values here
      // ];

      // const vehicleValues = [
      //   data.drivers,
      //   data.waiting,
      //   data.controls,
      //   data.branches,
      //   data.pendingInvoiceSum,
      //   data.receivedInvoiceSum,
      //   data.generatedInvoiceSum,
      //   // data.users
      //   // Add other data values here
      // ];

      // Destroy the previous charts if they exist
      if (chartInstanceRef1.current) {
        chartInstanceRef1.current.destroy();
      }
      if (chartInstanceRef2.current) {
        chartInstanceRef2.current.destroy();
      }

      // Create the first pie chart using Chart.js

      // Create the second pie chart using Chart.js
      const ctx2 = chartRef.current.getContext("2d");
      chartInstanceRef2.current = new Chart(ctx2, {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            // {
            //   label: t("dash_board_card.allCharts"),
            //   data: values,
            //   backgroundColor: [
            //     "rgba(255, 99, 132, 0.2)",
            //     "rgba(255, 159, 64, 0.2)",
            //     "rgba(255, 205, 86, 0.2)",
            //     "rgba(75, 192, 192, 0.2)",
            //     "rgba(54, 162, 235, 0.2)",
            //     "rgba(153, 102, 255, 0.2)",
            //     "rgba(201, 203, 207, 0.2)",
            //     "rgba(180, 95, 150, 0.2)",
            //   ],
            //   borderColor: [
            //     "rgb(255, 99, 132)",
            //     "rgb(255, 159, 64)",
            //     "rgb(255, 205, 86)",
            //     "rgb(75, 192, 192)",
            //     "rgb(54, 162, 235)",
            //     "rgb(153, 102, 255)",
            //     "rgb(201, 203, 207)",
            //     "rgba(180, 95, 150)",
            //   ],
            //   borderWidth: 1,
            // },

            {
              label               : ' ',
              backgroundColor     : 'rgba(60,141,188,0.9)',
              borderColor         : 'rgba(60,141,188,0.8)',
              pointRadius          : false,
              pointColor          : '#3b8bba',
              pointStrokeColor    : 'rgba(60,141,188,1)',
              pointHighlightFill  : '#fff',
              pointHighlightStroke: 'rgba(60,141,188,1)',
              data                : values
            },
            // {
            //   label               : 'Electronics',
            //   backgroundColor     : 'rgba(210, 214, 222, 1)',
            //   borderColor         : 'rgba(210, 214, 222, 1)',
            //   pointRadius         : false,
            //   pointColor          : 'rgba(210, 214, 222, 1)',
            //   pointStrokeColor    : '#c1c7d1',
            //   pointHighlightFill  : '#fff',
            //   pointHighlightStroke: 'rgba(220,220,220,1)',
            //   data                : vehicleValues
            // },
          ],
        },
        options: {
          responsive: true,
          cutout: "50%",
          animation: {
            animateRotate: true,
            animateScale: false,
          },
        },
      });
    }
  }, [data]);

  return (
    // <div className="rounded-3 row bg-light m-0 mt-2 py-5">
    //   {/* Add canvas elements for the pie charts */}
    //   <div className="col-sm-12 col-md-8 col-lg-5">
    // <canvas ref={chartRef} className="line-chart"></canvas>
    //   </div>
    // </div>

    <div className="card card-info my-2">
              <div className="card-header">
                <h3 className="card-title text-center">{t("dash_board_card.allCharts")}</h3>

                {/* <div className="card-tools">
                  <button type="button" className="btn btn-tool" data-card-widget="collapse">
                    <i className="fas fa-minus"></i>
                  </button>
                  <button type="button" className="btn btn-tool" data-card-widget="remove">
                    <i className="fas fa-times"></i>
                  </button>
                </div> */}
              </div>
              <div className="card-body">
                <div className="chart">
                  <canvas ref={chartRef}  id="lineChart" style={{minHeight:"250px", height:"250px", maxHeight:"250px", maxWidth:"100%"}}></canvas>
                </div>
              </div>
              </div>
  );
};

export default LineChart;
