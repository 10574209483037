import React from "react";
import ImageOne from "../Media/Traffict.png";
// import ImageTwo from "../Media/islamic_emirate.png";
import ImageTwo from "../Media/Company2.jpg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useSelector } from "react-redux";
const FormHeader = ({ province, title }) => {
  const { t } = useTranslation("translation");
  const currentLanguage = i18next.language;
  const user = useSelector((state)=>state.authentication.user);
  const userProvince = user.province;
  return (
    <div className="p-3 px-lg-5 rounded-3 form-header bg-white">
      <div className="rounded-3 overflow-hidden d-flex flex-row justify-content-between align-items-center">
        <img
          src={ImageOne}
          alt="traffic image"
           className="form-header-img"
        />
        <div
          className="d-flex flex-column text-center"
        >
          <h5 className="fw-semibold fs-6">{t("pages.islamic_emirate_title")}</h5>
          <h5 className="fs-6">{t("pages.ministry_interior_title")}</h5>
          <h5 className="fs-6">{t("pages.traffic_department_title")}</h5>
          <h5 className="fs-6">{t("general_key_words.projectName")}</h5>
          {/* <h5 className="fs-6">{t("pages.traffic_province",{province:userProvince})}</h5> */}

          {/* <h5>{province} {t("pages.traffic_province")}</h5> */}
        </div>
        <img
          src={ImageTwo}
          alt="company image"
          className="form-header-img2 companyImg"
          // style={{maxWidth:"120px"}}
        />
      </div>
    </div>
  );
};

export default FormHeader;
