import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import PageHeader from "../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import LoadingAnimation from "../../shared/LoadingAnimation";
import NoContentsFound from "../../shared/NoContentsFound";

import WaitingListTable from "./WaitingListTable";
import {FetchWaitingData, FetchWaitingListByBranchId } from "../../redux/WaitingSection/FetchWaitingData";
import MessageModal from "../../shared/MessageModal";
import { useParams } from "react-router-dom";

export default function WaitingList(props) {
  // sendSMS();
  const { t } = useTranslation("translation");
  const data = useSelector((state) => state.wait.data);
  const CarsData = useSelector((state) => state.wait);
  const loading = useSelector((state) => state.wait.loading);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);
  const {id} = useParams();
  const type = props.type;

  useEffect(() => {
    if(type==="waitingListByBranch"){
      dispatch(FetchWaitingListByBranchId(token, id))
    }else{
      dispatch(FetchWaitingData(token));
    }
    
  }, []);
  // console.log("Vhicle:");
  // console.log(data);
  return (
    <div>
      <MessageModal />
      <div className="mt-2">
        {/* <FormHeader province={"Kabul"} /> */}
        <PageHeader title={t("dashboard_menu.waiting_list_link")} />
      </div>
      {loading ? (
        <LoadingAnimation />
      ) : data && data.length>0? (
        // <DriverTabularView data={data} />
        // <CarTable carsData={CarsData} />
        <WaitingListTable carsData={CarsData} recordType={t("vehicle_details_form.colorWaitinVehicleList")}/>
      ) : (
        // <DriverTabularView data={data} options={true}/>
        <NoContentsFound message={t("general_key_words.dataNotFound")} />
      )}
    </div>
  );
}
