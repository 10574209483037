import SearchBox from "./SearchBox";
import Footer from "./Footer";
import VehicleDate from "./VehicleData";
const VehicleStatusContainer = () => {
    
  return (
    <>
    <div className="public-wropper">
        <SearchBox />
        <div className="data" style={{minHeight:"200px"}}>
            <VehicleDate />   
        </div>
        <Footer />
    </div>
    </>
  );
};

export default VehicleStatusContainer;
