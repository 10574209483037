import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MessageTextArea from "./MessageTextArea";
import { useTranslation } from "react-i18next";
import { RemoveMessageRecord } from "../redux/WaitingSection/WaitSlice";
import { SendMessage } from "../redux/Messages/SendMessage";
import DownloadLoading from "./DownloadLoading";

const MessageModal = () => {
  const driver = useSelector((state) => state.wait.messageRecord);
  const user = useSelector((state) => state.authentication.user);
  const token = useSelector((state) => state.authentication.token);
  const loading = useSelector((state) => state.message.loading);

  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const messageHeader = t("message.body");

  const currentLanguage = i18n.language;
  const [message, setMessage] = useState(messageHeader);

  const handleMessageSend = () => {
    const data = {
      user_id: user.id,
      driver_id: driver.dId,
      message: message,
      driver:driver
    };
    dispatch(SendMessage(data, token));
  };
  const handleCancel = () => {
    dispatch(RemoveMessageRecord());
  };
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header" dir="ltr">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              {t("message.title")}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {driver ? (
              <div className="alert alert-success py-2 text-center">
                {t("message.success_message")}
                <span className="fw-bold mx-2">{driver.firstName}</span>
              </div>
            ) : (
              <div className="alert alert-danger py-2">
                {t("message.unableToLoadDriverData")}
              </div>
            )}
            <form>
              <div className="mb-3">
                <label for="recipient-name" className="col-form-label">
                  {t("message.recipient_label")}:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="recipient-name"
                  value={driver && driver.phone}
                />
              </div>
              <div className="mb-3">
                <label for="message-text" className="col-form-label">
                  {t("message.message_label")}:
                </label>
                {driver && (
                  <MessageTextArea
                    driver={driver}
                    message={message}
                    setMessage={setMessage}
                    defaultMessage={message}
                  />
                )}
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={handleCancel}
            >
              {t("message.cancel_button")}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleMessageSend}
              disabled={loading}
            >
              {loading ? (
                <div className="d-flex align-items-center gap-2">
                  <DownloadLoading /> <span>Sending...</span>{" "}
                </div>
              ) : (
                t("message.send_button")
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageModal;
