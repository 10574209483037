export const engControlStatus = ["Accept", "Reject", "Repair again", "None"];
export const dariControlStatus = ["قبول", "رد", "درحال برسی"];

export const engInvoiceStatus = [
  "پرداخت شده",
  "باقی",
  "حواله به بانک",
  "هیچ کدام",
];

export const perCarTypes = [
 "سراچه",
 "کرولا",
 "فیلدر",
 "تکسی",
];
export const engCarTypes = [
  "Compact Car", "Sports Car", "Luxury Car", "Hybrid Car", 
  "Sedan", "SUV","Convertible", "Hatchback", "Coupe", 
  "Truck", "Minivan",  "Crossover", "Electric Car",  
  "Wagon", "Off-Road Vehicle"
];

export const acceptedFileTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
];
