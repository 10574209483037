import toast from "react-hot-toast";
let toastShown = false;
export const showToast = (toastType, toastMessage)=>{
    if(toastType==="success"){
        if(!toastShown){
          toast.success(toastMessage);
          toastShown = true;
        }   
    }else if(toastType==="error"){
        if(!toastShown){
            toast.error(toastMessage);
            toastShown = true;
        }  
    }
}