import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import { UpdateRecords } from "./VehicleSlice";
// import { DeleteError, DeleteSuccess, DeleteStart } from "../Util/DeleteSlice";

import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const AddToWaitingCollection = (token, data) => async (dispatch) => {
    
  try {
    const response = await http.post(`api/collection/registration`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    // console.log(response.data)
    dispatch(UpdateRecords(response.data.records));
    toast.success(t("toast.vehicle_dd_to_waiting"));

  } catch (error) {
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
      toast.error(t("toast.apiOffline"));
    }
  }
};
