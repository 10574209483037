import { t } from "i18next";
import React, { useMemo, useState, useRef } from "react";
import { useTable, useSortBy, usePagination } from "react-table"; // Import useSortBy hook

import jsPDF from "jspdf";
import "jspdf-autotable";

import { useDownloadExcel } from "react-export-table-to-excel";
import { FaDownload, FaFileExcel, FaFilePdf } from "react-icons/fa";

import jalaliMoment from "jalali-moment";

import AllControlOptionButton from "../traffic-control/AllControlOptionButton";
import InvoicePrintedOptions from "./InvoicePrintedOptions";
import CollectingCashOptionButton from "./CollectingCashOptionButton";
import { BiSolidChevronLeft, BiSolidChevronRight, BiSolidChevronsLeft, BiSolidChevronsRight } from "react-icons/bi";
import { FILES_BASE_URL } from "../../Utility/Domain";
import { BsDownload } from "react-icons/bs";

// import "jspdf-autotable/dist/polyfills.js";
// import "jspdf-autotable/dist/jspdf.plugin.autotable.js";

const CollectedCashTable = ({ carsData, type, recordType }) => {

  const downloadTechFile = (fileName) => {
    if (fileName) {
      window.open(`${FILES_BASE_URL}/invoice/${fileName}`, "_blank");
    }
  };


  const [filterValue, setFilterValue] = useState(""); // State variable for filtering

  // console.log("Received Data:");
  // console.log(carsData);

  const data = useMemo(() => {
    if (!carsData || !carsData.data) {
      return [];
    }

    // Apply filtering based on the filterValue
    return carsData.data.filter((row) => {
      // return Object.values(row).some((value) =>
      return Object.values(row).some((value) =>
        value
          ? value.toString().toLowerCase().includes(filterValue.toLowerCase())
          : false
      );
    });
  }, [carsData, filterValue]);

  const columns = useMemo(
    () => [
      {
        Header: t("vehicle_table.serial_number"),
        accessor: (row, index) => index + 1, // Generate sequential numbers
      },
      
      {
        Header: t("vehicle_table.serial_number"),
        accessor: "generate_invoice_id",
      },
      //   {
      //     Header: t("vehicle_table.date"),
      //     accessor: "generate_date",
      //     // Enable sorting for this column
      //     canSort: true,
      //   },
      {
        Header: t("invoice.invoiceStatus"),
        // accessor: "invoice_status",
        accessor: (row) =>
          row.invoice_status === "paid_invoice" ? t("invoice.received") : null,
      },

      {
        Header: t("general_key_words.invoiceAmound"),
        accessor: "invoice_amount",
        // Enable sorting for this column
        canSort: true,
      },
      {
        Header: t("general_key_words.ivoiceReceivedAt"),
        accessor: "received",
        // Enable sorting for this column
        canSort: true,
        Cell: ({ value }) => {
          if (value) {
            // Convert Gregorian date to Jalali using jalali-moment
            const jalaliDate = jalaliMoment(value)
              .locale("fa")
              .format("YYYY-MM-DD HH:mm:ss");
            return <span>{jalaliDate}</span>;
          }
          return null;
        },
      },
      {
        Header: t("invoice.tarifaNumber"),
        accessor: "tarifa_number",
      },
      {
        Header: t("invoice.tarifaFile"),
        accessor: "tarifa_file",
        Cell: ({ value }) => {
          if (value) {
            // Display "View File" when tarifa_file exists
            return <span className="btn btn-secondary" title={t("invoice.dawnload")}
            onClick={(fileName) => downloadTechFile(value)}
            >
              {/* {t("invoice.dawnload")} */}
            <FaDownload className="bi" />
            </span>;
          } else {
            // Display "No File Attached" when tarifa_file is null or empty
            // return t("general_key_words.noFileAttached");
            return <span className="fw-bold btn btn-danger" title={t("general_key_words.noFileAttached")}>X</span>;
          }
        },
      },

      {
        Header: t("general_key_words.recordOptions"),
        accessor: "optionsButton",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },

    useSortBy, // Enable sorting
    usePagination
  );

  const totalPrice = useMemo(() => {
    if (!data || data.length === 0) {
      return 0;
    }
    return data.reduce((sum, row) => sum + parseInt(row.invoice_amount, 10), 0);
  }, [data]);

  //downlaod excel sheet:
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: t("excelTable.receivedInvoiceTable"),
    sheet: "driverSheet",
  });

  const handleExportToExcel = () => {
    const exportColumns = columns.filter(
      (column) => column.id !== "optionsButton"
    );
    const exportData = page.map((row) => {
      const rowData = {};
      exportColumns.forEach((column) => {
        rowData[column.Header] = row.values[column.accessor];
      });
      return rowData;
    });
    onDownload(exportData, exportColumns);
  };

  //download to pdf:
  const exportPDF = () => {
    // console.log("PDF");
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3, or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Driver Table";
    const headers = [
      [
        t("Name"),
        t("Father Name"),
        t("NID"),
        t("Phone#"),
        t("Driving Permit"),
        t("Car Num Plat"),
        t("Province"),
      ],
    ];

    const data = page.map((row) => [
      row.original.firstName,
      row.original.fatherName,
      row.original.nid,
      row.original.phone,
      row.original.drivingPermit,
      row.original.carNumPlat,
      row.original.province,
    ]);

    doc.text(title, marginLeft, 40);
    doc.autoTable({
      startY: 50,
      head: headers,
      body: data,
    });

    doc.save("report.pdf");
  };

  return (
    <div>
      <div className="row mt-2 mb-2">
        <div className="col-sm-12 col-md-8">
          <input
            type="text"
            className="form-control"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            placeholder={t("general_key_words.search")}
          />
        </div>
        <div className="col-sm-12 col-md-4">
          <div className="bg-white rounded-2 mt-2 mt-lg-0 mb-0 d-flex flex-row align-items-center justify-content-between">
            <div onClick={handleExportToExcel}>
              <FaFileExcel
                style={{ color: "green", cursor: "pointer", fontSize: "25px" }}
              />
            </div>
            <div className="d-flex flex-row align-items-center">
              <span className="mx-2">{t("general_key_words.show")}</span>
              <select
                value={pageSize}
                className="form-select"
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 50, 100, 200, 400, 800, 1600].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive rounded-3">
        <table
          className="table table-hover"
          id="property-table"
          {...getTableProps()}
          border="1"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key="header" className="text-center">
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="table-header-style"
                  >
                    {column.render("Header")}
                    {/* Display sorting indicators */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.original.id} className="text-center">
                  {row.cells.map((cell, index) => {
                    //   return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                    return (
                      <td {...cell.getCellProps()}
                      className="table-cells-style"
                      >
                        {index === row.cells.length - 1 ? (
                          <CollectingCashOptionButton
                            record={row.original}
                            type={type}
                          />
                        ) : // cell.column.id === 'regGregorianDate' ? (
                        cell.column.id === "generate_date" ? (
                          // Assuming your date is in the format YYYY-MM-DD
                          // Adjust format as needed
                          //   "date"
                          jalaliMoment(new Date(cell.value).toDateString())
                            .locale("fa")
                            .format("YYYY-MM-DD")
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr>
              <td className="text-center fw-bold" colSpan={columns.length}>
                {t("invoice.totalPrice")}: {totalPrice} (AF)
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <table
        className="table d-none table-hover"
        id="property-table"
        {...getTableProps()}
        ref={tableRef}
        border="1"
      >
        <caption style={{ textAlign: "center", fontSize: "20px", fontWeight: "bold" }}>
            <br/>
            {t("message.brand")} <br /> {recordType}
            ({jalaliMoment(new Date().toDateString()).locale("fa").format("DD-MM-YYYY")})
        </caption>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key="header" className="text-center">
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}
                className="table-header-style"
                >
                  {column.render("Header")}
                  {/* Display sorting indicators */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.original.id} className="text-center">
                {row.cells.map((cell, index) => {
                  //   return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  return (
                    <td {...cell.getCellProps()}
                    className="table-cells-style"
                    >
                      {index === row.cells.length - 1
                        ? null
                        : // <CollectingCashOptionButton record={row.original} />
                        // cell.column.id === 'regGregorianDate' ? (
                        cell.column.id === "generate_date"
                        ? // Assuming your date is in the format YYYY-MM-DD
                          // Adjust format as needed
                          //   "date"
                          jalaliMoment(new Date(cell.value).toDateString())
                            .locale("fa")
                            .format("YYYY-MM-DD")
                        : cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <tr>
            <td className="text-center fw-bold" colSpan={columns.length-2}>
              {t("invoice.totalPrice")}: {totalPrice} (AF)
            </td>
          </tr>
        </tbody>
      </table>

      <div>
      {pageCount > 1 && (
        <ul className="pagination bg-white py-2 rounded-3 d-flex flex-row justify-content-between px-2 mt-2">
          <li className="page-item ">
            <button className="btn btn-light p-0 p-1" disabled>
              {t("general_key_words.page")}{" "}
              <strong>
                {pageIndex + 1} / {pageCount}
              </strong>{" "}
            </button>
          </li>
          <div className="d-flex flex-row gap-1">
            <li className="page-item ">
              <button
                className="btn btn-primary py-2 d-flex align-items-center"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <BiSolidChevronsLeft className="fs-6" />
              </button>
            </li>
            <li className="page-item ">
              <button
                className="btn btn-primary p-1 px-2 d-flex align-items-center"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <BiSolidChevronLeft className="fs-6 bi" />
                <span className="fs-6">{t("general_key_words.previous")}</span>
              </button>
            </li>
            <li className="page-item ">
              <button
                className="btn btn-primary p-1 px-2 d-flex align-items-center"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <BiSolidChevronRight className="fs-6 bi" />
                <span className="fs-6">{t("general_key_words.next")}</span>
              </button>
            </li>
            <li className="page-item ">
              <button
                className="btn btn-primary py-2 d-flex align-items-center"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <BiSolidChevronsRight className="fs-6" />
              </button>
            </li>
          </div>
        </ul>
      )}
      </div>
    </div>
  );
};

export default CollectedCashTable;
