import { http } from "../../../Utility/APIRequest";
import { t } from "i18next";
import toast from "react-hot-toast";
import { HandleHTTPRequestError } from "../../../Utility/HandleHTTPRequestError";
import {FetchError, FetchStart, FetchSuccess } from "../slice/userLogSlice";
import { showToast } from "../../Util/ToastFunction";


export const GetAllUsersLog = () => async (dispatch) => {
  // console.log("Role:")
  // console.log(role)
    dispatch(FetchStart());
    // http request to backend
    try {
      const response = await http.get("api/auth/users/log/get");
      // console.log("Response:");
      // console.log(response);
      if (response && response.status === 200) {
        showToast("success",t("toast.data_fetch"));
        dispatch(FetchSuccess(response.data));
        return response.data;
      } else {
        // Handle unexpected response status here
        dispatch(FetchError());
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      dispatch(FetchError());
      if (error.response) {
        HandleHTTPRequestError(error.response.status, error, dispatch);
      } else {
        // Handle the case where the API is offline or unreachable
        console.error("API is offline or unreachable.");
        showToast("success",t("toast.apiOffline"));
      }
    }
  };


  export const GetSuperUsersLog = () => async (dispatch) => {
    // console.log("Role:")
    // console.log(role)
      dispatch(FetchStart());
      // http request to backend
      try {
        const response = await http.get("api/auth/users/log/get/super");
        // console.log("Response:");
        // console.log(response);
        if (response && response.status === 200) {
          showToast("success",t("toast.data_fetch"));
          dispatch(FetchSuccess(response.data));
          return response.data;
        } else {
          // Handle unexpected response status here
          dispatch(FetchError());
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        dispatch(FetchError());
        if (error.response) {
          HandleHTTPRequestError(error.response.status, error, dispatch);
        } else {
          // Handle the case where the API is offline or unreachable
          console.error("API is offline or unreachable.");
          showToast("success",t("toast.apiOffline"));
        }
      }
    };

    export const GetAdminUsersLog = () => async (dispatch) => {
      // console.log("Role:")
      // console.log(role)
        dispatch(FetchStart());
        // http request to backend
        try {
          const response = await http.get("api/auth/users/log/get/admin");
          // console.log("Response:");
          // console.log(response);
          if (response && response.status === 200) {
            showToast("success",t("toast.data_fetch"));
            dispatch(FetchSuccess(response.data));
            return response.data;
          } else {
            // Handle unexpected response status here
            dispatch(FetchError());
            console.error("Unexpected response status:", response.status);
          }
        } catch (error) {
          dispatch(FetchError());
          if (error.response) {
            HandleHTTPRequestError(error.response.status, error, dispatch);
          } else {
            // Handle the case where the API is offline or unreachable
            console.error("API is offline or unreachable.");
            showToast("success",t("toast.apiOffline"));
          }
        }
      };

      export const GetUsersLog = () => async (dispatch) => {
          dispatch(FetchStart());
          // http request to backend
          try {
            const response = await http.get("api/auth/users/log/get/users");
            // console.log("Response:");
            // console.log(response);
            if (response && response.status === 200) {
              showToast("success",t("toast.data_fetch"));
              dispatch(FetchSuccess(response.data));
              return response.data;
            } else {
              // Handle unexpected response status here
              dispatch(FetchError());
              console.error("Unexpected response status:", response.status);
            }
          } catch (error) {
            dispatch(FetchError());
            if (error.response) {
              HandleHTTPRequestError(error.response.status, error, dispatch);
            } else {
              // Handle the case where the API is offline or unreachable
              console.error("API is offline or unreachable.");
              showToast("success",t("toast.apiOffline"));
            }
          }
        };


