import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: null,
  data: null,
};

const DashboardSlice = createSlice({
  name: "dash",
  initialState,
  reducers: {
    FetchStart: (state) => {
      state.loading = true;
      state.data = null;
    },
    FetchSuccess: (state,action) => {
      state.loading = false;
      state.data = action.payload;
    },
    FetchError: (state) => {
      state.loading = false;
      state.data = null;
    },
  },
});

export const {FetchError,FetchSuccess,FetchStart} = DashboardSlice.actions;
export default DashboardSlice.reducer;
