import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import PageHeader from "../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import LoadingAnimation from "../../shared/LoadingAnimation";
import NoContentsFound from "../../shared/NoContentsFound";

import CollectingCashTable from "./CollectingCashTable";

import { FetchCollectedInvoiceListByBranch, FetchCollectedInvoices } from "../../redux/InvoicesSection/FetchCollectedInvoices";
import CollectedCashTable from "./CollectedCashTable";
import { useParams } from "react-router-dom";
export default function CollectedInvoicesList(props) {
  // sendSMS();
  const { t } = useTranslation("translation");
  const data = useSelector((state) => state.invoice.data);
  const CarsData = useSelector((state) => state.invoice);
  const loading = useSelector((state) => state.invoice.loading);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);
  const {id} = useParams();
  const type = props.type;
  useEffect(() => {
    if(type==="receivedInvoiceByBranch"){
      dispatch(FetchCollectedInvoiceListByBranch(token,id));
    }else{
      dispatch(FetchCollectedInvoices(token));
    }
  }, []);


  // console.log("Cash collection data:");
  // console.log(CarsData);
  return (
    <div>
      <div className="mt-2">
        {/* <FormHeader province={"Kabul"} /> */}
        <PageHeader title={t("invoice.allApprovedInvoice")} />
      </div>

      {loading ? (
        <LoadingAnimation />
      ) : data && data.length>0 ? (
        // <DriverTabularView data={data} />
        // <CarTable carsData={CarsData} />
        <CollectedCashTable carsData={CarsData} recordType={t("vehicle_details_form.collectedGroupInvoiceList")} />
      ) : (
        // <DriverTabularView data={data} options={true}/>
        <NoContentsFound message={t("general_key_words.dataNotFound")} />
      )}
    </div>
  );
}
