import React, { useEffect, useState } from "react";
import PageHeader from "../../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import RegistrationForm from "../../../auth/RegistrationForm";
import UsersTable from "./UsersTable";
import UsersPageHeader from "./UsersPageHeader";
import { FetchUsers } from "../../../redux/authentication/FetchUsers";
import LoadingAnimation from "../../../shared/LoadingAnimation";
import { CancelUpdate } from "../../../redux/authentication/AuthenticationSlice";
import { t } from "i18next";

const Users = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);
  const data = useSelector((state) => state.authentication.data);
  const loading = useSelector((state) => state.authentication.loading);
  const update = useSelector((state) => state.authentication.update);
  const [form, setForm] = useState(false);
  useEffect(() => {
    dispatch(FetchUsers(token));
    dispatch(CancelUpdate())
  }, []);
  return (
    <div>
      <UsersPageHeader title={t("setting.userList")} setForm={setForm} update={update} form={form} />
      {update && (
        <div className="mt-3 text-center alert alert-success ">
          <span className="fw-bold">{update.email}</span> has been selected for update
        </div>
      )}
      <div className="mt-2 bg-white rounded-3">
        {form && (
          <div className="p-3">
            <RegistrationForm update={update} />
          </div>
        )}
      </div>

      <div className="mt-2 bg-white rounded-3 p-3">
        {loading ? (
          <LoadingAnimation />
        ) : (
          data && <UsersTable setForm={setForm} data={data} />
        )}
      </div>
    </div>
  );
};

export default Users;
