import { t } from "i18next";

export const SearchTypes = [
  // registration links
  {
    id: "registration_all",
    value: `${t('general_key_words.registration')} - ${t("vehicle_details_form.allRegisteredVehicle")}`,
  },

  {
    id: "registration_waiting_color",
    value: `${t('general_key_words.registration')} - ${t("vehicle_details_form.inWaiting")}`,
  },
 
  {
    id: "registration_pending",
    value: `${t('general_key_words.registration')} - ${t("general_key_words.pending")}`,
  },

  {
    id: "registration_rejected",
    value: `${t('general_key_words.registration')} - ${t("general_key_words.rejected")}`,
  },

  {
    id: "registration_control",
    value: `${t('general_key_words.registration')} - ${t("vehicle_details_form.inContrl")}`,
  },

  {
    id: "registration_completed",
    value: `${t('general_key_words.registration')} - ${t("general_key_words.completedList")}`,
  },
  //   waiting links
  {
    id: "waiting_all",
    value: `${t('general_key_words.wait')} - ${t("dashboard_menu.waiting_list_link")}`,
  },
  //   controls links
  {
    id: "control_all",
    value: `${t('general_key_words.control')} - ${t("traffic_control.allControlledVehicle")}`,
  },
  {
    id: "control_approved",
    value: `${t('general_key_words.control')} - ${t("dash_board_card.approvedControls")}`,
  },
  {
    id: "control_pending",
    value: `${t('general_key_words.control')} - ${t("general_key_words.pending")}`,
  },
  //   invoices links
  {
    id: "invoices_pending",
    value: `${t('general_key_words.invoice')} - ${t("dash_board_card.pendingInvoice")}`,
  },
  {
    id: "invoices_generated",
    value: `${t('general_key_words.invoice')} - ${t("invoice.generatedInvoice")}`,
  },
  {
    id: "invoices_received",
    value: `${t('general_key_words.invoice')} - ${t("dash_board_card.receivedInvoice")}`,
  },
];
