import { t } from "i18next";
import React, { useMemo, useState, useRef } from "react";
import { useTable, useSortBy, usePagination } from "react-table"; // Import useSortBy hook

import jsPDF from "jspdf";
import "jspdf-autotable";

import { useDownloadExcel } from "react-export-table-to-excel";
import { FaDownload, FaFileExcel, FaFilePdf } from "react-icons/fa";
import jalaliMoment from "jalali-moment";
import AllControlOptionButton from "./AllControlOptionButton";
import {
  BiSolidChevronLeft,
  BiSolidChevronRight,
  BiSolidChevronsLeft,
  BiSolidChevronsRight,
} from "react-icons/bi";
import { FILES_BASE_URL } from "../../Utility/Domain";

// import "jspdf-autotable/dist/polyfills.js";
// import "jspdf-autotable/dist/jspdf.plugin.autotable.js";

const ControRejectedTable = ({ carsData,recordType }) => {

    const downloadTechFile = (fileName) => {
        if (fileName) {
          window.open(`${FILES_BASE_URL}/control_files/${fileName}`, "_blank");
        }
      };

  const [filterValue, setFilterValue] = useState(""); // State variable for filtering

//   console.log("Controon
  const driver = [];
  carsData.data.map((item, index) => [
    // console.log("item:"),
    driver.push(item.driver),
    // console.log(item.driver)
  ]);
  // console.log("Drivers:");
  // console.log(driver);

  const data = useMemo(() => {
    if (!carsData || !carsData.data) {
      return [];
    }
    // Apply filtering based on the filterValue
    return carsData.data.filter((row) => {
      // return driver.filter((row) => {
      return Object.values(row.driver).some((value) =>
        value
          ? value.toString().toLowerCase().includes(filterValue.toLowerCase())
          : false
      );
    });
  }, [carsData, filterValue]);

  // console.log("Filter Value:");
  // console.log(filterValue);
  const firstName = "driver.firstName";
  const columns = useMemo(
    () => [
      // {
      //   Header: t("vehicle_table.serial_number"),
      //   accessor: "id",
      // },
      {
        Header: t("vehicle_table.serial_number"),
        accessor: (row, index) => index + 1, // Generate sequential numbers
      },
      {
        Header: t("vehicle_details_form.firstName"),
        // accessor: "driver.firstName",
        accessor: "driver.firstName",
      },

      {
        Header: t("vehicle_table.driver_nid"),
        accessor: "driver.nid",
      },
      {
        Header: t("vehicle_details_form.carNumPlat"),
        accessor: "driver.carNumPlat",
        // Enable sorting for this column
        canSort: true,
      },
      {
        Header: t("vehicle_details_form.phone"),
        accessor: "driver.phone",
      },
      
      {
        Header: t("vehicle_table.date"),
        accessor: "Per_date",
        // Enable sorting for this column
        canSort: true,
      },
      {
        Header: t("vehicle_table.company_agent_name"),
        accessor: "company_agent_name",
      },
      {
        Header: t("vehicle_table.traffic_agent_name"),
        accessor: "traffic_agent_name",
      },
      {
        Header: t("traffic_control.controlStatus"),
        // accessor: "control_status",
        accessor: (row) =>
          row.control_status === "rejected"
          ? t("vehicle_details_form.rejectControl")
          : t("Null"),
      },
      {
        Header: t("traffic_control.rejectedFile"),
        accessor: "approve_file",
        Cell: ({ value }) => {
            if (value) {
              // Display "View File" when tarifa_file exists
              return <span className="btn btn-secondary" title={t("invoice.dawnload")}
              onClick={(fileName) => downloadTechFile(value)}
              >
                {/* {t("invoice.dawnload")} */}
              <FaDownload className="bi" />
              </span>;
            } else {
              // Display "No File Attached" when tarifa_file is null or empty
              // return t("general_key_words.noFileAttached");
              return <span className="fw-bold btn btn-danger" title={t("general_key_words.noFileAttached")}>X</span>;
            }
          },
      },
      {
        Header: t("vehicle_table.options_button"),
        id: "optionsButton", // Add a unique id for this column
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },

    useSortBy, // Enable sorting
    usePagination
  );

  //downlaod excel sheet:
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: t("excelTable.waitingVehicleTable"),
    sheet: t("excelTable.waitingVehicleSheet"),
  });

  const handleExportToExcel = () => {
    const exportColumns = columns.filter(
      (column) => column.id !== "optionsButton"
    );
    const exportData = page.map((row) => {
      const rowData = {};
      exportColumns.forEach((column) => {
        rowData[column.Header] = row.values[column.accessor];
      });
      return rowData;
    });
    onDownload(exportData, exportColumns);
  };

  //download to pdf:
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3, or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Driver Table";
    const headers = [
      [
        t("Name"),
        t("Father Name"),
        t("NID"),
        t("Phone#"),
        t("Driving Permit"),
        t("Car Num Plat"),
        t("Province"),
      ],
    ];

    const data = page.map((row) => [
      row.original.firstName,
      row.original.fatherName,
      row.original.nid,
      row.original.phone,
      row.original.drivingPermit,
      row.original.carNumPlat,
      row.original.province,
    ]);

    doc.text(title, marginLeft, 40);
    doc.autoTable({
      startY: 50,
      head: headers,
      body: data,
    });

    doc.save("report.pdf");
  };

  return (
    <div>
      <div className="row mt-2 mb-2">
        <div className="col-sm-12 col-md-8">
          <input
            type="text"
            className="form-control"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            placeholder={t("general_key_words.search")}
          />
        </div>
        <div className="col-sm-12 col-md-4">
          <div className="bg-white rounded-2 mt-2 mt-lg-0 mb-0 d-flex flex-row align-items-center justify-content-between">
            <div onClick={handleExportToExcel}>
              <FaFileExcel
                style={{ color: "green", cursor: "pointer", fontSize: "25px" }}
              />
            </div>
            <div className="d-flex flex-row align-items-center">
              <span className="mx-2">{t("general_key_words.show")}</span>
              <select
                value={pageSize}
                className="form-select"
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 50, 100, 200, 400, 800, 1600].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table
          className="table table-hover"
          id="property-table"
          {...getTableProps()}
          border="1"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key="header" className="text-center">
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="table-header-style"
                  >
                    {column.render("Header")}
                    {/* Display sorting indicators */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.original.id} className="text-center">
                  {row.cells.map((cell, index) => {
                    //   return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                    return (
                      <td {...cell.getCellProps()}
                     className="table-cells-style" 
                      >
                        {index === row.cells.length - 1 ? (
                          // <ControlOptionButton
                          <AllControlOptionButton
                            record={row.original}
                            section={"driver"}
                          />
                        ) : // cell.column.id === 'regGregorianDate' ? (
                        cell.column.id === "gre_date" ? (
                        //   jalaliMoment(new Date(cell.value).toDateString())
                        //     .locale("fa")
                        //     .format("YYYY-MM-DD")
                        null
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <table
          className="table d-none"
          id="property-table"
          ref={tableRef}
          {...getTableProps()}
          border="1"
        >
          <caption style={{ textAlign: "center", fontSize: "20px", fontWeight: "bold" }}>
            <br/>
            {t("message.brand")} <br /> {recordType}
            ({jalaliMoment(new Date().toDateString()).locale("fa").format("DD-MM-YYYY")})
          </caption>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key="header" className="text-center">
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="table-header-style" 
                  >
                    {column.render("Header")}
                    {/* Display sorting indicators */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.original.id} className="text-center">
                  {row.cells.map((cell, index) => {
                    //   return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                    return (
                      <td {...cell.getCellProps()}
                      className="table-cells-style" 
                      >
                        {index === row.cells.length - 1 ? (
                          // <ControlOptionButton
                          // <AllControlOptionButton
                          //   record={row.original}
                          //   section={"driver"}
                          // />
                          null
                        ) : // cell.column.id === 'regGregorianDate' ? (
                        cell.column.id === "gre_date" ? (
                          jalaliMoment(new Date(cell.value).toDateString())
                            .locale("fa")
                            .format("YYYY-MM-DD")
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}

          <tr className="border-0">
            <td className="border-0" colSpan={9}>
              <hr />
            </td>
          </tr>
          <tr className="border-0">
            <td className="border-0" colSpan={4}>
              <u>{t("general_key_words.preperdBy")}</u>
            </td>
            <td className="border-0" colSpan={5}>
              <u>{t("general_key_words.approvedBy")}</u>
            </td>
          </tr>
          <tr className="border-0">
            <td className="border-0" colSpan={9}></td>
          </tr>
          </tbody>
        </table>
      {pageCount > 1 && (
        <ul className="pagination bg-white py-2 rounded-3 d-flex flex-row justify-content-between px-2 mt-2">
          <li className="page-item ">
            <button className="btn btn-light p-0 p-1" disabled>
              {t("general_key_words.page")}{" "}
              <strong>
                {pageIndex + 1} / {pageCount}
              </strong>{" "}
            </button>
          </li>
          <div className="d-flex flex-row gap-1">
            <li className="page-item ">
              <button
                className="btn btn-primary py-2 d-flex align-items-center"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <BiSolidChevronsLeft className="fs-6" />
              </button>
            </li>
            <li className="page-item ">
              <button
                className="btn btn-primary p-1 px-2 d-flex align-items-center"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <BiSolidChevronLeft className="fs-6 bi" />
                <span className="fs-6">{t("general_key_words.previous")}</span>
              </button>
            </li>
            <li className="page-item ">
              <button
                className="btn btn-primary p-1 px-2 d-flex align-items-center"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <BiSolidChevronRight className="fs-6 bi" />
                <span className="fs-6">{t("general_key_words.next")}</span>
              </button>
            </li>
            <li className="page-item ">
              <button
                className="btn btn-primary py-2 d-flex align-items-center"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <BiSolidChevronsRight className="fs-6" />
              </button>
            </li>
          </div>
        </ul>
      )}
    </div>
  );
};

export default ControRejectedTable;
