import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";

import {
  HandleProgress,
  LogOut,
  UploadError,
  UploadStart,
} from "./AuthenticationSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";
import Cookies from "js-cookie";
import { ClearSession } from "../session/slice/SessionSlice";

export const UpdateUserPassword =
  (data, token) => async (dispatch, getState) => {
    dispatch(UploadStart());
    // http request to backend
    try {
      const formData = new FormData();

      formData.append("old_password", data.oldPassword);
      formData.append("password", data.password);

      const user = getState().authentication.user;
      const response = await http.post(
        `api/auth/password/update/${user.id}`,
        formData,
        {
          headers: { Authorization: `Bearer ${token }` },
          onUploadProgress: (progressEvent) => {
            const progress = Math.floor(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            dispatch(HandleProgress(progress));
            // console.log(progress);
          },
        }
      );
      // localStorage.removeItem("user");
      // localStorage.removeItem("token");

      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      

      if(Cookies.get("userTokenCookie")){
        Cookies.remove("userTokenCookie");
        Cookies.remove("userDataCookie");
      }

      toast.success("user password updated successfully");
      dispatch(ClearSession())
      dispatch(LogOut());
    } catch (error) {
      if (error.response.status === 422) {
        dispatch(UploadError(error.response.data.errors));
      }
      if (error.response) {
        HandleHTTPRequestError(error.response.status, error, dispatch);
      } else {
        // Handle the case where the API is offline or unreachable
        console.error("API is offline or unreachable.");
          toast.error(t("toast.apiOffline"));
      }
    }
  };
