//Development:
// export const API_BASE_URL = 'http://localhost:8000/';
// export const API_Image_BASE_URL = 'http://localhost:8000/';
// export const FILES_BASE_URL = "http://localhost:8000/public/storage/driver";
// export const FRONT_BASE_URL = "http://localhost:3000/";


//Deployment(Afgsoft):
// export const API_BASE_URL = 'https://api.afgsoft.net/public/';
// export const API_Image_BASE_URL = 'https://api.afgsoft.net/public/';
// export const FILES_BASE_URL = "https://api.afgsoft.net/public/storage/driver";
// export const FRONT_BASE_URL = "https://afgsoft.net/";

//Deployment(Afgsoft+static):
export const API_BASE_URL = 'https://api.static-holding.com/public/';
export const API_Image_BASE_URL = 'https://api.static-holding.com/public/';
export const FILES_BASE_URL = "https://api.static-holding.com/public/storage/driver";
export const FRONT_BASE_URL = "https://afgsoft.net/";

//Deployment(STATIC):
// export const API_BASE_URL = 'https://api.static-holding.com/public/';
// export const API_Image_BASE_URL = 'https://api.static-holding.com/public/';
// export const FILES_BASE_URL = "https://api.static-holding.com/public/storage/driver";
// export const FRONT_BASE_URL = "https://static-holding.com/";



// export const FRONT_BASE_URL = "https://traffic.afgsoft.net/";