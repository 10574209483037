import React from 'react'
import { useSelector } from 'react-redux'

const MessageTextArea = ({driver,defaultMessage,message,setMessage}) => {

  return (
    <textarea className="form-control" onChange={e=> setMessage(e.target.value)} rows={12} defaultValue={defaultMessage}>
    </textarea>
  )
}

export default MessageTextArea