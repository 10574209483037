import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import PageHeader from "../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import LoadingAnimation from "../../shared/LoadingAnimation";
import NoContentsFound from "../../shared/NoContentsFound";
import { FetchBranchData } from "../../redux/branchSection/actions/FetchBranchData";
import BranchTable from "./BranchTable";
import TitlePanelGeneral from "../../shared/TitlePanelGeneral";
import { useState } from "react";

import {motion} from "framer-motion";
import BranchRegistration from "./BranchRegistration";

export default function BranchList() {
  const [isForm, setIsForm] = useState(false);
  // sendSMS();
  const { t } = useTranslation("translation");
  const data = useSelector((state) => state.branch.data);
  const CarsData = useSelector((state) => state.branch);
  const loading = useSelector((state) => state.branch.loading);
  // const progress = useSelector((state) => state.vehicle.progress);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);

  useEffect(() => {
    // sendSMS();
    dispatch(FetchBranchData(token));
  }, []);
  // console.log("Vhicle:");
  // console.log(data);
  return (
    <div>
      <div className="mt-2">
        {/* <FormHeader province={"Kabul"} /> */}
        <PageHeader title={t("branch.branchListTitle")} />
      </div>
      {loading ? (
        <>
        <LoadingAnimation />
        </>
        
        
      ) : data && data.length>0? (
        <>
        <TitlePanelGeneral
        title={t("vehicle_details_form.registerForm")}
        isForm={isForm}
        buttonText={t("branch.addBranch")}
        setIsForm={setIsForm}
        />
        {isForm && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="bg-light p-3"
        >
        <BranchRegistration />
        </motion.div>
      )}
      <BranchTable carsData={CarsData} recordType={t("vehicle_details_form.branchList")} />
        {/* // <DriverTabularView data={data} options={true}/> */}
        </>
        
      ) : (
        <NoContentsFound message={"No data was found on server"} />
      )}
    </div>
  );
}
