import React from "react";
import Image from "../../../Media/profile.png";
import { FaEdit, FaTrash } from "react-icons/fa";
import { StartUpdate } from "../../../redux/authentication/AuthenticationSlice";
import { useDispatch } from "react-redux";
import { t } from "i18next";
const UsersTable = ({ data,setForm }) => {
    const dispatch = useDispatch();

    // console.log("showing branches data")
    // console.log(data);
  const handleRecordUpdate = (record) => {
    dispatch(StartUpdate(record))
    setForm(true)
  };
  return (
    <div className="row">
      {data.map((user) => (
        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
          <div className="card text-center border-1 shadow-sm">
            <div className="card-header">{user.role}</div>
            <div className="card-body">
              <img
                src={Image}
                alt="user profile image"
                className="rounded-circle border my-2"
                width="100px"
                height="100px"
              />
              <h5 className="card-title text-capitalize">
                {user.firstName} {user.lastName}
              </h5>
              <p className="card-text fw-semibold text-decoration-underline">
                {user.email}
              </p>
              <div className="d-flex flex-row justify-content-between">
                <button className="btn btn-success btn-sm d-flex align-items-center gap-2" onClick={(e)=>handleRecordUpdate(user)}>
                  <FaEdit />
                  {t("general_key_words.edit")}
                </button>
                <button
                  className="btn btn-danger btn-sm d-flex align-items-center gap-2"
                  disabled
                >
                  <FaTrash />
                  {t("general_key_words.remove")}
                </button>
              </div>
            </div>
            <div className="card-footer text-body-secondary">{user.branches.branchName}</div>
          </div>
        </div>
      ))}
    </div>
  );

  //   return (
  //     data.map((user)=> (
  // <div>{user.email}</div>
  //     ))
  //   )
};

export default UsersTable;
