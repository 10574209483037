import React from "react";
import Logo1 from "../../Media/Company.jpg";
import Logo from "../../Media/Company2.jpg";
import { useDispatch, useSelector } from "react-redux";
import NoContentsFound from "../../shared/NoContentsFound";
import { useEffect } from "react";
import LoadingAnimation from "../../shared/LoadingAnimation";
import { useParams } from "react-router-dom";
import { FetchReprintInvoiceData } from "../../redux/InvoicesSection/FetchReprintInvoiceData";
import { useTranslation } from "react-i18next";
import jalaliMoment from "jalali-moment";
import { t } from "i18next";
const RePrintInvoice = () => {
  const token = useSelector((state) => state.authentication.token);
  const invoice = useSelector((state) => state.invoice.invoice);
  const loading = useSelector((state) => state.invoice.loading);

  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(FetchReprintInvoiceData(token, id));
    }
  }, []);

  const handleDriver = (id) => {
    window.open(`/view-list/view/${id}`, "_blank");
  };
  return loading ? (
    <LoadingAnimation />
  ) : invoice ? (
    <div className="row">
      <div className="col-12">
        <div className="d-flex flex-row justify-content-between align-items-center px-lg-5 px-3 bg-dark text-white">
          <div>
            <img
              src={Logo}
              alt="website logo"
              // className="w-50 h-100"
              width="250px"
              height="102px"
            />
          </div>
          <div className="">
            <div className="d-flex flex-row align-items-center  gap-3" dir="ltr">
              <h4 className=" align-self-end pb-1">{id && id}</h4>
              <div>
                <h1 className="display-4 fw-bold">
                  {t("invoice.invoiceNumber")}
                </h1>
              </div>
            </div>
            <h6 className="text-danger text-end">
              {t("general_key_words.date")} :{" "}
              {invoice &&
                jalaliMoment(new Date().toISOString())
                  .locale("fa")
                  .format("YYYY-MM-DD")}
            </h6>
          </div>
        </div>
        <div className=" p-3 px-lg-5">
          <h5 className="fw-semibold">{t("invoicePrint.agentDetails")}</h5>

          <div className="row">
            <div className="col-sm-8">
              <div className="d-flex flex-row align-items-center py-2">
                <span className="">{t("invoicePrint.company_label")}:</span>
                <span className="mx-3 fw-semibold">{t("message.brand")}</span>
              </div>
              <div className="d-flex flex-row align-items-center py-2">
                <span className="">{t("vehicle_details_form.phone")}:</span>
                {/* <span className="mx-3 fw-semibold">{t("invoicePrint.phone")}</span> */}
                <span className="mx-3 fw-semibold">{t("0700180006")}</span>
                {/* <span className="mx-3 fw-semibold">{t("0772709870")}</span> */}
              </div>
              <div className="d-flex flex-row align-items-center py-2">
                <span className="">{t("setting.email")}:</span>
                <span className="mx-3 fw-semibold">
                  {/* {t("invoicePrint.email")} */}
                  {t("staticcompany9@gmail.com")}
                  {/* {t("mearajuddn.haidary@gmail.com")} */}
                </span>
              </div>
              {/* <div className="d-flex flex-row align-items-center py-2">
                <span className="">{t("invoicePrint.website")}:</span>
                <span className="mx-3 fw-semibold">
                {t("invoicePrint.websiteUrl")}
                </span>
              </div> */}
            </div>
            <div className="col-sm-4">
              <div className="d-flex flex-row align-items-center py-2">
                <span className="">{t("invoicePrint.address")}:</span>
                <span className="mx-3 fw-semibold">
                {t("invoicePrint.mainAddress")}
                </span>
              </div>
              <div className="d-flex flex-row align-items-center py-2">
                <span className="">{t("invoicePrint.state")}:</span>
                <span className="mx-3 fw-semibold">
                {t("invoicePrint.stateAddress")}
                </span>
              </div>
              <div className="d-flex flex-row align-items-center py-2">
                <span className="">{t("invoicePrint.zip_code")}:</span>
                <span className="mx-3 fw-semibold">
                {t("invoicePrint.zipAddress")}
                </span>
              </div>
              {/* <div className="d-flex flex-row align-items-center py-2">
                <span className="">
                  Website
                </span>
                <span className="mx-3 fw-semibold">https://static.af.com</span>
              </div> */}
            </div>
          </div>
        </div>

        <hr className="" />

        <div className="row p-3 px-lg-5">
          <div className="col-md-6">
            <h5 className="fw-semibold">{t("general_key_words.clientInfo")}</h5>
          </div>
        </div>

        <div className="row px-lg-5">
          <div className="col-md-12">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="border-0 text-uppercase small font-weight-bold">
                    {t("vehicle_table.serial_number")}
                  </th>
                  <th className="border-0 text-uppercase small font-weight-bold">
                    {t("vehicle_table.driver_name")}
                  </th>
                  <th className="border-0 text-uppercase small font-weight-bold">
                    {t("vehicle_table.driver_father_name")}
                  </th>
                  <th className="border-0 text-uppercase small font-weight-bold">
                    {t("vehicle_table.driver_nid")}
                  </th>
                  <th className="border-0 text-uppercase small font-weight-bold">
                    {t("vehicle_details_form.carNumPlat")}
                  </th>
                  <th className="border-0 text-uppercase small font-weight-bold">
                    {t("vehicle_details_form.techContNum")}
                  </th>
                  <th className="border-0 text-uppercase small font-weight-bold">
                    {t("vehicle_details_form.commissionNum")}
                  </th>
                  <th className="border-0 text-uppercase small font-weight-bold">
                    {t("vehicle_details_form.province")}
                  </th>
                  <th className="border-0 text-uppercase small font-weight-bold">
                    {t("invoice.warrantyTime")}
                  </th>

                  <th className="border-0 text-uppercase small font-weight-bold">
                    {t("invoice.price")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <LoadingAnimation />
                ) : (
                  invoice &&
                  invoice.map((item, index) => (
                    <tr key={index}>
                      <td>{++index}</td>
                      <td
                        className="cursor-pointer hover-link"
                        onClick={(e) => handleDriver(item.driver.id)}
                      >
                        {item.driver.firstName}
                      </td>
                      <td>{item.driver.fatherName}</td>
                      <td>{item.driver.nid}</td>
                      <td>{item.driver.carNumPlat}</td>
                      <td>{item.driver.techContNum}</td>
                      <td>{item.driver.commissionNum}</td>
                      <td>{item.driver.province}</td>
                      <td>{item.warranty_time}</td>
                      {/* <td>{item.price} <span className="text-success">AFS</span></td> */}
                      <td>
                        {item.price}
                        <span className="text-success">AFS</span>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="d-flex flex-row-reverse bg-dark text-white p-4">
          <div className="py-3 px-5 text-right">
            <div className="mb-2">{t("general_key_words.grantTotal")}</div>
            <div className="h2 font-weight-light">
              {invoice &&
                invoice.reduce(
                  (total, item) => total + parseFloat(item.price),
                  0
                )}
              <span className="text-success"> AFS</span>
            </div>
          </div>

          {/* <div className="py-3 px-5 text-right">
                <div className="mb-2">Discount</div>
                <div className="h2 font-weight-light">10%</div>
              </div>

              <div className="py-3 px-5 text-right">
                <div className="mb-2">Sub - Total amount</div>
                <div className="h2 font-weight-light">$32,432</div>
              </div> */}
        </div>
      </div>
    </div>
  ) : (
    <NoContentsFound message={"No records were selected for invoice"} />
  );
};

export default RePrintInvoice;
