import React, { useEffect } from "react";
import { BsCheckCircle } from "react-icons/bs";
import Profile from "../../Media/profile.png";
import { Driver } from "../../Data/Driver";
import { useDispatch, useSelector } from "react-redux";
import { FetchRecordID } from "../../redux/vehicleRegistration/FetchRecordID";
import LoadingAnimation from "../../shared/LoadingAnimation";
import { t } from "i18next";
const DriverDetails = ({ id }) => {
  const loading = useSelector((state) => state.vehicle.loading);
  const record = useSelector((state) => state.vehicle.record);
  const token = useSelector((state) => state.authentication.token);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(FetchRecordID(token, id));
  }, []);

  return loading ? (
    <LoadingAnimation />
  ) : record&&(
    <div className="mt-3">
      <div className="position-relative p-3 text-center text-muted bg-body border border-dashed rounded-3">
        {/* <button
          type="button"
          className="position-absolute top-0 end-0 p-3 m-5 btn-close bg-secondary bg-opacity-50 rounded-pill"
          aria-label="Close"
        ></button> */}
        {/* <div className="rounded-3 p-3 bg-light d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-md-start">
          <img src={Profile} className="profile-image rounded-circle shadow" />
          <h3 class="text-body-emphasis mt-3 text-capitalize mx-5">
            {record.firstName}
          </h3>
        </div> */}
        <h5 className="text-center mt-0">{t("vehicle_details_form.driverInfo")}</h5>
        <hr/>
        <div className="row mt-3">
          <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted"> {t("vehicle_details_form.firstName")}:</span>
              <span className="text-black fw-semibold">
              {record.firstName}
              </span>
            </div>
          </div>

          
          <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">{t("vehicle_details_form.fatherName")}:</span>
              <span className="text-black fw-semibold">
                {record.fatherName}
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">{t("vehicle_details_form.nid")}:</span>
              <span className="text-black fw-semibold">{record.nid}</span>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">{t("vehicle_details_form.phone")}:</span>
              <span className="text-black fw-semibold">{record.phone}</span>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">{t("vehicle_details_form.province")}:</span>
              <span className="text-black fw-semibold">{record.province}</span>
            </div>
          </div>
          {/* <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">Activity Type:</span>
              <span className="text-black fw-semibold">
                {record.activityType}
              </span>
            </div>
          </div> */}
          <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">{t("general_key_words.registrationDate")}:</span>
              <span className="text-black fw-semibold">{record.regGregorianDate}</span>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">{t("vehicle_details_form.carType")}:</span>
              <span className="text-black fw-semibold">{record.carType}</span>
            </div>
          </div>
          {record.carNumPlat ? 
          <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">{t("vehicle_details_form.carNumPlat")}:</span>
              <span className="text-black fw-semibold">
                {record.carNumPlat}
              </span>
            </div>
          </div>
          :
          <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">{t("vehicle_details_form.maktobNumber")}:</span>
              <span className="text-black fw-semibold">
              {record.maktoob_number ? record.maktoob_number : t("vehicle_details_form.na")}
              </span>
            </div>
          </div>
          }
                   
          {/* <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">Chassis Number:</span>
              <span className="text-black fw-semibold">
                {record.carChassis}
              </span>
            </div>
          </div> */}
          <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">{t("vehicle_details_form.drivingPermit")}:</span>
              <span className="text-black fw-semibold">
                {record.drivingPermit}
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">{t("vehicle_table.work_status")}:</span>
              <span className="text-black fw-semibold">
              {record.control_status === "pending"? t("vehicle_details_form.inContrl"):""}  
              </span>
            </div>
          </div>
          {/* <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">{t("vehicle_details_form.workStatus")}:</span>
              <span className="text-black fw-semibold">{record.workStatus}</span>
            </div>
          </div> */}
          <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">{t("vehicle_details_form.carModel")}:</span>
              <span className="text-black fw-semibold">
                {record.carModel}
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">{t("vehicle_details_form.techContNum")}:</span>
              <span className="text-black fw-semibold">
                {record.techContNum}
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">{t("invoice.tarifaNumber")}:</span>
              <span className="text-black fw-semibold">
                {record.tarifa_number}
              </span>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">{t("vehicle_table.paintArea")}:</span>
              <span className="text-black fw-semibold text-success">
                  {record.color_area === "town"
                      ? t("vehicle_table.town")
                      : t("vehicle_table.suburb")}
              </span>
            </div>
          </div>

          
          <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
            <div className=" d-flex align-items-center gap-2">
              <span className="text-muted">{t("vehicle_details_form.commissionNum")}:</span>
              <span className="text-black fw-semibold">
                {record.commissionNum}
              </span>
            </div>
          </div>
        </div>
        {/* <button class="btn btn-primary px-5 mb-5" type="button">
          Call to action
        </button> */}
      </div>
    </div>
  );
};

export default DriverDetails;
