import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Pashto from "./pashto.json";
import English from "./English.json";
import Farsi from "./Farsi.json";

const initiallanguage = localStorage.getItem("selectedLanguage") || "en";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: English,
      },
      ar: {
        translation: Pashto,
      },
      fa: {
        translation: Farsi,
      },
    },
    lng: initiallanguage,
    fallbackLng: "ar",
  });
export default i18n;
