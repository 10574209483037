import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import { MoveError, MoveStart, MoveSuccess } from "./WaitSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const RejectWaitingRecord =
  (token, id, section) => async (dispatch, getState) => {
    dispatch(MoveStart(id));
    // http request to backend
    try {
      const data = {
        reject_date: new Date().toDateString(),
      };
      const response = await http.post(
        `api/driver/waiting/reject/${id}`,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const currentState = getState();
      const updatedData = currentState.wait.data.filter(
        (record) => record.id !== id
      );

      toast.success(t("toast.data_reject"));
      dispatch(MoveSuccess(updatedData));
    } catch (error) {
      dispatch(MoveError());
      if (error.response) {
        HandleHTTPRequestError(error.response.status, error, dispatch);
      } else {
        // Handle the case where the API is offline or unreachable
        console.error("API is offline or unreachable.");
          toast.error(t("toast.apiOffline"));
      }
    }
  };
