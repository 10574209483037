import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import { MoveSuccess, MoveError, MoveStart } from "./InvoiceSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const InvoiceClearance = (token,data) => async (dispatch,getState) => {
  dispatch(MoveStart());
  // http request to backend
  try {
    const DataToSend = [];
    data.forEach(element => {
          
        DataToSend.push({
            warranty_time: "2 Years",
            // lot_number : "100-01",
            invoice_status:"generated",
            driver_id : parseInt(element.driver_id),
            invoice_id: parseInt(element.invoice_id),
            gre_date: new Date().toDateString()
        })
    });
    const response = await http.post("api/invoice", DataToSend,{
      headers: { Authorization: `Bearer ${token}` },
    });
    // const existingRecords = getState().invoice.data;
    // const removedRecords = response.data.records; // Assuming this contains the records that have been removed
    
    // // Filter out the records that still exist in the state
    // const newRecords = existingRecords.filter(existingRecord =>
    //   !removedRecords.some(removedRecord => removedRecord.id === existingRecord.id)
    // );

  //  console.log(response)
    toast.success("invoice has been paid successfully");
    dispatch(MoveSuccess(response.data.records));
  } catch (error) {
    // console.log(error)
    dispatch(MoveError());
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};
