import React, { useEffect, useState } from "react";
import PageHeader from "../../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import InlineLoadingAnimation from "../../../shared/InlineLoadingAnimation";
import { FetchBranchData } from "../../../redux/branchSection/actions/FetchBranchData";
import { GetBranchData } from "../../../redux/branchSection/actions/GetBranchData";
import LoadingAnimation from "../../../shared/LoadingAnimation";

const BranchData = () => {
  const [branch, setBranch] = useState("");
  const loading = useSelector((state) => state.branch.loading);
  const data = useSelector((state) => state.branch.data);
  const token = useSelector((state) => state.authentication.token);
  const dispatch = useDispatch();
  const branchData = useSelector((state) => state.branch.branchData);
  const inlineLoading = useSelector((state) => state.branch.inlineLoading);
  const handleChange = (e) => {
    const value = e.target.value;
    if (value) {
      setBranch(value);
    }
  };

  useEffect(() => {
    dispatch(FetchBranchData(token));
  }, []);
  useEffect(() => {
    // dispatch and get data
    if (branch) {
      dispatch(GetBranchData(token, branch));
    }
  }, [branch]);
  return (
    <div>
      <PageHeader title={"Branch Data"} />

      <div className="border mt-2 rounded-3">
        {loading ? (
          <InlineLoadingAnimation />
        ) : (
          <select
            id="branch"
            name="branch"
            onChange={handleChange}
            className="form-select"
          >
            <option value="">Choose...</option>
            {data &&
              data.map((item, index) => (
                <option value={item.id} key={index} className="text-capitalize">
                  {item.branchName}
                </option>
              ))}
          </select>
        )}
      </div>
      {inlineLoading ? <LoadingAnimation /> : branchData && branchData.map((item)=>(
        <div className="border">{item.firstName}</div>
      ))}
    </div>
  );
};

export default BranchData;
