import toast from "react-hot-toast";
import { http } from "../../../Utility/APIRequest"; 

import { t } from "i18next";
import { BranchError, BranchStart, BranchSuccess } from "../slice/branchSlice";
import { HandleHTTPRequestError } from "../../../Utility/HandleHTTPRequestError";

export const GetBranchData = (token, branch) => async (dispatch) => {
  dispatch(BranchStart());
  // console.log()
  // http request to backend
  try {
    const response = await http.post(`api/branches/search/${branch}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    // console.log(response.data)
    toast.success(t("toast.data_fetch"));
    dispatch(BranchSuccess(response.data.records));
  } catch (error) {
    dispatch(BranchError());
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};
