import { t } from "i18next";
import React, { useMemo, useState, useRef } from "react";
import { useTable, useSortBy, usePagination } from "react-table"; // Import useSortBy hook

import jsPDF from "jspdf";
import "jspdf-autotable";

import { useDownloadExcel } from "react-export-table-to-excel";
import { FaCheck, FaCheckDouble, FaCheckSquare, FaFileExcel, FaFilePdf } from "react-icons/fa";
import {IoMdClose} from "react-icons/io"
import OptionButton from "../../Utility/OptionButton";
import {
  BiCheckboxChecked,
  BiSolidChevronLeft,
  BiSolidChevronRight,
  BiSolidChevronsLeft,
  BiSolidChevronsRight,
} from "react-icons/bi";
import { BsChevronDoubleRight } from "react-icons/bs";

import Company from "../../Media/Company.jpg";
import toast from "react-hot-toast";
import { MdCancel, MdSend } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { AddToWaitingCollection } from "../../redux/vehicleRegistration/AddToWaitingCollection";
import Swal from "sweetalert2";

// import "jspdf-autotable/dist/polyfills.js";
// import "jspdf-autotable/dist/jspdf.plugin.autotable.js";

const CarTable4 = ({ carsData, recordType }) => {
  // console.log("CAr3:")
  // console.log(carsData)
  const [filterValue, setFilterValue] = useState(""); // State variable for filtering
  const [selectedItems, setSelectedItems] = useState([]);
  const [checkBox, setCheckBox] = useState(true);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);

  const user = useSelector((state) => state.authentication.user);
  
  const data = useMemo(() => {
    if (!carsData || !carsData.data) {
      return [];
    }

    // Apply filtering based on the filterValue
    return carsData.data.filter((row) => {
      return Object.values(row).some((value) =>
        value
          ? value.toString().toLowerCase().includes(filterValue.toLowerCase())
          : false
      );
    });
  }, [carsData, filterValue]);

  const columns = useMemo(
    () => [
      // {
      //   Header: t("vehicle_table.serial_number"),
      //   accessor: "id",
      // },
      {
        Header: t("vehicle_table.serial_number"),
        accessor: (row, index) => index + 1, // Generate sequential numbers
      },
      {
        Header: t("vehicle_table.driver_name"),
        accessor: "firstName",
      },
      {
        Header: t("vehicle_table.driver_father_name"),
        accessor: "fatherName",
      },

      {
        Header: t("vehicle_table.driver_nid"),
        accessor: "nid",
      },
      {
        Header: t("vehicle_table.province"),
        accessor: "province",
      },
      {
        Header: t("vehicle_table.date"),
        accessor: "regPersianDate",
      },
      {
        Header: t("vehicle_table.car_type"),
        accessor: "carType",
      },
      {
        Header: t("vehicle_details_form.maktobNumber"),
        accessor: (row) => row.maktoob_number || t("vehicle_details_form.na"),
      },
      {
        Header: t("vehicle_table.car_plate"),
        accessor: (row) => row.carNumPlat || t("vehicle_details_form.na"),
        // Enable sorting for this column
        canSort: true,
      },
      {
        Header: t("vehicle_table.work_status"),
        // accessor: "workStatus",
        accessor: (row) =>
          row.workStatus === "waiting"
            ? t("vehicle_details_form.inWaiting")
            : row.workStatus === "approved"
            ? t("vehicle_details_form.inContrl")
            : row.workStatus === "registration"
            ? t("vehicle_details_form.newRegister")
            : row.workStatus === "rejected"
            ? t("vehicle_details_form.rejectControl")
            : t("vehicle_details_form.complated"),
        // row.workStatus==="registration" ? t("ثبت سیستم") : "رد شده"
        // Enable sorting for this column
        canSort: true,
      },
      {
        Header: t("validation_errors.receipt_number_label"),
        accessor: "receipt_number",
        // Enable sorting for this column
        canSort: true,
      },
      {
        Header:  t("invoice.tarifaNumber"),
        accessor: "tarifa_number",
        // Enable sorting for this column
        canSort: true,
      },

      {
        Header: t("vehicle_table.options_button"),
        id: "optionsButton", // Add a unique id for this column
      },
      
      {
        Header: user.role !== "approver" ? t("general_key_words.choose") : "",
        id: "Check", // Add a unique id for this column
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },

    useSortBy, // Enable sorting
    usePagination
  );

  //downlaod excel sheet:
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: t("excelTable.driverTable"),
    sheet: t("excelTable.driverTableSheet"),
  });

  //   const handleExportToExcel = () => {
  //     const exportData = page.map((row) => {
  //       const rowData = {};
  //       columns.forEach((column) => {
  //         // rowData[column.Header] = row.values[column.accessor];
  //         if (column.id !== "optionsButton") { // Exclude the "optionsButton" column
  //             rowData[column.Header] = row.values[column.accessor];
  //           }
  //       });
  //       return rowData;
  //     });
  //     onDownload(exportData, columns);
  //   };

  const handleExportToExcel = () => {
    const exportColumns = columns.filter(
      (column) => column.id !== "optionsButton"
    );
    const exportData = page.map((row) => {
      const rowData = {};
      exportColumns.forEach((column) => {
        rowData[column.Header] = row.values[column.accessor];
      });
      return rowData;
    });
    onDownload(exportData, exportColumns);
  };

  //download to pdf:
  const exportPDF = () => {
    // console.log("PDF");
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3, or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Driver Table";
    const headers = [
      [
        t("Name"),
        t("Father Name"),
        t("NID"),
        t("Phone#"),
        t("Driving Permit"),
        t("Car Num Plat"),
        t("Province"),
      ],
    ];

    const data = page.map((row) => [
      row.original.firstName,
      row.original.fatherName,
      row.original.nid,
      row.original.phone,
      row.original.drivingPermit,
      row.original.carNumPlat,
      row.original.province,
    ]);

    doc.text(title, marginLeft, 40);
    doc.autoTable({
      startY: 50,
      head: headers,
      body: data,
    });

    doc.save("report.pdf");
  };
  const driverCheckHandler = (e, originalRow) => {
    // const driverId = originalRow.dId;
    const driverID = originalRow.id;
  
    setSelectedItems((prevSelectedItems) => {
      const isSelected = prevSelectedItems.some(
        (item) => item.id === driverID
      );
  
      if (isSelected) {
        return prevSelectedItems.filter(
          (item) => item.id !== driverID
        );
      } else {
        return [
          ...prevSelectedItems,
          { id: driverID ,  start_date : new Date().toDateString()},
        ];
      }
     
    });
  };
  const handleCheckBoxActivate = (e) => {
    setCheckBox(!checkBox);
    setSelectedItems([]);
  };

  const handleTransfer = () => {
    if (selectedItems.length > 0) {
      Swal.fire({
        title: t("general_key_words.areYouSure"),
        text: t("general_key_words.confirmChangeMsg"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("general_key_words.yesWaiting"),
        cancelButtonText: t("general_key_words.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(AddToWaitingCollection(token, selectedItems));
          handleCheckBoxActivate();
      // console.log(selectedItems)
        }
      });

    } else toast.error(t("general_key_words.noRecordSelect"));
    // handleCheckBoxActivate();
  };
  return (
    <div>
      {/* Input field for filtering */}
      {/* <input
        type="text"
        className="form-control m-0 rounded-3"
        value={filterValue}
        onChange={(e) => setFilterValue(e.target.value)}
        placeholder={t("general_key_words.search")}
      /> */}

      <div className="row mt-2 mb-2">
        <div className="col-sm-12 col-md-8">
          <input
            type="text"
            className="form-control"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            placeholder={t("general_key_words.search")}
          />
        </div>
        <div className="col-sm-12 col-md-4">
          <div className="bg-white rounded-2 mt-2 mt-lg-0 mb-0  d-flex flex-row align-items-center justify-content-between">
            <div onClick={handleExportToExcel}>
              <FaFileExcel
                style={{ color: "green", cursor: "pointer", fontSize: "25px" }}
              />
            </div>
            <div className="d-flex flex-row align-items-center">
              <span className="mx-2">{t("general_key_words.show")}</span>
              <select
                value={pageSize}
                className="form-select"
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 50, 100, 200, 400, 800, 1600].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
            {user.role !== "approver" && (
            <div className="mx-1 d-flex flex-row gap-1">
              <button
                className={`btn ${
                  checkBox ? "btn-secondary" : "btn-danger"
                }`}
                onClick={handleCheckBoxActivate}
              >
                {checkBox
                  ? <FaCheckDouble />
                  : <IoMdClose />
                }
              </button>
              {!checkBox && (
                <button
                  className={`btn btn-success d-flex gap-1 align-items-center`}
                  onClick={handleTransfer}
                >
                  {t("general_key_words.send")}
                  <MdSend className="bi"/>
                </button>
              )}
            </div>
            )}
          </div>
        </div>
      </div>

      {/* <div className="table-responsive" style={{minHeight:"90vh"}}> */}
      <div className="table-responsive">
        <table
          className="table table-hover"
          id="property-table"
          {...getTableProps()}
          border="1"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key="header" className="texrt-center bg-gray">
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="table-header-style"
                  >
                    {column.render("Header")}
                    {/* Display sorting indicators */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.original.id}>
                  {row.cells.map((cell, index) => {
                    //   return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                    return (
                      <td {...cell.getCellProps()}
                      className="table-cells-style"
                      >
                        {index === row.cells.length - 1 ? 
                        (row.original.workStatus === "registration" ||
                        row.original.workStatus === "rejected") && user.role !== "approver" ?
                        (
                          
                          <input
                            type="checkbox"
                            className="form-check-input"
                            disabled={checkBox}
                            id={row.original.id}
                            onChange={(e) =>
                              driverCheckHandler(e, row.original)
                            }
                            checked={selectedItems.some(
                              (item) => item.id === row.original.id
                            )}
                          />
                        ) : null : index === row.cells.length - 2 ? (
                          <OptionButton
                            record={row.original}
                            section={"driver"}
                          
                          />
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <table
        style={{ display: "none" }}
        className="table table-hover"
        id="property-table"
        ref={tableRef}
        {...getTableProps()}
        border="1"
      >
        <caption
          style={{ textAlign: "center", fontSize: "20px", fontWeight: "bold" }}
        >
           <br/>
          {t("message.brand")} <br /> {recordType}
          {/* =HYPERLINK("http://localhost:3000/Company.jpg", "Download Image") */}
        </caption>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key="header" className="text-center bg-green">
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}
                className="table-header-style"
                >
                  {column.render("Header")}
                  {/* Display sorting indicators */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              // <tr {...row.getRowProps()} key={row.original.id}>
              <tr {...row.getRowProps()} key={row.original.id}>
                {row.cells.map((cell, index) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                  //   return (
                  //     <td {...cell.getCellProps()}>
                  //      {index === row.cells.length -1 ? <OptionButton record={row.original} section={"driver"}/> :cell.render("Cell")}
                  // </td>
                  //   )
                })}
              </tr>
            );
          })}
          <tr className="border-0">
            <td className="border-0" colSpan={12}>
              <hr />
            </td>
          </tr>
          <tr className="border-0">
            <td className="border-0" colSpan={6}>
              <u>{t("general_key_words.preperdBy")}</u>
            </td>
            <td className="border-0" colSpan={6}>
              <u>{t("general_key_words.approvedBy")}</u>
            </td>
          </tr>
          <tr className="border-0">
            <td className="border-0" colSpan={12}></td>
          </tr>
        </tbody>
      </table>

      {pageCount > 1 && (
        <ul className="pagination bg-white py-2 rounded-3 d-flex flex-row justify-content-between px-2 mt-2">
          <li className="page-item ">
            <button className="btn btn-light p-0 p-1" disabled>
              {t("general_key_words.page")}{" "}
              <strong>
                {pageIndex + 1} / {pageCount}
              </strong>{" "}
            </button>
          </li>
          <div className="d-flex flex-row gap-1">
            <li className="page-item ">
              <button
                className="btn btn-primary py-2 d-flex align-items-center"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <BiSolidChevronsLeft className="fs-6" />
              </button>
            </li>
            <li className="page-item ">
              <button
                className="btn btn-primary p-1 px-2 d-flex align-items-center"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <BiSolidChevronLeft className="fs-6 bi" />
                <span className="fs-6">{t("general_key_words.previous")}</span>
              </button>
            </li>
            <li className="page-item ">
              <button
                className="btn btn-primary p-1 px-2 d-flex align-items-center"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <BiSolidChevronRight className="fs-6 bi" />
                <span className="fs-6">{t("general_key_words.next")}</span>
              </button>
            </li>
            <li className="page-item ">
              <button
                className="btn btn-primary py-2 d-flex align-items-center"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <BiSolidChevronsRight className="fs-6" />
              </button>
            </li>
          </div>
        </ul>
      )}
    </div>
  );
};

export default CarTable4;
