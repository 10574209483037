import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invoice:null,
  loading: null,
  data: null,
  inlineLoading: false,
  selected: null,
  paid:null,
  printID:null,
  invoiceCollect:null
};

const InvoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    FetchStart: (state) => {
      state.loading = true;
      state.data = null;
    },
    FetchSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    FetchError: (state) => {
      state.loading = false;
      state.data = null;
    },
    FetchInvoiceStart: (state) => {
      state.loading = true;
      state.invoice = null;
    },
    FetchInvoiceSuccess: (state, action) => {
      state.loading = false;
      state.invoice = action.payload;
    },
    FetchInvoiceError: (state) => {
      state.loading = false;
      state.invoice = null;
    },
    MoveStart: (state, action) => {
      state.inlineLoading = true;
      state.selected = action.payload;
    },
    MoveSuccess: (state,action) => {
      state.inlineLoading = false;
      state.selected = null;
      state.data =action.payload;
    },
    MoveReturn: (state, action) => {
      state.inlineLoading=false;
      state.invoice=action.payload;
      
    },
    MoveError: (state) => {
      state.inlineLoading = false;
      state.selected = null;
    },

   RejectStart: (state,action) => {
      state.inlineLoading = true;
      state.selected = action.payload;
    },
    RejectSuccess: (state,action) => {
      state.inlineLoading = false;
      state.data = action.payload;
      state.selected = null;
    },
    RejectError: (state) => {
      state.inlineLoading = false;
      state.selected = null;
    },
    PrintableData:(state,action)=>
    {
      state.printID = action.payload;
    },
    SetInvoice:(state,action)=>{
      state.invoiceCollect = action.payload;
    },
    CancelInvoice:(state)=>{
      state.invoiceCollect = null;
    }
  },
});

export const {
  FetchError,
  FetchSuccess,
  FetchStart,
  MoveError,
  MoveStart,
  MoveSuccess,
  RejectError,
  RejectStart,
  RejectSuccess,
  PrintableData,
  MoveReturn,
  FetchInvoiceError,
  FetchInvoiceStart,
  FetchInvoiceSuccess,
  SetInvoice,
  CancelInvoice
} = InvoiceSlice.actions;
export default InvoiceSlice.reducer;
