import { configureStore } from "@reduxjs/toolkit";
import AuthenticationReducer from "../redux/authentication/AuthenticationSlice";
import VehicleReducer from "./vehicleRegistration/VehicleSlice";
// import FormReducer from "./Util/FormSlice";
import ControlReducer from "./ControlSection/ControlSlice";
import DeleteReducer from "./Util/DeleteSlice";
import DashboardReducer from "./dashboard/DashboardSlice";
import branchSlice from "./branchSection/slice/branchSlice";
import WaitSlice from "./WaitingSection/WaitSlice";
import InvoiceSlice from "./InvoicesSection/InvoiceSlice";
import DownloadSlice from "./Download/DownloadSlice";
import MessageSlice from "./Messages/MessageSlice";
import SearchSlice from "./Search/SearchSlice";
import SessionSlice from "./session/slice/SessionSlice";
import userLogSlice from "./userLog/slice/userLogSlice";
import PublicDriverSlice from "./Public/PublicDriverSlice";
export default configureStore({
  reducer: {
    authentication: AuthenticationReducer,
    vehicle: VehicleReducer,
    delete: DeleteReducer,
    control: ControlReducer,
    dash: DashboardReducer,
    branch: branchSlice,
    wait:WaitSlice,
    invoice:InvoiceSlice,
    download:DownloadSlice,
    message:MessageSlice,
    search:SearchSlice,
    session:SessionSlice,
    userLog:userLogSlice,
    public_driver : PublicDriverSlice,
  },
});
