import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormHeader from "../../Utility/FormHeader";

import PageHeader from "../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
// import { FetchDriverData } from "../../redux/vehicleRegistration/FetchDriversData";
import { FetchControlData } from "../../redux/ControlSection/FetchControlData";
import LoadingAnimation from "../../shared/LoadingAnimation";
import NoContentsFound from "../../shared/NoContentsFound";
// import ControlTabularView from "./ControlTabularView";
// import ControlTable from "./ControlTable";

// import ControlWaitingTable from "./ControlWaitingTalbe";
import AllControlTable from "./AllControlTable";

export default function ControlList() {
  const { t } = useTranslation("translation");

  const data = useSelector((state) => state.control.data);
  const loading = useSelector((state) => state.control.loading);
  const CarsData = useSelector((state) => state.control);

  // const progress = useSelector((state) => state.vehicle.progress);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);

  useEffect(() => {
    // sendSMS();
    dispatch(FetchControlData(token));
  }, []);
  return (
    <div>
      <div className="mt-2">
        {/* <FormHeader province={"Kabul"} /> */}
        <PageHeader title={t("pages.traffic_control_list")} />
      </div>
      {loading ? (
        <LoadingAnimation />
      ) : data ? (
        <AllControlTable carsData={CarsData}  recordType={t("vehicle_details_form.allControlVehicleList")} />
      ) : (
        <NoContentsFound message={"No data was found on server"} />
      )}
    </div>
  );
}

// const { t } = useTranslation("translation");
//   return (
//     <div>
//       {/* <div class="p-5 mt-3 bg-light rounded-3">
//         <div class="container-fluid">
//           <h1 class=" fw-bold">{t("pages.traffic_control_list")}</h1>
//           <div className="d-flex flex-column flex-md-row gap-3">
//             <div>{t("pages.islamic_emirate_title")},</div>
//             <div>{t("pages.ministry_interior_title")},</div>
//             <div>{t("pages.traffic_title")},</div>
//             <div>{t("pages.traffic_department_title")}</div>
//           </div>
//         </div>
//       </div> */}
//       <FormHeader province={"Kabul"}/>
//       <div className="mt-3 p-3 rounded-3 bg-white">
//         <div className="table-responsive">
//           <table class="table  table-striped table-hover ">
//             {/* <caption>List of users</caption> */}
//             <thead>
//               <th>{t("vehicle_table.serial_number")}</th>
//               <th>{t("vehicle_table.driver_name")}</th>
//               {/* <th>{t("vehicle_table.driver_nid")}</th> */}
//               <th>{t("vehicle_table.province")}</th>

//               <th>{t("vehicle_table.date")}</th>
//               <th>{t("vehicle_table.price")}</th>
//               <th>{t("vehicle_table.car_plate")}</th>
//               <th>{t("vehicle_table.warranty_time")}</th>
//               <th>{t("vehicle_table.control_status")}</th>
//               <th>{t("vehicle_table.traffic_agent_remark")}</th>
//               {/* <th>{t("vehicle_table.company_agent_remark")}</th> */}
//               <th>{t("vehicle_table.work_status")}</th>
//             </thead>
//             <tbody>
//               {provincesDari.map((item, index) => (
//                 <tr key={index}>
//                   <td>{++index}</td>
//                   <td>علی - {index}</td>
//                   {/* <td>123211312</td> */}
//                   <td>{item}</td>
//                   <td>1402-06-20</td>
//                   <td>25,000</td>
//                   <td>5-888{index + 10}</td>
//                   <td>1 year</td>
//                   <td className="text-success">Accept</td>
//                   {/* <td className="text-success">Approved</td> */}
//                   <td className="text-success">Accept</td>

//                   <td className="text-info">Work Status</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
