import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { t } from "i18next";

const DoughnutChart = ({ data }) => {
  // const domain = "http://localhost:3000/";

  // const handleViewMore = (page) => {
  //   const navigateUrl = domain + page;
  //   window.open(navigateUrl, "_blank");
  // };

  const chartRef = useRef(null);
  const chartInstanceRef1 = useRef(null);
  const chartInstanceRef2 = useRef(null);

  useEffect(() => {
    if (data) {
      // Extract the data you want to display in the pie charts
      const labels = [
        t("dash_board_card.registeredVehicle") + ":" + data.drivers,
        // t("dash_board_card.pendingInvoice"),
        // t("dash_board_card.receivedInvoice"),
        t("dash_board_card.colorWaiting") + ":" + data.waiting,
        // t("dash_board_card.approvedControls"),
        t("dash_board_card.branches") + ":" + data.branches,
        // Add other data labels here
        t("setting.users") + ":" + data.users,
      ];
      const values = [
        data.drivers,
        // data.pendingInvoiceSum,
        // data.receivedInvoiceSum,
        data.waiting,
        // data.receivedInvoiceSum,
        data.branches,
        // Add other data values here
        data.users
      ];

      // Destroy the previous charts if they exist
      if (chartInstanceRef1.current) {
        chartInstanceRef1.current.destroy();
      }
      if (chartInstanceRef2.current) {
        chartInstanceRef2.current.destroy();
      }

      // Create the first pie chart using Chart.js

      // Create the second pie chart using Chart.js
      const ctx2 = chartRef.current.getContext("2d");
      chartInstanceRef2.current = new Chart(ctx2, {
        // type: "pie",
        type: "doughnut",
        data: {
          labels: labels,
          datasets: [
            {
              data: values,
              backgroundColor: [
                // "red",   // Red color for the first part
                // "green", // Green color for the second part
                // "blue",  // Blue color for the third part
                // Add other colors here
                "rgba(54, 162, 235, 0.4)",
                "rgba(153, 102, 255, 0.4)",
                "rgba(201, 203, 207, 0.4)",
                "rgba(255, 205, 86, 0.4)",
              ],
              borderColor: [
                "rgba(54, 162, 235, 0.6)",
                "rgba(153, 102, 255, 0.6)",
                "rgba(201, 203, 207, 0.6)",
                "rgba(255, 205, 86, 0.6)",
                // Add other border colors here
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          cutout: "50%",
          animation: {
            animateRotate: true,
            animateScale: false,
          },
          plugins: {
            legend: {
              display: true,
              position: "bottom",
            },
            title: {
              display: true,

              fontSize: 25, // Set the font size for the chart title
            },
          },
        },
      });
    }
  }, [data]);

  return (
    // <div className="rounded-3 row bg-light m-0 mt-2 py-5">
    //   {/* Add canvas elements for the pie charts */}
    //   <div className="col-sm-12 col-md-8 col-lg-5">
    // <canvas ref={chartRef} className=" mx-auto chartCustom"></canvas>
    //   </div>
    // </div>

    <div className="card card-danger my-2">
              <div className="card-header">
                <h3 className="card-title text-center">{t("dash_board_card.allCharts")}</h3>

                {/* <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                  <button type="button" class="btn btn-tool" data-card-widget="remove">
                    <i class="fas fa-times"></i>
                  </button>
                </div> */}
              </div>
              <div className="card-body">
                <canvas ref={chartRef} id="donutChart" style={{minHeight:"250px", height:"250px", maxHeight:"250px", maxWidth:"100%"}}></canvas>
              </div>
            </div>
  );
};

export default DoughnutChart;
