import React from "react";
import PageHeader from "../../Utility/PageHeader";
import SearchHeader from "./SearchHeader";
import CarTable3 from "../car-registration/CarTable3";
import { useSelector } from "react-redux";
import RegisterationTable from "./SearchTables/RegisterationTable";
import WaitSearchTable from "./SearchTables/WaitSearchTable";
import ControlSearchTable from "./SearchTables/ControlSearchTable";
import InvoicePendingSearchTable from "./SearchTables/InvoicePendingSearchTable";
import GeneratedInvoicesSearchTable from "./SearchTables/GeneratedInvoicesSearchTable";
import CollectedInvoicesSearchTable from "./SearchTables/CollectedInvoicesSearchTable";
import DriverCompletedListTable from "../car-registration/DriverComplatedListTable";

const SearchResults = ({ allData }) => {
  const section = useSelector((state) => state.search.type);
  // console.log("Type:")
  // console.log(section)
  return section.section === "registration" ? (
    section.id === "registration_completed" ? (
      <DriverCompletedListTable carsData={allData} section={section} />
    ) : (
      <RegisterationTable carsData={allData} section={section} />
    )
  ) : section.section === "waiting" ? (
    <WaitSearchTable carsData={allData} section={section} />
  ) : section.section === "control" ? (
    <ControlSearchTable carsData={allData} section={section} />
  ) : section.section === "invoice" && section.id === "invoices_pending" ? (
    <InvoicePendingSearchTable carsData={allData} section={section} />
  ) : section.id === "invoices_generated" ? (
    <GeneratedInvoicesSearchTable carsData={allData} section={section} />
  ) : section.id === "invoices_received" ? (
    <CollectedInvoicesSearchTable carsData={allData} section={section} />
  ) : (
    <div>{section.section}</div>
  );
};

export default SearchResults;
