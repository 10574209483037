import React from "react";
import {
  BsChevronDoubleDown,
  BsChevronDoubleRight,
  BsChevronDoubleUp,
} from "react-icons/bs";
import { MdAdd } from "react-icons/md";
import { CancelUpdate } from "../../../redux/authentication/AuthenticationSlice";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
const UsersPageHeader = ({ title, setForm, form }) => {
  const dispatch = useDispatch();
  const update = useSelector((state)=>state.authentication.update)
  const handleCancel = () => {
    dispatch(CancelUpdate());
  };
  return (
    <div className="mt-2 p-3 bg-light rounded-3 shadow-sm d-flex align-items-center justify-content-between">
      <h6 className="fw-semibold d-flex align-items-center gap-2">
        <BsChevronDoubleRight className="bi text-primary" /> {title}
      </h6>
      {update ? (
        <button
          className="btn btn-danger d-flex align-items-center gap-2"
          onClick={handleCancel}
        >
          {t("setting.cancelUpdate")}
        </button>
      ) : (
        <button
          className="btn btn-light d-flex align-items-center gap-2"
          onClick={(e) => setForm(!form)}
        >
          {!form ? (
            <BsChevronDoubleDown className="bi" />
          ) : (
            <BsChevronDoubleUp className="bi" />
          )}
          {t("setting.userRegisterForm")}
        </button>
      )}
    </div>
  );
};

export default UsersPageHeader;
