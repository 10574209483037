import React from "react";
import { Outlet } from "react-router-dom";
import ControlNav from "./ControlNav";

const ControlLayout = () => {
  return (
    <div>
      <div className="mt-2 bg-white rounded-3 overflow-hidden">
        <ControlNav />
      </div>
      <div className="">
        <Outlet />
      </div>
    </div>
  );
};

export default ControlLayout;
