import React from "react";
import { Outlet } from "react-router-dom";
import CollectedInvoiceNav from "./CollectedInvoiceNav";


const CollectedInvoiceLayout = () => {
  return (
    <div>
      <div className="mt-2 bg-white rounded-3 overflow-hidden">
        <CollectedInvoiceNav />
      </div>
      <div className="">
        <Outlet />
      </div>
    </div>
  );
};

export default CollectedInvoiceLayout;
