import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import { UpdateRecords } from "./VehicleSlice";
import { DeleteError, DeleteSuccess, DeleteStart } from "../Util/DeleteSlice";

import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const AddToWaiting =
  (token, id, section) => async (dispatch, getState) => {
    dispatch(DeleteStart(id));
    // http request to backend
    const data = {
        start_date : new Date().toDateString()
    }
    try {
      const response = await http.post(`api/driver/waiting/add/${id}`,data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (section === "table") {
        const currentState = getState();

        const updatedData = currentState.vehicle.data.map((record) => {
          if (record.id === id) {
            // Modify the record if it matches the updated one
            return response.data.record; // Assuming you have an updated record available
          }
          return record;
        });
        dispatch(UpdateRecords(updatedData));
      }
      // console.log(response);
      toast.success(t("toast.vehicle_dd_to_waiting"));
      dispatch(DeleteSuccess());
    } catch (error) {
      dispatch(DeleteError());
      if (error.response) {
        HandleHTTPRequestError(error.response.status, error, dispatch);
      } else {
        // Handle the case where the API is offline or unreachable
        console.error("API is offline or unreachable.");
          toast.error(t("toast.apiOffline"));
      }
    }
  };
