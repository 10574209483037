import { t } from "i18next";
import React from "react";
import { BiListUl, BiLock, BiSolidLock, BiSolidUserBadge, BiUserCircle } from "react-icons/bi";
import { FaUserCircle } from "react-icons/fa";
import { RxSection } from "react-icons/rx";
import { Link, NavLink } from "react-router-dom";

const SettingsNavbar = () => {
  return (
    <div className="container">
      <header className="d-flex justify-content-center py-3">
        <ul className="nav nav-pills">
          
          
          <li className="nav-item">
            <NavLink
              to="settings/users-list"
              className={({ isActive }) =>
                isActive
                  ? "nav-link active d-flex align-items-center gap-2"
                  : "nav-link d-flex align-items-center gap-2 text-dark"
              }
            >
              <BiListUl className="bi" />
              {t("setting.listView")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="settings/users"
              className={({ isActive }) =>
                isActive
                  ? "nav-link active d-flex align-items-center gap-2"
                  : "nav-link d-flex align-items-center gap-2 text-dark"
              }
            >
              <BiSolidUserBadge className="bi" />
              {t("setting.cardView")}
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              to="settings"
              className={({ isActive }) =>
                isActive
                  ? "nav-link active d-flex align-items-center gap-2"
                  : "nav-link d-flex align-items-center gap-2 text-dark"
              }
              end
            >
              <FaUserCircle className="bi" />
              {t("setting.personalizAccount")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="settings/privacy"
              className={({ isActive }) =>
                isActive
                  ? "nav-link active d-flex align-items-center gap-2"
                  : "nav-link d-flex align-items-center gap-2 text-dark"
              }
            >
              <BiSolidLock className="bi" />
              {t("setting.privacyAndSecurity")}
            </NavLink>
          </li>
          
          {/* <li className="nav-item">
            <Link to="" className="nav-link  d-flex align-items-center gap-2">
              <RxSection />
              Branch
            </Link>
          </li> */}
        </ul>
      </header>
    </div>
  );
};

export default SettingsNavbar;
