import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import { FetchError, FetchStart, FetchSuccess } from "./AuthenticationSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const FetchUsers = (token) => async (dispatch) => {
  dispatch(FetchStart());
  // http request to backend
  try {
    const response = await http.get("api/auth/users/all", {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
  } catch (error) {
    dispatch(FetchError());
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};

export const FetchSessions = (token) => async (dispatch) => {
  dispatch(FetchStart());
  // http request to backend
  try {
    const response = await http.get("api/session/get", {
      headers: { Authorization: `Bearer ${token}` },
    }); 
    // console.log("Response:");
    // console.log(response);

    toast.success(t("toast.data_fetch"));
    // dispatch(FetchSuccess(response.data.records));
  } catch (error) {
    dispatch(FetchError());
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};
