import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";

import {
  HandleProgress,
  LogOut,
  UploadError,
  UploadStart,
  UploadSuccess,
  setUser,
} from "./AuthenticationSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";
import Cookies from "js-cookie";
import { ClearSession } from "../session/slice/SessionSlice";

export const UpdateUserAccount =
  (data, token) => async (dispatch, getState) => {
    dispatch(UploadStart());
    // http request to backend
    try {
      const formData = new FormData();

      formData.append("firstName", data.firstName);
      formData.append("lastName", data.lastName);
      formData.append("email", data.email);

      const user = getState().authentication.user;
      const response = await http.post(`api/auth/update/${user.id}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
        onUploadProgress: (progressEvent) => {
          const progress = Math.floor(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          dispatch(HandleProgress(progress));
          // console.log(progress);
        },
      });
      // localStorage.removeItem("user");
      // localStorage.setItem("user", JSON.stringify(response.data.user));

      // sessionStorage.removeItem("user");
      // sessionStorage.setItem("user", JSON.stringify(response.data.user));

      dispatch(ClearSession());
      dispatch(LogOut())

      if(Cookies.get("userTokenCookie")){
        Cookies.remove("userTokenCookie");
        Cookies.remove("userDataCookie");
      }

      dispatch(setUser(JSON.stringify(response.data.user)));
      toast.success(t("toast.data_upload"));
      dispatch(UploadSuccess());
    } catch (error) {
      if (error.response.status === 422) {
        dispatch(UploadError(error.response.data.errors));
      }
      if (error.response) {
        HandleHTTPRequestError(error.response.status, error, dispatch);
      } else {
        // Handle the case where the API is offline or unreachable
        console.error("API is offline or unreachable.");
          toast.error(t("toast.apiOffline"));
      }
    }
  };
