import toast from "react-hot-toast";
import { http } from "../../../Utility/APIRequest"; 

import { HandleHTTPRequestError } from "../../../Utility/HandleHTTPRequestError";
import { t } from "i18next";
import { 
    FetchError,
    FetchStart,
    FetchSuccess,
    HandleProgress,
 } from "../slice/branchSlice";

export const FetchBranchData = (token) => async (dispatch) => {
  dispatch(FetchStart());
  // http request to backend
  try {
    const response = await http.get("api/branch", {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
  } catch (error) {
    dispatch(FetchError())
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};
