import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedRecord: null,
  loading: false,
  success: null,
  error: null,
  progress: null,
  APIErrors: null,
  data: null,
  inlineLoading: false,
  record: null,
};

const ControlSlice = createSlice({
  name: "control",
  initialState,
  reducers: {
    SelectRecord: (state, action) => {
      state.selectedRecord = action.payload;
    },
    UploadStart: (state) => {
      state.loading = true;
      state.success = null;
      state.error = null;
      state.APIErrors = null;
      state.progress = null;
    },
    UploadSuccess: (state, action) => {
      state.loading = false;
      state.success = action.payload;
      state.error = null;
      state.APIErrors = null;
      state.progress = null;
    },
    UploadError: (state, action) => {
      state.loading = false;
      state.success = null;
      state.error = action.payload.message;
      state.APIErrors = action.payload.errors;
      state.progress = null;
    },
    HandleProgress: (state, action) => {
      state.progress = action.payload;
    },
    FetchStart: (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
      state.success=null;
    },
    FetchSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    //   state.success=action.payload.message;
      state.error = null;
    },
    FetchError: (state,action) => {
      state.loading = false;
      state.data = null;
      state.success=null;
      state.error = action.payload;
    },
    DeleteStart: (state, action) => {
      state.inlineLoading = true;
      state.selectedDelete = action.payload;
    },
    DeleteSuccess: (state) => {
      state.inlineLoading = false;
      state.selectedDelete = null;
    },
    DeleteError: (state) => {
      state.inlineLoading = false;
      state.selectedDelete = null;
    },
    UpdateRecords: (state, action) => {
      state.data = action.payload;
    },
    FetchIDStart: (state) => {
      state.loading = true;
    },
    FetchIDSuccess: (state, action) => {
      state.loading = false;
      state.record = action.payload;
    },
    FetchIDError: (state) => {
      state.loading = false;
    },
    MoveStart: (state, action) => {
      state.inlineLoading = true;
      state.selected = action.payload;
    },
    MoveSuccess: (state,action) => {
      state.inlineLoading = false;
      state.selected = null;
      state.data =action.payload;
    },
    MoveError: (state) => {
      state.inlineLoading = false;
      state.selected = null;
    },
  },
});

export const {
  SelectRecord,
  UploadError,
  UploadStart,
  UploadSuccess,
  HandleProgress,
  FetchError,
  FetchStart,
  FetchSuccess,
  DeleteError,
  DeleteSuccess,
  DeleteStart,
  UpdateRecords,
  FetchIDStart,
  FetchIDError,
  FetchIDSuccess,
  MoveError,
  MoveStart,
  MoveSuccess,
} = ControlSlice.actions;
export default ControlSlice.reducer;
