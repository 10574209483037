import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingAnimation from "../../../shared/LoadingAnimation";
import { API_Image_BASE_URL } from "../../../Utility/Domain";
import { t } from "i18next";
import { FaPrint } from "react-icons/fa";
import { CompanyMessage } from "./CompanyMessage";

const VehicleDate = () => {
  const loading = useSelector((state) => state.public_driver.loading);
  const record = useSelector((state) => state.public_driver.data);
  const message = useSelector((state) => state.public_driver.message);

  const [images, setImages] = useState([]);
  useEffect(() => {
    if (record && record.images) {
      setImages(JSON.parse(record.images));
    }else{
        setImages([])
    }
  }, [record]);

  return (
    <>
        {loading ? (
          <LoadingAnimation />
        ) : (
            record &&(
            <>
            <div className="panel">
            <h5>
            <div  className={`alert alert-success text-center`}>{t("مشخصات موتر")}
                <div className="print" style={{float:"right"}}
                onClick={(e)=>window.print()}
                ><FaPrint /></div>
            </div>
            </h5>
            <div className="panel-body px-5" >

            <div className="row p-lg-0" >
                <div className="text-center">
                
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="d-flex flex-column my-2">
                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                        <span className="">{t("اسم")}:</span>
                        <span className="mx-3 fw-semibold">{record.firstName}</span>
                    </div>

                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                        <span className="">{t("اسم پدر")}:</span>
                        <span className="mx-3 fw-semibold">{record.fatherName}</span>
                    </div>
                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                        <span className="">{t("نمبر تذکره")}:</span>
                        <span className="mx-3 fw-semibold">{record.nid}</span>
                    </div>

                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                        <span className="">{t("نمبر مبایل")}:</span>
                        <span className="mx-3 fw-semibold">{record.phone}</span>
                    </div>
                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                        <span className="">{t("ولایت")}:</span>
                        <span className="mx-3 fw-semibold">{record.province}</span>
                    </div>

                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                        <span className="">{t("نوع موتر")}:</span>
                        <span className="mx-3 fw-semibold">{record.carType}</span>
                    </div>

                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                        <span className="">{t("مادل موتر")}:</span>
                        <span className="mx-3 fw-semibold">{record.carModel}</span>
                    </div>

                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                        {record.carNumPlat ? 
                        <>
                        <span className="">{t("نمبر پلیت")}:</span>
                         <span className="mx-3 fw-semibold">{record.carNumPlat}</span>
                        </>
                        : 
                        <>
                        <span className="">{t("نمبر مکتوب")}:</span>
                         <span className="mx-3 fw-semibold">{record.maktoob_number}</span>
                        </>
                        }
                       
                    </div>

                    {record.drivingPermit && (
                        <>
                            <div className="d-flex flex-row align-items-center py-2  border-bottom border-2">
                            
                                <span className="">{t("جواز سیر")}:</span>
                                <span className="mx-3 fw-semibold">
                                    {record.drivingPermit}</span>
                                
                            </div> 
                        </>
                        )}
                    {record.maktoob_number && (
                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                        <span className="">{t("تاریخ ثبت موتر")}:</span>
                        <span className="mx-3 fw-semibold">
                            {/* {(new Date(record.created_at)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                            {record.regPersianDate} 
                        </span>
                    </div>
                    )}

                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="d-flex flex-column py-2">
                    {!record.maktoob_number && (
                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                        <span className="">{t("تاریخ ثبت موتر")}:</span>
                        <span className="mx-3 fw-semibold">
                            {/* {(new Date(record.created_at)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
                            {record.regPersianDate} 
                        </span>
                    </div>
                    )}

                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                        <span className="">{t("نمبر کمیسیون ریاست ترافیک")}:</span>
                        <span className="mx-3 fw-semibold">{record.commissionNum}</span>
                    </div>

                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                        <span className="">{t("نمبر معاینه تخنیکی ریاست ترافیک")}:</span>
                        <span className="mx-3 fw-semibold">{record.techContNum}</span>
                    </div>

                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                    <span className="">{t("کود ثبت در سیستم")}:</span>
                        <span className="mx-3 fw-semibold">{record.id}</span>
                    </div>

                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                        <span className="">{t("نمبر تسلیم گیری شرکت رنگمالی")}:</span>
                        <span className="mx-3 fw-semibold">
                            {record.receipt_number ? record.receipt_number : "-"}
                        </span>
                    </div>

                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                        <span className="">{t("رنگ ساحه فعالیت موتر")}:</span>
                        <span className="mx-3 fw-semibold text-success">
                            {record.color_area === "town"
                            ? t("شهری")
                            : t("اطرافی")}
                        </span>
                    </div>

                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2"> 
                        <span className="">
                        {t("نمبر آویز بانکی")}:
                        </span>
                        <span className="mx-3 fw-semibold">
                            {record.tarifa_number}
                        </span>
                    </div>

                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                        <span className="">{t("تایید آویز بانکی")}:</span>
                        <span className="mx-3 fw-semibold text-success">
                            {record.bank_slip_status === "approved"
                            ? t("تایید گردیده")
                            : t("تایید نگردیده")}
                        </span>
                    </div>

                    <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                        <span className="">{t("وضعیت کاری موتر")}:</span>
                        <span className="mx-3 fw-semibold">
                      
                            {
                                record.workStatus === "waiting"
                                ? t("درحال رنگمالی")
                                : record.workStatus === "approved"
                                ? t("در کنترول")
                                : record.workStatus === "registration"
                                ? t("ثبت جهت طی مراحل")
                                : record.workStatus === "rejected"
                                ? t("مسترد شده")
                                : t("تکمیل شده")
                            }
                        </span>
                    </div>
                    </div>
                </div>
                {/* {record.images>0 &&( */}
                <div className="row m-0">
                    {images.map((img) => (
                    <div className="col-sm-12 col-md-6 overflow-hidden p-2">
                        <img
                        src={`${API_Image_BASE_URL}storage/driver/images/${img}`}
                        alt="this is an"
                        className="driver-view-image"
                        />
                    </div>
                    ))}
                </div>
                {/* )} */}
            </div>
            </div>
            </div>
            <br/>      
            <br/>       
            </>
          )
        )}

        {!record &&(
            <>
             <CompanyMessage /> 
             {message !=="success" &&(
                <>
                    <br></br>
                    <br></br>
                </>
             )}
             
             </>
              
        )}

    </>
  );
};

export default VehicleDate;
