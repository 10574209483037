import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import {
  FetchError,
  FetchStart,
  FetchSuccess,
  HandleProgress,
} from "../vehicleRegistration/VehicleSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

//all vehicles group by branches:
export const FetchVehicleDashBranchData = (token) => async (dispatch) => {
  dispatch(FetchStart());
  try {

    const response = await http.get("api/driver/dashboard/branch", {
    // const response = await http.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
    // console.log("response for branch:");
    // console.log(response);
  } catch (error) {
    dispatch(FetchError())
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};

//all controlled vehicles group by branches:
export const FetchControlledVehicleDashBranchData = (token) => async (dispatch) => {
  dispatch(FetchStart());
  try {
    const response = await http.get("api/driver/controlled/dashboard/branch", {
    // const response = await http.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
    // console.log("response for branch:");
    // console.log(response);
  } catch (error) {
    dispatch(FetchError())
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
}

//all controlled vehicles group by branches:
export const FetchWaitingVehicleDashBranchData = (token) => async (dispatch) => {
  dispatch(FetchStart());
  try {
    const response = await http.get("api/driver/waiting/dashboard/branch", {
    // const response = await http.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
    // console.log("response for branch:");
    // console.log(response);
  } catch (error) {
    dispatch(FetchError())
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};

//all completed vehicles group by branches:
export const FetchComplatedVehicleDashBranchData = (token) => async (dispatch) => {
  dispatch(FetchStart());
  try {
    const response = await http.get("api/driver/complated/dashboard/branch", {
    // const response = await http.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
    // console.log("response for branch:");
    // console.log(response);
  } catch (error) {
    dispatch(FetchError())
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};

//all pending invoices group by branches:
export const FetchPendingInvoiceDashBranchData = (token) => async (dispatch) => {
  dispatch(FetchStart());
  try {
    const response = await http.get("api/invoice/pending/dashboard/branch", {
    // const response = await http.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
  } catch (error) {
    dispatch(FetchError())
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};

//all generated invoices group by branches:
export const FetchGeneratedInvoiceDashBranchData = (token) => async (dispatch) => {
  dispatch(FetchStart());
  try {
    const response = await http.get("api/invoice/generated/dashboard/branch", {
    // const response = await http.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
  } catch (error) {
    dispatch(FetchError())
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};
//all Received invoices group by branches:
export const FetchReceivedInvoiceDashBranchData = (token) => async (dispatch) => {
  dispatch(FetchStart());
  try {
    const response = await http.get("api/invoice/received/dashboard/branch", {
    // const response = await http.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
  } catch (error) {
    dispatch(FetchError())
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};




