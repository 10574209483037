import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { provinces, provincesDari } from "../../Data/provinces";
import { BsCloudUploadFill } from "react-icons/bs";
import FileField from "../../Utility/FileField";
import FormHeader from "../../Utility/FormHeader";
import { perCarTypes } from "../../Data/statuses";

import i18next from "i18next";
import PageHeader from "../../Utility/PageHeader";
import APIFormErrors from "../../Utility/APIFormErrors";
import { useDispatch, useSelector } from "react-redux";
import { UploadDriverData } from "../../redux/vehicleRegistration/UploadDriverData";
import ProgressBar from "../../shared/ProgressBar";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import DatePicker from "react-multi-date-picker";
import DateObject from "react-date-object";

import jalaliMoment from "jalali-moment";
import DateConverter from "../../Utility/DateConverter";

export default function RegistrationForm() {

  const formik = useFormikContext();

  const { t } = useTranslation("translation");
  const currentLanguage = i18next.language;
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);
  const loading = useSelector((state) => state.vehicle.loading);
  const progress = useSelector((state) => state.vehicle.progress);
  const errors = useSelector((state) => state.vehicle.APIErrors);
  const user = useSelector((state) => state.authentication.user);
  //  persian dates
  const [regPersianDate, setRegPersianDate] = useState(new Date());
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());

  //  gregorian date
  const [regGregorianDate, setRegGregorianDate] = useState(new Date());
  const [fromGre, setFromGre] = useState(new Date());
  const [toGre, setToGre] = useState(new Date());


  const [shamsiDate, setShamsiDate] = useState(
    new Intl.DateTimeFormat("fa-IR", { numberingSystem: "latn" })
      .format(new Date())
      .replace(/\//g, '-') // Replace "/" with "-"
  );

  const [meladiDate, setMeladiDate] = useState(
    new Date().toISOString().split('T')[0]
);



  const acceptedFileTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
  ];

  // console.log("shamsiDate:", shamsiDate);
  // console.log("meladiDate:", meladiDate);


  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, '0');
 

  const initialValues = {
    // firstName: "First name",
    // // lastName: "",
    // fatherName: "Father name",
    // phone: "phone#0772709870",
    // nid: "nid#1234",
    // nidFile: "",
    // // image: "",
    // province: "kabul",
    // // activityType: "",
    // regGregorianDate: "",
    // regPersianDate: "",
    // // regHijriDate: "",
    // carType: "",
    // carNumPlat: "plate#123",
    // carChassis: "chassis#14",
    // drivingPermit: "dirving permit#123",
    // drivingPermitFile: "",
    // paintingStatus: "notStart",
    // workStatus: "registration",
    // carModel: "Model#2008",
    // techContNum: "tech#123",
    // commissionNum: "Commission#123",
    // purchaseDoc: "",
    // commissionFile: "",
    // techContFile: "",

    firstName: "",
    // lastName: "",
    fatherName: "",
    phone: "",
    nid: "",
    // nidFile: "",
    images: [],
    province: user.province,
    // activityType: "",
    // regGregorianDate: "",
    regGregorianDate: `${year}-${month}-${day}`,
    // regPersianDate: "",
    // regPersianDate: new Intl.DateTimeFormat("fa-IR", { numberingSystem: "latn" })
    // .format(new Date())
    // .replace(/\//g, '-'),
    regPersianDate: new Intl.DateTimeFormat("fa-IR", { numberingSystem: "latn" })
    .format(new Date())
    .split('/')
    .map((part) => part.padStart(2, '0'))
    .join('-'),
  // ...remaining fields// regHijriDate: "",
    carType: "",
    carNumPlat: "",
    carChassis: "null",
    drivingPermit: "",
    colored: "town",
    maktoob: "false",
    maktoob_number: "",
    // drivingPermitFile: "",
    paintingStatus: "notStart",
    workStatus: "registration",
    carModel: "",
    techContNum: "",
    commissionNum: "",
    // purchaseDoc: "",
    commissionFile: "",
    techContFile: "",
    userId: "1",
    tarifa_file: "",
    tarifa_number: "",
    receipt_number:""
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("validation_errors.name")),
    fatherName: Yup.string().required(t("validation_errors.fatherName")),
    receipt_number: Yup.string().required(t("validation_errors.receipt_number_required")),

    // phone: Yup.string().required(t("validation_errors.phone")),
    phone: Yup.string()
      .matches(
        /^(077|076|078|079|073|070|072|071|074|020)\d{7}$/,
        t("validation_errors.invalidPhone")
      )
      .required(t("validation_errors.phone")),

    nid: Yup.string().required(t("validation_errors.nid")),
    province: Yup.string().required(t("validation_errors.province")),

    // regGregorianDate: Yup.string().required(
    //   t("validation_errors.regGregorianDate")
    // ),

    regPersianDate: Yup.string().required(
      t("validation_errors.regPersianDate")
    ),

    carType: Yup.string().required(t("validation_errors.carType")),

    // carNumPlat: Yup.string().required(t("validation_errors.carNumPlat"))
    carNumPlat: Yup.string().matches(
      /^[-0-9]+$/,
      t("validation_errors.carNumPlat")),
    // carChassis: Yup.string().required(t("validation_errors.carChassis")),
    // drivingPermit: Yup.string().required(t("validation_errors.drivingPermit")),

    // paintingStatus: Yup.string().required(t("validation_errors.regPersianDate")),
    // workStatus: Yup.string().required("Work Status is required"),
    carModel: Yup.string().required(t("validation_errors.carModel")),

    techContNum: Yup.string().required(t("validation_errors.techContNum")),
    commissionNum: Yup.string().required(t("validation_errors.commissionNum")),
    tarifa_number: Yup.string().required(t("validation_errors.tarifaNumberRequire")),
    colored: Yup.string().required(t("validation_errors.colorAreaRequired")),
    // maktoob : Yup.string().required(t("validation_errors.maktobNumRequired")),
    // maktoob_number: Yup.string().when(['maktoob'],{
    //   is: (maqtoob) => maqtoob == "true",
    //   then: Yup.string().required("maktoob number is required"),
    //   otherwise: Yup.string().nullable()
    // }),
    maktoob_number:Yup.string()
    .matches(
      /^[-0-9]+$/,
      t("validation_errors.invalidMaktoobNumber"))
      .nullable(),
    images: Yup.array()
      // .required("Image is required")
      // .min(1, "At least one image is required")
      .nullable()
      .max(4, "imageNumber.validation_errors")
      .test("fileType", t("validation_errors.imageType"), (value) => {
        if (!value) return true; // If no image is selected, validation passes.

        // const acceptedFileTypes = [
        //   "image/jpeg",
        //   "image/jpg",
        //   "image/png",
        //   "image/gif",
        // ];
        return value.every((file) => acceptedFileTypes.includes(file.type));
      })
      .test("fileSize", t("validation_errors.imageSize"), (value) => {
        if (!value) return true; // If no image is selected, validation passes.

        const maxSize = 8 * 1024 * 1024; // 1MB in bytes
        return value.every((file) => file.size <= maxSize);
      }),
    // image: Yup.mixed()
    //   .required("Image is required")
    //   .test("fileType", t("validation_errors.imageType"), (value) => {
    //     if (!value) return true; // If no image is selected, validation passes.
    //     const acceptedFileTypes = [
    //       "image/jpeg",
    //       "image/jpg",
    //       "image/png",
    //       "image/gif",
    //     ]; // Add more file types if needed.
    //     return acceptedFileTypes.includes(value.type);
    //   })
    // .test("fileSize", t("validation_errors.imageSize"), (value) => {
    //   if (!value) return true; // If no image is selected, validation passes.

    //   const maxSize = 1024 * 1024; // 1MB in bytes
    //   return value.size <= maxSize;
    // }),

    commissionFile: Yup.mixed()
      // .required(t("validation_errors.commissionFile"))
      .test("fileSize", t("validation_errors.commissionFileSize"), (value) => {
        if (value) {
          return value.size <= 1 * 1024 * 1024;
        }
        return true;
      })
      // .test("fileFormat", t("validation_errors.commissionFileType"),(value) => {
        .test("fileType",t("validation_errors.imageType"),(value) => {
          if (value) {
            return acceptedFileTypes.includes(value.type);
            // return value.type === "application/pdf";
          }
          return true;
        }
      ),
    tarifa_file: Yup.mixed()
      // .required("Tarifa file scan is required")
      .test("fileSize", "file should not exceed more then 1MB", (value) => {
        if (value) {
          return value.size <= 1 * 1024 * 1024;
        }
        return true;
      })
      // .test("fileFormat", "file should be PDF only", (value) => {
        .test("fileType",t("validation_errors.imageType"),(value) => {
        if (value) {
          return acceptedFileTypes.includes(value.type);
          // return value.type === "application/pdf";
        }
        return true;
      }),
    techContFile: Yup.mixed()
      // .required(t("validation_errors.techContFile"))
      .test("fileSize", t("validation_errors.techContFileSize"), (value) => {
        if (value) {
          return value.size <= 1 * 1024 * 1024;
        }
        return true;
      })
      // .test("fileFormat", t("validation_errors.techContFileType"), (value) => {
        .test("fileType",t("validation_errors.imageType"),(value) => {
        if (value) {
          return acceptedFileTypes.includes(value.type);
          // return value.type === "application/pdf";
        }
        return true;
      }),

    // drivingPermitFile: Yup.mixed()
    //   .required(t("validation_errors.drivingPermitFile"))
    //   .test("fileSize", t("validation_errors.drivingPermitFileSize"), (value) => {
    //     if (value) {
    //       return value.size <= 1 * 1024 * 1024;
    //     }
    //     return true;
    //   })
    //   .test("fileFormat", t("validation_errors.drivingPermitFileType"), (value) => {
    //     if (value) {
    //       return value.type === "application/pdf";
    //     }
    //     return true;
    //   }),

    // nidFile: Yup.mixed()
    //   .required(t("validation_errors.nidFile"))
    //   .test("fileSize", t("validation_errors.nidFileSize"), (value) => {
    //     if (value) {
    //       return value.size <= 1 * 1024 * 1024;
    //     }
    //     return true;
    //   })
    //   .test("fileFormat", t("validation_errors.nidFileType"), (value) => {
    //     if (value) {
    //       return value.type === "application/pdf";
    //     }
    //     return true;
    //   }),
    // purchaseDoc: Yup.mixed()
    //   .required(t("validation_errors.purchaseDoc"))
    //   .test("fileSize", t("validation_errors.purchaseDocSize"), (value) => {
    //     if (value) {
    //       return value.size <= 1 * 1024 * 1024;
    //     }
    //     return true;
    //   })
    //   .test("fileFormat", t("validation_errors.purchaseDocType"), (value) => {
    //     if (value) {
    //       return value.type === "application/pdf";
    //     }
    //     return true;
    //   }),
  });

  const Datalist = ({ id, options }) => {
    return (
      <datalist id={id}>
        {options.map((option, index) => (
          <option value={option} key={index} />
        ))}
      </datalist>
    );
  };

  // const formik = useFormikContext();

  const PersianDateConverter = (e) => {
    // console.log("test")
    const name = "regPersianDate";
    // if(e.isValid()){
    const dateObject = new DateObject(e.toDate());
    const jalaliDate = jalaliMoment(e.toDate());

    const persianDateString = jalaliDate.locale("fa").format("YYYY-MM-DD");
    // console.log(persianDateString)
    const gregorianDate = dateObject.format("YYYY-MM-DD");
    
    setMeladiDate(gregorianDate);
    setShamsiDate(e.toDate());
    // setDateInFormData(gregorianDate);
    formik.setFieldValue(name, persianDateString);
    formik.setFieldValue("regGregorianDate", gregorianDate);
    // }else{
    //   console.log("Invalid Persian date!");
    // }
    // console.log(persianDateString)
    // document.getElementById("regGregorianDate").focus();
  };

  return (
    <>
      {/* <PageHeader title={t("vehicle_details_form.carDetailsRegForm")} /> */}
      <div className="">
        {/* <div class="p-5 bg-light rounded-3">
        <div class="container-fluid">
          <h3 class="display-5 fw-bold">Vehicle registration form</h3>
          <p class="col-md-8 ">
            Islamic Emirate of afghanistan: <span className="d-block">Traffic </span>
          </p>
        </div>
      </div> */}

        <PageHeader title={t("vehicle_details_form.carDetailsRegForm")} />
        {errors && (
          <div className=" bg-white rounded-3 mt-2">
            <div className="container-fluid">
              <APIFormErrors errors={errors} />
            </div>
          </div>
        )}
        <div className="mt-2 bg-white rounded-3 p-3">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              // console.log(values);
              
              dispatch(UploadDriverData(values, token, resetForm));

              setSubmitting(false);
            }}
          >
            {({ isSubmitting, handleReset, values }) => (
              <Form>
                <div className="row g-3">
                  {/* account names section */}

                  <div className="col-sm-12">
                    <div className="row">
                      <label
                        htmlFor="maktoob"
                        className="col-sm-2 col-form-label"
                      >
                        {t("vehicle_details_form.permitType")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-10">
                        <Field
                          as="select"
                          id="maktoob"
                          name="maktoob"
                          className="form-select"
                        >
                          <option value={"true"} key="0">
                          {t("vehicle_details_form.maktob")}
                          </option>
                          <option value={"false"} key="1">
                            {t("vehicle_table.car_plate")}
                          </option>
                        </Field>

                        <ErrorMessage
                          name="maktoob"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      <label
                        htmlFor="firstName"
                        className="col-sm-3 col-form-label"
                      >
                        {t("vehicle_details_form.firstName")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="firstName"
                          id="firstName"
                          placeholder={t("place_holder.name")}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="lastName"
                      className="col-sm-3 col-form-label"
                    >
                      {t("vehicle_details_form.lastName")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                      <Field
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder={t("vehicle_details_form.lastName")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div> */}

                  {/* account email and contact section*/}
                  <div className="col-sm-6">
                    <div className="row">
                      <label
                        htmlFor="fatherName"
                        className="col-sm-3 col-form-label"
                      >
                        {t("vehicle_details_form.fatherName")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="fatherName"
                          id="fatherName"
                          placeholder={t("place_holder.fatherName")}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="fatherName"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      <label
                        htmlFor="phone"
                        className="col-sm-3 col-form-label"
                      >
                        {t("vehicle_details_form.phone")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder={t("place_holder.phone")}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      <label htmlFor="nid" className="col-sm-3 col-form-label">
                        {t("vehicle_details_form.nid")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="number"
                          name="nid"
                          id="nid"
                          placeholder={t("place_holder.nid")}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="nid"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="row">
                      <label
                        htmlFor="province"
                        className="col-sm-3 col-form-label"
                      >
                        {t("vehicle_details_form.province")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-9">
                        <Field
                          as="select"
                          id="province"
                          name="province"
                          className="form-select"
                          disabled={user.role === "super" ? false : true}
                        >
                          <option value="">{t("place_holder.province")}</option>
                          {provincesDari.map((pro, index) => (
                            <option value={pro} key={index}>
                              {pro}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="province"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-sm-6">
                    <div className="row">
                      <label
                        htmlFor="carType"
                        className="col-sm-3 col-form-label"
                      >
                        {t("vehicle_details_form.carType")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="carType"
                          id="carType"
                          placeholder={t("place_holder.carType")}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="carType"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="col-sm-6">
                    <div className="row">
                      <label
                        htmlFor="carType"
                        className="col-sm-3 col-form-label"
                      >
                        {t("vehicle_details_form.carType")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-9">
                        <Field
                          as="select"
                          id="carType"
                          name="carType"
                          className="form-select"
                        >
                          <option value="">
                            {t("place_holder.carType")}
                          </option>
                          {perCarTypes.map((type, index) => (
                            <option value={type} key={index}>
                              {type}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="carType"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div> */}

                  <div className="col-sm-6">
                    <div className="row">
                      <label
                        htmlFor="carType"
                        className="col-sm-3 col-form-label"
                      >
                        {t("vehicle_details_form.carType")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="carType"
                          list="carTypeOptions"
                          className="form-control"
                          placeholder={t("place_holder.carType")}
                        />
                        <Datalist id="carTypeOptions" options={perCarTypes} />
                        <ErrorMessage
                          name="carType"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div>

                  {/* <DateComponent /> */}
                  {values.maktoob === "false" &&(
                  // <>
                    <div className="col-sm-6">
                      <div className="row">
                        <label
                          htmlFor="carNumPlat"
                          className="col-sm-3 col-form-label"
                        >
                          {t("vehicle_details_form.carNumPlat")}
                          <span className="text-danger"> *</span>
                        </label>
                        <div className="col-sm-9">
                          <Field
                            type="text"
                            name="carNumPlat"
                            id="carNumPlat"
                            placeholder={t("place_holder.carNumPlat")}
                            className="form-control"
                          />
                          <ErrorMessage
                            name="carNumPlat"
                            component="div"
                            className="alert alert-danger p-2 my-1"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  
                  {values.maktoob === "true" &&(
                  <div className="col-sm-6">
                    <div className="row">
                      <label
                        htmlFor="maktoob_number"
                        className="col-sm-3 col-form-label"
                      >
                        {t("vehicle_details_form.maktobNumber")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="text"
                          name="maktoob_number"
                          id="maktoob_number"
                          placeholder={t("place_holder.typeMaktobNumber")}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="maktoob_number"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div>
                  )}

                  {/* <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="activityType"
                      className="col-sm-3 col-form-label"
                    >
                      {t("vehicle_details_form.activityType")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                      <Field
                        type="text"
                        name="activityType"
                        id="activityType"
                        placeholder={t("vehicle_details_form.activityType")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="activityType"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div> */}
                  {/* date converters into three date systems */}
                    <DateConverter
                        t={t}
                        shamsiName="regPersianDate"
                        miladiName="regGregorianDate"
                    />


             
                {/* <div className="col-sm-6">
                    <div className="row">
                    <label htmlFor="regPersianDate" className="col-sm-3 col-form-label">
                        {t("general_key_words.registrationDate")}
                        <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                    <DatePicker
                        name="regPersianDate"
                        id="regPersianDate"
                        calendar={persian}
                        locale={persian_fa}
                        inputClass="form-control rounded-0"
                        containerStyle={{ width: "100%" }}
                        value={regPersianDate}
                        onChange={() => console.log("hi")}
                        
                    />
                        <ErrorMessage
                        name="regPersianDate"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                        />
                    </div>
                    </div>
                </div> */}

                  {values.maktoob === "false" &&(
                    <div className="col-sm-6">
                      <div className="row">
                        <label
                          htmlFor="drivingPermit"
                          className="col-sm-3 col-form-label"
                        >
                          {t("vehicle_details_form.drivingPermit")}
                          <span className="text-danger"> *</span>
                        </label>
                        <div className="col-sm-9">
                          <Field
                            type="number"
                            name="drivingPermit"
                            id="drivingPermit"
                            placeholder={t("place_holder.drivingPermit")}
                            className="form-control"
                          />
                          <ErrorMessage
                            name="drivingPermit"
                            component="div"
                            className="alert alert-danger p-2 my-1"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-sm-6">
                    <div className="row">
                      <label
                        htmlFor="commissionNum"
                        className="col-sm-3 col-form-label"
                      >
                        {t("vehicle_details_form.commissionNum")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="number"
                          name="commissionNum"
                          id="commissionNum"
                          placeholder={t("place_holder.commissionNum")}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="commissionNum"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="row">
                      <label
                        htmlFor="techContNum"
                        className="col-sm-3 col-form-label"
                      >
                        {t("vehicle_details_form.techContNum")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="number"
                          name="techContNum"
                          id="techContNum"
                          placeholder={t("place_holder.techContNum")}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="techContNum"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      <label
                        htmlFor="carModel"
                        className="col-sm-3 col-form-label"
                      >
                        {t("vehicle_details_form.carModel")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="number"
                          name="carModel"
                          id="carModel"
                          placeholder={t("place_holder.carModel")}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="carModel"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      <label
                        htmlFor="tarifa_number"
                        className="col-sm-3 col-form-label"
                      >
                        {t("invoice.tarifaNumber")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="number"
                          name="tarifa_number"
                          id="tarifa_number"
                          placeholder={t("place_holder.typeTarifaNumber")}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="tarifa_number"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 d-flex flex-row gap-5 ">
                    <div className="row w-100">
                      <div className="col-sm-3 col-form-label ">
                        {t("vehicle_table.paintArea")}
                        <span className="text-danger"> *</span>
                      </div>
                      <div className="col-sm-9">
                        <div class="form-check">
                          <Field
                            type="radio"
                            name="colored"
                            id="colored"
                            value="town"
                            placeholder="type tarifa number"
                            className="form-check-input"
                          />
                          <label class="form-check-label" for="colored">
                            {t("vehicle_table.town")}
                          </label>
                        </div>
                        <div class="form-check">
                          <Field
                            type="radio"
                            name="colored"
                            id="coloredTwo"
                            value="suburb"
                            placeholder="type tarifa number"
                            className="form-check-input"
                          />
                          <label class="form-check-label" for="coloredTwo">
                            {t("vehicle_table.suburb")}
                          </label>
                        </div>
                      </div>
                    </div>

                    <ErrorMessage
                      name="colored"
                      component="div"
                      className="alert alert-danger p-2 my-1"
                    />
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      <label
                        htmlFor="receipt_number"
                        className="col-sm-3 col-form-label"
                      >
                        {t("validation_errors.receipt_number_label")}
                        <span className="text-danger"> *</span>
                      </label>
                      <div className="col-sm-9">
                        <Field
                          type="number"
                          name="receipt_number"
                          id="receipt_number"
                          placeholder={t("validation_errors.receipt_number_placeholder")}
                          className="form-control"
                        />
                        <ErrorMessage
                          name="receipt_number"
                          component="div"
                          className="alert alert-danger p-2 my-1"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="paintingStatus"
                      className="col-sm-3 col-form-label"
                    >
                      {t("vehicle_details_form.paintingStatus")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                      <Field
                        type="text"
                        name="paintingStatus"
                        id="paintingStatus"
                        placeholder={t("vehicle_details_form.paintingStatus")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="paintingStatus"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="workStatus"
                      className="col-sm-3 col-form-label"
                    >
                      {t("vehicle_details_form.workStatus")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                      <Field
                        type="text"
                        name="workStatus"
                        id="workStatus"
                        placeholder={t("vehicle_details_form.workStatus")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="workStatus"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div> */}
                  <hr className="mx-auto my-3 w-75" />
                  {/* <div className="col-sm-12">
                  <h4 className="text-muted">
                    {t("vehicle_details_form.attachements")}
                  </h4>
                </div> */}

                  <div className="col-sm-12 col-md-4">
                    <label
                      htmlFor="images"
                      className="form-label border shadow-sm text-muted rounded px-5 py-2 w-100 text-center"
                    >
                      {t("vehicle_details_form.image")}
                      <span className="text-danger"> *</span>
                      <span
                        className={
                          values.images
                            ? "ms-3 fs-5 text-success"
                            : "ms-3 fs-5 text-danger"
                        }
                      >
                        <BsCloudUploadFill />
                      </span>
                    </label>
                    {values.images && (
                      <div className="text-muted">
                        <span className="text-primary">
                          {t("vehicle_details_form.image")}
                        </span>
                        :{" "}
                        <span className="text-success">
                          {values.images.length > 1
                            ? `${values.images.length} images are selected`
                            : `${values.images.length} image is selected`}
                        </span>
                        {/* {values.images.name} */}
                      </div>
                    )}
                    <FileField
                      name={"images"}
                      type={"multiple"}
                      id={"images"}
                      accept={"image/*"}
                    />
                    <ErrorMessage
                      name="images"
                      className="text-danger"
                      component="div"
                    />
                  </div>

                  <div className="col-sm-12 col-md-4">
                    <label
                      htmlFor="commissionFile"
                      className="form-label border shadow-sm text-muted rounded px-5 py-2 w-100 text-center"
                    >
                      {t("vehicle_details_form.commissionFile")}
                      <span className="text-danger"> *</span>
                      <span
                        className={
                          values.commissionFile
                            ? "ms-3 fs-5 text-success"
                            : "ms-3 fs-5 text-danger"
                        }
                      >
                        <BsCloudUploadFill />
                      </span>
                    </label>
                    {values.commissionFile && (
                      <div className="text-muted">
                        <span className="text-primary">
                          {t("vehicle_details_form.commissionFile")}
                        </span>
                        : {values.commissionFile.name}
                      </div>
                    )}
                    <FileField
                      name={"commissionFile"}
                      id={"commissionFile"}
                      accept={"image/*"}
                      // accept={".pdf"}
                    />
                    <ErrorMessage
                      name="commissionFile"
                      className="text-danger"
                      component="div"
                    />
                  </div>

                  <div className="col-sm-12 col-md-4">
                    <label
                      htmlFor="techContFile"
                      className="form-label border shadow-sm text-muted rounded px-5 py-2 w-100 text-center"
                    >
                      {t("vehicle_details_form.techContFile")}
                      <span className="text-danger"> *</span>
                      <span
                        className={
                          values.techContFile
                            ? "ms-3 fs-5 text-success"
                            : "ms-3 fs-5 text-danger"
                        }
                      >
                        <BsCloudUploadFill />
                      </span>
                    </label>
                    {values.techContFile && (
                      <div className="text-muted">
                        <span className="text-primary">
                          {t("vehicle_details_form.techContFile")}
                        </span>
                        : {values.techContFile.name}
                      </div>
                    )}
                    <FileField
                      name={"techContFile"}
                      id={"techContFile"}
                      accept={"image/*"}
                      // accept={".pdf"}
                    />
                    <ErrorMessage
                      name="techContFile"
                      className="text-danger"
                      component="div"
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <label
                      htmlFor="tarifa_file"
                      className="form-label border shadow-sm text-muted rounded px-5 py-2 w-100 text-center"
                    >
                      {t("invoice.tarifaFile")}
                      <span className="text-danger"> *</span>
                      <span
                        className={
                          values.tarifa_file
                            ? "ms-3 fs-5 text-success"
                            : "ms-3 fs-5 text-danger"
                        }
                      >
                        <BsCloudUploadFill />
                      </span>
                    </label>
                    {values.tarifa_file && (
                      <div className="text-muted">
                        <span className="text-primary">
                          {t("vehicle_details_form.techContFile")}
                        </span>
                        : {values.tarifa_file.name}
                      </div>
                    )}
                    <FileField
                      name={"tarifa_file"}
                      id={"tarifa_file"}
                      accept={"image/*"}
                      // accept={".pdf"}
                    />
                    <ErrorMessage
                      name="tarifa_file"
                      className="text-danger"
                      component="div"
                    />
                  </div>
                  {/* <div className="col-sm-12 col-md-4">
                    <label
                      htmlFor="drivingPermitFile"
                      className="form-label border shadow-sm text-muted rounded px-5 py-2 w-100 text-center"
                    >
                      {t("vehicle_details_form.drivingPermitFile")}
                      <span className="text-danger"> *</span>
                      <span
                        className={
                          values.drivingPermitFile
                            ? "ms-3 fs-5 text-success"
                            : "ms-3 fs-5 text-danger"
                        }
                      >
                        <BsCloudUploadFill />
                      </span>
                    </label>
                    {values.drivingPermitFile && (
                      <div className="text-muted">
                        <span className="text-primary">
                          {t("vehicle_details_form.drivingPermitFile")}
                        </span>
                        : {values.drivingPermitFile.name}
                      </div>
                    )}
                    <FileField
                      name={"drivingPermitFile"}
                      id={"drivingPermitFile"}
                    />
                    <ErrorMessage
                      name="drivingPermitFile"
                      className="text-danger"
                      component="div"
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <label
                      htmlFor="nidFile"
                      className="form-label border shadow-sm text-muted rounded px-5 py-2 w-100 text-center"
                    >
                      {t("vehicle_details_form.nidFile")}
                      <span className="text-danger"> *</span>
                      <span
                        className={
                          values.nidFile
                            ? "ms-3 fs-5 text-success"
                            : "ms-3 fs-5 text-danger"
                        }
                      >
                        <BsCloudUploadFill />
                      </span>
                    </label>
                    {values.nidFile && (
                      <div className="text-muted">
                        <span className="text-primary">
                          {t("vehicle_details_form.nidFile")}
                        </span>
                        : {values.nidFile.name}
                      </div>
                    )}
                    <FileField name={"nidFile"} id={"nidFile"} />
                    <ErrorMessage
                      name="nidFile"
                      className="text-danger"
                      component="div"
                    />
                  </div> */}

                  {/* <div className="col-sm-12 col-md-4">
                    <label
                      htmlFor="purchaseDoc"
                      className="form-label border shadow-sm text-muted rounded px-5 py-2 w-100 text-center"
                    >
                      {t("vehicle_details_form.purchaseDoc")}
                      <span className="text-danger"> *</span>
                      <span
                        className={
                          values.purchaseDoc
                            ? "ms-3 fs-5 text-success"
                            : "ms-3 fs-5 text-danger"
                        }
                      >
                        <BsCloudUploadFill />
                      </span>
                    </label>
                    {values.purchaseDoc && (
                      <div className="text-muted">
                        <span className="text-primary">
                          {t("vehicle_details_form.purchaseDoc")}
                        </span>
                        : {values.purchaseDoc.name}
                      </div>
                    )}
                    <FileField name={"purchaseDoc"} id={"purchaseDoc"} />
                    <ErrorMessage
                      name="purchaseDoc"
                      className="text-danger"
                      component="div"
                    />
                  </div> */}

                  <hr className="mx-auto my-3 w-75" />
                  {loading && <ProgressBar progress={progress} />}

                  <button
                    className="btn btn-primary w-100 py-2"
                    disabled={loading && true}
                    type="submit"
                  >
                    {loading ? (
                      <>
                        <div
                          className="mx-3 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        <span>Processing, please wait</span>
                      </>
                    ) : (
                      t("vehicle_details_form.registerForm")
                    )}
                  </button>
                  {/* <button
                    className="w-100 btn btn-primary "
                    disabled={isSubmitting && true}
                    type="submit"
                  >
                    {t("vehicle_details_form.registerForm")}
                  </button> */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
