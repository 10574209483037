import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import PageHeader from "../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import LoadingAnimation from "../../shared/LoadingAnimation";
import NoContentsFound from "../../shared/NoContentsFound";
import ControlWaitingTable from "./ControlWaitingTalbe";

import { PendingControls } from "../../redux/ControlSection/PendingControls";
import { ApprovedControl } from "../../redux/ControlSection/ApprovedControl";
import ControllApprovedTable from "./ControllApprovedTable";
// import DriverTabularView from "./DriverTabularView";
// import { sendSMS } from "./SendSMS";

export default function ApprovedControlLIst() {
  // sendSMS();
  const { t } = useTranslation("translation");
  const data = useSelector((state) => state.control.data);
  const loading = useSelector((state) => state.control.loading);

  const CarsData = useSelector((state) => state.control);
  // const progress = useSelector((state) => state.vehicle.progress);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);

  useEffect(() => {
    dispatch(ApprovedControl(token));
  }, []);
  return (
    <div>
      <div className="mt-2">
        {/* <FormHeader province={"Kabul"} /> */}
        <PageHeader title={t("dash_board_card.approvedControls")} />
      </div>
      {loading ? (
        <LoadingAnimation />
      ) : data && data.length>0? (
        <ControllApprovedTable carsData={CarsData} recordType={t("vehicle_details_form.approvedControlVehicleList")}/>
      ) : (
        <NoContentsFound message={t("general_key_words.dataNotFound")} />
      )}
    </div>
  );
}
