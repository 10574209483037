import React from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
const NoContentsFound = ({message}) => {
  return (
    <div className="my-3 rounded-3 shadow-sm alert alert-warning d-flex flex-row justify-content-center align-items-center">
      <AiOutlineExclamationCircle className="bi mx-2" />
     {message}
    </div>
  );
};

export default NoContentsFound;
