import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import {
  HandleProgress,
  UploadError,
  UploadStart,
  UploadSuccess,
} from "./ControlSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const UploadUpdateControlData =
  (data, token, id) => async (dispatch) => {
    dispatch(UploadStart());

    try {
      const formData = new FormData();
      formData.append("traffic_agent_name", data.trafficAgentName);
      formData.append("company_agent_name", data.companyAgentName);
      formData.append("gre_date", data.regGregorianDate);
      formData.append("per_date", data.regPersianDate);

      formData.append("control_status", data.controlStatus);
      formData.append("traffic_remark", data.trafficAgentRemark);
      formData.append("company_remark", data.companyAgentRemark);
      formData.append("approve_file", data.controAttachement);
      formData.append("id", data.id);
      formData.append("driver_id", data.driverID);
      if(data.controlStatus==="rejected"){
        const reject_date = new Date().toDateString();
        formData.append("reject_date", reject_date);
      }

      const response = await http.post(`api/control/${id}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
        onUploadProgress: (progressEvent) => {
          const progress = Math.floor(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          dispatch(HandleProgress(progress));
          // console.log(progress);
        },
      });

      // console.log("Respn0090909:")
      // console.log(response)

      toast.success(t("toast.data_upload"));
      dispatch(UploadSuccess());
    } catch (error) {
      if (error.response.status === 422) {
        const Errors = {
          message: error.message,
          errors: error.response.data.errors,
        };

        dispatch(UploadError(Errors));
      }
      if (error.response) {
        HandleHTTPRequestError(error.response.status, error, dispatch);
      } else {
        // Handle the case where the API is offline or unreachable
        console.error("API is offline or unreachable.");
          toast.error(t("toast.apiOffline"));
      }
    }
  };
