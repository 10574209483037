import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import LoadingAnimation from "../../shared/LoadingAnimation";
import NoContentsFound from "../../shared/NoContentsFound";
import {FetchInvoicesList, FetchPendingInvoicesByBranchId } from "../../redux/InvoicesSection/FetchInvoices";

import InvoiceListTable from "./InvoiceListTable";
import { useParams } from "react-router-dom";
import { t } from "i18next";
export default function InvoiceList(props) {

  const data = useSelector((state) => state.invoice.data);
  const carsData = useSelector((state) => state.invoice);
  const loading = useSelector((state) => state.invoice.loading);
  // const progress = useSelector((state) => state.vehicle.progress);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);
  const type = props.type;
  const { id } = useParams();

  useEffect(() => {
    if(type==="pendingInvoice"){
      dispatch(FetchPendingInvoicesByBranchId(token, id));
    }
    else{
      dispatch(FetchInvoicesList(token,"api/driver/invoices/get"));
    }
    
  }, []);
  return (
    <div>
      <div className="mt-2">
        {/* <FormHeader province={"Kabul"} /> */}
        <PageHeader title={t("dash_board_card.pendingInvoice")} />
      </div>
      {loading ? (
        <LoadingAnimation />
      ) : data && data.length>0? (
        <InvoiceListTable carsData={carsData}  recordType={t("vehicle_details_form.pendingInvoiceList")} />
      ) : (
        <NoContentsFound message={t("general_key_words.dataNotFound")} />
      )}
    </div>
  );
}

// return (
//   <div>
//     {/* <div class="p-5 mt-3 bg-light rounded-3">
//       <div class="container-fluid">
//         <h1 class=" fw-bold">{t("pages.invoice_list")}</h1>
//         <div className="d-flex flex-column flex-md-row gap-3">
//           <div>{t("pages.islamic_emirate_title")},</div>
//           <div>{t("pages.ministry_interior_title")},</div>
//           <div>{t("pages.traffic_title")},</div>
//           <div>{t("pages.traffic_department_title")}</div>
//         </div>
//       </div>
//     </div> */}

//     <div className="mt-3 p-3 rounded-3 bg-white">
//       <div className="table-responsive">
//         <table className="table  table-striped table-hover ">
//           {/* <caption>List of users</caption> */}
//           <thead>
//             <th>{t("vehicle_table.serial_number")}</th>
//             <th>{t("vehicle_table.driver_name")}</th>
//             <th>{t("vehicle_table.province")}</th>
//             <th>{t("vehicle_table.date")}</th>
//             <th>{t("vehicle_table.price")}</th>
//             <th>{t("vehicle_table.car_plate")}</th>
//             <th>{t("vehicle_table.warranty_time")}</th>
//             <th>{t("vehicle_table.lot_number")}</th>
//             <th>{t("vehicle_table.invoice_status")}</th>
//             <th>{t("vehicle_table.company_agent_name")}</th>
//             <th>{t("vehicle_table.traffic_agent_name")}</th>
//           </thead>
//           <tbody>
//             {provincesDari.map((item, index) => (
//               <tr key={index} onClick={ViewDetails}>
//                 <td>{++index}</td>
//                 <td>علی - {index}</td>
//                 <td>{item}</td>
//                 <td>1402-06-20</td>
//                 <td>25,000</td>
//                 <td>5-888{index + 10}</td>
//                 <td>1 year</td>
//                 <td>12332-22</td>
//                 <td className="text-primary">Pending</td>
//                 <td> محمد</td>
//                 <td>احمد</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   </div>
// );
