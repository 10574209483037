import React from "react";
import {
  BsBookmarkCheckFill,
  BsChevronDoubleDown,
  BsViewList,
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SelectRecord } from "../../redux/vehicleRegistration/VehicleSlice";
import { AiFillEdit } from "react-icons/ai";
import InlineLoadingAnimation from "../../shared/InlineLoadingAnimation";
import Swal from "sweetalert2";
import { DeleteControlRecord } from "../../redux/ControlSection/DeleteControlRecord";
import { FcAddDatabase } from "react-icons/fc";
import { t } from "i18next";
import { AddToWaiting } from "../../redux/vehicleRegistration/AddToWaiting";
import { AddToWaitingInvoice } from "../../redux/ControlSection/AddToWaitingInvoice";
import { FaMinus } from "react-icons/fa";
import { RejectWaitingRecord } from "../../redux/WaitingSection/RejectWaitingRecord";
import { RejecPendingControls } from "./RejectPendingControls";
const AllControlOptionButton = ({ record, section }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inlineLoading = useSelector((state) => state.delete.loading);
  const selectedDelete = useSelector((state) => state.delete.item);
  const token = useSelector((state) => state.authentication.token);
  const user = useSelector((state) => state.authentication.user);

  const handleAddToInvoice = () => {
    Swal.fire({
      title: t("general_key_words.areYouSure"),
      text: t("general_key_words.deleteConfirmMsg"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("general_key_words.yesInvoice"),
      cancelButtonText: t("general_key_words.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          AddToWaitingInvoice(token, record.driver.id, record.id, "table")
        );
      }
    });
  };

  const NavigateControlSection = () => {
    dispatch(AddToWaiting(token, record.id, "table"));
  };

  const NavigateToPrintPage = () => {
    dispatch(SelectRecord(record));
    // window.open('/view-list/view/print', '_blank');
    navigate("view/print");
  };

  const handleView = () => {
    window.open(`/control-list/view/${record.id}`, "_blank");
  };

  //edit use for objections
  const handleEdit = (url) => {
    // console.log(url)
    // url = "waiting/edit/";
    //control-list/edit
    //control-list/edit/:id
    // navigate(`${url}${record.id}?driverID=${record.driver.id}`);
    navigate(`edit/${record.id}?driverID=${record.driver.id}`);
  };
  const handleReject = () => {
    // console.log(record.id)
    // console.log(record.driver.id)
    // dispatch(RejectWaitingRecord(token, record.id))
    dispatch(RejecPendingControls(token, record.id, record.driver.id));
  };
  const handleDelete = () => {
    Swal.fire({
      title: t("general_key_words.areYouSure"),
      text: t("general_key_words.deleteConfirmMsg"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("general_key_words.deleteConfirmButtonText"),
      cancelButtonText: t("general_key_words.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteControlRecord(token, record.id, "table"));
      }
    });
  };

  return selectedDelete === record.id && inlineLoading ? (
    <div className="">
      <InlineLoadingAnimation />
    </div>
  ) : (
    <div className="btn-group">
      <button
        className="btn btn-secondary d-flex align-items-center gap-2 rounded"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <BsChevronDoubleDown />
        {t("general_key_words.choose")}
      </button>
      <ul className="dropdown-menu" style={{ zIndex: 2000 }}>
        {record.control_status !== "approved" &&
          record.control_status !== "rejected" && (
            
            <>
            {user.role !=="admin" &&(
              <li>
                <a
                  className="dropdown-item d-flex align-items-center gap-2 text-success"
                  type="button"
                  onClick={handleAddToInvoice}
                >
                  <BsBookmarkCheckFill />
                  { user.staff ==="traffic staff" ? 
                  t("traffic_control.approveVehicle") : 
                  t("invoice.addToInvoice")
                  }
                  {/* {t("invoice.addToInvoice")} */}
                </a>
              </li>
              )}
              {/* <hr /> */}
              

              {/* <li>
          <a
            className="dropdown-item d-flex align-items-center gap-2 text-danger"
            type="button"
            onClick={handleReject}
          >
            <FaMinus className="bi" />
            Reject
          </a>
        </li> */}
            </>
          )}

        {record.control_status !== "approved" &&
          record.control_status !== "rejected" && (
            user.staff !=="traffic staff" && user.role !=="admin" &&( <>
            <li>
              <a
                className="dropdown-item d-flex align-items-center gap-2 text-muted"
                type="button"
                onClick={(url) => handleEdit("waiting/edit/")}
              >
                <AiFillEdit className="bi" />
                {t("general_key_words.objections")}
              </a>
            </li>

            </>
              
            )
          )}

        <li>
          <a
            className="dropdown-item d-flex align-items-center gap-2 text-muted"
            type="button"
            onClick={handleView}
          >
            <BsViewList className="bi" />
            {t("general_key_words.view")}
          </a>
        </li>

        {/* <li>
          <a
            className="dropdown-item d-flex align-items-center gap-2 text-danger"
            type="button"
            onClick={handleDelete}
          >
            <BsFillTrash3Fill className="bi" />
            {t("general_key_words.delete")}
          </a>
        </li> */}
      </ul>
    </div>
  );
};

export default AllControlOptionButton;
