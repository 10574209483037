import React from "react";

const DriverSelectionMessage = ({ message,type }) => {
  return (
    <div className={`mt-3 shadow-sm text-center rounded-3 alert alert-${type}`}>
      {message}
    </div>
  );
};

export default DriverSelectionMessage;
