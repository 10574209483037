import React from "react";
import Logo from "../../Media/Company.jpg";
import { useDispatch, useSelector } from "react-redux";
import NoContentsFound from "../../shared/NoContentsFound";
import { useEffect } from "react";
import { FetchPrintedInvoices } from "../../redux/InvoicesSection/FetchPrintedInvoices";
import LoadingAnimation from "../../shared/LoadingAnimation";
import { t } from "i18next";

const InvoiceView = () => {
  const printID = useSelector((state) => state.invoice.printID);
  const user = useSelector((state) => state.authentication.user);
  const token = useSelector((state) => state.authentication.token);

  const invoice = useSelector((state) => state.invoice.invoice);
  const data = useSelector((state) => state.invoice.data);
  const loading = useSelector((state) => state.invoice.loading);
  const dispatch = useDispatch();
  useEffect(() => {
    if (invoice) {
      dispatch(FetchPrintedInvoices(token, invoice.id));
    }
  }, []);

  const handleDriver = (id) => {
    window.open(`/view-list/view/${id}`, "_blank");
  };
  return printID ? (
    <div className="row mt-2">
      <div className="col-12">
        <div className="card border-0">
          <div className="card-body p-0">
            <div className="row p-5">
              <div className="col-md-6">
                <img src={Logo} alt="website logo" className="invoice-image" />
              </div>

              <div className="col-md-6 text-right">
                <p className="font-weight-bold mb-1">
                  t("invoice.InvoiceNumber"): {invoice && invoice.id}
                </p>
                <p className="text-muted">
                  t("general_key_words.date"):{" "}
                  {invoice && new Date(invoice.created_at).toDateString()}
                </p>
              </div>
            </div>

            <hr className="mx-5" />

            <div className="row  px-5">
              <div className="col-md-6">
                <p className="font-weight-bold">{t("general_key_words.clientInfo")}</p>

                {/* <div className="d-flex flex-row align-items-center">
                  <span className="">Driver ID</span>
                  <div className="d-flex flex-row align-items-center">
                    {printID.map((item) => (
                      <span
                        onClick={(e) => handleDriver(item)}
                        className="hover-link mx-2 shadow-sm py-1 px-3 rounded-2"
                      >
                        {item}
                      </span>
                    ))}
                  </div>
                </div> */}
                {/* <p className="mb-1">John Doe, Mrs Emma Downson</p>
                <p>Acme Inc</p>
                <p className="mb-1">Berlin, Germany</p>
                <p className="mb-1">6781 45P</p> */}
              </div>

              {/* <div className="col-md-6 text-right">
                <p className="font-weight-bold mb-4">Payment Details</p>
                <p className="mb-1">
                  <span className="text-muted">VAT: </span> 1425782
                </p>
                <p className="mb-1">
                  <span className="text-muted">VAT ID: </span> 10253642
                </p>
                <p className="mb-1">
                  <span className="text-muted">Payment Type: </span> Root
                </p>
                <p className="mb-1">
                  <span className="text-muted">Name: </span> John Doe
                </p>
              </div> */}
            </div>

            <div className="row px-lg-5">
              <div className="col-md-12">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="border-0 text-uppercase small font-weight-bold">
                        ID
                      </th>
                      <th className="border-0 text-uppercase small font-weight-bold">
                        Driver
                      </th>
                      <th className="border-0 text-uppercase small font-weight-bold">
                        Number Plate
                      </th>
                      <th className="border-0 text-uppercase small font-weight-bold">
                        Warranty
                      </th>

                      <th className="border-0 text-uppercase small font-weight-bold">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <LoadingAnimation />
                    ) : (
                      data &&
                      data.map((item, index) => (
                        <tr key={index}>
                          <td>{++index}</td>
                          <td
                            className="cursor-pointer hover-link"
                            onClick={(e) => handleDriver(item.driver.id)}
                          >
                            {item.driver.firstName}
                          </td>
                          <td>{item.driver.carNumPlat}</td>

                          <td>{item.warranty_time}</td>
                          {/* <td>{item.price} <span className="text-success">AFS</span></td> */}
                          <td>
                            {item.price}{" "}
                            <span className="text-success">AFS</span>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="d-flex flex-row-reverse bg-dark text-white p-4">
              <div className="py-3 px-5 text-right">
                <div className="mb-2">{t("general_key_words.grantTotal")}</div>
                <div className="h2 font-weight-light">
                  {data &&
                    data.reduce(
                      (total, item) => total + parseFloat(item.price),
                      0
                    )}
                </div>
              </div>

              {/* <div className="py-3 px-5 text-right">
                <div className="mb-2">Discount</div>
                <div className="h2 font-weight-light">10%</div>
              </div>

              <div className="py-3 px-5 text-right">
                <div className="mb-2">Sub - Total amount</div>
                <div className="h2 font-weight-light">$32,432</div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <NoContentsFound message={"No records were selected for invoice"} />
  );
};

export default InvoiceView;
