import React, { useState } from 'react';

const VehicleBack = () => {
  const [backupStatus, setBackupStatus] = useState(null);

  const handleBackupRequest = async () => {
    try {
      const response = await fetch('/api/driver/get/b/vehicles', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers if needed, such as authentication headers
        },
      });

      if (response.ok) {
        // If the response is successful, initiate the download
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'backup.zip';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setBackupStatus('Backup download initiated.');
      } else {
        // Handle non-successful response
        const errorData = await response.json();
        setBackupStatus(`Error: ${errorData.message}`);
      }
    } catch (error) {
      // Handle network or other errors
      console.error('Error:', error);
      setBackupStatus('Error: Unable to initiate backup download.');
    }
  };

  return (
    <>
      <div className="public-wrapper">
        <h2>Welcome</h2>
        <button onClick={handleBackupRequest}>Request Backup</button>
        {backupStatus && <p>{backupStatus}</p>}
      </div>
    </>
  );
};

export default VehicleBack;
