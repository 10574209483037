import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import { FetchError, FetchStart, FetchSuccess } from "./WaitSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const FetchWaitingData = (token) => async (dispatch) => {
  dispatch(FetchStart());
  // http request to backend
  try {
    const response = await http.get("api/driver/waiting/get", {
      headers: { Authorization: `Bearer ${token}` },
    });
    // console.log(response)
    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
  } catch (error) {
    dispatch(FetchError());
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};
export const FetchWaitingListByBranchId = (token, id) => async (dispatch) => {
  dispatch(FetchStart());
  // http request to backend
  try {
    const response = await http.get(`api/drivers/waiting/branch/get/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    // console.log(response)
    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
  } catch (error) {
    dispatch(FetchError());
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};


