
import './Style.css'
import { FaAddressBook, FaEnvelope, FaFacebook, FaGithub, FaGlobe, FaTelegram, FaTwitter, FaVoicemail, FaWeebly, FaYoutube } from "react-icons/fa";
const Footer = () => {
  return (
    <footer className="footer">
        
        <div className="footer-column">
            <div className="social-media">

                <a 
                title='فیسبوک'
                style={{color:"#1877F2"}}
                href="https://www.facebook.com/profile.php?id=100086953245363" 
                target="_blank"><FaFacebook/></a>
                
                <a 
                title='چینل یوتوپ'
                style={{color:"#FF0000"}}
                href="https://www.youtube.com/channel/UCGi7828S0X5wKBT9PDkzLAw" 
                target="_blank"><FaYoutube /></a>

                <a 
                style={{color:"#3498db"}}
                title='ویبسایت'
                href="https://afgsoft.net/haidari/" target="_blank"><FaGlobe /></a>

                <a
                 style={{color:"#3498db"}}
                 title='در باره ما'
                 href="mailto:mearajussin.haidary@gmail.com" 
                 target="_blank"><FaEnvelope /></a>
                
                <a title='تویتر' href="#" 
                    style={{color:'#1DA1F2'}}
                ><FaTwitter /></a>
                <a title='تلگرام' href="#" 
                    style={{color:"#0088CC"}}
                ><FaTelegram /></a>
                 <a
                // style={{color:"#181717"}}
                title='گیت هپ'
                 href="https://github.com/MirajHaidari/" target="_blank"><FaGithub /></a>
                
            </div>
        </div>

        <div className="footer-column">
            <div className="copy-wright">
            Copyright © 2023 All rights reserved
            </div>
        </div>
        
    </footer>

  );
};

export default Footer;
