export const provinces = [
    "Badakhshan",
    "Badghis",
    "Baghlan",
    "Balkh",
    "Bamyan",
    "Daykundi",
    "Farah",
    "Faryab",
    "Ghazni",
    "Ghor",
    "Helmand",
    "Herat",
    "Jowzjan",
    "Kabul",
    "Kandahar",
    "Kapisa",
    "Khost",
    "Kunar",
    "Kunduz",
    "Laghman",
    "Logar",
    "Nangarhar",
    "Nimroz",
    "Nuristan",
    "Paktia",
    "Paktika",
    "Panjshir",
    "Parwan",
    "Samangan",
    "Sar-e Pol",
    "Takhar",
    "Urozgan",
    "Zabul",
];


export const provincesDari = [
    "بدخشان",
    "بادغیس",
    "بغلان",
    "بلخ",
    "بامیان",
    "دایکندی",
    "فراه",
    "فاریاب",
    "غزنی",
    "غور",
    "هلمند",
    "هرات",
    "جوزجان",
    "کابل",
    "کندز",
    "کاپیسا",
    "خوست",
    "کونر",
    "کندهار",
    "لغمان",
    "لوگر",
    "ننگرهار",
    "نیمروز",
    "نورستان",
    "پکتیا",
    "پکتیکا",
    "پنجشیر",
    "پروان",
    "سمنگان",
    "سرپل",
    "تخار",
    "ارزگان",
    "زابل",
  ];
  

const provinces3 = [
    { id: 1, name: "Badakhshan" },
    { id: 2, name: "Badghis" },
    { id: 3, name: "Baghlan" },
    { id: 4, name: "Balkh" },
    { id: 5, name: "Bamyan" },
    { id: 6, name: "Daykundi" },
    { id: 7, name: "Farah" },
    { id: 8, name: "Faryab" },
    { id: 9, name: "Ghazni" },
    { id: 10, name: "Ghor" },
    { id: 11, name: "Helmand" },
    { id: 12, name: "Herat" },
    { id: 13, name: "Jowzjan" },
    { id: 14, name: "Kabul" },
    { id: 15, name: "Kandahar" },
    { id: 16, name: "Kapisa" },
    { id: 17, name: "Khost" },
    { id: 18, name: "Kunar" },
    { id: 19, name: "Kunduz" },
    { id: 20, name: "Laghman" },
    { id: 21, name: "Logar" },
    { id: 22, name: "Nangarhar" },
    { id: 23, name: "Nimroz" },
    { id: 24, name: "Nuristan" },
    { id: 25, name: "Paktia" },
    { id: 26, name: "Paktika" },
    { id: 27, name: "Panjshir" },
    { id: 28, name: "Parwan" },
    { id: 29, name: "Samangan" },
    { id: 30, name: "Sar-e Pol" },
    { id: 31, name: "Takhar" },
    { id: 32, name: "Urozgan" },
    { id: 33, name: "Zabul" },
  ];
  
