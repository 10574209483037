import React from "react";
import NoContentsFound from "./NoContentsFound";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Roled = ({ children, allowedRoles }) => {
  const { t } = useTranslation("translation");
  const user = useSelector((state) => state.authentication.user);

  if (!allowedRoles.includes(user.role)) {
    return <NoContentsFound message={t("login.anAuthorizedUser")} />;
  }
  return children;
};

export default Roled;
