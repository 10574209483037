import React from "react";
import CarNav from "../CarNav";

const Nav = () => {
  return (
    <div className="mt-2 bg-white rounded-3 overflow-hidden">
      <CarNav />
    </div>
  );
};

export default Nav;
