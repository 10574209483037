import React from "react";
import RegistrationForm from "./RegistrationForm";
import { t } from "i18next";

function Register() {

  return (
    <div className="container">
      <main>
        <div className="py-5 text-center">
          {/* <img
            className="d-block mx-auto mb-4"
            src="/docs/5.3/assets/brand/bootstrap-logo.svg"
            alt="logo"
            width="72"
            height="57"
          /> */}
          <h2>{t("setting.userRegisterForm")}</h2>
          <p className="lead">
            below registration form fields are directly connected to system, and
            vary based on province.
          </p>
        </div>

        <div className="row g-5">
          {/* <SidePricing /> */}
          <div className="col-md-8 mx-auto">
            <h6 className="mb-3 text-muted">
              <span className="text-success">Note</span>: please carefully fill
              the required fields with appropriate data.
            </h6>
            <RegistrationForm />
          </div>
        </div>
      </main>

      <footer className="my-5 pt-5 text-body-secondary text-center text-small">
        <p className="mb-1">© 2017–2023 Company Name</p>
        <ul className="list-inline">
          <li className="list-inline-item">
            <a href="#">Privacy</a>
          </li>
          <li className="list-inline-item">
            <a href="#">Terms</a>
          </li>
          <li className="list-inline-item">
            <a href="#">Support</a>
          </li>
        </ul>
      </footer>
    </div>
  );
}

export default Register;
