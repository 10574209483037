import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import PageHeader from "../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import LoadingAnimation from "../../shared/LoadingAnimation";
import NoContentsFound from "../../shared/NoContentsFound";

import UserLogTable from "./UserLogTable";
import { GetAllUsersLog } from "../../redux/userLog/actions/getUsersLog";

export default function AllUsersLogList(props) {
  // sendSMS();
  const { t } = useTranslation("translation");
  const data = useSelector((state) => state.userLog.data);
  const CarsData = useSelector((state) => state.userLog);
  const loading = useSelector((state) => state.userLog.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAllUsersLog());
  }, []);

  return (
    <div>
      <div className="mt-2">
        {/* <FormHeader province={"Kabul"} /> */}
        <PageHeader title={t("userLog.allUsersLog")} />
      </div>
      {loading ? (
        <LoadingAnimation />
      ) : data ? (
        // <DriverTabularView data={data} />
        // <CarTable carsData={CarsData} />
        <UserLogTable carsData={CarsData} />
      ) : (
        // <DriverTabularView data={data} options={true}/>
        <NoContentsFound message={t("general_key_words.dataNotFound")} />
      )}
    </div>
  );
}
