function APIFormErrors({ errors }) {
  return (
    <div className="py-3">
      <h6 className="my-2 text-danger fw-semibold">Field Errors:</h6>
      <ul className="list-group list-group-flush">
        {Object.keys(errors).map((field, index) => (
          <li className="list-group-item bg-light px-0 text-danger" key={index}>
            {errors[field].join(", ")}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default APIFormErrors;
