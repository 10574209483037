import toast from "react-hot-toast";
import { http } from "../../../Utility/APIRequest";
import FormDataHelper from "../../../Utility/FormDataHelper";
import { 
  FetchError,
  HandleProgress,
  UploadError,
  UploadStart,
  UploadSuccess,
 } from "../slice/branchSlice";

import { useSelector } from "react-redux";
import { HandleHTTPRequestError } from "../../../Utility/HandleHTTPRequestError";
import { t } from "i18next";
import { FetchBranchData } from "./FetchBranchData";

export const UploadBranch = (branchData, token) => 
    async (dispatch) => {
      // console.log("Branch Data in action:")
      // console.log(branchData)
        dispatch(UploadStart());
        // http request to backend
        try {
            // const formData = FormDataHelper(driverData);
            const formData = new FormData();
            formData.append("branchName", branchData.branchName);
            formData.append("description", branchData.description);
            formData.append("province", branchData.province);

            // console.log("Action Called:");
            // console.log(formData);

            const response = await http.post("api/branch", formData, {
            // const response = await http.post("api/branch", branchData, {
            headers: { Authorization: `Bearer ${token}` },

            onUploadProgress: (progressEvent) => {
                const progress = Math.floor(
                (progressEvent.loaded / progressEvent.total) * 100
                );
                dispatch(HandleProgress(progress));
                // console.log(progress);
            },
            });

            // console.log("Response:");
            // console.log(response);
            // console.log(toast.success(response));
            toast.success(t("toast.data_fetch"));
            dispatch(UploadSuccess());
            dispatch(FetchBranchData(token));
  } catch (error) {
    if (error.response.status === 422) {
      const Errors = {
        message: error.message,
        errors: error.response.data.errors,
      };

      dispatch(UploadError(Errors));
    }

    dispatch(FetchError());
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};
