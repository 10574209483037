import { useDispatch, useSelector } from "react-redux";
// import Chart from "chart.js/auto";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FormHeader from "../../Utility/FormHeader";
import DashCard from "./DashCard";
import { FetchDashboardData } from "../../redux/dashboard/FetchDashboardData";
import LoadingAnimation from "../../shared/LoadingAnimation";
import DashChart from "./DashChart";
import PieChart from "./PieChart";
import DoughnutChart from "./DoughnutChar";
import LineChart from "./LineChart";
import { BsCheck } from "react-icons/bs";
import { BiRefresh } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import TrafficDashCard from "./TrafficDashCard";
import AreaChart from "./AreaChart";

function Dashboard() {
  
  // const email = useSelector((state) => state.authentication.user.email);
  // const { t } = useTranslation("translation");
  const loading = useSelector((state) => state.dash.loading);
  const data = useSelector((state) => state.dash.data);
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);
  const { t } = useTranslation("translation");

  const [autoUpdate, setAutoUpdate] = useState(false);

  const user = useSelector((state) => state.authentication.user);
  // console.log("dddddd:000:")
  // console.log(data)
  // chart
  // useEffect(() => {
  //   // console.log("Use Effect Called:::::")
  //   dispatch(FetchDashboardData(token));
  // }, []);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(FetchDashboardData(token));

    if (autoUpdate) {
      const intervalId = setInterval(() => {
        dispatch(FetchDashboardData(token));
      // }, 5000);
      }, 120000);

      // Clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [dispatch, token, autoUpdate]);

  const handleAutoUpdateChange = () => {
    setAutoUpdate(!autoUpdate);
  };
  
  if(user.role==="approver"){
    // window.open("/driver-no-waiting");
    navigate("/driver-no-waiting");
  }else if(user.role==="user"){
    navigate("/view-list");
  }
  else{
    // console.log("Dashboard called2:")
    // console.log(data)

  return (
    <div className="mt-2">
      
      <FormHeader />
      {!autoUpdate && loading ? (
        <LoadingAnimation />
      ) : (
        <>
          {data && (
            user.staff ==="traffic staff" ? 
            <TrafficDashCard data={data} />
             :
            <>
              <DashCard data={data} />
              
              {/* {user.role ==="super" &&( */}
              {user.role !=="user" &&(
              <div className="mt-4 bg-white rounded-3 p-3" ref={containerRef}>
                <div className="row">
                {/* {user.role ==="super" &&( */}
                  <>
                  <div className="col-sm-6 ">
                    {/* <h4 className="bg-light text-center py-2 my-2">
                      {t("general_key_words.invoice")}{" "}
                      {t("general_key_words.statistics")}
                    </h4> */}
                    
                    <DashChart data={data} user = {user} />
                    
                    
                  </div>
                  {/* <hr className="mt-5" /> */}
                  </>
                  {/* )} */}
                  
                  {/* for super admin and admin */}
                  {(user.role ==="admin" || user.role==="super") &&(
                  <>
                  <div className="col-sm-6">
                    {/* <h4 className="bg-light text-center py-2 my-2">
                      {t("dash_board_card.allCharts")}
                    </h4> */}
                    <LineChart data={data} />
                  </div>
                  {/* <hr className="mt-5" /> */}
                  <div className="col-sm-6">
                    {/* <h4 className="bg-light text-center py-2 my-2">
                      {t("dash_board_card.allCharts")}
                    </h4> */}
                    <DoughnutChart data={data} />
                    

                  </div>

                  <div className="col-sm-6">
                    {/* <h4 className="bg-light text-center py-2 my-2">
                      {t("dash_board_card.allCharts")}
                    </h4> */}

                    <AreaChart data={data} />
                  </div>
                  </>)}


                 
                </div>

                {/* <div className="row">
                  <div className="col-sm-12 col-lg-6">
                    <DoughnutChart data={data} />
                  </div>

                 <div className="col-sm-12 col-lg-6">
                    <PieChart data={data} />
                  </div>
                </div> */}
                {/* Check box section */}
                

                 <div className="text-center">
                 <div className="p-3">
                        <BiRefresh className="fs-3 text-success" />
                      </div>
                      <div className="d-flex flex-column p-3">
                        <div className="fs-6 fw-semibold text-secondary">
                          {t("general_key_words.autoUpdate")}
                        </div>
                        <div className="mt-1 fs-3 fw-semibold">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="autoCheckbox"
                            checked={autoUpdate}
                            onChange={handleAutoUpdateChange}
                          />
                        </div>
                      </div>
                 </div>
              </div>
              
              )}
              
            </>
          
          )}
        </>
      )}

    </div>
  );
}
}

export default Dashboard;
