import Swal from "sweetalert2";

export function SwalMessage(subject, message, type) {
  Swal.fire({
    title: subject,
    text: message,
    icon: type,
    timer: 1500,
    showCancelButton: false,
  });
}
