import React from "react";
import ImageOne from "../Media/Traffict.png";
import ImageTwo from "../Media/islamic_emirate.png";
import ImageComp from "../Media/Company2.jpg";
import i18next, { t } from "i18next";
import { useSelector } from "react-redux";
import jalaliMoment from "jalali-moment";
import { useParams } from "react-router-dom";
import { BsPrinter } from "react-icons/bs";
import "./fields.css"; 
const StilamHeader = ({ province, title }) => {
  const user = useSelector((state)=>state.authentication.user);
  const userProvince = user.province;
  const { id, number } = useParams();
  return (
    <div className="px-5">
      <div className=" overflow-hidden d-flex flex-row justify-content-between">
      {/* <h5 className="fw-semibold">{t("stilam.brand")}</h5> */}
        <div className="py-4 " style={{fontSize:"16px"}}>
          {number==="0" ? <p>شماره درخواستی............. </p> 
          
          : <p>شماره درخواستی : 
            <input placeholder="........." className="border-0" style={{ width:"30%", outline: 'none', padding:"2px"  }} />
            </p>

          }
            
            <p>
            {t("stilam.date")} : {jalaliMoment(new Date())
                  .locale("fa").format("YYYY-MM-DD")}
            </p>
            <p>عنوان :‌ درخواستی حواله تکمیل کار</p>
            <p>{t("stilam.fromMsg")}</p>
            <p> {t("stilam.projectName")} </p>
            <p> موضوع : انوایس یا حواله نمبر
            ({id})
            </p>
        </div>  
        <div className="py-5 text-center" id="printer">
          <button className="btn btn-success"
            onClick={() => window.print()}
          ><BsPrinter /></button>
        </div>
        <div className="py-4">
          <img
            src={ImageComp}
            alt="background image"
            style={{ width: "250px", height: "100px" }}
          />
          
        </div>
        
      </div>
    </div>
  );
};

export default StilamHeader;
