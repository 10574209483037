import { t } from "i18next";
import React, { useMemo, useState, useRef } from "react";
import { useTable, useSortBy, usePagination } from "react-table"; // Import useSortBy hook

import jsPDF from "jspdf";
import "jspdf-autotable";

import { useDownloadExcel } from "react-export-table-to-excel";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";

import jalaliMoment from "jalali-moment";

import AllControlOptionButton from "../traffic-control/AllControlOptionButton";

// import "jspdf-autotable/dist/polyfills.js";
// import "jspdf-autotable/dist/jspdf.plugin.autotable.js";

const AllApprovedInvoiceTable = ({ carsData }) => {
  const [filterValue, setFilterValue] = useState(""); // State variable for filtering

  const data = useMemo(() => {
    if (!carsData || !carsData.data) {
      return [];
    }

    // Apply filtering based on the filterValue
    return carsData.data.filter((row) => {
      // return Object.values(row).some((value) =>
      return Object.values(row.driver).some((value) =>
        value
          ? value.toString().toLowerCase().includes(filterValue.toLowerCase())
          : false
      );
    });
  }, [carsData, filterValue]);

  const columns = useMemo(
    () => [
      // {
      //   Header: t("vehicle_table.serial_number"),
      //   accessor: "id",
      // },
      {
        Header: t("vehicle_table.serial_number"),
        accessor: (row, index) => index + 1, // Generate sequential numbers
      },
      {
        Header: t("vehicle_details_form.firstName"),
        accessor: "driver.firstName",
      },
      {
        Header: t("vehicle_table.driver_father_name"),
        // accessor: "driver.fatherName",
        accessor: "driver.fatherName",
      },

      {
        Header: t("vehicle_table.driver_nid"),
        accessor: "driver.nid",
      },
      {
        Header: t("vehicle_table.province"),
        accessor: "driver.province",
      },
      // {
      // Header: t("vehicle_table.date"),
      // accessor: "regGregorianDate",
      // },
      {
        Header: t("vehicle_table.car_type"),
        accessor: "driver.carType",
      },
      {
        Header: t("vehicle_details_form.carNumPlat"),
        accessor: "driver.carNumPlat",
        // Enable sorting for this column
        canSort: true,
      },
      {
        Header: t("vehicle_table.date"),
        accessor: "gre_date",
        // Enable sorting for this column
        canSort: true,
      },
      {
        Header: t("invoice.invoiceStatus"),
        accessor: "invoice_status",
      },
      {
        Header: t("invoice.generated"), // Use the appropriate translation for "Printed"
        accessor: (row) => (row.invoice_print_id ? "Yes" : "No"),
      },
      {
        Header: t("invoice.price"),
        accessor: "price",
        // Enable sorting for this column
        canSort: true,
      },
      {
        Header: t(""),
        accessor: "optionsButton",
      },
      //   {
      //     Header: t("vehicle_table.options_button"),
      //     id: "optionsButton", // Add a unique id for this column
      //   },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },

    useSortBy, // Enable sorting
    usePagination
  );

  const totalPrice = useMemo(() => {
    if (!data || data.length === 0) {
      return 0;
    }
    return data.reduce((sum, row) => sum + parseInt(row.price, 10), 0);
  }, [data]);

  //downlaod excel sheet:
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: t("excelTable.receivedInvoiceTable"),
    sheet: t("excelTable.receivedInvoiceSheet"),
  });

  const handleExportToExcel = () => {
    const exportColumns = columns.filter(
      (column) => column.id !== "optionsButton"
    );
    const exportData = page.map((row) => {
      const rowData = {};
      exportColumns.forEach((column) => {
        rowData[column.Header] = row.values[column.accessor];
      });
      return rowData;
    });
    onDownload(exportData, exportColumns);
  };

  //download to pdf:
  const exportPDF = () => {
    // console.log("PDF");
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3, or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Driver Table";
    const headers = [
      [
        t("Name"),
        t("Father Name"),
        t("NID"),
        t("Phone#"),
        t("Driving Permit"),
        t("Car Num Plat"),
        t("Province"),
      ],
    ];

    const data = page.map((row) => [
      row.original.firstName,
      row.original.fatherName,
      row.original.nid,
      row.original.phone,
      row.original.drivingPermit,
      row.original.carNumPlat,
      row.original.province,
    ]);

    doc.text(title, marginLeft, 40);
    doc.autoTable({
      startY: 50,
      head: headers,
      body: data,
    });

    doc.save("report.pdf");
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm-12 col-md-8">
          <input
            type="text"
            className="form-control"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            placeholder={t("general_key_words.search")}
          />
        </div>
        <div className="col-sm-12 col-md-4">
          <div className="bg-white rounded-2 mt-2 mt-lg-0 mb-0 d-flex flex-row align-items-center justify-content-between">
            <div onClick={handleExportToExcel}>
              <FaFileExcel
                style={{ color: "green", cursor: "pointer", fontSize: "25px" }}
              />
            </div>
            <div className="d-flex flex-row align-items-center">
              <span className="mx-2">{t("general_key_words.show")}</span>
              <select
                value={pageSize}
                className="form-select"
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 50, 100, 200, 400, 800, 1600].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table
          className="table table-hover"
          id="property-table"
          {...getTableProps()}
          ref={tableRef}
          border="1"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key="header">
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Display sorting indicators */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.original.id}>
                  {row.cells.map((cell, index) => {
                    //   return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                    return (
                      <td {...cell.getCellProps()}>
                        {index === row.cells.length - 1
                          ? null
                          : // <AllControlOptionButton
                          //   record={row.original}
                          //   section={"driver"}
                          // />
                          // cell.column.id === 'regGregorianDate' ? (
                          cell.column.id === "gre_date"
                          ? // Assuming your date is in the format YYYY-MM-DD
                            // Adjust format as needed
                            jalaliMoment(new Date(cell.value).toDateString())
                              .locale("fa")
                              .format("YYYY-MM-DD")
                          : cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            <tr>
              <td className="text-center fw-bold" colSpan={columns.length}>
                {t("invoice.totalPrice")}: {totalPrice} (AF)
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        {pageCount > 1 && (
          <span>
            <span>
              {t("general_key_words.page")}{" "}
              <strong>
                {pageIndex + 1} / {pageCount}
              </strong>{" "}
            </span>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
          </span>
        )}
      </div>
    </div>
  );
};

export default AllApprovedInvoiceTable;
