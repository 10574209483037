import React from "react";
import { useSelector } from "react-redux";
import SearchPanel from "../../shared/SearchPanel";
import PageHeader from "../../Utility/PageHeader";
import { useTranslation } from "react-i18next";
import SearchResults from "./SearchResults";
import NoContentsFound from "../../shared/NoContentsFound";
import SearchHeader from "./SearchHeader";
import { useState } from "react";
import { t } from "i18next";

const SearchPage = () => {
  const data = useSelector((state) => state.search.data);
  const AllData = useSelector((state) => state.search);

  
//   const query = useSelector((state) => state.search.query);
  // const { t } = useTranslation("translation");
  // if(data){
  //   console.log("Comming Data for Search:");
  //   console.log(AllData);
  // }

  return (
    <div className="mt-2">
      <PageHeader title={t("general_key_words.reports")} />
      <SearchPanel />
      <SearchHeader title={t("general_key_words.searchResult")}/>
      { data && data.length > 0 ? (
        <SearchResults allData={AllData}/>
      ) : (
        <NoContentsFound
          message={t("general_key_words.notFounResultWithQuery")}
        />
      )}
    </div>
  );
};

export default SearchPage;
