import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormHeader from "../../Utility/FormHeader";

import PageHeader from "../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchDriverData,
  FetchDriverWaitingData,
  FetchDriverWaitingDataList,
} from "../../redux/vehicleRegistration/FetchDriversData";
import LoadingAnimation from "../../shared/LoadingAnimation";
import NoContentsFound from "../../shared/NoContentsFound";
import DriverTabularView from "./DriverTabularView";
// import { sendSMS } from "./SendSMS";
import CarTable from "./CarTable";
import CarTable3 from "./CarTable3";

export default function DriverWaitingList() {
  // sendSMS();
  const { t } = useTranslation("translation");
  const data = useSelector((state) => state.vehicle.data);
  const CarsData = useSelector((state) => state.vehicle);
  const loading = useSelector((state) => state.vehicle.loading);
  // const progress = useSelector((state) => state.vehicle.progress);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);

  useEffect(() => {
    dispatch(FetchDriverWaitingDataList(token));
  }, []);
  return (
    <div>
      <div className="mt-2">
        <PageHeader title={t("vehicle_details_form.carDetailsRegForm")} />
      </div>
      {loading ? (
        <LoadingAnimation />
      ) : data && data.length>0? (
        <CarTable3 carsData={CarsData} recordType={t("vehicle_details_form.colorWaitinVehicleList")} />
      ) : (
        <NoContentsFound message={t("general_key_words.dataNotFound")} />
      )}
    </div>
  );
}
