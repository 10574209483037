import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingAnimation from "../../shared/LoadingAnimation";
import PageHeader from "../../Utility/PageHeader";
import NoContentsFound from "../../shared/NoContentsFound";
import { FetchControlID } from "../../redux/ControlSection/FetchControlID";
import { BsFillTrashFill } from "react-icons/bs";
import { AiFillEdit } from "react-icons/ai";
import DriverGridView from "../car-registration/DriverGridView";
import { FaDownload } from "react-icons/fa";
import DownloadLoading from "../../shared/DownloadLoading";
import toast from "react-hot-toast";
import { FILES_BASE_URL, FRONT_BASE_URL } from "../../Utility/Domain";

const ViewControl = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.control.loading);
  const record = useSelector((state) => state.control.record);
  //   const selectedDelete = useSelector((state) => state.control.selectedDelete);
  const token = useSelector((state) => state.authentication.token);
  const { id } = useParams();
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const downloadLoading = useSelector((state)=>state.download.loading)
  // const domain = "http://localhost:3000/control-list/";
  const handleEdit = () => {
    // navigate(`edit/${record.id}?driverID=${record.driver.id}`);
    window.open(`${FRONT_BASE_URL}control-list/edit/${record.id}?driverID=${record.driver.id}`);
  };

  // console.log("Records:")
  // console.log(record)
  useEffect(() => {
    if (id) {
      dispatch(FetchControlID(token, id));
    }
  }, []);

  const downloadControlFile = (fileName) => {
    // console.log("File Name:",fileName);
    if (fileName) {
      // console.log("File Name:",fileName);
      // return `${FILES_BASE_URL}/control_files/${fileName}`;
      window.open(`${FILES_BASE_URL}/control_files/${fileName}`, "_blank");
      // console.log("Called::",fileName)
      // const data = {
      //   path: "app/public/driver/control/",
      //   name: fileName,
      // };
      // dispatch(DownloadFile(data, token));
    }else{
      toast.error("File not upload for this record")
    }
  };
  return loading ? (
    <LoadingAnimation />
  ) : (
    <div className="">
      <PageHeader title={t("traffic_control.controlAndDriverInfo")} />
      {record ? (
        <div className="mt-2 bg-white rounded-3 ">
          <div className="p-3 p-lg-5">
            <h4 className="fw-semibold">
              {t("traffic_control.controlInformation")}
            </h4>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">
                    {t("traffic_control.trafficAgentName")}:
                  </span>
                  <span className="mx-3 fw-semibold">
                    {record.traffic_agent_name}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">
                    {t("traffic_control.companyAgentName")}:
                  </span>
                  <span className="mx-3 fw-semibold">
                    {record.company_agent_name}
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">{t("general_key_words.date")}:</span>
                  <span className="mx-3 fw-semibold">{record.Per_date}</span>
                </div>
              </div>
              <div className="col-12 mt-3">
                <h6 className="fw-semibold">
                  {t("traffic_control.trafficAgentRemark")}:
                </h6>
                <p className="bg-light p-3 rounded-3">
                  {record.traffic_remark}
                </p>
              </div>
              <div className="col-12 mt-3">
                <h6 className="fw-semibold">
                  {t("traffic_control.companyAgentRemark")}:
                </h6>
                <p className="bg-light p-3 rounded-3">
                  {record.company_remark}
                </p>
              </div>
              <DriverGridView driver={record.driver} />
              <hr className="my-4" />
              <h4 className="col-12 fw-semibold">
                {t("vehicle_details_form.attachements")}
              </h4>
              
                <div className="col-sm-12 col-md-6 col-lg-4 text-center  mb-2">
                {record.approve_file && (
                <button onClick={e=>downloadControlFile(record.approve_file)} className="btn btn-primary w-75 d-flex align-items-center justify-content-center gap-2">
                {downloadLoading ? (
                      <DownloadLoading />
                    ) : (
                      <FaDownload className="bi" />
                    )}
                  {t("traffic_control.controAttachement")}
                </button>
                )}
                
              </div>
              <div className="col-sm-12 col-md-4 col-lg-2"> 
              {record.control_status !== "approved" &&
              record.control_status !== "rejected" && (
                <button
                    className="btn btn-primary d-flex align-items-center  gap-2"
                    onClick={handleEdit}
                  >
                    <AiFillEdit className="bi" /> {t("general_key_words.edit")}
                </button>
                )}
              </div>
            </div>

            {/* {record.control_status !== "approved" &&
              record.control_status !== "rejected" && (
                <div className="d-flex flex-row justify-content-center align-items-center gap-3">
                  <button
                    className="btn btn-primary d-flex align-items-center  gap-2"
                    onClick={handleEdit}
                  >
                    <AiFillEdit className="bi" /> {t("general_key_words.edit")}
                  </button> */}
                  {/* <button className="btn btn-danger d-flex align-items-center  gap-2"> */}
                    {/* {selectedDelete ? (
                   <InlineLoadingAnimation />
                 ) : ( */}
                    {/* <BsFillTrashFill className="bi" /> */}
                    {/* )} */}
                    {/* Delete
                  </button> */}
                {/* </div>
              )} */}
          </div>
        </div>
      ) : (
        <NoContentsFound message={"No data was found on server"} />
      )}
    </div>
  );
};

export default ViewControl;
