import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const InvoiceNav2 = () => {
  const { t } = useTranslation("translation");
  // const user = useSelector((state) => state.authentication.user);
  const user = useSelector((state)=> state.authentication.user)
  return (
    <ul className="nav justify-content-start bg-white">
      {(user.role ==="super" || user.role ==="finance") &&(<>
      <li className="nav-item">
        <NavLink
        //   to="/control-list/waiting"
        to="/approved_invoice-list/collecting-cash"
          className={({ isActive }) =>
            isActive ? "nav-link text-primary" : "nav-link text-black-50"
          }
          end
        >
            {t("invoice.generatedInvoice")}
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink
        //   to="/control-list/waiting"
        to="/approved_invoice-vehicle-list/collecting-cash"
          className={({ isActive }) =>
            isActive ? "nav-link text-primary" : "nav-link text-black-50"
          }
          end
        >
            {t("invoice.invoiceAndItems")}
        </NavLink>
      </li>

      </> 
      
      )}

    </ul>
  );
};

export default InvoiceNav2;
