import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { BsCloudUploadFill } from "react-icons/bs";
import FileField from "../../Utility/FileField";
import { acceptedFileTypes, dariControlStatus, engControlStatus } from "../../Data/statuses";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DriverDetails from "./DriverDetails";
import DriverSelectionMessage from "./DriverSelectionMessage";
import DateConverter from "../../Utility/DateConverter";
import PageHeader from "../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "../../shared/ProgressBar";
import APIFormErrors from "../../Utility/APIFormErrors";
import { FetchControlID } from "../../redux/ControlSection/FetchControlID";
import { UploadUpdateControlData } from "../../redux/ControlSection/UploadUpdateControlData";

export default function ControlEdit() {
  const { t } = useTranslation("translation");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const driverID = searchParams.get("driverID");

  const loading = useSelector((state) => state.control.loading);
  const progress = useSelector((state) => state.control.progress);
  const APIErrors = useSelector((state) => state.control.APIErrors);
  const token = useSelector((state) => state.authentication.token);
  const update = useSelector((state) => state.control.record);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [value, setValue] = useState();
  // console.log(driverID);
  const initialValues = {
    trafficAgentName: update ? update.traffic_agent_name : "",
    companyAgentName: update ? update.company_agent_name : "",
    regGregorianDate: update ? update.gre_date : "",
    regPersianDate: update ? update.Per_date : "",
    // carDetailsFkId: driverID ? driverID : "",
    controlStatus: update ? update.control_status : "",
    trafficAgentRemark: update ? update.traffic_remark : "",
    companyAgentRemark: update ? update.company_remark : "",
    controAttachement: "",
    approveFileName: update ? update.approve_file : "",
  };

  useEffect(() => {
    if (id) {
      dispatch(FetchControlID(token, id));
    }
  }, []);

  const validationSchema = Yup.object().shape({
    trafficAgentName: Yup.string().required(t("validation_errors.trafficAgentNameRequired")),
    companyAgentName: Yup.string().required(t("validation_errors.companyAgentNameRequired")),
    regGregorianDate:  Yup.string().required(t("validation_errors.regGregorianDate")),
    regPersianDate: Yup.string().required(t("validation_errors.regPersianDate")),
    // conHijriDate: Yup.string().required("Hijri Date is required"),
    // carDetailsFkId: Yup.string().required("Car ID is required"),
    controlStatus: Yup.string().required(t("validation_errors.controlStatusRequired")),
    trafficAgentRemark: Yup.string().required(t("validation_errors.trafficRemarkRequired")),
    companyAgentRemark: Yup.string().required(t("validation_errors.companyRemarkRequired")),
    controAttachement: Yup.mixed()
      //   .required("Driving permit file is required")
      .test("fileSize", t("validation_errors.fileSize"), (value) => {
        if (value) {
          return value.size <= 1 * 1024 * 1024;
        }
        return true;
      })
      // .test("fileFormat", "Only PDF are allowed", (value) => {
      .test("fileFormat", t("validation_errors.imageType"), (value) => {
        if (value) {
          return acceptedFileTypes.includes(value.type);
          // return value.type === "application/pdf";
        }
        return true;
      }),
  });

  return (
    <div className="pt-2">
      <PageHeader title={t("traffic_control.controlRegistrationForm")} />
      {driverID ? (
        <>
          <DriverSelectionMessage
            type={"success"}
            message={t("toast.VehicleSelectedWithID")+ driverID}
          />
          <DriverDetails id={driverID} />
        </>
      ) : (
        <DriverSelectionMessage
          type={"danger"}
          message={`driver is not selected`}
        />
      )}
      {APIErrors && (
        <div class=" bg-light rounded-3 mt-2">
          <div class="container-fluid">
            <APIFormErrors errors={APIErrors} />
          </div>
        </div>
      )}
      <div className="mt-2 bg-white rounded-3 p-3">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            values.id= id;
            values.driverID=driverID;
            // console.log("Values555666:");
            // console.log(values);
            dispatch(UploadUpdateControlData(values, token, id));
            navigate("/control-list");
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, handleReset, values }) => (
            <Form>
              <div className="row g-3">
                {/* account names section */}
                {/* <div className="col-sm-12">
       
                  <h4 className="text-muted">{t("traffic_control.trafficAgentName")}</h4>
                </div> */}
                <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="firstName"
                      className="col-sm-3 col-form-label"
                    >
                      {t("traffic_control.trafficAgentName")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                      <Field
                        type="text"
                        name="trafficAgentName"
                        id="trafficAgentName"
                        placeholder={t("traffic_control.trafficAgentName")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="trafficAgentName"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="companyAgentName"
                      className="col-sm-3 col-form-label"
                    >
                      {t("traffic_control.companyAgentName")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                      <Field
                        type="text"
                        name="companyAgentName"
                        id="companyAgentName"
                        placeholder={t("traffic_control.companyAgentName")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="companyAgentName"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>

                {/* account email and contact section*/}

                <DateConverter
                  t={t}
                  shamsiName="conPersianDate"
                  miladiName="conGregorianDate"
                  shamsiId="conPersianDate"
                  miladiId="conGregorianDate"
                />

                {/* <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="carDetailsFkId"
                      className="col-sm-3 col-form-label"
                    >
                     {t("traffic_control.carDetailsFkId")}
                      
                      <span className="text-danger"> *</span>
                    </label>

                    <div className="col-sm-9">
                      <Field
                        type="number"
                        name="carDetailsFkId"
                        id="carDetailsFkId"
                        placeholder={t("traffic_control.carDetailsFkId")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="carDetailsFkId"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>
                 */}

                <div className="col-sm-12">
                  <div className="row">
                    <label
                      htmlFor="trafficAgentRemark"
                      className="col-sm-2 col-form-label"
                    >
                      {t("traffic_control.trafficAgentRemark")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-10">
                      <Field
                        as="textarea"
                        name="trafficAgentRemark"
                        id="trafficAgentRemark"
                        placeholder={t("traffic_control.trafficAgentRemark")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="trafficAgentRemark"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="row">
                    <label
                      htmlFor="companyAgentRemark"
                      className="col-sm-2 col-form-label"
                    >
                      {t("traffic_control.companyAgentRemark")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-10">
                      <Field
                        as="textarea"
                        name="companyAgentRemark"
                        id="companyAgentRemark"
                        placeholder={t("traffic_control.companyAgentRemark")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="companyAgentRemark"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="controlStatus"
                      className="col-sm-3 col-form-label"
                    >
                      {t("traffic_control.controlStatus")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                      <Field
                        as="select"
                        id="controlStatus"
                        name="controlStatus"
                        className="form-select"
                      >
                        <option value="">
                          {t("general_key_words.choose")}
                        </option>
                        <option value="approved">
                            {t("general_key_words.approve")}
                        </option>
                        <option value="pending">
                            {t("general_key_words.pending")}
                        </option>
                        <option value="rejected">
                           {t("general_key_words.reject")}
                        </option>
                        {/* {dariControlStatus.map((status, index) => ( */}
                          
                        {/* <option value={value} key={index}>
                            {status}
                          </option>
                         
                        ))} */}
                      </Field>
                      <ErrorMessage
                        name="controlStatus"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-4">
                  <label
                    htmlFor="controAttachement"
                    className="form-label border shadow-sm text-muted rounded px-5 py-2 w-100 text-center"
                  >
                    {t("traffic_control.controAttachement")}
                    <span className="text-danger"> *</span>
                    <span
                      className={
                        values.controAttachement
                          ? "ms-3 fs-5 text-success"
                          : "ms-3 fs-5 text-danger"
                      }
                    >
                      <BsCloudUploadFill />
                    </span>
                  </label>
                  {values.controAttachement ? (
                    <div className="text-muted">
                      <span className="text-primary">
                        {t("traffic_control.controAttachement")}
                      </span>
                      : {values.controAttachement.name}
                    </div>
                  ) : (
                    <div className="text-muted">
                      <span className="text-primary">
                        {t("traffic_control.controAttachement")}
                      </span>
                      : {values.approveFileName}
                    </div>
                  )}

                  <FileField
                    name={"controAttachement"}
                    id={"controAttachement"}
                    accept={"image/*"}
                    // accept={".pdf"}
                  />
                  <ErrorMessage
                    name="controAttachement"
                    className="text-danger"
                    component="div"
                  />
                </div>

                <hr className="mx-auto my-3 w-75" />

                {loading && <ProgressBar progress={progress} />}

                <button
                  className="btn btn-primary w-100 py-2"
                  disabled={loading && true}
                  type="submit"
                >
                  {loading ? (
                    <>
                      <div
                        class="mx-3 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="visually-hidden">{("general_key_words.loading")}</span>
                      </div>
                      <span>{("general_key_words.processingPleaseWait")}</span>
                    </>
                  ) : 
                  // t("general_key_words.updateRecord")
                  t("vehicle_details_form.registerForm")
                  }
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
