import React from "react";
import {CiMenuFries} from "react-icons/ci"
import {BsSearch} from "react-icons/bs"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const LayoutHeader = () => {
  const {t}= useTranslation("translation")
  return (
    <header className="navbar bg-dark flex-md-nowrap p-0 rounded-2 mt-1">
      <Link
        className="navbar-brand col-md-3 col-lg-12 text-center text-capitalize me-0 px-2 fs-6 text-white hover-link"
        to="/"
      >
        {t("general_key_words.company_title")}
      </Link>
      <ul className="navbar-nav flex-row d-md-none">
        {/* <li className="nav-item text-nowrap">
          <button
            className="nav-link px-3 text-white collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSearch"
            aria-controls="navbarSearch"
            aria-expanded="false"
            aria-label="Toggle search"
          >
            <BsSearch />
          </button>
        </li> */}
        <li className="nav-item text-nowrap">
          <button
            className="nav-link px-2 text-white fs-5"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <CiMenuFries/>
          </button>
        </li>
      </ul>

      <div id="navbarSearch" className="navbar-search w-100 collapse">
        <input
          className="form-control w-100 rounded-0 border-0"
          type="text"
          placeholder="Search"
          aria-label="Search"
        />
      </div>
    </header>
  );
};

export default LayoutHeader;
