import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import PageHeader from "../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import LoadingAnimation from "../../shared/LoadingAnimation";
import NoContentsFound from "../../shared/NoContentsFound";
// import { sendSMS } from "./SendSMS";
import CarTable3 from "./CarTable3";
import { useParams } from "react-router-dom";
import { FetchControlledDriverByBranchId, FetchDriverByBranchId } from "../../redux/vehicleRegistration/FetchDriversByBranchId";

export default function DriverListById(props) {
  // sendSMS();
  const { t } = useTranslation("translation");
  const data = useSelector((state) => state.vehicle.data);
  const CarsData = useSelector((state) => state.vehicle);
  const loading = useSelector((state) => state.vehicle.loading);
  // const progress = useSelector((state) => state.vehicle.progress);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);
  const { id } = useParams();
  // console.log("Branch ID:",id);
  // console.log("Data:")

  const type = props.type;
  // console.log("Type:",type)
 
  useEffect(() => {
    if(type==="allDrivers"){
      dispatch(FetchDriverByBranchId(token, id));
    }else if(type==="approvedDrivers"){
      dispatch(FetchControlledDriverByBranchId(token, id));
    }
    
  }, []);

  if(data)
  // console.log("Branch name:")
  // console.log(CarsData.branchName)
  // console.log("carsData:")
  // console.log(CarsData)
  return (
    <div>
      <div className="mt-2">
        {CarsData && data.length>0 ?
          // <PageHeader title={t("vehicle_details_form.carDetailsRegForm")} />
          <PageHeader title={t("branch.allRegisteredVehicleBy") + ` ${CarsData.data[0].branchName
          }`} />
        :null}
        
      </div>
      {loading ? (
        <LoadingAnimation />
      ) : data && data.length>0? (
        <CarTable3 carsData={CarsData} />
      ) : (
        <NoContentsFound message={t("general_key_words.dataNotFound")} />
      )}
    </div>
  );
}
