import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import { FetchInvoiceError, FetchInvoiceStart, FetchInvoiceSuccess } from "./InvoiceSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const FetchReprintInvoiceData = (token,id) => async (dispatch) => {
  dispatch(FetchInvoiceStart());
  // http request to backend
  try {
    const response = await http.get(`api/drivers/invoices/reprinted/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    }); 

    // console.log(response.data)
    toast.success(t("toast.data_fetch"));
    dispatch(FetchInvoiceSuccess(response.data.record));
  } catch (error) {
    dispatch(FetchInvoiceError());
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};
