import React from "react";
import {
  BsBookmarkCheckFill,
  BsChevronDoubleDown,
  BsTrash,
  BsViewList,
} from "react-icons/bs";
import { t } from "i18next";
import { FcAddDatabase } from "react-icons/fc";
import { AiFillEdit, AiOutlineMessage } from "react-icons/ai";
import { FaMinus } from "react-icons/fa";
import InlineLoadingAnimation from "../../shared/InlineLoadingAnimation";
import { useDispatch, useSelector } from "react-redux";
import { RejectWaitingRecord } from "../../redux/WaitingSection/RejectWaitingRecord";
import { AddToControl } from "../../redux/vehicleRegistration/AddToControl";
import { SelectMessageRecord } from "../../redux/WaitingSection/WaitSlice";
import Swal from "sweetalert2";
const TableOptions = ({ record, section }) => {
  const inlineLoading = useSelector((state) => state.wait.inlineLoading);
  const selected = useSelector((state) => state.wait.selected);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);
  const user = useSelector((state) => state.authentication.user);

  const handleControl = () => {
    Swal.fire({
      title: t("general_key_words.areYouSure"),
      text: t("general_key_words.confirmChangeMsg"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("general_key_words.yesControl"),
      cancelButtonText: t("general_key_words.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(AddToControl(token, record.dId, record.id, "table"));
      }
    });
  };

  const handleView = () => {
    window.open(`/view-list/view/${record.dId}`, "_blank");
  };

  const handleReject = () => {
    Swal.fire({
      title: t("general_key_words.areYouSure"),
      text: t("general_key_words.deleteConfirmMsg"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("general_key_words.reject"),
      cancelButtonText: t("general_key_words.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(RejectWaitingRecord(token, record.id));
      }
    });
  };

  const messageHandler = () => {
    // dispatch(SelectMessageRecord(record.driver));
    dispatch(SelectMessageRecord(record));
  };
  return selected === record.id && inlineLoading ? (
    <div className="">
      <InlineLoadingAnimation />
    </div>
  ) : (
    <div className="btn-group">
      <button
        className="btn btn-secondary d-flex align-items-center gap-2 rounded"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <BsChevronDoubleDown />
        {t("general_key_words.choose")}
      </button>
      <ul className="dropdown-menu" style={{ zIndex: 2000 }}>
        {user.role !=="user" && user.staff !=="traffic staff" && (
          <>
        <li>
          <a
            className="dropdown-item d-flex align-items-center gap-2 text-success"
            type="button"
            onClick={handleControl}
          >
            <BsBookmarkCheckFill />
            {t("general_key_words.addToControl")}
          </a>
        </li>
        
        <li>
          <button
            type="button"
            class="dropdown-item d-flex align-items-center gap-2"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            data-bs-whatever="@getbootstrap"
            onClick={messageHandler}
          >
            <AiOutlineMessage />
            {t("message.sendMessage")}
          </button>
        </li>

        <hr />
        </>
        )}

        <li>
          <a
            className="dropdown-item d-flex align-items-center gap-2 text-muted"
            type="button"
            onClick={handleView}
          >
            <BsViewList className="bi" />
            {t("general_key_words.view")}
          </a>
        </li>
        {user.staff !=="traffic staff" && (
        <li>
          <a
            className="dropdown-item d-flex align-items-center gap-2 text-danger"
            type="button"
            onClick={handleReject}
          >
            <BsTrash className="bi" />
            {t("general_key_words.reject")}
          </a>
        </li>
        )}
      </ul>
    </div>
  );
};

export default TableOptions;
