import axios from 'axios';
import { SendError } from '../../redux/Messages/MessageSlice';
import toast from 'react-hot-toast';

export const sendSMS = async (receiver, message, dispatch) => {
  const modifiedReceiver = `+93${receiver.slice(1)}`;
  // console.log("SMS sent");
  // console.log("Phone:");
  // console.log(modifiedReceiver);
  // console.log("message:");
  // console.log(message);

  const accountSid = 'AC2cf4ad563a1e5b4b50b1f90562b5e142';
  const authToken = '23bb56903a1db3a5b9b146cbb5982e3a';
  const twilioPhoneNumber = '+12563056860';
  // const recipientPhoneNumber = '+93772709870';
  const recipientPhoneNumber = modifiedReceiver;
  // const messageBody = 'Hello, this is a test message from my React app!';
  // const messageBody = 'اسلام علیکم، لطفا گلگل کاری و کوپی کشی موتر خود را الی تاریخ ۱۴۰۲-۰۸-۰۱ تمام نموده و موتر خود را به نماینده گی کمپنی ستاتیک حاضر نمایید.';
  const messageBody = message;

  const twilioApiUrl = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`;
//   const twilioApiUrl = `https://api.twilio.com/2010-04-01/Accounts/AC2cf4ad563a1e5b4b50b1f90562b5e142/Messages.json`;

  try {
    const response = await axios.post(
      twilioApiUrl,
      new URLSearchParams({
        To: recipientPhoneNumber,
        From: twilioPhoneNumber,
        Body: messageBody,
      }),
      {
        auth: {
          username: accountSid,
          password: authToken,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    // console.log('Message sent:', response.data);
  } catch (error) {
    toast.error(error.message);
    // dispatch(SendError());
    console.error('Error sending message:', error);
  }
};

// Call the function to send the SMS
// sendSMS();
