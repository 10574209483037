import React, { useEffect, useState } from "react";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import DatePicker from "react-multi-date-picker";
import jalaliMoment from "jalali-moment";
import DateObject from "react-date-object";
import { ErrorMessage, useFormikContext } from "formik";
const EditDateConverter = ({
  t,
  shamsiName,
  miladiName,
  ShamsiValue,
  MiladiValue
}) => {
  const [hijriDate, setHijriDate] = useState(new Date());
  const [meladiDate, setMeladiDate] = useState(
    MiladiValue
  );

  const [shamsiDate, setShamsiDate] = useState(ShamsiValue);
  const formik = useFormikContext();

  const handleInputChange = (event) => {
    // console.log(event)
    if (event === null) {
      // console.log("Invalid date");
    } else {
      // console.log(event)
      PersianDateConverter(event);
    }
  };
  const PersianDateConverter = (e) => {
    const name = "regPersianDate";
    // if(e.isValid()){
    const dateObject = new DateObject(e.toDate());
    const jalaliDate = jalaliMoment(e.toDate());

    const persianDateString = jalaliDate.locale("fa").format("YYYY-MM-DD");
    // console.log(persianDateString)
    const gregorianDate = dateObject.format("YYYY-MM-DD");

    setMeladiDate(gregorianDate);
    setShamsiDate(e.toDate());
    // setDateInFormData(gregorianDate);
    formik.setFieldValue(name, persianDateString);
    // }else{
    //   console.log("Invalid Persian date!");
    // }
    document.getElementById("regGregorianDate").focus();
  };

  const MeladiDateConverter = (e) => {
    const name = "regGregorianDate";
    const gregorianDate = e.target.value;
    const jalaliDate = jalaliMoment(gregorianDate);

    const persianDateString = jalaliDate.locale("fa").format("YYYY-MM-DD");
    // setHijriDate(persianDateString);
    setMeladiDate(gregorianDate);
    setShamsiDate(persianDateString); // Update the Persian date input
    formik.setFieldValue(name, gregorianDate);
    document.getElementById("regPersianDate").focus();
  };
  return (
    <>
      <div className="col-sm-6">
        <div className="row">
          <label htmlFor="regPersianDate" className="col-sm-3 col-form-label">
            {t("vehicle_details_form.regPersianDate")}
            <span className="text-danger"> *</span>
          </label>
          <div className="col-sm-9">
            <DatePicker
              name="regPersianDate"
              id="regPersianDate"
              calendar={persian}
              locale={persian_fa}
              inputClass="form-control"
              containerStyle={{ minWidth: "100%" }}
              value={shamsiDate} // Update to shamsiDate
              // onChange={PersianDateConverter}
              onChange={handleInputChange}
              onBlur={handleInputChange}
              onInput={handleInputChange}
            />
            <ErrorMessage
              name="regPersianDate"
              component="div"
              className="alert alert-danger p-2 my-1"
            />
          </div>
        </div>
      </div>

      <div className="col-sm-6">
        <div className="row">
          <label htmlFor="regGregorianDate" className="col-sm-3 col-form-label">
            {t("vehicle_details_form.regGregorianDate")}
            <span className="text-danger"> *</span>
          </label>
          <div className="col-sm-9">
            <input
              type="date"
              name="regGregorianDate"
              id="regGregorianDate"
              onChange={MeladiDateConverter}
              onBlur={MeladiDateConverter}
              onInput={MeladiDateConverter}
              value={meladiDate}
              className="form-control"
            />
            <ErrorMessage
              name={miladiName}
              component="div"
              className="alert alert-danger p-2 my-1"
            />
          </div>
        </div>
      </div>
      {/* <div className="col-sm-6">
        <div className="row">
          <label htmlFor="regHijriDate" className="col-sm-3 col-form-label">
            {t("vehicle_details_form.regHijriDate")}
            <span className="text-danger"> *</span>
          </label>
          <div className="col-sm-9">
            <DatePicker
              name="regHijriDate"
              id="regHijriDate"
              calendar={persian}
              locale={persian_fa}
              inputClass="form-control"
              containerStyle={{ minWidth: "100%" }}
              // placeholder={placeholder}
              value={hijriDate}
              onChange={HijriDateConverter}
            />

            <ErrorMessage
              name="regHijriDate"
              component="div"
              className="alert alert-danger p-2 my-1"
            />
          </div>
        </div>
      </div> */}
    </>
    // <div className="d-flex flex-row gap-3">
    //   <div className="w-50">
    //     <input
    //       type="date"
    //       name={name}
    //       id={id}
    //       className="form-control"
    //       // placeholder={placeholder}
    //       value={meladiDate}
    //       onChange={MeladiDateConverter}
    //     />
    //   </div>
    //   <div className="w-50">
    // <DatePicker
    //   name="hijriDate"
    //   id="hijriDate"
    //   calendar={persian}
    //   locale={persian_fa}
    //   inputClass="form-control"
    //   containerStyle={{ minWidth: "100%" }}
    //   // placeholder={placeholder}
    //   value={hijriDate}
    //   onChange={HijriDateConverter}
    // />
    //   </div>
    // </div>
  );
};

export default EditDateConverter;
