import toast from "react-hot-toast";

import { t } from "i18next";
import { FetchError, FetchStart, FetchSuccess } from "./PublicDriverSlice";
import { http } from "../../Utility/APIRequest";
import { CSRFTokenRefresh } from "../../Utility/CSRFTokenRefresh";

export const FetchPublicDriverData = (search) => async (dispatch) => {
  dispatch(FetchStart());

  // http request to backend
  try {
    const objectTemp = {
      search: search,
    };
    const primaryRequest = await http.get(`sanctum/csrf-cookie`);
    const response = await http.post("api/public/driver/search", objectTemp);

    const object = {
      data: response.data.records,
      message: response.data.message,
      type: response.data.status ? "success" : "danger",
    };
    dispatch(FetchSuccess(object));

    // console.log(response.data);
  } catch (error) {
    dispatch(FetchError());
    if (error.response) {
      // do nothing
      if (error.response.status === 419);
      {
        CSRFTokenRefresh();
      }
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
      toast.error(t("toast.apiOffline"));
    }
  }
};
