import React, { useRef } from "react";
import {
  BsArrowReturnLeft,
  BsArrowReturnRight,
  BsBookmarkCheckFill,
  BsCashCoin,
  BsChevronDoubleDown,
  BsCollection,
  BsFiletypeDoc,
  BsFillTrash3Fill,
  BsViewList,
} from "react-icons/bs";
import { t } from "i18next";
import InlineLoadingAnimation from "../../shared/InlineLoadingAnimation";
import { useDispatch, useSelector } from "react-redux";
import { PayInvoice } from "../../redux/InvoicesSection/PayInvoice";
import InvoiceCollectionModal from "./InvoiceCollectionModal";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import FileField from "../../Utility/FileField";
import { SetInvoice } from "../../redux/InvoicesSection/InvoiceSlice";
import Swal from "sweetalert2";
import { InvoiceClearance } from "../../redux/InvoicesSection/InvoiceClearance";
import { RejectGeneratedInvoice } from "../../redux/InvoicesSection/RejectGeneratedInvoice";

const CollectingCashOptionButton = ({ record, section, type }) => {
  const inlineLoading = useSelector((state) => state.invoice.inlineLoading);
  const selected = useSelector((state) => state.invoice.selected);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);
  // const formikRef = useRef();

  // end of form modal and validation

  const handleView = () => {
    window.open(
      `/approved_invoice-list/printed/${record.generate_invoice_id}`,
      "_blank"
    );
  };

  // const handleReject = () => {
  //    dispatch(RejectInvoice(token, record.generate_invoice_id));
  // };

 
  const handlePay = () => {
    // console.log("Pay:");
    // console.log(record.generate_invoice_id)
    // PayInvoice(token, record.generate_invoice_id, formData)
    //    formikRef.current.submitForm();
    dispatch(SetInvoice(record));
  };

  const handleStilam = (record)=>{
      // console.log("Record:",{record});
      const id=record.generate_invoice_id;
      const count = record.invoice_count;
      const amount = record.invoice_amount;

      const url = `/invoice-stilam/print/${id}/${count}/${amount}`;

      // Open a new window or tab with the URL
      window.open(url, '_blank');
      // window.open(`/invoice-stilam/print/${record.generate_invoice_id}`, "_blank");
      // const history = unstable_HistoryRouter();
      // history.push(`/invoice-stilam/print/${id}/${count}/${amount}`);
  }

  const handleCancelInvoice = (generatedInvoiceId) => {
      Swal.fire({
        title: t("general_key_words.areYouSure"),
        text: t("general_key_words.confirmChangeMsg")+"("+generatedInvoiceId+")",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("general_key_words.yesCancelGenerateIvoice"),
        cancelButtonText: t("general_key_words.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(RejectGeneratedInvoice(token, generatedInvoiceId, "generated"));
          // handleCheckBoxActivate();
          // console.log(generatedInvoiceId)
        }
      });

      // dispatch(InvoiceClearance(token, selectedItems));
      // console.log(selectedItems)
    
    // setSelectedItems([]);
  };
 

  return selected === record.id && inlineLoading ? (
    <div className="">
      <InlineLoadingAnimation />
    </div>
  ) : (
    <div className="btn-group">
      <button
        className="btn btn-secondary d-flex align-items-center gap-2 rounded"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <BsChevronDoubleDown />
        {t("general_key_words.choose")}
      </button>
      <ul className="dropdown-menu" style={{ zIndex: 2000 }}>
        <li>
          <a
            className="dropdown-item d-flex align-items-center gap-2 text-muted"
            type="button"
            onClick={handleView}
          >
            <BsViewList className="bi" />
            {t("general_key_words.view")}
          </a>
        </li>
        {record.invoice_status === "generated" ? (
          <>
            <li>
              <a
                className="dropdown-item d-flex align-items-center gap-2 text-muted"
                type="button"
                onClick={handlePay}
              >
                <BsCashCoin className="bi" style={{ color: "green" }} />
                {t("invoice.collectInvoce")}
              </a>
            </li>

            <li>
              <a
                className="dropdown-item d-flex align-items-center gap-2 text-muted"
                type="button"
                // onClick={handleStilam}
                onClick={(e) => handleStilam(record)}
              >
                <BsFiletypeDoc className="bi" style={{ color: "red" }} />
                {t("invoice.generateEstilam")}
              </a>
            </li>

            <li>
              <a
                className="dropdown-item d-flex align-items-center gap-2 text-muted"
                type="button"
                // onClick={handleStilam}
                onClick={(e) => handleCancelInvoice(record.generate_invoice_id)}
              >
                <BsArrowReturnRight className="bi" style={{ color: "red" }} />
                {t("general_key_words.reject")}
              </a>
            </li>
          </>
        ) : null}
      </ul>
    </div>
  );
};

export default CollectingCashOptionButton;
