import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ControlNav = () => {
  const { t } = useTranslation("translation");
  // const user = useSelector((state) => state.authentication.user);
  const user = useSelector((state)=> state.authentication.user)
  return (
    <ul className="nav justify-content-start bg-white">
      {user.staff !=="traffic staff" &&(<>
      <li className="nav-item">
        <NavLink
        //   to="/control-list/waiting"
        to="/control-list"
          className={({ isActive }) =>
            isActive ? "nav-link text-primary" : "nav-link text-black-50"
          }
          end
        >
            {t("traffic_control.allControlledVehicle")}
        </NavLink>
      </li>
      </> )}
      
      <li className="nav-item">
        <NavLink
        //   to="/control-list"
          to="/approved-control-list"
          className={({ isActive }) =>
            isActive ? "nav-link text-primary" : "nav-link text-black-50"
          }
          end
        >
            {t("dash_board_card.approvedControls")}
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink
        //   to="view-list"
          to="/control-list/waiting"
          className={({ isActive }) =>
            isActive ? "nav-link text-primary" : "nav-link text-black-50"
          }
          end
        >
          {t("general_key_words.pending")}
        </NavLink>
      </li>
      {user.staff !=="traffic staff" &&(<>
      <li className="nav-item">
        <NavLink
        //   to="view-list"
          to="/control-list/rejected"
          className={({ isActive }) =>
            isActive ? "nav-link text-primary" : "nav-link text-black-50"
          }
          end
        >
          {t("general_key_words.rejected")}
        </NavLink>
      </li>
      </>)}

    </ul>
  );
};

export default ControlNav;
