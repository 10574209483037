import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchDashboardData } from "../../../redux/dashboard/FetchDashboardData";
import { FetchComplatedVehicleDashBranchData, FetchControlledVehicleDashBranchData, FetchGeneratedInvoiceDashBranchData, FetchPendingInvoiceDashBranchData, FetchReceivedInvoiceDashBranchData, FetchVehicleDashBranchData, FetchWaitingVehicleDashBranchData } from "../../../redux/dashboard/FetchVehicleDash";
import LoadingAnimation from "../../../shared/LoadingAnimation";
import NoContentsFound from "../../../shared/NoContentsFound";
import PageHeader from "../../../Utility/PageHeader";
import { t } from "i18next";

const VehicleDash = (props) => {
const viewMorUrl = props.viewMorUrl;
const type = props.type;
// console.log("Type:",props.type)

const dispatch = useDispatch();
const token = useSelector((state) => state.authentication.token);
const data = useSelector((state) => state.vehicle.data);
const CarsData = useSelector((state) => state.vehicle);
const loading = useSelector((state) => state.vehicle.loading);


  // const domain = "http://localhost:3000/";

  const handleViewMore = (page)=>{
    // console.log(page)
    // window.location.assign(navigateUrl);
    window.open(`/${page}`, "_blank");
  }

  useEffect(() => {
    if(type==="allVehicle"){
      dispatch(FetchVehicleDashBranchData(token));
    }else if(type==="controlledVehicle"){
      dispatch(FetchControlledVehicleDashBranchData(token));
      
    }else if(type==="waitingVehicle"){
      dispatch(FetchWaitingVehicleDashBranchData(token));
    }
    else if(type==="complatedVehicle"){
      dispatch(FetchComplatedVehicleDashBranchData(token));
    }
    // driverComplated
    else if(type==="invoicePending"){
      dispatch(FetchPendingInvoiceDashBranchData(token));
    }
    
    else if(type==="invoiceGenerated"){
      dispatch(FetchGeneratedInvoiceDashBranchData(token));
    }
    else if(type==="invoiceReceived"){
      dispatch(FetchReceivedInvoiceDashBranchData(token));
    }
    // type="" viewMorUrl="invoice-received-list"
    
    else{
      // console.log("not all vehicle");
    }
    
  }, []);

  return  loading ? <LoadingAnimation/> :data ?(
    <>
    <PageHeader title={t("branch.allRegisteredVehicleBy")} />
    
    {/* <PageHeader title={t("branch.allRegisteredVehicleBy")` ${CarsData.data[0].branchName }`} /> */}
    <div className="rounded-3 row bg-light m-0 mt-2 py-5">
      {
        data.map((item,index)=>(
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="dash-card p-3 ">
                <div className="position-relative bg-white  rounded-3 shadow d-flex flex-column justify-content-center align-items-center">
                  <div className="top-color bg-primary shadow bg-opacity-75"></div>
                  <div className="d-flex flex-column  align-items-center justify-content-center py-5 px-3">
                    <h4 className="fw-semibold ">
                      {item.branchName}
                    </h4>
                    <hr className="w-75 mx-auto" />
                    <h3 className="text-primary">{item.driverCount}</h3>
                    {item.driverCount>0 ? 
                      <>
                        <button className="btn btn-primary"
                        onClick={(msg) => handleViewMore(`${viewMorUrl}/${item.id}`)}
                      >
                        {t("dash_board_card.details")}
                      </button>
                      </>
                    :null}
                  </div>
                </div>
              </div>
            </div>
            ))
          }
          </div>
    </>
   
  ) : <NoContentsFound message={"Data not found"}/>
};

export default VehicleDash;
