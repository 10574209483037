import React, { useEffect, useState } from "react";
import PageHeader from "../../../Utility/PageHeader";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { UpdateUserAccount } from "../../../redux/authentication/UpdateUserAccount";
import APIFormErrors from "../../../Utility/APIFormErrors";
import { ProgressBar } from "react-bootstrap";
import { t } from "i18next";
const ProfileSettings = () => {
  const user = useSelector((state) => state.authentication.user);
  const errors = useSelector((state) => state.authentication.APIErrors);
  const navigate = useNavigate();
  const loading = useSelector((state) => state.branch.loading);
  const token = useSelector((state) => state.authentication.token);
  const progress = useSelector((state) => state.authentication.progress);

  const dispatch = useDispatch();

  useEffect(()=>{
    // console.log('udpated')
  },[user])
  const initialValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("validation_errors.firstNameRequired")),
    lastName: Yup.string().required(t("validation_errors.lastNameRequired")),
    email: Yup.string()
      .email("invalid email address")
      .required("Email is required"),
  });

  return (
    <div>
      <PageHeader title={t("setting.userAccount")} />
      {errors && (
          <div className=" bg-light rounded-3 mt-2">
            <div className="container-fluid">
              <APIFormErrors errors={errors} />
            </div>
          </div>
        )}
      <div className="mt-2 bg-white rounded-3 p-3 px-lg-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            // console.log(values);
            dispatch(UpdateUserAccount(values,token))
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, handleReset, values }) => (
            <Form>
              <div className="row">
                <div className="col-sm-6 mb-2">
                  <label htmlFor="firstName" className="form-label">
                  {t("setting.firstName")} <span className="text-danger"> *</span>
                  </label>
                  <Field
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder={t("place_holder.typeFirstName")}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="alert alert-danger p-2 my-1"
                  />
                </div>
                <div className="col-sm-6 mb-2">
                  <label htmlFor="lastName" className="form-label">
                  {t("setting.lastname")} <span className="text-danger"> *</span>
                  </label>
                  <Field
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder={t("place_holder.typeLastname")}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="alert alert-danger p-2 my-1"
                  />
                </div>
                <div className="col-12 mb-2">
                  <label htmlFor="email" className="form-label">
                  {t("setting.email")} <span className="text-danger"> *</span>
                  </label>
                  <Field
                    type="text"
                    name="email"
                    id="email"
                    placeholder={"you@example.com"}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="alert alert-danger p-2 my-1"
                  />
                </div>
                <hr className="mx-auto my-3 w-75" />
                {loading && <ProgressBar progress={progress} />}
                <button
                  className="w-100 btn btn-primary "
                  disabled={loading}
                  type="submit"
                >
                  {loading ? (
                    <>
                      <div
                        class="mx-3 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="visually-hidden">{t("general_key_words.loading")}</span>
                      </div>
                      <span>{t("general_key_words.processingPleaseWait")}</span>
                    </>
                  ) : (
                    t("setting.updateAccount")
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ProfileSettings;
