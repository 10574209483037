import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  data: null,
  // for updating user record
  loading: null,
  APIErrors: null,
  progress: null,
  update: null,
};

const AuthenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    // making login request

    LogIn: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    LogOut: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
    },
    UploadStart: (state) => {
      state.loading = true;
      state.APIErrors = null;
      state.progress = null;
    },
    UploadSuccess: (state) => {
      state.loading = false;
      state.APIErrors = null;
      state.progress = null;
    },
    UploadError: (state, action) => {
      state.loading = false;
      state.APIErrors = action.payload;
      state.progress = null;
    },
    HandleProgress: (state, action) => {
      state.progress = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    FetchStart: (state) => {
      state.loading = true;
      state.data = null;
    },
    FetchSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    FetchError: (state) => {
      state.loading = false;
      state.data = null;
    },
    StartUpdate: (state, action) => {
      state.update = action.payload;
    },
    CancelUpdate: (state) => {
      state.update = null;
    },
    AccountUpdateStart: (state) => {
      state.loading = true;
    },
    AccountUpdateSuccess: (state, action) => {
      state.loading = false;
      state.update = null;
      state.data = action.payload;
      state.APIErrors = null;
      
    },
    Session: (state, action) => {
      // state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
  },
});

export const {
  LogIn,
  LogOut,
  setIsAuthenticated,
  UploadError,
  UploadSuccess,
  UploadStart,
  HandleProgress,
  setUser,
  FetchStart,
  FetchSuccess,
  FetchError,
  StartUpdate,
  CancelUpdate,
  AccountUpdateStart,
  AccountUpdateSuccess,
  Session
} = AuthenticationSlice.actions;
export default AuthenticationSlice.reducer;
