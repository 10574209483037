import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { provinces, provincesDari } from "../../Data/provinces";
import { BsCloudUploadFill } from "react-icons/bs";
import FileField from "../../Utility/FileField";
import FormHeader from "../../Utility/FormHeader";
import { acceptedFileTypes, engControlStatus } from "../../Data/statuses";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import DateObject from "react-date-object";

import jalaliMoment from "jalali-moment";
import moment from "jalali-moment";

export default function Waiting() {

  const [shamsiDate, setShamsiDate] = useState(new Date());
  const [meladiDate, setMeladiDate] = useState(new Date());

  const [solarDate, setSolarDate] = useState(new Date());
  const [selectedStartDate, setSelectedStartDate] = useState(new Date()); // Step 1
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const [gregoStartDate, setGregoStartDate] = useState(new Date()); // Step 1
  const [gregoEndDate, setGregoEndDate] = useState(new Date());

  // const formik = useFormikContext();


  const { t } = useTranslation("translation");
  const initialValues = {
    waiting_eng_start_date:"",
    waiting_eng_end_date:"",
    waiting_per_start_date:"",
    waiting_per_end_date:"",
    car_details_fk_id:"1",
    waiting_status:"",
    description:"",
    waiting_file:"",
  };

  const validationSchema = Yup.object().shape({

    // waiting_eng_start_date: Yup.string().required("Waiting start gregorian date is required"),
    // waiting_eng_end_date: Yup.string().required("Waiting end gregorian is required"),
    // waiting_per_start_date: Yup.string().required("Waiting start persian is required"),
    // waiting_per_end_date: Yup.string().required("Waiting  end persian is required"),
    waiting_status: Yup.string().required("Waiting  status is required"),
    description: Yup.string().required("Waiting  description is required"),
    waiting_file: Yup.mixed()
      // .required("Commission file is required")
      .test("fileSize", "File size exceeds 1MB limit", (value) => {
        if (value) {
          return value.size <= 1 * 1024 * 1024;
        }
        return true;
      })
      // .test("fileFormat", "Only PDF are allowed", (value) => {
        .test("fileType",t("validation_errors.imageType"),(value) => {
        if (value) {
          return acceptedFileTypes.includes(value.type);
          // return value.type === "application/pdf";
        }
        return true;
      }),


  });


  const handleInputChange = (event) => {
    // console.log(event)
    if(event===null){
      // console.log("Invalid date");
    }else{
      // console.log(event)
      PersianDateConverter(event);
    }
  };


  const PersianDateConverter = (e) => {
    const name = "waiting_per_start_date";
    // if(e.isValid()){
    const dateObject = new DateObject(e.toDate());
    const jalaliDate = jalaliMoment(dateObject);
    const persianDateString = jalaliDate.locale("fa").format("YYYY-MM-DD");
    // console.log(persianDateString)
    const gregorianDate = dateObject.format("YYYY-MM-DD");
    // console.log(gregorianDate)
    setMeladiDate(gregorianDate);
    setShamsiDate(e.toDate());
    // console.log(persianDateString);
    setSolarDate(persianDateString);

    // formik.setFieldValue(name, persianDateString);

  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    const gregorianDate = jalaliMoment(date).format("YYYY-MM-DD");
    // console.log("Gregorian Date:", gregorianDate);
    setGregoStartDate(gregorianDate);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    const gregorianDate = jalaliMoment(date).format("YYYY-MM-DD");
    setGregoEndDate(gregorianDate)
  };

  const addDates = (values) =>{
    values.waiting_per_start_date = selectedStartDate
              ? selectedStartDate.format("YYYY-MM-DD")
              : ""; 
    values.waiting_per_end_date = selectedEndDate
    ? selectedEndDate.format("YYYY-MM-DD")
    : "";
    values.waiting_eng_start_date = gregoStartDate;
    values.waiting_eng_end_date = gregoEndDate;

}

  return (
    <div className="pt-3">
      
      <FormHeader province={"Kabul"}/>
      <div className="mt-3 bg-white rounded-3 p-3">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            // Step 3: Include the selected date in the values object
            // Format the date as needed
            addDates(values);
            // console.log(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, handleReset, values }) => (
            <Form>
              <div className="row g-3">
                {/* account names section */}
                <div className="col-sm-12">
          
                 
                  {/* <h4 className="text-muted">{t("traffic_control.trafficAgentName")}</h4> */}
                </div>
                

                <div className="col-sm-6">
                <div className="row">
                  <label htmlFor="regPersianDate" className="col-sm-3 col-form-label">
                  {t("painting.waiting_per_start_date")}
                    <span className="text-danger"> *</span>
                  </label>
                  <div className="col-sm-9">
                    <DatePicker
                      name="waiting_per_start_date"
                      id="waiting_per_start_date"
                      calendar={persian}
                      locale={persian_fa}
                      inputClass="form-control"
                      containerStyle={{ minWidth: "100%" }}

                      value={selectedStartDate} // Use selectedDate as the value
                      onChange={handleStartDateChange}
                    />
                    <ErrorMessage
                      name="waiting_per_start_date"
                      component="div"
                      className="alert alert-danger p-2 my-1"
                    />
                  </div>
                </div>
               </div>

               <div className="col-sm-6">
                <div className="row">
                  <label htmlFor="waiting_per_end_date" className="col-sm-3 col-form-label">
                  {t("painting.waiting_per_end_date")}
                    <span className="text-danger"> *</span>
                  </label>
                  <div className="col-sm-9">
                    <DatePicker
                      name="waiting_per_end_date"
                      id="waiting_per_end_date"
                      calendar={persian}
                      locale={persian_fa}
                      inputClass="form-control"
                      containerStyle={{ minWidth: "100%" }}
                      value={gregoEndDate} // Use selectedDate as the value
                      onChange={handleEndDateChange}
                    />
                    <ErrorMessage
                      name="waiting_per_end_date"
                      component="div"
                      className="alert alert-danger p-2 my-1"
                    />
                  </div>
                </div>
               </div>

                <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="waiting_status"
                      className="col-sm-3 col-form-label"
                    >
                      {t("painting.waiting_status")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                      <Field
                        as="select"
                        id="waiting_status"
                        name="waiting_status"
                        className="form-select"
                      >
                        <option value="">{t("general_key_words.choose")}</option>
                        {engControlStatus.map((status, index) => (
                          <option value={status} key={index}>
                            {status}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="waiting_status"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                      
                    </div>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="row">
                    <label
                      htmlFor="description"
                      className="col-sm-2 col-form-label"
                    >
                      
                      {t("painting.description")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-10">
                      <Field
                        as="textarea"
                        name="description"
                        id="description"
                        placeholder={t("painting.description")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-2"></div>
               
                <div className="col-sm-12 col-md-4">
                  <label
                    htmlFor="waiting_file"
                    className="form-label border shadow-sm text-muted rounded px-5 py-2 w-100 text-center"
                  >
                    {t("painting.waiting_file")}
                    <span className="text-danger"> *</span>
                    <span
                      className={
                        values.waiting_file
                          ? "ms-3 fs-5 text-success"
                          : "ms-3 fs-5 text-danger"
                      }
                    >
                      <BsCloudUploadFill />
                    </span>
                  </label>
                  {values.waiting_file && (
                    <div className="text-muted">
                      <span className="text-primary">
                      {t("painting.waiting_file")}
                      </span>:{" "}
                      {values.waiting_file.name}
                    </div>
                  )}
                  <FileField name={"waiting_file"} id={"waiting_file"} 
                  accept={"image/*"}
                  // accept={".pdf"}
                  
                  />
                  <ErrorMessage
                    name="waiting_file"
                    className="text-danger"
                    component="div"
                  />
                </div>


                <hr className="mx-auto my-3 w-75" />

                <button
                  className="w-100 btn btn-primary "
                  disabled={isSubmitting && true}
                  type="submit"
                >{t("vehicle_details_form.registerForm")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}


