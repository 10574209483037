export function CheckAuthentication() {

  // const user = JSON.parse(localStorage.getItem("user"));
  // const token = localStorage.getItem("token");

  const user = JSON.parse(sessionStorage.getItem("user"));
  const token = sessionStorage.getItem("token");

  if (token) {
    const UserObject = {
      user: user,
      token: token,
    };

    return {
      status: true,
      userObject: UserObject,
    };
  }
  else 
  {
    return {
        status: null,
        userObject: null,
      };
  }
}