import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import { FetchError, FetchStart, FetchSuccess } from "./InvoiceSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const FetchPrintedInvoices = (token,id) => async (dispatch) => {
  dispatch(FetchStart());
  // http request to backend
  try {
    const response = await http.get(`api/driver/invoice/print/${parseInt(id)}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.success(t("toast.data_fetch"));
    // console.log(response.data)
    dispatch(FetchSuccess(response.data.records));
  } catch (error) {
    dispatch(FetchError());
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};
