import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import FormDataHelper from "../../Utility/FormDataHelper";
import { SearchStart, SearchError, SearchSuccess } from "./SearchSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const SearchQuery = (data, token) => async (dispatch) => {
  // console.log("Send data for backend:");
  // console.log(data);
  dispatch(SearchStart());
  // http request to backend
  try {
    const response = await http.post("api/search/query", data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    // console.log("Response::::")
    // console.log(response.data.records)
    // toast.success(t("toast.data_upload"));
    dispatch(SearchSuccess(response.data.records));
  } catch (error) {
    dispatch(SearchError());
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};
