import { useFormikContext } from "formik";

const FileField = ({ name, id, type, accept }) => {
  const { setFieldValue } = useFormikContext();

  const handleFileChange = (event) => {
    if (type) {
      const files = Array.from(event.currentTarget.files); // Convert FileList to an array
      setFieldValue(name, files);
    } else {
      const file = event.currentTarget.files[0];
      setFieldValue(name, file);
    }
  };

  return (
    <div>
      {type ? (
        <input
          type="file"
          className="d-none"
          name={name}
          id={id}
          onChange={handleFileChange}
          multiple
          accept={accept}
        />
      ) : (
        <input
          type="file"
          className="d-none"
          name={name}
          accept={accept}
          id={id}
          onChange={handleFileChange}
        />
      )}
    </div>
  );
};

export default FileField;
