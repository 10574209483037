import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { provinces, provincesDari } from "../../Data/provinces";
import { BsCloudUploadFill } from "react-icons/bs";
import FileField from "../../Utility/FileField";
import FormHeader from "../../Utility/FormHeader";
import { acceptedFileTypes, dariControlStatus, engControlStatus } from "../../Data/statuses";
import { useLocation, useParams } from "react-router-dom";
import DriverDetails from "./DriverDetails";
import DriverSelectionMessage from "./DriverSelectionMessage";
import DateConverter from "../../Utility/DateConverter";
import { convertToGregorian } from "../../Utility/ConvertJalaitToGregorian";
import PageHeader from "../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "../../shared/ProgressBar";
import APIFormErrors from "../../Utility/APIFormErrors";
import { UploadControlData } from "../../redux/ControlSection/UploadControlData";

export default function VehicleControl() {
  const { t } = useTranslation("translation");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const driverID = searchParams.get("driverID");

  const loading = useSelector((state) => state.control.loading);
  const progress = useSelector((state) => state.control.progress);
  const APIErrors = useSelector((state) => state.control.APIErrors);
   const token=useSelector((state) => state.authentication.token);
  const dispatch = useDispatch();
  // console.log(driverID);
  const initialValues = {
    trafficAgentName: "",
    companyAgentName: "",
    regGregorianDate: "",
    regPersianDate: "",
    carDetailsFkId: driverID ? driverID : "",
    controlStatus: "",
    trafficAgentRemark: "",
    companyAgentRemark: "",
    controAttachement: "",
  };

  const validationSchema = Yup.object().shape({
    trafficAgentName: Yup.string().required(t("validation_errors.trafficAgentNameRequired")),
    companyAgentName: Yup.string().required(t("validation_errors.companyAgentNameRequired")),
    // regPersianDate: Yup.string().required("Shamsi Date is required"),
    // regGregorianDate: Yup.string().required("Gregorian Date is required"),
    regGregorianDate:  Yup.string().required(t("validation_errors.regGregorianDate")),
    regPersianDate: Yup.string().required(t("validation_errors.regPersianDate")),
    // conHijriDate: Yup.string().required("Hijri Date is required"),
    carDetailsFkId: Yup.string().required("Car ID is required"),
    controlStatus: Yup.string().required("Control Status is required"),
    trafficAgentRemark: Yup.string().required("Traffic remark is required"),
    companyAgentRemark: Yup.string().required(
      "Static Compmay remark is required"
    ),
    controAttachement: Yup.mixed()
        .required("Traffic approve file is required")
      .test("fileSize", "File size exceeds 1MB limit", (value) => {
        if (value) {
          return value.size <= 1 * 1024 * 1024;
        }
        return true;
      })
      // .test("fileFormat", "Only PDF are allowed", (value) => {
        .test("fileType",t("validation_errors.imageType"),(value) => {
        if (value) {
          return acceptedFileTypes.includes(value.type);
          // return value.type === "application/pdf";
        }
        return true;
      }),
  });

  return (
    <div className="pt-2">
      
      <PageHeader title={t("traffic_control.controlRegistrationForm")} />
      {driverID ? (
        <>
          <DriverSelectionMessage
            type={"success"}
            message={`driver has been selected with ${driverID}`}
          />
          <DriverDetails id={driverID} />
        </>
      ) : (
        <DriverSelectionMessage
          type={"danger"}
          message={`driver is not selected`}
        />
      )}
      {APIErrors && (
        <div class=" bg-light rounded-3 mt-2">
          <div class="container-fluid">
            <APIFormErrors errors={APIErrors} />
          </div>
        </div>
      )}
      <div className="mt-2 bg-white rounded-3 p-3">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            // convertToGregorian(values.regPersianDate)
            dispatch(UploadControlData(values,token));
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, handleReset, values }) => (
            <Form>
              <div className="row g-3">
                {/* account names section */}
                {/* <div className="col-sm-12">
       
                  <h4 className="text-muted">{t("traffic_control.trafficAgentName")}</h4>
                </div> */}
                <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="firstName"
                      className="col-sm-3 col-form-label"
                    >
                      {t("traffic_control.trafficAgentName")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                      <Field
                        type="text"
                        name="trafficAgentName"
                        id="trafficAgentName"
                        placeholder={t("traffic_control.trafficAgentName")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="trafficAgentName"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="companyAgentName"
                      className="col-sm-3 col-form-label"
                    >
                      {t("traffic_control.companyAgentName")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                      <Field
                        type="text"
                        name="companyAgentName"
                        id="companyAgentName"
                        placeholder={t("traffic_control.companyAgentName")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="companyAgentName"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>

                {/* account email and contact section*/}

                <DateConverter
                  t={t}
                  shamsiName="conPersianDate"
                  miladiName="conGregorianDate"
                  shamsiId="conPersianDate"
                  miladiId="conGregorianDate"
                />

                {/* <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="carDetailsFkId"
                      className="col-sm-3 col-form-label"
                    >
                     {t("traffic_control.carDetailsFkId")}
                      
                      <span className="text-danger"> *</span>
                    </label>

                    <div className="col-sm-9">
                      <Field
                        type="number"
                        name="carDetailsFkId"
                        id="carDetailsFkId"
                        placeholder={t("traffic_control.carDetailsFkId")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="carDetailsFkId"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>
                 */}

                <div className="col-sm-12">
                  <div className="row">
                    <label
                      htmlFor="trafficAgentRemark"
                      className="col-sm-2 col-form-label"
                    >
                      {t("traffic_control.trafficAgentRemark")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-10">
                      <Field
                        as="textarea"
                        name="trafficAgentRemark"
                        id="trafficAgentRemark"
                        placeholder={t("traffic_control.trafficAgentRemark")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="trafficAgentRemark"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="row">
                    <label
                      htmlFor="companyAgentRemark"
                      className="col-sm-2 col-form-label"
                    >
                      {t("traffic_control.companyAgentRemark")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-10">
                      <Field
                        as="textarea"
                        name="companyAgentRemark"
                        id="companyAgentRemark"
                        placeholder={t("traffic_control.companyAgentRemark")}
                        className="form-control"
                      />
                      <ErrorMessage
                        name="companyAgentRemark"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="row">
                    <label
                      htmlFor="controlStatus"
                      className="col-sm-3 col-form-label"
                    >
                      {t("traffic_control.controlStatus")}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="col-sm-9">
                      <Field
                        as="select"
                        id="controlStatus"
                        name="controlStatus"
                        className="form-select"
                      >
                        <option value="">
                          {t("general_key_words.choose")}
                        </option>
                        {dariControlStatus.map((status, index) => (
                          <option value={status} key={index}>
                            {status}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="controlStatus"
                        component="div"
                        className="alert alert-danger p-2 my-1"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-4">
                  <label
                    htmlFor="controAttachement"
                    className="form-label border shadow-sm text-muted rounded px-5 py-2 w-100 text-center"
                  >
                    {t("traffic_control.controAttachement")}
                    <span className="text-danger"> *</span>
                    <span
                      className={
                        values.controAttachement
                          ? "ms-3 fs-5 text-success"
                          : "ms-3 fs-5 text-danger"
                      }
                    >
                      <BsCloudUploadFill />
                    </span>
                  </label>
                  {values.controAttachement && (
                    <div className="text-muted">
                      <span className="text-primary">
                        {t("traffic_control.controAttachement")}
                      </span>
                      : {values.controAttachement.name}
                    </div>
                  )}
                  <FileField
                    name={"controAttachement"}
                    id={"controAttachement"}
                    accept={"image/*"}
                  // accept={".pdf"}
                  />
                  <ErrorMessage
                    name="controAttachement"
                    className="text-danger"
                    component="div"
                  />
                </div>

                <hr className="mx-auto my-3 w-75" />

                {loading && <ProgressBar progress={progress} />}

                <button
                  className="btn btn-primary w-100 py-2"
                  disabled={loading && true}
                  type="submit"
                >
                  {loading ? (
                    <>
                      <div
                        class="mx-3 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <span>Processing, please wait</span>
                    </>
                  ) : (
                    t("vehicle_details_form.registerForm")
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
