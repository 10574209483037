import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormHeader from "../../Utility/FormHeader";

import PageHeader from "../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { FetchDriverData } from "../../redux/vehicleRegistration/FetchDriversData";
import LoadingAnimation from "../../shared/LoadingAnimation";
import NoContentsFound from "../../shared/NoContentsFound";
import CarTable3 from "./CarTable3";

import { useLocation, useParams } from "react-router-dom";

export default function ViewList() {
  const { flag } = useParams();
  // console.log("flag:", flag);
  // const flag = new URLSearchParams(location.search).get("flag");
  // sendSMS();
  const { t } = useTranslation("translation");
  const data = useSelector((state) => state.vehicle.data);
  const CarsData = useSelector((state) => state.vehicle);
  const loading = useSelector((state) => state.vehicle.loading);
  // const progress = useSelector((state) => state.vehicle.progress);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);

  useEffect(() => {
    // sendSMS();
    dispatch(FetchDriverData(token));
    // var url;
    // if(flag==="waiting"){
    //   dispatch(FetchDriverData(token, "api/driver/waiting/list/get"));
    //   // url = "api/driver/waiting/list/get";
    // }else if(flag==="no-waiting"){
    //   dispatch(FetchDriverData(token, "api/driver/no-waiting/list/get"));
    // }else if(flag==="invoiced"){
    //   dispatch(FetchDriverData(token, "api/driver/invoiced/list/get"));
    // }
    // else if(flag==="complate"){
    //   dispatch(FetchDriverData(token, "api/driver/complate/list/get"));
    // }else{
    //   dispatch(FetchDriverData(token, "api/driver"));
    // }
    // dispatch(FetchDriverData(token, url));
  }, []);
  // console.log("Vhicle:");
  // console.log(data);
  return (
    <div>
      <div className="mt-2">
        {/* <FormHeader province={"Kabul"} /> */}
        <PageHeader title={t("vehicle_details_form.carDetailsRegForm")} />
      </div>
      {loading ? (
        <LoadingAnimation />
      ) : data && data.length>0? (
        // <DriverTabularView data={data} />
        // <CarTable carsData={CarsData} />
        <div className="mt-2">
          <CarTable3 carsData={CarsData} recordType={t("vehicle_details_form.allVehicleList")} />
        </div>
      ) : (
        // <DriverTabularView data={data} options={true}/>
        <NoContentsFound message={t("general_key_words.dataNotFound")} />
      )}
    </div>
  );
}
