import { useFormikContext } from "formik";
import { t } from "i18next";

const SelectField = ({ name, id, data , province}) => {
  const { setFieldValue } = useFormikContext();

  const handleSelectionChange = (event) => {
   if(event.target.value)
   {
    const value = JSON.parse(event.target.value)
    setFieldValue(name, value.id);
    setFieldValue(province,value.province)
   }
  };

  return (
    <div>
      <select
        id={id}
        name={name}
        className="form-select"
        onChange={handleSelectionChange}
      >
        <option value=""> {t("general_key_words.choose")}</option>
        {data &&
          data.map((item, index) => (
            <option
              value={JSON.stringify(item)}
              key={index}
            //   onClick={(e) => setFieldValue("province", item.province)}
            >
              {item.branchName}
            </option>
          ))}
      </select>
    </div>
  );
};

export default SelectField;
