import toast from "react-hot-toast";
import { http } from "../../../Utility/APIRequest"; 
import { DeleteError, DeleteStart, DeleteSuccess, UpdateRecords } from "../slice/branchSlice";

import { HandleHTTPRequestError } from "../../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const DeleteBranchRecord =
  (token, id, section) => async (dispatch, getState) => {
    dispatch(DeleteStart(id));
    // http request to backend
    try {
      const response = await http.delete(`api/branch/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (section === "table") {
        // get the data state:
        const currentState = getState();
        // console.log("type of data ---> "+ typeof(currentState.vehicle.data))
        const updatedData = currentState.branch.data.filter(
          (record) => record.id !== id
        );
        dispatch(UpdateRecords(updatedData));
      }
      toast.success(t("toast.data_fetch"));
      dispatch(DeleteSuccess());
    } catch (error) {
      dispatch(DeleteError());
      if (error.response) {
        HandleHTTPRequestError(error.response.status, error, dispatch);
      } else {
        // Handle the case where the API is offline or unreachable
        console.error("API is offline or unreachable.");
          toast.error(t("toast.apiOffline"));
      }
    }
  };
