import React from "react";
import {
  BsArrowReturnRight,
  BsBookmarkCheckFill,
  BsChevronDoubleDown,
  BsViewList,
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SelectRecord } from "../../redux/vehicleRegistration/VehicleSlice";
import { AiFillEdit } from "react-icons/ai";
import InlineLoadingAnimation from "../../shared/InlineLoadingAnimation";
import Swal from "sweetalert2";
import { DeleteControlRecord } from "../../redux/ControlSection/DeleteControlRecord";
import { FcAddDatabase } from "react-icons/fc";
import { t } from "i18next";
import { AddToWaiting } from "../../redux/vehicleRegistration/AddToWaiting";
import { AddToWaitingInvoice } from "../../redux/ControlSection/AddToWaitingInvoice";
import { FaMinus } from "react-icons/fa";
import { RejectWaitingRecord } from "../../redux/WaitingSection/RejectWaitingRecord";
import { RejecPendingControls } from "./RejectPendingControls";
import { RejectGeneratedInvoice } from "../../redux/InvoicesSection/RejectGeneratedInvoice";
import { RejectPendingInvoice } from "../../redux/InvoicesSection/RejectPendingInvoice";
const PendingInvoiceRejectOption = ({ record, section }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inlineLoading = useSelector((state) => state.delete.loading);
  const selectedDelete = useSelector((state) => state.delete.item);
  const token = useSelector((state) => state.authentication.token);
  const user = useSelector((state) => state.authentication.user);

  const handleAddToInvoice = () => {
    Swal.fire({
      title: t("general_key_words.areYouSure"),
      text: t("general_key_words.confirmChangeMsg"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("general_key_words.yesRejectIt"),
      cancelButtonText: t("general_key_words.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(RejectPendingInvoice(token, record.id, "generated", record.dId));
        // dispatch(
        //   AddToWaitingInvoice(token, record.driver.id, record.id, "reject")
        // );
      }
    });
  };

 
  const handleView = () => {
    window.open(`/view-list/view/${record.dId}`, "_blank");
  };


  return selectedDelete === record.id && inlineLoading ? (
    <div className="">
      <InlineLoadingAnimation />
    </div>
  ) : (
    <div className="btn-group">
      <button
        className="btn btn-secondary d-flex align-items-center gap-2 rounded"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <BsChevronDoubleDown />
        {t("general_key_words.choose")}
      </button>
      <ul className="dropdown-menu" style={{ zIndex: 2000 }}>

          <li>
              <a
                className="dropdown-item d-flex align-items-center gap-2 text-muted"
                type="button"
                onClick={handleView}
              >
                <BsViewList className="bi" />
                {t("general_key_words.view")}
              </a>
            </li>
        
              <li>
                <a
                  className="dropdown-item d-flex align-items-center gap-2 text-success"
                  type="button"
                  onClick={handleAddToInvoice}
                >
                  <BsArrowReturnRight className="bi" style={{ color: "red" }} />
                {t("general_key_words.reject")}
                </a>
              </li>
              {/* <hr /> */}
       

        {/* <li>
          <a
            className="dropdown-item d-flex align-items-center gap-2 text-danger"
            type="button"
            onClick={handleDelete}
          >
            <BsFillTrash3Fill className="bi" />
            {t("general_key_words.delete")}
          </a>
        </li> */}
      </ul>
    </div>
  );
};

export default PendingInvoiceRejectOption;
