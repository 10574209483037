import React, { useEffect, useState } from "react";
import "../App.css";
import Login from "../components/auth/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Register from "../components/auth/Register";
import Dashboard from "./pages/dashboard/Dashboard";
import Layout from "../components/shared/Layout";
import Protected from "../components/shared/Protected";
import NotFound from "./Utility/NotFound";

// app pages and components
import RegistrationForm from "./pages/car-registration/RegistrationForm2";
import ViewList from "./pages/car-registration/ViewList";
import VehicleControl from "./pages/traffic-control/VehicleControl";
import ControlList from "./pages/traffic-control/ControlList";
import Invoice from "./pages/invoice-section/Invoice";
import Painting from "./pages/painting-section/Painting";
import Settings from "./pages/settings/Settings";
import InvoiceList from "./pages/invoice-section/InvoiceList";
import PaintingList from "./pages/painting-section/PaintingList";
import InvoiceView from "./pages/invoice-section/InvoiceView";
import { useDispatch, useSelector } from "react-redux";
import { setIsAuthenticated } from "./redux/authentication/AuthenticationSlice";
import { CheckAuthentication } from "./Utility/CheckAuthentication";
import { CheckProcessMode } from "./Utility/CheckProcessMode";
import PrintableVehicleInfo from "./pages/car-registration/PrintableVehicleInfo";
import ViewDriver from "./pages/car-registration/ViewDriver";
import DriverLayout from "./pages/car-registration/DriverLayout";
import EditDriver from "./pages/car-registration/EditDriver";
import ViewControl from "./pages/traffic-control/ViewControl";
import ControlEdit from "./pages/traffic-control/ControlEdit";
import ControlListWaiting from "./pages/traffic-control/ControlListWaiting";
import BranchRegistration from "./pages/branch-section/BranchRegistration";
import BranchList from "./pages/branch-section/BranchList";
import BranchEdit from "./pages/branch-section/BranchEdit";
import WaitingList from "./pages/waiting-section/WaitingList";
import DriverWaitingList from "./pages/car-registration/DriverWaitionList";
import DriverNoWaitingList from "./pages/car-registration/DriverNoWaitingList";
import DriverApprovedList from "./pages/car-registration/DriverApprovedList";
import DriverRejectedList from "./pages/car-registration/DriverRejectedList";
import ApprovedList from "./pages/invoice-section/ApprovedList";
import ApprovedControlLIst from "./pages/traffic-control/ApprovedControl";
import SettingsLayout from "./pages/settings/SettingsLayout";
import InvoiceLayout from "./pages/invoice-section/InvoiceLayout";
import PrintedList from "./pages/invoice-section/PrintedList";
import RePrintInvoice from "./pages/invoice-section/RePrintInvoice";
import AllApprovedInvoices from "./pages/invoice-section/AllApprovedInvoices";
import ProfileSettings from "./pages/settings/AccountSettings/ProfileSettings";
import Privacy from "./pages/settings/Privacy/Privacy";
import Users from "./pages/settings/Users/Users";
import BranchData from "./pages/branch-section/BranchData/BranchData";
import CollectInvoiceCash from "./pages/invoice-section/CollectInvoiceCash";
import CollectedInvoicesList from "./pages/invoice-section/CollectedInvoicesList";
import VehicleDash from "./pages/dashboard/userDashboard/VehicleDashboard";
import Roled from "./shared/Roled";
import {
  InvoiceRoles,
  RegistrationRoles,
  ReportRoles,
  Roles,
} from "./Data/Roles";
import CarLayout from "./pages/car-registration/shared/CarLayout";
import DriverCompletedList from "./pages/car-registration/DriverCompletedList";
import ControlLayout from "./pages/traffic-control/shared/ControlLayout";
import DriverListById from "./pages/car-registration/DriverListById";
import SearchPage from "./pages/SearchPanel/SearchPage";
import UserLogLayout from "./pages/user-login-log/UserLogLayout";
import SuperAdminLogs from "./pages/user-login-log/SuperAdminLogs";
import AdminUsersLog from "./pages/user-login-log/AdminUsersLog";
import AllUsersLogList from "./pages/user-login-log/AllUsersLog";
import UserLogList from "./pages/user-login-log/UserLoginLog";
import UsersList from "./pages/settings/Users/UsersList";
import PrintStilamPage from "./pages/invoice-section/PrintInvoiceStilam";
import RejectedControlList from "./pages/traffic-control/RejectedControlsList";
import BankSlipApproved from "./pages/car-registration/BankSlipApproved";
import InvoiceLayout2 from "./pages/invoice-section/shared/InvoiceLayout2";
import GeneratedInvoiceWithVehicles from "./pages/invoice-section/GeneratedInvoiceWithVehicle";
import CollectedInvoiceLayout from "./pages/invoice-section/shared/CollectedInvoiceLayout";
import DriverContainer from "./pages/Public/Driver/DriverContainer"
import VehicleStatusContainer from "./pages/Public/Vehicle/VehicleStatusContainer";
import ChangePassword from "./pages/settings/Users/ChangePassword";
import { utiAuth } from "./Utility/auth";
import VehicleBack from "./pages/Public/Vehicle/Vehiclesback";
// import { NewSideNav } from "./shared/NewSideBar/NewSideNav";

const LandingPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  const user = useSelector((state) => state.authentication.user);
  const dushboard = utiAuth();
  // check if user is authenticated
  useEffect(() => {
    // const status = CheckProcessMode();
    // if (status !== 200) {
    //   return;
    // }
    const AuthCheck = CheckAuthentication();

    if (AuthCheck.status) {
      dispatch(setIsAuthenticated(AuthCheck.userObject));
    }
    setLoading(false);
  }, [isAuthenticated]);

  return (
    !loading && dushboard &&(
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Protected>
                <Layout />
              </Protected>
            }
          >
            <Route index element={<Dashboard />} />
            <Route element={<DriverLayout />}>
              {/* {(user && user.role !=="approver" && user.role !=="finance") && (
              <Route path="car-registration" element={<RegistrationForm />} />
              )} */}

              <Route
                path="car-registration"
                element={
                  <Roled allowedRoles={RegistrationRoles}>
                    <RegistrationForm />
                  </Roled>
                }
              />

              <Route element={<CarLayout />}>
                <Route path="view-list" element={<ViewList />} />
                <Route
                  path="driver-no-waiting"
                  element={<DriverNoWaitingList />}
                />
                <Route
                  path="/driver-slip-approved"
                  element={<BankSlipApproved />}
                />
                <Route path="driver-waiting" element={<DriverWaitingList />} />
                <Route
                  path="driver-approved"
                  element={<DriverApprovedList />}
                />
                <Route
                  path="driver-rejected"
                  element={<DriverRejectedList />}
                />
                <Route
                  path="driver-complate"
                  element={<DriverCompletedList />}
                />
                <Route
                  path="driver-complated-list/:id"
                  element={<DriverCompletedList type="complatedListByBranch" />}
                />
              </Route>
              <Route
                path="driver-list/:id"
                element={<DriverListById type="allDrivers" />}
              />

              <Route
                path="driver-controlled-list/:id"
                element={<DriverListById type="approvedDrivers" />}
              />

              {/* <Route path="driver-no-waiting" element={<ViewList />} /> */}
              <Route path="view-list/view/:id" element={<ViewDriver />} />
              <Route path="view-list/edit/:id" element={<EditDriver />} />
              <Route path="driver-rejected/edit/:id" element={<EditDriver />} />

              <Route element={<ControlLayout />}>
                <Route path="vehicle-control" element={<VehicleControl />} />
                <Route path="control-list" element={<ControlList />} />
                <Route
                  path="approved-control-list"
                  element={<ApprovedControlLIst />}
                />
                <Route
                  path="control-list/waiting"
                  element={<ControlListWaiting />}
                />

                <Route
                  path="control-list/rejected"
                  element={<RejectedControlList />}
                />

                <Route path="control-list/view/:id" element={<ViewControl />} />
                <Route path="control-list/edit/:id" element={<ControlEdit />} />
                <Route
                  path="control-list/waiting/edit/:id"
                  element={<ControlEdit />}
                />
              </Route>

              <Route
                path="branch-registration"
                element={<BranchRegistration />}
              />
              <Route
                path="branch-list"
                element={
                  <Roled allowedRoles={Roles}>
                    <BranchList />
                  </Roled>
                }
              />
              <Route
                path="branch-list/edit/:id"
                element={
                  <Roled allowedRoles={Roles}>
                    <BranchEdit />
                  </Roled>
                }
              />
              <Route
                path="branch-list/data"
                element={
                  <Roled allowedRoles={Roles}>
                    <BranchData />
                  </Roled>
                }
              />

              <Route
                path="invoice-list"
                element={
                  <Roled allowedRoles={InvoiceRoles}>
                    <InvoiceList />
                  </Roled>
                }
              />

              <Route
                path="invoice-pending-list/:id"
                element={<InvoiceList type="pendingInvoice" />}
              />

              <Route
                path="invoice"
                element={
                  <Roled allowedRoles={Roles}>
                    <Invoice />
                  </Roled>
                }
              />
              <Route
                element={
                  // <Roled allowedRoles={Roles}>
                  <Roled allowedRoles={InvoiceRoles}>
                    <InvoiceLayout />
                  </Roled>
                }
              >
                <Route
                  path="approved_invoice-list"
                  element={<ApprovedList />}
                />

                <Route
                  path="approved_invoice-list/all"
                  element={<AllApprovedInvoices />}
                />
                <Route element={<InvoiceLayout2 />}>
                  <Route
                    path="approved_invoice-list/collecting-cash"
                    element={<CollectInvoiceCash />}
                  />
                  <Route
                    path="approved_invoice-vehicle-list/collecting-cash"
                    element={<GeneratedInvoiceWithVehicles />}
                  />
                </Route>
                <Route
                  path="invoice-generated-list/:id"
                  element={
                    <CollectInvoiceCash type="generatedInvoiceByBranch" />
                  }
                />

                {/*  */}
                <Route element={<CollectedInvoiceLayout />}>
                  <Route
                    path="approved_invoice-list/collected"
                    element={<CollectedInvoicesList />}
                  />

                  <Route
                    path="approved_invoice-vehicle-list/collected-cash"
                    element={<GeneratedInvoiceWithVehicles type="collected" />}
                  />
                </Route>
                <Route
                  path="invoice-received-list/:id"
                  element={
                    <CollectedInvoicesList type="receivedInvoiceByBranch" />
                  }
                />

                <Route
                  path="approved_invoice-list/printed"
                  element={<PrintedList />}
                />
              </Route>
              <Route
                path="approved_invoice-list/print"
                element={<InvoiceView />}
              />

              <Route
                path="vehicle-dashboard"
                element={
                  <VehicleDash type="allVehicle" viewMorUrl="driver-list" />
                }
              />

              <Route
                path="vehicle-controlled-dashboard"
                element={
                  <VehicleDash
                    type="controlledVehicle"
                    viewMorUrl="driver-controlled-list"
                  />
                }
              />
              <Route
                path="vehicle-waiting-dashboard"
                element={
                  <VehicleDash
                    type="waitingVehicle"
                    viewMorUrl="driver-waiting-list"
                  />
                }
              />
              <Route
                path="vehicle-complated-dashboard"
                element={
                  <VehicleDash
                    type="complatedVehicle"
                    viewMorUrl="driver-complated-list"
                  />
                }
              />

              <Route
                path="invoice-pending-dashboard"
                element={
                  <VehicleDash
                    type="invoicePending"
                    viewMorUrl="invoice-pending-list"
                  />
                }
              />

              <Route
                path="invoice-generated-dashboard"
                element={
                  <VehicleDash
                    type="invoiceGenerated"
                    viewMorUrl="invoice-generated-list"
                  />
                }
              />
              <Route
                path="invoice-received-dashboard"
                element={
                  <VehicleDash
                    type="invoiceReceived"
                    viewMorUrl="invoice-received-list"
                  />
                }
              />

              <Route path="waiting-list" element={<WaitingList />} />
              <Route
                path="driver-waiting-list/:id"
                element={<WaitingList type="waitingListByBranch" />}
              />

              {/* <Route
                  path="driver-waiting-list/:id"
                  element={<DriverListById type="waitingDrivers" />}
                /> */}
            </Route>
            <Route
              element={
                <Roled allowedRoles={Roles}>
                  <UserLogLayout />{" "}
                </Roled>
              }
            >
              <Route path="login-log-list" element={<AllUsersLogList />} />
              <Route path="login-log-list/super" element={<SuperAdminLogs />} />
              <Route path="login-log-list/admin" element={<AdminUsersLog />} />
              <Route path="login-log-list/users" element={<UserLogList />} />
            </Route>
            {/* traffic control section */}

            {/* waiting section */}
            <Route path="waiting" element={<Painting />} />

            <Route path="complate-list" element={<ViewList />} />
            {/* settings section */}
            <Route element={<SettingsLayout />}>
              <Route path="settings" element={<ProfileSettings />} />
              <Route path="settings/privacy" element={<Privacy />} />
              <Route
                path="settings/users"
                element={
                  <Roled allowedRoles={Roles}>
                    <Users />
                  </Roled>
                }
              />
              <Route
                path="settings/users-list"
                element={
                  <Roled allowedRoles={Roles}>
                    <UsersList />
                  </Roled>
                }
              />
            </Route>

            <Route
                path="settings/password-change"
                element={
                  // <Roled allowedRoles={Roles}>
                    <ChangePassword />
                  // </Roled>
                }
              />

            {/* search area */}
            {/* adding super account authorization to reporting or search page */}
            <Route
              path="search"
              element={
                <Roled allowedRoles={ReportRoles}>
                  <SearchPage />
                </Roled>
              }
            />
          </Route>

          {/* protected non-layout pages */}
          <Route
            // path="view-list/view/print"
            path="view/print"
            element={
              <Protected>
                <PrintableVehicleInfo />
              </Protected>
            }
          />
          <Route
            path="search/view/print"
            element={
              <Protected>
                <PrintableVehicleInfo />
              </Protected>
            }
          />
          {/* invoice reprint */}
          <Route
            path="approved_invoice-list/printed/:id"
            element={<RePrintInvoice />}
          />

          <Route
            path="invoice-stilam/print/:id/:count/:amount"
            element={<PrintStilamPage />}
          />

          {/* <Route
            path="new/sidebar"
            element={<NewSideNav />}
          /> */}

          {/* public routes goes here ...  */}
          {/* <Route path="login" element={<Login />} /> */}
          {/* <Route path="/public/driver" element={<DriverContainer/>} /> */}
          <Route path="/vehicle/status" element={<VehicleStatusContainer/>} />
          <Route path="/vehicle/b" element={<VehicleBack/>} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    )
  );
};

export default LandingPage;
