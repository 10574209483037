import React from 'react'
import ReactLoading from "react-loading";
const DownloadLoading = () => {
  return (
    <div className="bi">
    <ReactLoading
      type={"spinningBubbles"}
      color={"white"}
      height={20}
      width={20}
    />
  </div>
  )
}

export default DownloadLoading