import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";

import PageHeader from "../../../Utility/PageHeader";
import { useNavigate } from "react-router-dom";
import APIFormErrors from "../../../Utility/APIFormErrors";
import ProgressBar from "../../../shared/ProgressBar";
import { UpdateUserPassword } from "../../../redux/authentication/UpdateUserPassword";
import { t } from "i18next";

const Privacy = () => {
  const errors = useSelector((state) => state.authentication.APIErrors);
  const navigate = useNavigate();
  const loading = useSelector((state) => state.branch.loading);
  const token = useSelector((state) => state.authentication.token);
  const progress = useSelector((state) => state.authentication.progress);

  const dispatch = useDispatch();
  const initialValues = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
  };
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(t("validation_errors.passwordRequired")),
    password: Yup.string()
      .min(8, t("validation_errors.passWordLength"))
      .required(t("validation_errors.passwordRequired")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("validation_errors.passwordNotMatch"))
      .required("Confirm password is required"),
  });
  return (
    <div>
      <PageHeader title={t("setting.privacyAndSecurity")} />
      {errors && (
        <div className=" bg-light rounded-3 mt-2">
          <div className="container-fluid">
            <APIFormErrors errors={errors} />
          </div>
        </div>
      )}
      <div className="mt-2 bg-white rounded-3 p-3 px-lg-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            // console.log(values);
            dispatch(UpdateUserPassword(values, token));
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, handleReset, values }) => (
            <Form>
              <div className="row">
                <div className="col-sm-12 mb-2">
                  <label htmlFor="oldPassword" className="form-label">
                    {t("setting.oldPassword")}<span className="text-danger"> *</span>
                  </label>
                  <Field
                    type="password"
                    name="oldPassword"
                    id="oldPassword"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="oldPassword"
                    component="div"
                    className="alert alert-danger p-2 my-1"
                  />
                </div>
                <div className="col-sm-12 mb-2">
                  <label htmlFor="password" className="form-label">
                  {t("setting.newPassword")}<span className="text-danger"> *</span>
                  </label>
                  <Field
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="alert alert-danger p-2 my-1"
                  />
                </div>
                <div className="col-12 mb-2">
                  <label htmlFor="confirmPassword" className="form-label">
                  {t("setting.confirmNewPassword")}<span className="text-danger"> *</span>
                  </label>
                  <Field
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="alert alert-danger p-2 my-1"
                  />
                </div>
                <hr className="mx-auto my-3 w-75" />
                {loading && <ProgressBar progress={progress} />}
                <button
                  className="w-100 btn btn-primary "
                  disabled={loading}
                  type="submit"
                >
                  {loading ? (
                    <>
                      <div
                        className="mx-3 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">{t("general_key_words.loading")}</span>
                      </div>
                      <span>{t("general_key_words.processingPleaseWait")}</span>
                    </>
                  ) : (
                    t("general_key_words.updateRecord")
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Privacy;
