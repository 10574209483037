import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UserLogNav = () => {
  const { t } = useTranslation("translation");
  return (
    <ul className="nav justify-content-start bg-light">
      <li className="nav-item">
        <NavLink
        to="/login-log-list"
          className={({ isActive }) =>
            isActive ? "nav-link text-primary" : "nav-link text-black-50"
          }
          end
        >
            {t("userLog.allUsersLog")}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
        //   to="/control-list"
        to="/login-log-list/super"
          className={({ isActive }) =>
            isActive ? "nav-link text-primary" : "nav-link text-black-50"
          }
          end
        >
            {t("userLog.superAdmin")}
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink
        //   to="view-list"
        to="/login-log-list/admin"
          className={({ isActive }) =>
            isActive ? "nav-link text-primary" : "nav-link text-black-50"
          }
          end
        >
          {t("userLog.admin")}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
        //   to="view-list"
        to="/login-log-list/users"
          className={({ isActive }) =>
            isActive ? "nav-link text-primary" : "nav-link text-black-50"
          }
          end
        >
          {t("userLog.users")}
        </NavLink>
      </li>

    </ul>
  );
};

export default UserLogNav;
