import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import PageHeader from "../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import LoadingAnimation from "../../shared/LoadingAnimation";
import NoContentsFound from "../../shared/NoContentsFound";

import CollectingCashTable from "./CollectingCashTable";
import { FetchInvoiceForCashCollection } from "../../redux/InvoicesSection/FetchInvoiceForCashCollection";

import { FetchGeneratedInvoicesByBranchId } from "../../redux/InvoicesSection/FetchInvoices";
import { useParams } from "react-router-dom";
import InvoiceCollectionModal from "./InvoiceCollectionModal";
export default function CollectInvoiceCash(props) {
  // sendSMS();
  const { t } = useTranslation("translation");
  const data = useSelector((state) => state.invoice.data);
  const CarsData = useSelector((state) => state.invoice);
  const loading = useSelector((state) => state.invoice.loading);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);
  const invoiceCollect = useSelector((state) => state.invoice.invoiceCollect);

  const { id } = useParams();
  const type = props.type;
  // console.log("data:")
  // console.log(data)
  // console.log("carData:")
  // console.log(CarsData)

  useEffect(() => {
    if (type === "generatedInvoiceByBranch") {
      dispatch(FetchGeneratedInvoicesByBranchId(token, id));
    } else {
      dispatch(FetchInvoiceForCashCollection(token));
    }
  }, []);
  return (
    <div>
      <div className="mt-2">
        {/* <FormHeader province={"Kabul"} /> */}
        {/* <PageHeader title={t("invoice.allApprovedInvoice")} /> */}
        <PageHeader title={t("invoice.generatedInvoice")} />
      </div>
      {loading ? (
        <LoadingAnimation />
      ) : data && data.length > 0 ? (
        // <DriverTabularView data={data} />
        // <CarTable carsData={CarsData} />
        <>
          {invoiceCollect && <InvoiceCollectionModal  invoice={invoiceCollect}/>}
          <CollectingCashTable carsData={CarsData} id={id} recordType={t("vehicle_details_form.generatedGroupInvoiceList")} />
        </>
      ) : (
        // <DriverTabularView data={data} options={true}/>
        <NoContentsFound message={t("general_key_words.dataNotFound")} />
      )}
    </div>
  );
}
