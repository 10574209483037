import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import { t } from "i18next";
import { DeleteError, DeleteSuccess } from "../vehicleRegistration/VehicleSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { RejectSuccess } from "./InvoiceSlice";

export const RejectGeneratedInvoice = (token, id, section) => async (dispatch, getState) => {
    // dispatch(DeleteStart(id));
    // http request to backend
    const data = {
        start_date : new Date().toDateString(),
        generate_id: id
    }
    
    try {
    //   const response = await http.post(`api/driver/gen_invoice/reject/${id}`,data, {
        const response = await http.post(`api/driver/gen/invoice/reject`,data, {
        headers: { Authorization: `Bearer ${token}` },
      });

    //   console.log(response.data.record);

        const currentState = getState();
        const updatedData = currentState.invoice.data.filter(
        (record) => record.generate_invoice_id !== id
        );
        toast.success(t("toast.generatedInvoiceRejected"));
        dispatch(RejectSuccess(updatedData));
       
      // console.log(response);
      
      
    //   dispatch(DeleteSuccess());
      
    } catch (error) {
      dispatch(DeleteError());
      if (error.response) {
        HandleHTTPRequestError(error.response.status, error, dispatch);
      } else {
        // Handle the case where the API is offline or unreachable
        console.error("API is offline or unreachable.");
          toast.error(t("toast.apiOffline"));
        //   toast.success(t("toast.BankSlipApproved"));
      }
    }
  };


