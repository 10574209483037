import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import FormDataHelper from "../../Utility/FormDataHelper";
import {
  HandleProgress,
  UploadError,
  UploadStart,
  UploadSuccess,
} from "./ControlSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const UploadControlData = (data, token) => async (dispatch) => {
  dispatch(UploadStart());
  // http request to backend
  try {
    const formData = new FormData();
    formData.append("traffic_agent_name",data.trafficAgentName);
    formData.append("company_agent_name",data.companyAgentName);
    formData.append("gre_date",data.regGregorianDate);
    formData.append("per_date",data.regPersianDate);
    formData.append("driver_id",data.carDetailsFkId);
    formData.append("control_status",data.controlStatus);
    formData.append("traffic_remark",data.trafficAgentRemark);
    formData.append("company_remark",data.companyAgentRemark);
    formData.append("approve_file",data.controAttachement);

    const response = await http.post("api/control", formData, {
      headers: { Authorization: `Bearer ${token}` },
      onUploadProgress: (progressEvent) => {
        const progress = Math.floor(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        dispatch(HandleProgress(progress));
        // console.log(progress);
      },
    });

    toast.success(t("toast.data_upload"));
    dispatch(UploadSuccess("driver has been added to control"));
  } catch (error) {
    if (error.response.status === 422) {
      const Errors = {
        message: error.message,
        errors: error.response.data.errors,
      };

      dispatch(UploadError(Errors));
    }
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};
