import React from "react";
import SettingsNavbar from "./SettingsNavbar";
import { Outlet } from "react-router-dom";
import PageHeader from "../../Utility/PageHeader";

const SettingsLayout = () => {
  return (
    <div>
      <div className="rounded-3 shadow-sm bg-light mt-2">
        <SettingsNavbar />
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default SettingsLayout;
