import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedRecord: null,
  loading: false,
  success: null,
  error: null,
  progress: null,
  APIErrors: null,
  data: null,
  inlineLoading: false,
  selectedDelete: null,
  record: null,
  moved: null,
  branchData:null
};

const BranchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    SelectRecord: (state, action) => {
      state.selectedRecord = action.payload;
    },
    UploadStart: (state) => {
      state.loading = true;
      state.success = null;
      state.error = null;
      state.progress = null;
    },
    UploadSuccess: (state) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      state.APIErrors = null;
      state.record = null;
    },
    UploadError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload.message;
      state.APIErrors = action.payload.errors;
      state.record = null;
    },
    HandleProgress: (state, action) => {
      state.progress = action.payload;
    },
    FetchStart: (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
      state.progress = null;
    },
    FetchSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    FetchError: (state) => {
      state.loading = false;
      state.data = null;
    },
    DeleteStart: (state, action) => {
      state.inlineLoading = true;
      state.selectedDelete = action.payload;
    },
    DeleteSuccess: (state) => {
      state.inlineLoading = false;
      state.selectedDelete = null;
    },
    DeleteError: (state) => {
      state.inlineLoading = false;
      state.selectedDelete = null;
    },
    UpdateRecords: (state, action) => {
      state.data = action.payload;
    },
    FetchIDStart: (state) => {
      state.loading = true;
    },
    FetchIDSuccess: (state, action) => {
      state.loading = false;
      state.record = action.payload;
    },
    FetchIDError: (state) => {
      state.loading = false;
    },
    ProcessStart: (state) => {
      state.inlineLoading = null;
      state.moved = null;
    },
    ProcessSuccess: (state, action) => {
      state.inlineLoading = false;
      state.moved = action.payload;
    },
    ProcessError: (state) => {
      state.inlineLoading = false;
      state.moved = null;
    },
    BranchStart: (state) => {
      state.inlineLoading = true;
      state.branchData = null;
    },
    BranchSuccess: (state, action) => {
      state.inlineLoading = false;
      state.branchData = action.payload;
    },
    BranchError: (state) => {
      state.inlineLoading = false;
      state.branchData = null;
    },
  },
});

export const {
  SelectRecord,
  UploadError,
  UploadStart,
  UploadSuccess,
  HandleProgress,
  FetchError,
  FetchStart,
  FetchSuccess,
  DeleteError,
  DeleteSuccess,
  DeleteStart,
  UpdateRecords,
  FetchIDStart,
  FetchIDError,
  FetchIDSuccess,
  ProcessStart,
  ProcessSuccess,
  ProcessError,
  BranchStart,
  BranchError,
  BranchSuccess
} = BranchSlice.actions;
export default BranchSlice.reducer;
