import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';

function Protected({children}) {
    const isAuthenticated = useSelector(
        (state) => state.authentication.isAuthenticated
      );
    if(!isAuthenticated)
    {
        return <Navigate to={'/login'} replace/>
    }
    return children;
}
export default Protected;
