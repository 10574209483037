import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import {
  FetchError,
  FetchStart,
  FetchSuccess,
  HandleProgress,
} from "./VehicleSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const FetchDriverData = (token) => async (dispatch) => {
  dispatch(FetchStart());
  // console.log("Falg234:",flag);
  // console.log("URL:",url);
  // http request to backend
  try {

    const response = await http.get("api/driver", {
    // const response = await http.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
    // console.log("response23:");
    // console.log(response);
  } catch (error) {
    dispatch(FetchError())
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};

export const FetchDriverWaitingDataList = (token) => async (dispatch) => {
  dispatch(FetchStart());
  // http request to backend
  try {
    const response = await http.get("api/driver/waiting/list/get", {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
    // console.log("response:");
    // console.log(response);
  } catch (error) {
    dispatch(FetchError())
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};

export const FetchDriverNoWaitingDataList = (token) => async (dispatch) => {
  dispatch(FetchStart());
  // http request to backend
  try {
    const response = await http.get("api/driver/nowaiting/list/get", {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
    // console.log("response:");
    // console.log(response);
  } catch (error) {
    dispatch(FetchError())
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};

export const FetchDriverApprovedList = (token) => async (dispatch) => {
  dispatch(FetchStart());
  // http request to backend
  try {
    const response = await http.get("api/driver/approved/list/get", {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
    // console.log("response:");
    // console.log(response);
  } catch (error) {
    dispatch(FetchError())
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};

export const FetchDriverRejectedList = (token) => async (dispatch) => {
  dispatch(FetchStart());
  // http request to backend
  try {
    const response = await http.get("api/driver/rejected/list/get", {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
    // console.log("response:");
    // console.log(response);
  } catch (error) {
    dispatch(FetchError())
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};


export const FetchCompletedList = (token) => async (dispatch) => {
  dispatch(FetchStart());
  // http request to backend
  try {
    const response = await http.get("api/driver/completed/list/get", {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
    // console.log("response:");
    // console.log(response);
  } catch (error) {
    dispatch(FetchError())
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};

//fetch completed list according to branch ID:
export const FetchCompletedListByBranchId = (token, id) => async (dispatch) => {
  dispatch(FetchStart());
  // http request to backend
  try {
    const response = await http.get(`api/drivers/complated/branch/get/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
    // console.log("response:");
    // console.log(response);
  } catch (error) {
    dispatch(FetchError())
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};


export const FetchDriverApprovedBankSlip = (token) => async (dispatch) => {
  dispatch(FetchStart());
  // http request to backend
  try {
    const response = await http.get("api/driver/approved_banks_slip/list/get", {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
    // console.log("response:");
    // console.log(response);
  } catch (error) {
    dispatch(FetchError())
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};


//

