import toast from "react-hot-toast";
import { CSRFTokenRefresh } from "./CSRFTokenRefresh";
import { LogOut } from "../redux/authentication/AuthenticationSlice";
import Cookies from "js-cookie";
import { ClearSession } from "../redux/session/slice/SessionSlice";


export const HandleHTTPRequestError = (statusCode, error, dispatch) => {
  switch (statusCode) {
    case 419:
      toast.error("token has been expired");
      CSRFTokenRefresh();
      toast.error("token has been refreshed, try again");
      break;
    case 401:
      // localStorage.removeItem("user");
      // localStorage.removeItem("token");
      sessionStorage.removeItem("token"); // remove
      sessionStorage.removeItem("user"); 

      if(Cookies.get("userTokenCookie")){
        Cookies.remove("userTokenCookie");
        Cookies.remove("userDataCookie");
      }

      toast.error(error.response.data.message);
      dispatch(ClearSession())
      dispatch(LogOut());
      break;
    // Add more cases for other error codes as needed
    default:
      toast.error(error.response.data.message);
  }
};
