import { t } from "i18next";
import React from "react";
import { AiFillCar } from "react-icons/ai";
import {
  BiSolidCarMechanic,
  BiSolidTimer,
  BiSolidUserPin,
} from "react-icons/bi";
import { BsCalendarCheckFill, BsCheck } from "react-icons/bs";
import { FaBusinessTime, FaCheck, FaClock, FaFileInvoiceDollar, FaTimes } from "react-icons/fa";
import { MdAttachMoney, MdMore, MdPendingActions } from "react-icons/md";
import { SiCashapp } from "react-icons/si";
import { ImCheckboxChecked } from "react-icons/im";
import { RiAiGenerate, RiGitBranchFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FcApprove, FcProcess } from "react-icons/fc";

const DashCard = ({ data }) => {
  // console.log("Data44444446:");
  // console.log(data);
  // const domain = "/";
  const user = useSelector((state) => state.authentication.user);
  const navigate = useNavigate();
  const handleViewMore = (page) => {
    // const navigateUrl = domain+page;
    // window.location.assign(navigateUrl);
    // added a test comment
    window.open("/" + page, "_blank");
  };

  const handleViewDrivers = (page) => {
    window.open("/vehicle-dashboard", "_blank");
  };

  const visitList = (url) => {
    navigate("/" + url);
  };
  return (
    <>
    {/* {user.role !=="user" &&( */}
    {/* {(user.role ==="super") &&( */}
    {(user.role ==="super" || user.role==="admin") &&(
      <div className="rounded-3 row bg-light m-0 mt-2 py-3">
        {/* revenue section */}
        <div className="col-sm-12 col-md-6 col-lg-3 ">
          <div className="d-flex flex-column">
            <div className="d-flex flex-row align-items-lg-center justify-content-lg-center cursor-pointer"
              onClick={(msg) => handleViewMore("approved_invoice-list/collected")}
              >
              <div className="p-3">
                <SiCashapp className="fs-3 text-danger" />
              </div>
              <div className="d-flex flex-column p-3">
                <div className="fs-6 fw-semibold text-secondary">
                  {t("dashboard_menu.revenue")}
                </div>
                <div className="mt-1 fs-3 fw-semibold">
                  {data && data.receivedInvoiceSum}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* invoices section */}
        <div className="col-sm-12 col-md-6 col-lg-3 ">
          <div className="d-flex flex-column ">
            <div className="d-flex flex-row align-items-lg-center justify-content-lg-center cursor-pointer"
             onClick={(msg) => handleViewMore("invoice-list")} >
              <div className="p-3">
                <FaFileInvoiceDollar className="fs-3 text-warning" />
              </div>
              <div className="d-flex flex-column p-3">
                <div className="fs-6 fw-semibold text-secondary">{t("dashboard_menu.invoices")}</div>
                <div className="mt-1 fs-3 fw-semibold">
                  {user.role === "admin" ? 
                    data && data.pendingInvoiceSum && data.pendingInvoiceSum[0] && data.pendingInvoiceSum[0].price
                      ? data.pendingInvoiceSum[0].price
                      : "0"
                    : data && data.pendingInvoiceSum
                      ? data.pendingInvoiceSum
                      : "0"
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Clients section */}
        <div className="col-sm-12 col-md-6 col-lg-3">
          <div className="d-flex flex-column">
            <div className="d-flex flex-row align-items-lg-center justify-content-lg-center cursor-pointer"
            onClick={(msg) => handleViewMore("view-list")}
            >
              <div className="p-3">
                <BiSolidUserPin className="fs-2 text-primary" />
              </div>
              <div className="d-flex flex-column p-3">
                <div className="fs-6 fw-semibold text-secondary">{t("dashboard_menu.clients")}</div>
                <div className="mt-1 fs-3 fw-semibold">
                  {data && data.drivers}
                  
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* Clients section */}
        <div className="col-sm-12 col-md-6 col-lg-3">
          <div className="d-flex flex-column ">
            <div className="d-flex flex-row align-items-lg-center justify-content-lg-center cursor-pointer"
            onClick={(msg) => handleViewMore("driver-complate")}
            >
              <div className="p-3">
                <BsCalendarCheckFill className="fs-3 text-success" />
              </div>
              <div className="d-flex flex-column p-3">
                <div className="fs-6 fw-semibold text-secondary">{t("vehicle_details_form.complated")}</div>
                <div className="mt-1 fs-3 fw-semibold">
                  {data && data.complatedVehicle}
                  
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    )}


      <div className="rounded-3 row bg-light m-0 mt-2 py-5">
      {user.role !=="finance" &&(
      <>
        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
          <div
            className="dash-card-bg border rounded-3 shadow"
            style={{ backgroundPosition: "0px 0px" }}
          >
            <div className="d-flex flex-column p-4">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="fs-5">
                  {t("dash_board_card.registeredVehicle")}
                </div>
                <div>
                  <AiFillCar className="fs-4" />
                </div>
              </div>
              <div className="fs-2 fw-semibold my-2">
                {data && data.drivers > 0 ? data.drivers : "0"}
              </div>
              <div className="">
                {user.role === "super" ? (
                  <>
                    <button
                      className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                        data.drivers > 0 ? "" : "disabled"
                      }`}
                      // onClick={(msg) => handleViewMore("view-list")}
                      onClick={(msg) => handleViewDrivers("view-list")}
                    >
                      {t("dash_board_card.details")}
                      <MdMore className="bi" />
                    </button>
                  </>
                ) : (
                  <button
                    className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                      data.drivers > 0 ? "" : "disabled"
                    }`}
                    // onClick={(msg) => handleViewMore("view-list")}
                    onClick={(e) => visitList("view-list")}
                  >
                    {t("dash_board_card.details")}
                    <MdMore className="bi" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
          <div
            className="dash-card-bg border rounded-3 shadow"
            style={{ backgroundPosition: "0px 0px" }}
          >
            <div className="d-flex flex-column p-4">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="fs-5">
                  {t("vehicle_table.bankSlipApprove")}
                </div>
                <div>
                  <FaCheck className="fs-4" />
                </div>
              </div>
              <div className="fs-2 fw-semibold my-2">
                {data && data.approvedBankSlipCount > 0 ? data.approvedBankSlipCount : "0"}
              </div>
              <div className="">
                {user.role === "super" ? (
                  <>
                    <button
                      className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                        data.approvedBankSlipCount > 0 ? "" : "disabled"
                      }`}
                      // onClick={(msg) => handleViewMore("view-list")}
                      // onClick={(msg) => handleViewDrivers("driver-slip-approved")}
                      onClick={(e) => handleViewMore("driver-slip-approved")}
                      // onClick={(e) => visitList("driver-slip-approved")}
                    >
                      {t("dash_board_card.details")}
                      <MdMore className="bi" />
                    </button>
                  </>
                ) : (
                  <button
                    className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                      data.approvedBankSlipCount > 0 ? "" : "disabled"
                    }`}
                    // onClick={(msg) => handleViewMore("view-list")}
                    onClick={(e) => handleViewMore("driver-slip-approved")}
                  >
                    {t("dash_board_card.details")}
                    <MdMore className="bi" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
          <div
            className="dash-card-bg border rounded-3 shadow"
            style={{ backgroundPosition: "0px 0px" }}
          >
            <div className="d-flex flex-column p-4">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="fs-5">
                  {t("vehicle_table.bankSlipNotApprove")}
                </div>
                <div>
                  <FaClock className="fs-4" />
                </div>
              </div>
              <div className="fs-2 fw-semibold my-2">
                {data && data.notApprovedBankSlipCount > 0 ? data.notApprovedBankSlipCount : "0"}
              </div>
              <div className="">
                {user.role === "super" ? (
                  <>
                    <button
                      className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                        data.notApprovedBankSlipCount > 0 ? "" : "disabled"
                      }`}
                      // onClick={(msg) => handleViewMore("view-list")}
                      // onClick={(msg) => handleViewDrivers("driver-slip-approved")}
                      // onClick={(e) => visitList("driver-no-waiting")}
                      onClick={(e) => handleViewMore("driver-no-waiting")}
                    >
                      {t("dash_board_card.details")}
                      <MdMore className="bi" />
                    </button>
                  </>
                ) : (
                  <button
                    className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                      data.notApprovedBankSlipCount > 0 ? "" : "disabled"
                    }`}
                    // onClick={(msg) => handleViewMore("view-list")}
                    onClick={(e) => visitList("driver-slip-approved")}
                  >
                    {t("dash_board_card.details")}
                    <MdMore className="bi" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
          <div
            className="dash-card-bg border rounded-3 shadow"
            style={{ backgroundPosition: "0px 0px" }}
          >
            <div className="d-flex flex-column p-4">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="fs-5">{t("dash_board_card.colorWaiting")}</div>
                <div>
                  <BiSolidTimer className="fs-4" />
                </div>
              </div>
              <div className="fs-2 fw-semibold my-2">
                {data && data.waiting > 0 ? data.waiting : "0"}
              </div>
              <div className="">
                {user.role === "super" ? (
                  <>
                    <button
                      className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                        data.waiting > 0 ? "" : "disabled"
                      }`}
                      onClick={(msg) =>
                        handleViewMore("vehicle-waiting-dashboard")
                      }
                    >
                      {t("dash_board_card.details")}
                      <MdMore className="bi" />
                    </button>
                  </>
                ) : (
                  <button
                    className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                      data.waiting > 0 ? "" : "disabled"
                    }`}
                    // onClick={(msg) => handleViewMore("view-list")}
                    onClick={(e) => visitList("waiting-list")}
                  >
                    {t("dash_board_card.details")}
                    <MdMore className="bi" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Approved Controls: */}
        {/* {data && data.controls > 0 && ( */}
        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
          <div
            className="dash-card-bg border rounded-3 shadow"
            style={{ backgroundPosition: "0px 0px" }}
          >
            <div className="d-flex flex-column p-4">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="fs-5">
                  {t("dash_board_card.approvedControls")}
                </div>
                <div>
                  <BiSolidCarMechanic className="fs-4" />
                </div>
              </div>
              <div className="fs-2 fw-semibold my-2">
                {data && data.controls > 0 ? data.controls : "0"}
              </div>
              <div className="">
                {user.role === "super" ? (
                  <>
                    <button
                      className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                        data.controls > 0 ? "" : "disabled"
                      }`}
                      // onClick={(msg) => handleViewMore("view-list")}
                      onClick={(msg) =>
                        handleViewMore("vehicle-controlled-dashboard")
                      }
                      // onClick={(e) => visitList("control-list")}
                    >
                      {t("dash_board_card.details")}
                      <MdMore className="bi" />
                    </button>
                  </>
                ) : (
                  <button
                    className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                      data.controls > 0 ? "" : "disabled"
                    }`}
                    // onClick={(msg) => handleViewMore("view-list")}
                    onClick={(e) => visitList("control-list")}
                  >
                    {t("dash_board_card.details")}
                    <MdMore className="bi" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* )} */}

        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
          <div
            className="dash-card-bg border rounded-3 shadow"
            style={{ backgroundPosition: "0px 0px" }}
          >
            <div className="d-flex flex-column p-4">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="fs-5">
                  {t("dash_board_card.complatedVehicle")}
                </div>
                <div>
                  <ImCheckboxChecked className="fs-4 text-success" />
                </div>
              </div>
              <div className="fs-2 fw-semibold my-2">
                {data && data.complatedVehicle > 0
                  ? data.complatedVehicle
                  : "0"}
              </div>
              <div className="">
                {user.role === "super" ? (
                  <>
                    <button
                      className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                        data.complatedVehicle > 0 ? "" : "disabled"
                      }`}
                      onClick={(msg) =>
                        handleViewMore("vehicle-complated-dashboard")
                      }
                    >
                      {t("dash_board_card.details")}
                      <MdMore className="bi" />
                    </button>
                  </>
                ) : (
                  <button
                    className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                      data.complatedVehicle > 0 ? "" : "disabled"
                    }`}
                    // onClick={(msg) => handleViewMore("view-list")}
                    onClick={(e) => visitList("driver-complate")}
                  >
                    {t("dash_board_card.details")}
                    <MdMore className="bi" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {data && data.branches && (
          <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div
              className="dash-card-bg border rounded-3 shadow"
              style={{ backgroundPosition: "0px 0px" }}
            >
              <div className="d-flex flex-column p-4">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="fs-5">{t("dash_board_card.branches")}</div>
                  <div>
                    <RiGitBranchFill className="fs-4" />
                  </div>
                </div>
                <div className="fs-2 fw-semibold my-2">
                  {data && data.branches > 0 ? data.branches : "0"}
                </div>
                <div className="">
                  {user.role === "super" ? (
                    <>
                      <button
                        className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                          data.branches > 0 ? "" : "disabled"
                        }`}
                        onClick={(msg) => handleViewMore("branch-list")}
                      >
                        {t("dash_board_card.details")}
                        <MdMore className="bi" />
                      </button>
                    </>
                  ) : (
                    <button
                      className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                        data.branches > 0 ? "" : "disabled"
                      }`}
                      // onClick={(msg) => handleViewMore("view-list")}
                      onClick={(e) => visitList("branch-list")}
                    >
                      {t("dash_board_card.details")}
                      <MdMore className="bi" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
         </>
        )}
        {/* Invoices section: */}
        {data && data.pendingInvoiceSum > 0 && (
          <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div
              className="dash-card-bg border rounded-3 shadow"
              style={{ backgroundPosition: "0px 0px" }}
            >
              <div className="d-flex flex-column p-4">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="fs-5">
                    {t("dash_board_card.pendingInvoice")}
                  </div>
                  <div>
                    <MdPendingActions className="fs-4" />
                  </div>
                </div>
                <div className="fs-2 fw-semibold my-2">
                  {data && data.pendingInvoiceSum > 0
                    ? data.pendingInvoiceSum
                    : "0"}
                </div>
                <div className="">
                  {user.role === "super" ? (
                    <>
                      <button
                        className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                          data.pendingInvoiceSum > 0 ? "" : "disabled"
                        }`}
                        onClick={(msg) =>
                          handleViewMore("invoice-pending-dashboard")
                        }
                      >
                        {t("dash_board_card.details")}
                        <MdMore className="bi" />
                      </button>
                    </>
                  ) : (
                    <button
                      className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                        data.pendingInvoiceSum > 0 ? "" : "disabled"
                      }`}
                      // onClick={(msg) => handleViewMore("view-list")}
                      onClick={(e) => visitList("invoice-list")}
                    >
                      {t("dash_board_card.details")}
                      <MdMore className="bi" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {data && data.generatedInvoiceSum > 0 && (
          <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div
              className="dash-card-bg border rounded-3 shadow"
              style={{ backgroundPosition: "0px 0px" }}
            >
              <div className="d-flex flex-column p-4">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="fs-5">{t("invoice.generatedInvoice")}</div>
                  <div>
                    <RiAiGenerate className="fs-4" />
                  </div>
                </div>
                <div className="fs-2 fw-semibold my-2">
                  {data && data.generatedInvoiceSum > 0
                    ? data.generatedInvoiceSum
                    : "0"}
                </div>
                <div className="">
                  {user.role === "super" ? (
                    <>
                      <button
                        className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                          data.generatedInvoiceSum > 0 ? "" : "disabled"
                        }`}
                        onClick={(msg) =>
                          handleViewMore("invoice-generated-dashboard")
                        }
                      >
                        {t("dash_board_card.details")}
                        <MdMore className="bi" />
                      </button>
                    </>
                  ) : (
                    <button
                      className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                        data.generatedInvoiceSum > 0 ? "" : "disabled"
                      }`}
                      // onClick={(msg) => handleViewMore("view-list")}
                      onClick={(e) =>
                        visitList("approved_invoice-list/collecting-cash")
                      }
                    >
                      {t("dash_board_card.details")}
                      <MdMore className="bi" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {data && data.receivedInvoiceSum > 0 && (
          <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div
              className="dash-card-bg border rounded-3 shadow"
              style={{ backgroundPosition: "0px 0px" }}
            >
              <div className="d-flex flex-column p-4">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="fs-5">
                    {t("dash_board_card.receivedInvoice")}
                  </div>
                  <div>
                    <MdAttachMoney className="fs-4" />
                  </div>
                </div>
                <div className="fs-2 fw-semibold my-2">
                  {data && data.receivedInvoiceSum > 0
                    ? data.receivedInvoiceSum
                    : "0"}
                </div>
                <div className="">
                  {user.role === "super" ? (
                    <>
                      <button
                        className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                          data.receivedInvoiceSum > 0 ? "" : "disabled"
                        }`}
                        onClick={(msg) =>
                          handleViewMore("invoice-received-dashboard")
                        }
                      >
                        {t("dash_board_card.details")}
                        <MdMore className="bi" />
                      </button>
                    </>
                  ) : (
                    <button
                      className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                        data.receivedInvoiceSum > 0 ? "" : "disabled"
                      }`}
                      // onClick={(msg) => handleViewMore("view-list")}
                      onClick={(e) =>
                        visitList("approved_invoice-list/collected")
                      }
                    >
                      {t("dash_board_card.details")}
                      <MdMore className="bi" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
    </>
  );
};

export default DashCard;
