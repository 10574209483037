import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  loading: false,
  message:null,
  type:null
};

const PublicDriverSlice = createSlice({
  name: "public_driver",
  initialState,
  reducers: {
    FetchStart: (state) => {
      state.loading = true;
      state.data = null;
      state.message = null;
      state.type = null;
    
    },
    FetchSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.message = action.payload.message;
      state.type = action.payload.type;
     
    },
    FetchError: (state) => {
      state.loading = false;
      state.data = null;
      state.message =null;
      state.type = null;
    },
  },
});

export const { FetchError, FetchStart, FetchSuccess } =
  PublicDriverSlice.actions;
export default PublicDriverSlice.reducer;
