import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import { UpdateRecords } from "./VehicleSlice";
import { DeleteError, DeleteSuccess, DeleteStart } from "../Util/DeleteSlice";

import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";
import { MoveError, MoveStart, MoveSuccess } from "../WaitingSection/WaitSlice";

export const AddToControl =
  (token, id, waitID, section) => async (dispatch, getState) => {
    dispatch(MoveStart(id));
    // http request to backend
    try {
      const data = {
        wait_id: waitID,
      };
      const response = await http.post(`api/driver/control/add/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (section === "table") {
        const currentState = getState();

        const updatedData = currentState.wait.data.filter((record) => record.dId != id) 
              
        dispatch(MoveSuccess(updatedData));
      }
      // console.log(response);
      toast.success(t("toast.vehicle_add_to_control"));
      // dispatch(DeleteSuccess());
    } catch (error) {
      dispatch(MoveError());
      if (error.response) {
        HandleHTTPRequestError(error.response.status, error, dispatch);
      } else {
        // Handle the case where the API is offline or unreachable
        console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
      }
    }
  };
