import React from "react";
import { Outlet } from "react-router-dom";
import InvoiceNav2 from "./InvoiceNave2";

const InvoiceLayout2 = () => {
  return (
    <div>
      <div className="mt-2 bg-white rounded-3 overflow-hidden">
        <InvoiceNav2 />
      </div>
      <div className="">
        <Outlet />
      </div>
    </div>
  );
};

export default InvoiceLayout2;
