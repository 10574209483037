import toast from "react-hot-toast";
import { http } from "../../../Utility/APIRequest"; 
import FormDataHelper from "../../../Utility/FormDataHelper";
import { 
  FetchError,
    HandleProgress,
    UploadError,
    UploadStart,
    UploadSuccess,
 } from "../slice/branchSlice";
import { useSelector } from "react-redux";
import { HandleHTTPRequestError } from "../../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const UploadBranchEdit = (branchData, token,id) => async (dispatch) => {
  dispatch(UploadStart());
  // http request to backend
  try {
    // const formData = FormDataHelper(driverData);
    const formData = new FormData();
    // console.log("Branch Data:")
    // console.log(branchData)
    formData.append("branchName", branchData.branchName);
    formData.append("description", branchData.description);
    formData.append("province", branchData.province);

    const response = await http.post(`api/branch/${id}`, formData, {
      headers: { Authorization: `Bearer ${token}` },
      onUploadProgress: (progressEvent) => {
        const progress =
          Math.floor((progressEvent.loaded / progressEvent.total) * 100);
        dispatch(HandleProgress(progress));
        // console.log(progress);
      },
    });

    toast.success(t("toast.data_upload"));
    dispatch(UploadSuccess());
    // setTimeout(window.close(),10000)
  } catch (error) {
    if (error.response.status === 422) {
      const Errors = {
        message: error.message,
        errors: error.response.data.errors,
      };

      dispatch(UploadError(Errors));
    }

    dispatch(FetchError());
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }

  }
};
