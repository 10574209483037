import { t } from "i18next";
import React, { useMemo, useState, useRef } from "react";
import { useTable, useSortBy, usePagination } from "react-table"; // Import useSortBy hook

import jsPDF from "jspdf";
import "jspdf-autotable";

import { useDownloadExcel } from "react-export-table-to-excel";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import jalaliMoment from "jalali-moment";
import {
  BiSolidChevronLeft,
  BiSolidChevronRight,
  BiSolidChevronsLeft,
  BiSolidChevronsRight,
} from "react-icons/bi";
import moment from "jalali-moment";

// import "jspdf-autotable/dist/polyfills.js";
// import "jspdf-autotable/dist/jspdf.plugin.autotable.js";

const UserLogTable = ({ carsData }) => {
    // console.log("DAta in table file:");
    // console.log(carsData);
  const [filterValue, setFilterValue] = useState(""); // State variable for filtering

  const data = useMemo(() => {
    if (!carsData || !carsData.data) {
      return [];
    }

    // Apply filtering based on the filterValue
    return carsData.data.records.filter((row) => {
      return Object.values(row).some((value) =>
        value
          ? value.toString().toLowerCase().includes(filterValue.toLowerCase())
          : false
      );
    });
  }, [carsData, filterValue]);

  const columns = useMemo(
    () => [
    //   {
    //     Header: t("vehicle_table.serial_number"),
    //     accessor: "id",
    //   },
      {
        Header: t("vehicle_table.serial_number"),
        accessor: (row, index) => index + 1,
      },
      {
        Header: t("userLog.name"),
        accessor: "full_name",
      },
      {
        Header: t("userLog.email"),
        accessor: "email",
      },
      {
        Header: t("userLog.role"),
        accessor: "role",
      },
      {
        Header: t("userLog.userId"),
        accessor: "user_id",
      },
    //   {
    //     Header: t("User Agent"),
    //     accessor: "user_agent",
    //   },
      {
        Header: t("userLog.loginDate"),
        accessor: "date",
        Cell: ({ row }) => {
          const gregorianDate = new Date(row.original.created_at);
          const jalaliDate = moment(gregorianDate).locale("fa").format("jYYYY/jMM/jDD"); // Convert to Jalali date
          return jalaliDate;
        },
      },
      {
        Header: t("userLog.loginTime"),
        accessor: "time",
        Cell: ({ row }) =>
          new Date(row.original.created_at).toLocaleTimeString(),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },

    useSortBy, // Enable sorting
    usePagination
  );

  //downlaod excel sheet:
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: t("excelTable.usersLogTable"),
    sheet: t("excelTable.usersLogSheet"),
  });

  //   const handleExportToExcel = () => {
  //     const exportData = page.map((row) => {
  //       const rowData = {};
  //       columns.forEach((column) => {
  //         // rowData[column.Header] = row.values[column.accessor];
  //         if (column.id !== "optionsButton") { // Exclude the "optionsButton" column
  //             rowData[column.Header] = row.values[column.accessor];
  //           }
  //       });
  //       return rowData;
  //     });
  //     onDownload(exportData, columns);
  //   };
  const handleExportToExcel = () => {
    const exportColumns = columns.filter(
      (column) => column.id !== "optionsButton"
    );
    const exportData = page.map((row) => {
      const rowData = {};
      exportColumns.forEach((column) => {
        rowData[column.Header] = row.values[column.accessor];
      });
      return rowData;
    });
    onDownload(exportData, exportColumns);
  };

  //download to pdf:
  const exportPDF = () => {
    // console.log("PDF");
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3, or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Driver Table";
    const headers = [
      [
        t("Name"),
        t("Father Name"),
        t("NID"),
        t("Phone#"),
        t("Driving Permit"),
        t("Car Num Plat"),
        t("Province"),
      ],
    ];

    const data = page.map((row) => [
      row.original.firstName,
      row.original.fatherName,
      row.original.nid,
      row.original.phone,
      row.original.drivingPermit,
      row.original.carNumPlat,
      row.original.province,
    ]);

    doc.text(title, marginLeft, 40);
    doc.autoTable({
      startY: 50,
      head: headers,
      body: data,
    });

    doc.save("report.pdf");
  };

  return (
    <div>
      {/* Input field for filtering */}
      {/* <input
        type="text"
        className="form-control m-0 rounded-3"
        value={filterValue}
        onChange={(e) => setFilterValue(e.target.value)}
        placeholder={t("general_key_words.search")}
      /> */}

      <div className="row mt-2 mb-2">
        <div className="col-sm-12 col-md-8">
          <input
            type="text"
            className="form-control"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            placeholder={t("general_key_words.search")}
          />
        </div>
        <div className="col-sm-12 col-md-4">
          <div className="bg-white rounded-2 mt-2 mt-lg-0 mb-0 d-flex flex-row align-items-center justify-content-between">
            <div onClick={handleExportToExcel}>
              <FaFileExcel
                style={{ color: "green", cursor: "pointer", fontSize: "25px" }}
              />
            </div>
            <div className="d-flex flex-row align-items-center">
              <span className="mx-2">{t("general_key_words.show")}</span>
              <select
                value={pageSize}
                className="form-select"
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 50, 100, 200, 400, 800, 1600].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="table-responsive" >
        <table
          className="table table-hover"
          id="property-table"
          
          {...getTableProps()}
          border="1"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key="header" className="text-center">
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="table-header-style"
                  >
                    {column.render("Header")}
                    {/* Display sorting indicators */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.original.id} className="text-center">
                  {row.cells.map((cell, index) => {
                    //   return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                    return (
                      <td {...cell.getCellProps()}
                      className="table-cells-style"
                      >
                       
                         {cell.render("Cell")} 
                        
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <table
          className="table d-none"
          id="property-table"
          ref={tableRef}
          {...getTableProps()}
          border="1"
        >
          <caption style={{ textAlign: "center", fontSize: "20px", fontWeight: "bold" }}>
            <br/>
            {/* {t("message.brand")} <br /> {recordType} */}
            ({jalaliMoment(new Date().toDateString()).locale("fa").format("DD-MM-YYYY")})
        </caption>
          
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} key="header" className="text-center">
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="table-cells-style"
                  >
                    {column.render("Header")}
                    {/* Display sorting indicators */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.original.id} className="text-center">
                  {row.cells.map((cell, index) => {
                    //   return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                    return (
                      <td {...cell.getCellProps()}
                      className="table-cells-style"
                      >
                       
                         {cell.render("Cell")} 
                        
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

      {pageCount > 1 && (
        <ul className="pagination bg-white py-2 rounded-3 d-flex flex-row justify-content-between px-2 mt-2">
          <li className="page-item ">
            <button className="btn btn-light p-0 p-1" disabled>
              {t("general_key_words.page")}{" "}
              <strong>
                {pageIndex + 1} / {pageCount}
              </strong>{" "}
            </button>
          </li>
          <div className="d-flex flex-row gap-1">
            <li className="page-item ">
              <button
                className="btn btn-primary py-2 d-flex align-items-center"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <BiSolidChevronsLeft className="fs-6" />
              </button>
            </li>
            <li className="page-item ">
              <button
                className="btn btn-primary p-1 px-2 d-flex align-items-center"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <BiSolidChevronLeft className="fs-6 bi" />
                <span className="fs-6">{t("general_key_words.previous")}</span>
              </button>
            </li>
            <li className="page-item ">
              <button
                className="btn btn-primary p-1 px-2 d-flex align-items-center"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <BiSolidChevronRight className="fs-6 bi" />
                <span className="fs-6">{t("general_key_words.next")}</span>
              </button>
            </li>
            <li className="page-item ">
              <button
                className="btn btn-primary py-2 d-flex align-items-center"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <BiSolidChevronsRight className="fs-6" />
              </button>
            </li>
          </div>
        </ul>
      )}
    </div>
  );
};

export default UserLogTable;
