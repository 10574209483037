import React from "react";
import { useSelector } from "react-redux";
import FormHeader from "../../Utility/FormHeader";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
  

const PrintableVehicleInfo = () => {
  const record = useSelector((state) => state.vehicle.selectedRecord);
  const { t } = useTranslation("translation");
  return record ? (
    <div className="">

      {/* 
      
        {t("vehicle_details_form.carDetailsRegForm")}
      */}

      <div className="">
        <FormHeader province={"kabul"} title={t("general_key_words.company_title")} />
        <div className="row p-3 p-lg-5">
          <div className="col-sm-12 col-md-6">
            <div className="d-flex flex-column my-2">
              <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                <span className="">{t("vehicle_details_form.firstName")}:</span>
                <span className="mx-3 fw-semibold">{record.firstName}</span>
              </div>
              {/* <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                <span className="">Last Name:</span>
                <span className="mx-3 fw-semibold">{record.lastName}</span>
              </div> */}
              <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                <span className="">{t("vehicle_details_form.fatherName")}:</span>
                <span className="mx-3 fw-semibold">{record.fatherName}</span>
              </div>
              <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                <span className="">{t("vehicle_details_form.phone")}:</span>
                <span className="mx-3 fw-semibold">{record.phone}</span>
              </div>

              <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                <span className="">{t("general_key_words.registrationDate")}:</span>
                <span className="mx-3 fw-semibold">{record.regPersianDate}</span>
              </div>

              <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                <span className="">{t("general_key_words.time")}:</span>
                <span className="mx-3 fw-semibold">
                {(new Date(record.created_at)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}  
                </span>
              </div>

              {/* <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                <span className="">{t("vehicle_details_form.carChassis")}:</span>
                <span className="mx-3 fw-semibold">{record.carChassis}</span>
              </div> */}

              <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                <span className="">{t("vehicle_details_form.commissionNum")}:</span>
                <span className="mx-3 fw-semibold">{record.commissionNum}</span>
              </div>

              <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                <span className="">{t("vehicle_details_form.techContNum")}:</span>
                <span className="mx-3 fw-semibold">{record.techContNum}</span>
              </div>

            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="d-flex flex-column py-2">

            

              <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
              <span className="">{t("general_key_words.code")}:</span>
                  <span className="mx-3 fw-semibold">{record.id}</span>
              </div>

              <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                <span className="">{t("vehicle_details_form.nid")}:</span>
                <span className="mx-3 fw-semibold">{record.nid}</span>
              </div>

              <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                <span className="">{t("vehicle_details_form.province")}:</span>
                <span className="mx-3 fw-semibold">{record.province}</span>
              </div>
              <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                <span className="">{t("vehicle_details_form.carType")}:</span>
                <span className="mx-3 fw-semibold">{record.carType}</span>
              </div>

              <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                <span className="">{t("vehicle_details_form.carModel")}:</span>
                <span className="mx-3 fw-semibold">{record.carModel}</span>
              </div>

              <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                <span className="">{t("vehicle_details_form.carNumPlat")}:</span>
                <span className="mx-3 fw-semibold">{record.carNumPlat}</span>
              </div>

              <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                <span className="">{t("vehicle_details_form.drivingPermit")}:</span>
                <span className="mx-3 fw-semibold">{record.drivingPermit}</span>
              </div>
              {/* <div className="d-flex flex-row align-items-center py-2 ">
                <span className="">Gregorian Date:</span>
                <span className="mx-3 fw-semibold">{record.greDate}</span>
              </div> */}
            </div>
          </div>
          {/* <div className="col-sm-12 col-md-6">
            <div className="d-flex flex-column py-2">
              
             
            </div>
          </div> */}
          {/* <div className="col-sm-12 col-md-6 ">
            <div className="d-flex flex-column py-2">
              
              
              <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                <span className="">Painting Status:</span>
                <span className="mx-3 fw-semibold">{record.paintingStatus}</span>
              </div>
              <div className="d-flex flex-row align-items-center py-2 border-bottom border-2">
                <span className="">Work Status:</span>
                <span className="mx-3 fw-semibold">{record.workStatus}</span>
              </div>
              <div className="d-flex flex-row align-items-center py-2">
                <span className="">Other:</span>
                <span className="mx-3 fw-semibold">{"Blank Field"}</span>
              </div>
            </div>
          </div> */}
           
        </div>
        <div className="d-flex flex-row align-items-center mt-10 border-bottom border-2"> </div>
          <p className="text-center" >فورم هذا ثبت سیستم گردیده و صحت دارد</p>
          
      </div>
    </div>
  ) : (
    <div>Could not find a record with given ID </div>
  );
};

export default PrintableVehicleInfo;
