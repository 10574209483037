import React from 'react'

const ProgressBar = ({progress}) => {
  return (
    <div>
    <div
      className="progress"
      role="progressbar"
      aria-label="Animated striped example"
      aria-valuenow="0"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div
        className="progress-bar progress-bar-striped progress-bar-animated text-white"
        style={{ width: `${progress}%` }}
      >
        {progress}%
      </div>
    </div>
  </div>
  )
}

export default ProgressBar