import React, { useState } from "react";
import { RxDashboard } from "react-icons/rx";
import { CiSettings, CiLogout } from "react-icons/ci";
import {
  BsCalendarCheckFill,
  BsCashCoin,
  BsChevronDoubleDown,
  BsChevronDoubleUp,
  BsCollection,
  BsController,
  BsFillSignIntersectionFill,
  BsGear,
  BsLock,
  BsPaintBucket,
  BsPeople,
} from "react-icons/bs";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { LogOut } from "../redux/authentication/AuthenticationSlice";
import { useTranslation } from "react-i18next";
import { ChangeLanguage } from "../Language/ChangeLanguage";
import { GrLanguage } from "react-icons/gr";
import { http } from "../Utility/APIRequest";
import toast from "react-hot-toast";
import { CSRFTokenRefresh } from "../Utility/CSRFTokenRefresh";
import {
  AiFillCar,
  AiFillCloseCircle,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import {
  BiCar,
  BiListOl,
  BiRegistered,
  BiRepost,
  BiSearch,
  BiSolidUserDetail,
} from "react-icons/bi";
import { MdChecklist } from "react-icons/md";
import {
  FaBusinessTime,
  FaFileInvoiceDollar,
  FaKey,
  FaMoneyBill,
  FaSignInAlt,
  FaUnlock,
} from "react-icons/fa";
import Cookies from "js-cookie";
import LoadingAnimation from "./LoadingAnimation";

import style from "./style.css";
import Brand from "../Media/Brand.png";
const SideNavigation = () => {
  const [activeSection, setActiveSection] = useState(null);

  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);

  const handleSectionClick = (sectionName) => {
    if (activeSection === sectionName) {
      // If the same section is clicked again, collapse it
      setActiveSection(null);
      // setBranchMenu(!sectionName);
    } else {
      // Expand the clicked section
      setActiveSection(sectionName);
    }
  };

  const closeOffcanvas = () => {
    // console.log("closeOffcanvas called:");
    setOffcanvasOpen(false);
  };

  // const SideNavigation = () => {
  //   const [activeSection, setActiveSection] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [carMenu, setCarMenu] = useState(true);
  const [trafficMenu, setTrafficMenu] = useState(true);
  const [invoiceMenu, setInvoiceMenu] = useState(true);
  const [waitingMenu, setWaitingMenu] = useState(true);
  const [branchMenu, setBranchMenu] = useState(true);
  const [settingMenu, setSettingMenu] = useState(true);
  const [userSettingMenu, setUserSettingMenu] = useState(true);
  const [reportMenu, setReportMenu] = useState(true);

  const resetMenues = () => {
    setCarMenu(true);
    setTrafficMenu(true);
    setInvoiceMenu(true);
    setWaitingMenu(true);
    setBranchMenu(true);
    setSettingMenu(true);
    setUserSettingMenu(true);
    setReportMenu(true);
  };

  const { t } = useTranslation("translation");
  const token = useSelector((state) => state.authentication.token);

  const user = useSelector((state) => state.authentication.user);

  function logout() {
    // <LoadingAnimation />
    Swal.fire({
      title: t("general_key_words.areYouSure"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("general_key_words.logoutConfirmButtonText"),
      cancelButtonText: t("general_key_words.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        http
          .post(
            "api/auth/logout",
            {},
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            if (response.status === 200) {
              toast.success(response.data.message);
              // localStorage.removeItem("user");
              // localStorage.removeItem("token");

              sessionStorage.removeItem("user");
              sessionStorage.removeItem("token");

              if (Cookies.get("userTokenCookie")) {
                Cookies.remove("userTokenCookie");
                Cookies.remove("userDataCookie");
              }
              dispatch(LogOut());
              navigate("/login");
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              toast.error(error.response.data.message);
            } else if (error.response.status === 419) {
              toast.error(error.response.data.message);
              CSRFTokenRefresh();
            }
          });
      }
    });
  }
  return (
    <div className="sidebar col-md-3 col-lg-2 bg-dark">
      <div
        className={`offcanvas-md offcanvas-end ${
          isOffcanvasOpen ? "show" : ""
        }`}
        // className="offcanvas-md offcanvas-end"
        tabIndex="-1"
        id="sidebarMenu"
        aria-labelledby="sidebarMenuLabel"
        style={{ minHeight: "100vh" }}
      >
        <div className="offcanvas-header bg-dark text-white">
          <h5 className="offcanvas-title" id="sidebarMenuLabel">
            {t("general_key_words.company_title")}
          </h5>
          {/* <button
             type="button"
          >
            
          </button> */}
          <button
            type="button"
            // className="btn-close text-white"
            // className="btn-close"
            data-bs-dismiss="offcanvas"
            data-bs-target="#sidebarMenu"
            aria-label="Close"
            // style={{backgroundColor:"black", border:"0px"}}
            className="btn bg-dark p-0 m-0"
          >
            <span className="fs-1 text-white">
              <AiOutlineCloseCircle />
            </span>
          </button>
        </div>
        <div className="offcanvas-body d-md-flex flex-column p-3 p-lg-0 pt-lg-3 overflow-y-auto bg-dark">
    
          <ul className="nav flex-column p-0">
            <div className="text-center">
              <Link to="/" className="">
                <img
                  src={Brand}
                  alt="brand logo"
                  className="menu-brand"
                  data-bs-dismiss="offcanvas"
                  data-bs-target="#sidebarMenu"
                />
              </Link>
              {/* <div className="d-flex flex-column ">
              <h5 className="text-white hover-link fw-bold">Static</h5>
              <h6 className="text-white hover-link small-font">Leading Management & Construction</h6>
            </div> */}
            </div>
            <h6 className="d-flex justify-content-between align-items-center text-secondary mb-2">
              {/* <BiMain className="bi text-light" /> */}
              <span> {t("dashboard_menu.main_label")}</span>
            </h6>
            {user.role !== "approver" && (
              <span>
                {/* <li className="nav-item"> */}
                {user.role !== "user" && (
                  <>
                    <li
                      className="nav-item"
                      data-bs-dismiss="offcanvas"
                      data-bs-target="#sidebarMenu"
                    >
                      <NavLink
                        to="/"
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link d-flex align-items-center gap-2 isActive"
                            : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                        }
                      >
                        <RxDashboard className="bi" />
                        {t("dashboard_menu.dashboard_link")}
                      </NavLink>
                    </li>
                  </>
                )}
                {user.role !== "finance" && (
                  <>
                    <li className="nav-item my-2">
                      <a
                        // to="car-registration"
                        role="button"
                        data-bs-toggle="collapse"
                        href="#carMenu"
                        aria-expanded="true"
                        aria-controls="carMenu"
                        className={`d-flex flex-row justify-content-between text-decoration-none text-secondary  align-items-center`}
                        // onClick={(e) => setCarMenu(!carMenu)}
                        onClick={() => {
                          handleSectionClick("carMenu");
                          resetMenues();
                          setCarMenu(!carMenu);
                        }}
                      >
                        {/* <BiRegistered className="bi text-light" /> */}
                        <span className="d-flex flex-row align-items-center gap-2">
                          <BiRegistered className="bi" />
                          {t("dashboard_menu.car_registration_label")}
                        </span>
                        {carMenu ? (
                          // <BiCar className="bi" />
                          <BsChevronDoubleUp className="bi" />
                        ) : (
                          <BsChevronDoubleDown className="bi" />
                        )}
                      </a>
                    </li>

                    {/* <div className="collapse show" id="carMenu"> */}
                    <div
                      className={`collapse drop-animation  rounded py-2 px-1  ${
                        activeSection === "carMenu" ? "show" : ""
                      }`}
                      id="carMenu"
                    >
                      {user.staff !== "traffic staff" && (
                        <>
                          <li
                            className="nav-item"
                            data-bs-dismiss="offcanvas"
                            data-bs-target="#sidebarMenu"
                          >
                            <NavLink
                              onClick={closeOffcanvas}
                              to="car-registration"
                              className={({ isActive }) =>
                                isActive
                                  ? "nav-link d-flex align-items-center gap-2 isActive"
                                  : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                              }
                            >
                              <AiFillCar className="bi" />
                              {t("dashboard_menu.registration_form_link")}
                            </NavLink>
                          </li>
                        </>
                      )}
                      <li
                        className="nav-item"
                        data-bs-dismiss="offcanvas"
                        data-bs-target="#sidebarMenu"
                      >
                        <NavLink
                          // to="view-list"
                          to="driver-no-waiting"
                          className={({ isActive }) =>
                            isActive
                              ? "nav-link d-flex align-items-center gap-2 isActive"
                              : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                          }
                        >
                          <BiSolidUserDetail className="bi" />
                          {t("dashboard_menu.view_list_link")}
                        </NavLink>
                      </li>
                    </div>

                    <li className="nav-item my-2">
                      <a
                        // to="car-registration"
                        role="button"
                        data-bs-toggle="collapse"
                        href="#waitingMenu"
                        aria-expanded="true"
                        aria-controls="waitingMenu"
                        className="d-flex flex-row justify-content-between text-decoration-none text-secondary  align-items-center"
                        // onClick={(e) => setWaitingMenu(!waitingMenu)}
                        onClick={() => {
                          handleSectionClick("waitingMenu");
                          resetMenues();
                          setWaitingMenu(!waitingMenu);
                        }}
                      >
                        <span className="d-flex flex-row align-items-center gap-2">

                        <BsPaintBucket className="bi" />
                        {t("dashboard_menu.painting_section_label")}
                        </span>
                        {waitingMenu ? (
                          <BsChevronDoubleUp className="bi" />
                        ) : (
                          <BsChevronDoubleDown className="bi" />
                        )}
                      </a>
                    </li>
                    <div
                      className={`collapse drop-animation  rounded py-2 px-1 ${
                        activeSection === "waitingMenu" ? "show" : ""
                      }`}
                      id="waitingMenu"
                    >
                      {/* <li className="nav-item">
                <NavLink
                  to="waiting"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link d-flex align-items-center gap-2 isActive"
                      : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                  }
                >
                  <RxDashboard className="bi" />
                  {t("dashboard_menu.waiting_link")}
                </NavLink>
              </li> */}
                      <li
                        className="nav-item"
                        data-bs-dismiss="offcanvas"
                        data-bs-target="#sidebarMenu"
                      >
                        <NavLink
                          to="waiting-list"
                          className={({ isActive }) =>
                            isActive
                              ? "nav-link d-flex align-items-center gap-2 isActive"
                              : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                          }
                        >
                          <FaBusinessTime className="bi" />
                          {t("dashboard_menu.waiting_list_link")}
                        </NavLink>
                      </li>
                      {/* <li className="nav-item">
                <NavLink
                  to="complate-list"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link d-flex align-items-center gap-2 isActive"
                      : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                  }
                >
                  <RxDashboard className="bi" />
                  {t("dashboard_menu.complate-list")}
                </NavLink>
              </li> */}
                    </div>
                  </>
                )}
                {user.role !== "user" && user.role !== "finance" && (
                  <>
                    <li className="nav-item my-2">
                      <a
                        // to="car-registration"
                        role="button"
                        data-bs-toggle="collapse"
                        href="#trafficControl"
                        aria-expanded="true"
                        aria-controls="trafficControl"
                        className="d-flex flex-row justify-content-between text-decoration-none text-secondary  align-items-center"
                        // onClick={(e) => setTrafficMenu(!trafficMenu)}
                        onClick={() => {
                          handleSectionClick("trafficControl");
                          resetMenues();
                          setTrafficMenu(!trafficMenu);
                        }}
                      >
                        <span className="d-flex flex-row align-items-center gap-2">
                        <BsController className="bi" />
                        {t("dashboard_menu.traffic_control_label")}
                        </span>
                        {trafficMenu ? (
                          <BsChevronDoubleUp className="bi" />
                        ) : (
                          <BsChevronDoubleDown className="bi" />
                        )}
                      </a>
                    </li>
                    <div
                      className={`collapse drop-animation  rounded py-2 px-1 ${
                        activeSection === "trafficControl" ? "show" : ""
                      }`}
                      id="trafficControl"
                    >
                      {/* <li className="nav-item">
                <NavLink
                  to="vehicle-control"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link d-flex align-items-center gap-2 isActive"
                      : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                  }
                 
                >
                  <RxDashboard className="bi" />
                  {t("dashboard_menu.vehicle_control_link")}
                </NavLink>
              </li> */}

                      <li
                        className="nav-item"
                        data-bs-dismiss="offcanvas"
                        data-bs-target="#sidebarMenu"
                      >
                        <NavLink
                          // to="control-list"
                          to="control-list/waiting"
                          className={({ isActive }) =>
                            isActive
                              ? "nav-link d-flex align-items-center gap-2 isActive"
                              : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                          }
                          end
                        >
                          <MdChecklist className="bi" />
                          {/* {t("dashboard_menu.control_list_link")} */}
                          {t("traffic_control.controlsList")}
                        </NavLink>
                      </li>
                    </div>
                  </>
                )}

                {(user.role === "super" || user.role === "finance") && (
                  <>
                    <li className="nav-item my-2">
                      <a
                        // to="car-registration"
                        role="button"
                        data-bs-toggle="collapse"
                        href="#invoiceMenu"
                        aria-expanded="true"
                        aria-controls="invoiceMenu"
                        className="d-flex flex-row justify-content-between text-decoration-none text-secondary  align-items-center"
                        // onClick={(e) => setInvoiceMenu(!invoiceMenu)}
                        onClick={() => {
                          handleSectionClick("invoiceMenu");
                          resetMenues();
                          setInvoiceMenu(!invoiceMenu);
                        }}
                      >
                              <span className="d-flex flex-row align-items-center gap-2">
                        <BsCashCoin className="bi" />
                        {t("dashboard_menu.invoice_section_label")}
                        </span>
                        {invoiceMenu ? (
                          <BsChevronDoubleUp className="bi" />
                        ) : (
                          <BsChevronDoubleDown className="bi" />
                        )}
                      </a>
                    </li>
                    <div
                      className={`collapse drop-animation  rounded py-2 px-1 ${
                        activeSection === "invoiceMenu" ? "show" : ""
                      }`}
                      id="invoiceMenu"
                    >
                      {/* invoice edit option */}
                      {/* <li className="nav-item">
                <NavLink
                  to="invoice"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link d-flex align-items-center gap-2 isActive"
                      : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                  }
                >
                  <FaFileInvoiceDollar className="bi" />
                  {t("dashboard_menu.invoice_link")}
                </NavLink>
              </li> */}

                      <li
                        className="nav-item"
                        data-bs-dismiss="offcanvas"
                        data-bs-target="#sidebarMenu"
                      >
                        <NavLink
                          to="invoice-list"
                          className={({ isActive }) =>
                            isActive
                              ? "nav-link d-flex align-items-center gap-2 isActive"
                              : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                          }
                        >
                          <MdChecklist className="bi" />
                          {/* {t("dashboard_menu.invoice_list_link")} */}
                          {t("dash_board_card.pendingInvoice")}
                        </NavLink>
                      </li>

                      {/* approved_invoice-list/all */}
                      {/* <li className="nav-item">
                <NavLink
                  to="approved_invoice-list/all"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link d-flex align-items-center gap-2 isActive"
                      : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                  }
                >
                  <BsCalendarCheckFill className="bi" />
                  {t("dashboard_menu.approved_invoice")}
                </NavLink>
              </li> */}

                      {/*  */}
                      <li
                        className="nav-item"
                        data-bs-dismiss="offcanvas"
                        data-bs-target="#sidebarMenu"
                      >
                        <NavLink
                          to="approved_invoice-list/collecting-cash"
                          className={({ isActive }) =>
                            isActive
                              ? "nav-link d-flex align-items-center gap-2 isActive"
                              : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                          }
                        >
                          <BsCalendarCheckFill className="bi" />
                          {t("invoice.generatedInvoice")}
                        </NavLink>
                      </li>

                      <li
                        className="nav-item"
                        data-bs-dismiss="offcanvas"
                        data-bs-target="#sidebarMenu"
                      >
                        <NavLink
                          to="approved_invoice-list/collected"
                          className={({ isActive }) =>
                            isActive
                              ? "nav-link d-flex align-items-center gap-2 isActive"
                              : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                          }
                        >
                          <FaMoneyBill className="bi" />
                          {t("dash_board_card.receivedInvoice")}
                        </NavLink>
                      </li>
                    </div>
                  </>
                )}

                {user.role !== "user" &&
                  user.role !== "finance" &&(
                  // user.role === "traffic_admin" &&
                  // user.staff !== "traffic staff" && (
                  // user.role === "traffic_super" && (
                    <>
                      <li className="nav-item my-2">
                        <a
                          // to="car-registration"
                          role="button"
                          data-bs-toggle="collapse"
                          href="#reportMenu"
                          aria-expanded="true"
                          aria-controls="reportMenu"
                          className="d-flex flex-row justify-content-between text-decoration-none text-secondary  align-items-center"
                          // onClick={(e) => setreportMenu(!reportMenu)}
                          onClick={() => {
                            handleSectionClick("reportMenu");
                            resetMenues();
                            setReportMenu(!reportMenu);
                          }}
                        >
                           <span className="d-flex flex-row align-items-center gap-2">
                          <BiRepost className="bi" />
                          {t("dashboard_menu.report_section_label")}
                          </span>
                          {waitingMenu ? (
                            <BsChevronDoubleUp className="bi" />
                          ) : (
                            <BsChevronDoubleDown className="bi" />
                          )}
                        </a>
                      </li>

                      <div
                        className={`collapse drop-animation  rounded py-2 px-1 ${
                          activeSection === "reportMenu" ? "show" : ""
                        }`}
                        id="reportMenu"
                      >
                        {/* <li className="nav-item"> */}
                        <li
                          className="nav-item"
                          data-bs-dismiss="offcanvas"
                          data-bs-target="#sidebarMenu"
                        >
                          <NavLink
                            to="search"
                            className={({ isActive }) =>
                              isActive
                                ? "nav-link d-flex align-items-center gap-2 isActive"
                                : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                            }
                            end
                          >
                            {/* <BiRepost className="bi" /> */}
                            <BiListOl className="bi" />
                            {t("general_key_words.reports")}
                            {/* {t("dashboard_menu.branch_list")} */}
                          </NavLink>
                        </li>
                      </div>
                    </>
                  )}
              </span>
            )}
          </ul>

          {/* <hr className="my-2" /> */}
          {/* Setting Section */}
          {/* {(user.role !=="approver" && user.role !=="user") && ( */}
          {user.role === "super" && (
            <ul className="nav flex-column mb-auto p-0">
              <li className="nav-item my-2">
                <a
                  //
                  role="button"
                  data-bs-toggle="collapse"
                  href="#settingMenu"
                  aria-expanded="true"
                  aria-controls="settingMenu"
                  className="d-flex flex-row justify-content-between text-decoration-none text-secondary  align-items-center"
                  // onClick={(e) => setBranchMenu(!settingMenu)}
                  onClick={() => {
                    handleSectionClick("settingMenu");
                    resetMenues();
                    setSettingMenu(!settingMenu);
                  }}
                >
                  {/* <CiSettings className="bi" /> */}
                  <span className="d-flex flex-row align-items-center gap-2">
                  <BsGear className="bi" />
                  {t("dashboard_menu.settings_link")}
                  </span>
                  {settingMenu ? (
                    <BsChevronDoubleUp className="bi" />
                  ) : (
                    <BsChevronDoubleDown className="bi" />
                  )}
                </a>
              </li>

              <div
                className={`collapse drop-animation  rounded py-2 px-1 ${
                  activeSection === "settingMenu" ? "show" : ""
                }`}
                id="settingMenu"
              >
                {/* <li className="nav-item"> */}
                <li
                  className="nav-item"
                  data-bs-dismiss="offcanvas"
                  data-bs-target="#sidebarMenu"
                >
                  <NavLink
                    to="branch-list"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link d-flex align-items-center gap-2 isActive"
                        : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                    }
                    end
                  >
                    <BsFillSignIntersectionFill className="bi" />
                    {t("dashboard_menu.branch_link")}
                    {/* {t("dashboard_menu.branch_list")} */}
                  </NavLink>
                </li>

                {/* <li className="nav-item"> */}
                <li
                  className="nav-item"
                  data-bs-dismiss="offcanvas"
                  data-bs-target="#sidebarMenu"
                >
                  <NavLink
                    to="settings/users-list"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link d-flex align-items-center gap-2 isActive"
                        : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                    }
                  >
                    <BsPeople className="bi" />
                    {t("setting.userManagement")}
                  </NavLink>
                </li>

                {/* <li className="nav-item"> */}
                <li
                  className="nav-item"
                  data-bs-dismiss="offcanvas"
                  data-bs-target="#sidebarMenu"
                >
                  <NavLink
                    to="login-log-list"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link d-flex align-items-center gap-2 isActive"
                        : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                    }
                    end
                  >
                    {/* <BSUse className="bi" /> */}
                    <FaSignInAlt className="bi" />
                    {t("userLog.usersLog")}
                  </NavLink>
                </li>

                {/* <li className="nav-item">
                <NavLink
                  to="branch-list/data"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link d-flex align-items-center gap-2 isActive"
                      : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                  }
                >
                  <MdChecklist className="bi" />
                  {t("Branch Data")}
                  {/* {t("dashboard_menu.branch_list")} */}
                {/* </NavLink> */}
                {/* </li>  */}
              </div>
            </ul>
          )}



        {user.role !== "super" && user.role !== "approver" && (
            <ul className="nav flex-column mb-auto p-0">
              <li className="nav-item my-2">
                <a
                  //
                  role="button"
                  data-bs-toggle="collapse"
                  href="#userSettingMenu"
                  aria-expanded="true"
                  aria-controls="userSettingMenu"
                  className="d-flex flex-row justify-content-between text-decoration-none text-secondary  align-items-center"
                  // onClick={(e) => setBranchMenu(!settingMenu)}
                  onClick={() => {
                    handleSectionClick("userSettingMenu");
                    resetMenues();
                    setUserSettingMenu(!userSettingMenu);
                  }}
                >
                  {/* <CiSettings className="bi" /> */}
                  <span className="d-flex flex-row align-items-center gap-2">
                  <BsLock className="bi" />
                  {t("setting.privacyAndSecurity")}
                  </span>
                  {settingMenu ? (
                    <BsChevronDoubleUp className="bi" />
                  ) : (
                    <BsChevronDoubleDown className="bi" />
                  )}
                </a>
              </li>

              <div
                className={`collapse drop-animation  rounded py-2 px-1 ${
                  activeSection === "userSettingMenu" ? "show" : ""
                }`}
                id="userSettingMenu"
              >

                {/* <li className="nav-item"> */}
                <li
                  className="nav-item"
                  data-bs-dismiss="offcanvas"
                  data-bs-target="#sidebarMenu"
                >
                  <NavLink
                    to="settings/password-change"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link d-flex align-items-center gap-2 isActive"
                        : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                    }
                    end
                  >
                    {/* <BSUse className="bi" /> */}
                    <FaKey className="bi" />
                    {t("setting.changePassword")}
                  </NavLink>
                </li>

              </div>
            </ul>
          )}

<hr className="my-2" />
          {user.role === "approver" && (
            <ul className="nav flex-column mb-auto p-0">
              <li
                className="nav-item"
                data-bs-dismiss="offcanvas"
                data-bs-target="#sidebarMenu"
              >
                <NavLink
                  to="driver-no-waiting"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link d-flex align-items-center gap-2 isActive"
                      : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                  }
                >
                  <BiSolidUserDetail className="bi" />
                  {t("dashboard_menu.view_list_link")}
                </NavLink>
              </li>

              <li
                  className="nav-item"
                  data-bs-dismiss="offcanvas"
                  data-bs-target="#sidebarMenu"
                >
                  <NavLink
                    to="settings/password-change"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link d-flex align-items-center gap-2 isActive"
                        : "nav-link d-flex align-items-center gap-2 text-white hover-link"
                    }
                    end
                  >
                    {/* <BSUse className="bi" /> */}
                    <FaKey className="bi" />
                    {t("setting.changePassword")}
                  </NavLink>
                </li>

            </ul>
          )}

          <ul className="nav flex-column mb-2  p-0">
            {/* <li className="nav-item"> */}
            <li className="nav-item">
              <div className="d-flex align-items-center gap-2 text-white">
                {/* <GrLanguage className="bi text-white" /> */}
                <select
                  // style={{
                  //   padding: "10px",
                  //   border: "0px",
                  //   borderRadius: "5px",
                  // }}
                  className="form-select bg-dark text-white border-secondary form-select-sm"
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    if (selectedValue !== "") {
                      ChangeLanguage(selectedValue);
                    }
                  }}
                >
                  <option value="">
                    {t("general_key_words.change_language")}
                  </option>
                  <option value="fa">دری</option>
                  <option value="ar">پښتو</option>
                  <option value="en">English</option>
                </select>
              </div>
            </li>
          </ul>
          <ul className="nav flex-column mb-auto p-0">
            {/* <li className="nav-item"> */}
            <li
              className="nav-item"
              data-bs-dismiss="offcanvas"
              data-bs-target="#sidebarMenu"
            >
              <NavLink
                onClick={logout}
                className="d-flex align-items-center gap-2 btn btn-danger btn-sm justify-content-center"
              >
                <CiLogout className="bi" />
                {t("dashboard_menu.sign_out_link")}
              </NavLink>
            </li>
          </ul>

          {/* <ul className="nav flex-column mb-auto p-3">
            <li className="nav-item" 
              data-bs-dismiss="offcanvas"
              data-bs-target="#sidebarMenu">
              <div className="d-flex align-items-center gap-2 text-white">
                <a
                  href="https://afgsoft.net/haidari/"
                  href="http://localhost/haidari/"
                  style={{
                    textDecorationLine: "none",
                    color: "yellow",
                    fontWeight: "bold",
                  }}
                  target="_blank"
                >
                  {t("general_key_words.systemDeveloper")}
                </a>
              </div>
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  );
};

export default SideNavigation;
