import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: null,
  data: null,
  query: null,
  type: null,
};

const SearchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    SearchStart: (state) => {
      state.loading = true;
      state.data = null;
    },
    SearchSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    SearchError: (state) => {
      state.loading = false;
      state.data = null;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    ResetQuery: (state) => {
      state.query = null;
    },
    setTableType: (state, action) => {
      state.type = action.payload;
      state.data = null;
    },
    removeTableType: (state) => {
      state.type = null;
    },
  },
});

export const {
  SearchStart,
  SearchSuccess,
  SearchError,
  setQuery,
  ResetQuery,
  removeTableType,
  setTableType,
} = SearchSlice.actions;
export default SearchSlice.reducer;
