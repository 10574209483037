import React from "react";
import {
  BsChevronDoubleDown,
  BsFillTrash3Fill,
  BsKey,
  BsLock,
  BsPrinter,
  BsViewList,
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";
import InlineLoadingAnimation from "../../../shared/InlineLoadingAnimation";
import Swal from "sweetalert2";
import { FcAddDatabase } from "react-icons/fc";
import { t } from "i18next";
import { AddToWaiting } from "../../../redux/vehicleRegistration/AddToWaiting";
import { StartUpdate } from "../../../redux/authentication/AuthenticationSlice";
import { UpdateUserStatus } from "../../../redux/authentication/UpdateAccountCompletely";

const UsersOptionButton = ({ record, section, setForm }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inlineLoading = useSelector((state) => state.delete.loading);
  const selectedDelete = useSelector((state) => state.delete.item);
  const token = useSelector((state) => state.authentication.token);

  const NavigateToBlock = () => {
    Swal.fire({
      title: t("general_key_words.areYouSure"),
      text: t("general_key_words.confirmChangeMsg"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("general_key_words.yesWaiting"),
      cancelButtonText: t("general_key_words.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(AddToWaiting(token, record.id, "table"));
      }
    });
  };

 

//   const handleEdit = () => {
//     if (section === "driver") {
//       navigate(`/view-list/edit/${record.id}`);
//     } else if (section === "control") {
//       navigate(`/view-list/edit/${record.id}?driverID=${record.driver.id}`);
//     }
//   };

  const handleRecordUpdate = (record) => {
    // console.log("Update called")
    // console.log(record)
    dispatch(StartUpdate(record))
    setForm(true)
  };

  const handleBlock = (record)=>{
    var status = record.status;
    var confirmMessage = null;
    if(status==="active"){
        status="block"; 
        confirmMessage = t("setting.blockUser");
    }else{
        status="active";
        confirmMessage = t("setting.activeUser");
    }
    
    Swal.fire({
        title: t("general_key_words.areYouSure"),
        text: t("general_key_words.confirmChangeMsg"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        
        confirmButtonText: confirmMessage,
        cancelButtonText: t("general_key_words.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
            var data = {
                id:record.id,
                status:status
            }
            // console.log("Status:",status)
            dispatch(UpdateUserStatus(data, token));
        }
      });

    
  }

  return selectedDelete === record.id && inlineLoading ? (
    <div className="">
      <InlineLoadingAnimation />
    </div>
  ) : (
    <div className="btn-group">
      <button
        className="btn btn-secondary d-flex align-items-center gap-2 rounded"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <BsChevronDoubleDown />
        {t("general_key_words.choose")}
      </button>
      <ul className="dropdown-menu" style={{ zIndex: 2000 }}>
        <li>
            <a
            className="dropdown-item d-flex align-items-center gap-2 text-muted"
            type="button"
            // onClick={handleRecordUpdate}
            onClick={(e)=>handleRecordUpdate(record)}
            >
            <AiFillEdit className="bi" />
            {t("general_key_words.edit")}
            </a>
        </li> 

        <li>
          <a
            className="dropdown-item d-flex align-items-center gap-2 text-muted"
            // onClick={handleBlock}
            onClick={(e)=>handleBlock(record)}
            type="button"
          >
            {record.status==="active" ? 
            <>
                <BsLock className="bi" />
                { t("setting.blockStatus")}
            </>
                
            : 
            <>
                <BsKey className="bi" />
                { t("setting.activeStatus")}
            </>
            
            }
            
          </a>
        </li>

      </ul>
    </div>
  );
};

export default UsersOptionButton;
