import React from "react";
import {
  BsBookmarkCheckFill,
  BsChevronDoubleDown,
  BsFillTrash3Fill,
  BsViewList,
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SelectRecord } from "../../redux/branchSection/slice/branchSlice";
import { AiFillEdit } from "react-icons/ai";
import InlineLoadingAnimation from "../../shared/InlineLoadingAnimation";
import { DeleteDriverRecord } from "../../redux/vehicleRegistration/DeleteDriverRecord"; 
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import { DeleteControlRecord } from "../../redux/ControlSection/DeleteControlRecord"; 
import { AddToControl } from "../../redux/vehicleRegistration/AddToControl";
import { FcAddDatabase } from "react-icons/fc";
import { t } from "i18next";
import { DeleteBranchRecord } from "../../redux/branchSection/actions/DeleteBranchRecord";
const BranchOptionButton = ({ record, section }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inlineLoading = useSelector((state) => state.delete.loading);
  const selectedDelete = useSelector((state) => state.delete.item);
  const token = useSelector((state) => state.authentication.token);

//   const NavigateControlSection = () => {
//     // navigate(`/vehicle-control?driverID=${record.id}`);
//     dispatch(AddToControl(token, record.id, "table"));
//   };

  const NavigateToPrintPage = () => {
    dispatch(SelectRecord(record));
    // window.open('/view-list/view/print', '_blank');
    navigate("view/print");
  };

  const handleView = () => {
    if (section === "driver") {
      //      navigate(`view/${record.id}`);
      window.open(`/view-list/view/${record.id}`, "_blank");
    } else if (section === "control") {
      window.open(`/control-list/view/${record.id}`, "_blank");
    }
  };

  const handleEdit = () => {
    window.open(`branch-list/edit/${record.id}`, "_blank");
      // navigate(`edit/${record.id}`);
  };
  const handleDelete = () => {
      Swal.fire({
        title: t("general_key_words.areYouSure"),
        text: t("general_key_words.deleteConfirmMsg"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("general_key_words.deleteConfirmButtonText"),
        cancelButtonText: t("general_key_words.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
            
            dispatch(DeleteBranchRecord(token, record.id, "table"));
          
        }
      }
    );
  };
  return selectedDelete === record.id && inlineLoading ? (
    <div className="">
      <InlineLoadingAnimation />
    </div>
  ) : (
    <div className="btn-group">
      <button
        className="btn btn-light d-flex align-items-center gap-2 rounded"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <BsChevronDoubleDown />
        {t("general_key_words.choose")}
        
      </button>
      <ul className="dropdown-menu" style={{ zIndex: 2000 }}>

        <li>
          <a
            className="dropdown-item d-flex align-items-center gap-2 text-muted"
            type="button"
            onClick={handleEdit}
          >
            <AiFillEdit className="bi" />
            {t("general_key_words.edit")}
          </a>
        </li>
        {/* <li>
          <a
            className="dropdown-item d-flex align-items-center gap-2 text-muted"
            type="button"
            onClick={handleView}
          >
            <BsViewList className="bi" />
            {t("general_key_words.view")}
          </a>
        </li> */}
        {/* <li>
          <a
            className="dropdown-item d-flex align-items-center gap-2 text-danger"
            type="button"
            onClick={handleDelete}
          >
            <BsFillTrash3Fill className="bi" />
            {t("general_key_words.delete")}
          </a>
        </li> */}
      </ul>
    </div>
  );
};

export default BranchOptionButton;
