import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";

import {
  HandleProgress,
  UploadError,
  AccountUpdateStart,
  AccountUpdateSuccess,
} from "./AuthenticationSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const UpdateAccountCompletely =
  (data, token, resetForm) => async (dispatch, getState) => {
    dispatch(AccountUpdateStart());
    // http request to backend
    try {
      const response = await http.post(
        `api/auth/completely/update/${data.get("id")}`,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
          onUploadProgress: (progressEvent) => {
            const progress = Math.floor(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            dispatch(HandleProgress(progress));
            // console.log(progress);
          },
        }
      );
      const users = getState().authentication.data;
      const updatedRecord = response.data.record;

      const updatedUsers = users.map((user) =>
        user.id === updatedRecord.id ? updatedRecord : user
      );
      toast.success(t("toast.data_upload"));
      dispatch(AccountUpdateSuccess(updatedUsers));
      resetForm();
    } catch (error) {
      if (error.response.status === 422) {
        dispatch(UploadError(error.response.data.errors));
      }
      if (error.response) {
        HandleHTTPRequestError(error.response.status, error, dispatch);
      } else {
        // Handle the case where the API is offline or unreachable
        console.error("API is offline or unreachable.");
          toast.error(t("toast.apiOffline"));
      }
    }
  };

  //Update Status(Block users):
  export const UpdateUserStatus =
  (data, token) => async (dispatch, getState) => {
    // console.log(data.id)
    dispatch(AccountUpdateStart());
    // http request to backend
    try {
      const response = await http.post(
        `api/auth/users/status/${data.id}`,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
          onUploadProgress: (progressEvent) => {
            const progress = Math.floor(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            dispatch(HandleProgress(progress));
            // console.log(progress);
          },
        }
        
      );
      const users = getState().authentication.data;
      const updatedRecord = response.data.record;

      const updatedUsers = users.map((user) =>
        user.id === updatedRecord.id ? updatedRecord : user
      );
      toast.success(t("toast.data_upload"));
      dispatch(AccountUpdateSuccess(updatedUsers));
      // console.log("Response:");
      // console.log(response);
      } catch (error) {
      //   console.log("Error:");
      // console.log(error);

        // if (error.response.status === 422) {
        //   dispatch(UploadError(error.response.data.errors));
        // }
        if (error.response) {
          HandleHTTPRequestError(error.response.status, error, dispatch);
        } else {
          // Handle the case where the API is offline or unreachable
          console.error("API is offline or unreachable.");
            toast.error(t("toast.apiOffline"));
        }
      }
  };

