import React from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import FileField from "../../Utility/FileField";
import { t } from "i18next";
import { PayInvoice } from "../../redux/InvoicesSection/PayInvoice";
import { BsCloudUploadFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import APIFormErrors from "../../Utility/APIFormErrors";
import { CancelInvoice } from "../../redux/InvoicesSection/InvoiceSlice";
import { acceptedFileTypes } from "../../Data/statuses";
const InvoiceCollectionModal = ({ invoice }) => {
  const inlineLoading = useSelector((state) => state.invoice.inlineLoading);
  const token = useSelector((state) => state.authentication.token);
  const dispatch = useDispatch();

  // form modal data and validation

  const initialValues = {
    invoice_file: "",
    invoice_number: "",
  };
  const validationSchema = Yup.object().shape({
    invoice_number: Yup.string().required(
      t("invoice_modal.invoice_number_required")
    ),
    invoice_file: Yup.mixed()
      .nullable()
      // .required(t("invoice_modal.file_required_message"))
      .test("fileSize", t("invoice_modal.file_size_error_message"), (value) => {
        if (value) {
          return value.size <= 1 * 1024 * 1024;
        }
        return true;
      })
      // .test("fileFormat",t("invoice_modal.file_format_error_message"),(value) => {
        .test("fileType",t("validation_errors.imageType"),(value) => {
          if (value) {
            return acceptedFileTypes.includes(value.type);
            // return value.type === "application/pdf";
          }
          return true;
        }
      ),
  });

  const handleResetPay = () => {
    dispatch(CancelInvoice());
  };
  return (
    <>
      <div className="alert alert-success rounded-3 p-3 mb-2">
      {t("invoice_modal.invoiceModelMessage",{
        number:invoice.generate_invoice_id
      })}
        {/* Invoice {invoice.generate_invoice_id} has been selected for collection. */}
      </div>
      <div className="bg-white rounded-3 p-3 mb-2">
        <Formik
          // innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            const formData = new FormData();
            formData.append("tarifa_file", values.invoice_file);
            formData.append("tarifa_number", values.invoice_number);

            dispatch(PayInvoice(token, invoice.generate_invoice_id, formData));
            
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, handleReset, values }) => (
            <Form>
              <div className="row">
                <label
                  htmlFor="invoice_number"
                  className="col-sm-12 col-form-label "
                >
                  {t("invoice_modal.invoice_number")}
                  <span className="text-danger"> *</span>
                </label>
                <div className="col-sm-12 mb-2">
                  <Field
                    type="text"
                    name="invoice_number"
                    id="invoice_number"
                    placeholder={t("place_holder.typeTarifaNumber")}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="invoice_number"
                    component="div"
                    className="alert alert-danger p-2 my-1"
                  />
                </div>
                <div className="col-sm-12 mb-2">
                  <label
                    htmlFor="invoice_file"
                    className="form-label border shadow-sm text-muted rounded px-5 py-2 w-100 text-center"
                  >
                    {t("invoice_modal.invoice_attachment")}
                    <span className="text-danger"> *</span>
                    <span
                      className={
                        values.invoice_file
                          ? "ms-3 fs-5 text-success"
                          : "ms-3 fs-5 text-danger"
                      }
                    >
                      <BsCloudUploadFill />
                    </span>
                  </label>
                  {values.invoice_file && (
                    <div className="text-muted">
                      <span className="text-primary">
                        {t("invoice_modal.invoice_modal_file_selected")}
                      </span>
                      : {values.invoice_file.name}
                    </div>
                  )}
                  <FileField name={"invoice_file"} 
                    id={"invoice_file"}
                    accept={"image/*"}
                    // accept={".pdf"}
                    />
                  <ErrorMessage
                    name="invoice_file"
                    className="text-danger"
                    component="div"
                  />
                </div>
              </div>
              <hr />
              <div className="col-sm-12">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleResetPay}
                >
                  {t("invoice_modal.cancel_button")}
                </button>
                <button
                  type="submit"
                  // onClick={handlePay}
                  disabled={inlineLoading}
                  className="btn btn-primary mx-2"
                >
                  {inlineLoading
                    ? t("general_key_words.processingPleaseWait")
                    : t("invoice_modal.invoice_collect_button")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default InvoiceCollectionModal;
