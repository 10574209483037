import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import PageHeader from "../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import LoadingAnimation from "../../shared/LoadingAnimation";
import NoContentsFound from "../../shared/NoContentsFound";

import { FetchAllApprovedInvoices } from "../../redux/InvoicesSection/FetchAllApprovedInvoices";
import AllApprovedInvoiceTable from "./AllApprovedInvoiceTable";

export default function AllApprovedInvoices() {
  // sendSMS();
  const { t } = useTranslation("translation");
  const data = useSelector((state) => state.invoice.data);
  const CarsData = useSelector((state) => state.invoice);
  const loading = useSelector((state) => state.invoice.loading);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);

  useEffect(() => {
    dispatch(FetchAllApprovedInvoices(token));
  }, []);
  // console.log("All Approved Data:");
  // console.log(CarsData);
  return (
    <div>
      <div className="mt-2">
        {/* <FormHeader province={"Kabul"} /> */}
        <PageHeader title={t("invoice.allApprovedInvoice")} />
      </div>
      {loading ? (
        <LoadingAnimation />
      ) : data && data.length>0? (
        // <DriverTabularView data={data} />
        // <CarTable carsData={CarsData} />
        <AllApprovedInvoiceTable carsData={CarsData} />
      ) : (
        // <DriverTabularView data={data} options={true}/>
        <NoContentsFound message={t("general_key_words.dataNotFound")} />
      )}
    </div>
  );
}
