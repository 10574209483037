import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";

import { DeleteError, DeleteSuccess, DeleteStart } from "../Util/DeleteSlice";

import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";
import { UpdateRecords } from "./ControlSlice";
import { FetchControlData } from "./FetchControlData";

export const AddToWaitingInvoice =
  (token, driverId, controlId,section) => async (dispatch, getState) => {
    // console.log("id:",driverId,"contrlId:",controlId)
    dispatch(DeleteStart(controlId));
    // http request to backend
    try {

        const data = {
            control_id: controlId,
            driver_id: driverId,
            section: section
        }
      // const response = await http.post(`api/contro/pending_invoice/add/${id}`,data, {
      const response = await http.post(`api/contro/pending_invoice/add/${controlId}`,data, {
        headers: { Authorization: `Bearer ${token}` },
      });


      const currentState = getState();
      const updatedData = currentState.control.data.filter((record) => record.id != controlId) 
      // if (section === "table") {
      //   const currentState = getState();

      //   const updatedData = currentState.control.data.map((record) => {
      //     if (record.id === controlId) {
      //       // Modify the record if it matches the updated one
      //       return response.data.record; // Assuming you have an updated record available
      //     }
      //     return record;
      //   });
        dispatch(UpdateRecords(updatedData));
      // }

      // console.log(response);
      toast.success(t("toast.vehicle_add_to_control"));
      dispatch(DeleteSuccess());
      // dispatch(FetchControlData());
    } catch (error) {
      dispatch(DeleteError());
      if (error.response) {
        HandleHTTPRequestError(error.response.status, error, dispatch);
      } else {
        // Handle the case where the API is offline or unreachable
        console.error("API is offline or unreachable.");
          toast.error(t("toast.apiOffline"));
      }
    }
  };
