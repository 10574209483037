import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: null,
};

const DownloadSlice = createSlice({
  name: "download",
  initialState,
  reducers: {
    DownloadStart: (state) => {
      state.loading = true;
    },
    DownloadSuccess: (state) => {
      state.loading = false;
    },
    DownloadError: (state) => {
      state.loading = false;
    },
  },
});

export const { DownloadStart, DownloadSuccess, DownloadError } =
  DownloadSlice.actions;
export default DownloadSlice.reducer;
