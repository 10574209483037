import React from "react";
import {
  BsChevronDoubleDown,
  BsViewList,
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import InlineLoadingAnimation from "../../shared/InlineLoadingAnimation";
import { FcAddDatabase } from "react-icons/fc";
import { t } from "i18next";
import { AddToWaiting } from "../../redux/vehicleRegistration/AddToWaiting";
import { SelectRecord } from "../../redux/vehicleRegistration/VehicleSlice";
const DriverCompleteOptionButton = ({ record, section }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inlineLoading = useSelector((state) => state.delete.loading);
  const selectedDelete = useSelector((state) => state.delete.item);
  const token = useSelector((state) => state.authentication.token);

//   const NavigateControlSection = () => {
//     dispatch(AddToWaiting(token, record.id, "table"));
//   };

  const NavigateToPrintPage = () => {
    dispatch(SelectRecord(record));
    // window.open('/view-list/view/print', '_blank');
    navigate("view/print");
  };

  const handleView = () => {
      window.open(`/view-list/view/${record.driver_id}`, "_blank");
    
  };

  const handleInvoiceView = () => {
    window.open(`/approved_invoice-list/printed/${record.generate_invoice_id}`, "_blank");
  };



  return selectedDelete === record.id && inlineLoading ? (
    <div className="">
      <InlineLoadingAnimation />
    </div>
  ) : (
    <div className="btn-group">
      <button
        className="btn btn-secondary d-flex align-items-center gap-2 rounded"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <BsChevronDoubleDown />
        {t("general_key_words.choose")}
      </button>
      <ul className="dropdown-menu" style={{ zIndex: 2000 }}>
      <li>
          <a
            className="dropdown-item d-flex align-items-center gap-2 text-muted"
            type="button"
            onClick={handleView}
          >

          
            <BsViewList className="bi" />
            {t("general_key_words.viewVehicle")}
          </a>
        </li>

        <li>
          <a
            className="dropdown-item d-flex align-items-center gap-2 text-muted"
            type="button"
            onClick={handleInvoiceView}
          >
            <BsViewList className="bi" />
            {t("general_key_words.viewInvoice")}
          </a>
        </li>
      
        {/* <li>
          <a
            className="dropdown-item d-flex align-items-center gap-2 text-muted"
            onClick={NavigateToPrintPage}
            type="button"
          >
            <BsPrinter className="bi" />
            {t("general_key_words.print")}
          </a>
        </li> */}

      </ul>
    </div>
  );
};

export default DriverCompleteOptionButton;
