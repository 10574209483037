import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import { MoveReturn, MoveError, MoveStart } from "./InvoiceSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const InvoicePrintCollection = (token,data) => async (dispatch,getState) => {
  dispatch(MoveStart());
  // http request to backend
  try {
  //   console.log("in-------------------------------------------------sde");
  // console.log(data);
    const response = await http.post("api/invoice/print", data,{
      headers: { Authorization: `Bearer ${token}` },
    });
   

  //  console.log(response)
    toast.success("invoice has been created successfully");
    dispatch(MoveReturn(response.data.records));
  } catch (error) {
    // console.log(error)
    dispatch(MoveError());
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};
