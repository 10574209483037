import React from 'react'
import { Outlet } from 'react-router-dom'
import InvoiceNav from './InvoiceNav'

const InvoiceLayout = () => {
  return (
    <div>
        <div className='bg-white mt-2 rounded-3'><InvoiceNav/></div>
        <div><Outlet/></div>
    </div>
  )
}

export default InvoiceLayout