import React, { useEffect } from "react";
import LandingPage from "./components/LandingPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { Provider } from "react-redux";
import Store from "./components/redux/Store";
import i18next from "i18next";
import { Toaster } from "react-hot-toast";

// import './App.css';

function App() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", i18next.dir());
  }, [i18next.language]);
  return (
    <Provider store={Store}>
      <LandingPage />
      <Toaster/>
    </Provider>
  );
}

export default App;
