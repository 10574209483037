
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import PageHeader from "../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import LoadingAnimation from "../../shared/LoadingAnimation";
import NoContentsFound from "../../shared/NoContentsFound";

import { FetchWaitingData } from "../../redux/WaitingSection/FetchWaitingData";
import ApprovedTable from "./ApprovedTable";
import { FetchInvoiceApprovedList } from "../../redux/InvoicesSection/FetchInvoiceApprovedList";
import { FetchCompletedPrintedInvoices } from "../../redux/InvoicesSection/FetchCompletedPrintedInvoices";
import InvoicePrintedTable from "./InvoicePrintedTable";

export default function PrintedList() {
  // sendSMS();
  const { t } = useTranslation("translation");
  const data = useSelector((state) => state.invoice.data);
  const CarsData = useSelector((state) => state.invoice);
  const loading = useSelector((state) => state.invoice.loading);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);

  useEffect(() => {
    dispatch(FetchCompletedPrintedInvoices(token));
  }, []);
  // console.log("Vhicle:");
  // console.log(data);
  return (
    <div>
      <div className="mt-2">
        {/* <FormHeader province={"Kabul"} /> */}
        <PageHeader title={t("invoice.printedInvoice")} />
      </div>
      {loading ? (
        <LoadingAnimation />
      ) : data && data.length>0? (
        // <DriverTabularView data={data} />
        // <CarTable carsData={CarsData} />
        <InvoicePrintedTable carsData={CarsData} />
      ) : (
        // <DriverTabularView data={data} options={true}/>
        <NoContentsFound message={"No data was found on server"} />
      )}
    </div>
  );
}
