import toast from "react-hot-toast"
import { http } from "./APIRequest"
import { t } from "i18next";

export function CSRFTokenRefresh()
{
    http
    .get(`sanctum/csrf-cookie`)
    .then((res) => {
       toast.success(t("toast.token_referesh"))
       return;
    })
    .catch((error)=>{
        toast.error(error.message)
    })
}