import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import { FetchError, FetchStart, FetchSuccess } from "../dashboard/DashboardSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";
import { showToast } from "../Util/ToastFunction";

export const FetchDashboardData = (token) => async (dispatch) => {
  dispatch(FetchStart());
  // http request to backend
  try {
    const response = await http.get("api/dashboard/all", {
      headers: { Authorization: `Bearer ${token}` },
    });

    // toast.success(t("toast.dashboard_statistics"));
    showToast("success",t("toast.dashboard_statistics"));
    const data = {
        drivers:response.data.drivers,
        controls:response.data.controls,
        controlsPending:response.data.controlsPending,
        waiting:response.data.waiting,
        complatedVehicle:response.data.complatedVehicle,
        objectionsVehicle:response.data.objectionsVehicle,
        pendingInvoice:response.data.pendingInvoice,
        pendingInvoiceSum:response.data.pendingInvoiceSum,
        receivedInvoice:response.data.receivedInvoice,
        receivedInvoiceSum:response.data.receivedInvoiceSum,
        branches:response.data.branches,
        generatedInvoiceSum:response.data.generatedInvoiceSum,
        users:response.data.users,
        notApprovedBankSlipCount:response.data.notApprovedBankSlipCount,
        approvedBankSlipCount:response.data.approvedBankSlipCount,
    }
    dispatch(FetchSuccess(data));
  } catch (error) {
    dispatch(FetchError());
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};
