import React, { useEffect } from "react";
import { useState } from "react";
import { BiCheckDouble, BiSearch } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setQuery, setTableType } from "../redux/Search/SearchSlice";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { SearchTypes } from "../Data/SearchTypes";
import { BsList, BsSearch } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import jalaliMoment from "jalali-moment";
import { FaCross } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import { MdCancel } from "react-icons/md";
import InlineLoadingAnimation from "./InlineLoadingAnimation";
import { SearchQuery } from "../redux/Search/SearchQueryAll";
import LoadingAnimation from "./LoadingAnimation";
import DownloadLoading from "./DownloadLoading";
import DateObject from "react-date-object";

import { FetchBranchData } from "../redux/branchSection/actions/FetchBranchData";

const SearchPanel = () => {
  const { t } = useTranslation("translation");
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const [recordType, setRecordType] = useState("");

  const [branch, setBranch] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  //  persian dates
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());

  //  gregorian date
  const [fromGre, setFromGre] = useState(new Date());
  const [toGre, setToGre] = useState(new Date());

  const loading = useSelector((state) => state.search.loading);
  const token = useSelector((state) => state.authentication.token);

  const data = useSelector((state) => state.branch.data);

  const user = useSelector((state) => state.authentication.user);

  useEffect(() => {
    dispatch(FetchBranchData(token));
    // console.log(data);
  }, []);

  const SearchTypes = [
    // registration links
    {
      id: "registration_all",
      value: `${t("general_key_words.registration")} - ${t(
        "vehicle_details_form.allRegisteredVehicle"
      )}`,
      section: "registration",
      recordType:t("vehicle_details_form.allVehicleList"),
      from:from,
      to:to,
    },

    {
      id: "registration_pending",
      value: `${t("general_key_words.registration")} - ${t(
        "general_key_words.pending"
      )}`,
      section: "registration",
      recordType:t("vehicle_details_form.processingVehicleList"),
      from:from,
      to:to,
    },

    {
      id: "registration_rejected",
      value: `${t("general_key_words.registration")} - ${t(
        "general_key_words.rejected"
      )}`,
      section: "registration",
      recordType:t("vehicle_details_form.rejectedVehicleList"),
      from:from,
      to:to,
    },

    // {
    //   id: "registration_waiting_color",
    //   value: `${t("general_key_words.registration")} - ${t(
    //     "vehicle_details_form.inWaiting"
    //   )}`,
    //   section: "registration",
    // },

    {
      id: "registration_completed",
      value: `${t("general_key_words.registration")} - ${t(
        "general_key_words.completedList"
      )}`,
      section: "registration",
      recordType:t("vehicle_details_form.complatedVehicleList"),
      from:from,
      to:to,
    },
    //   waiting links
    {
      id: "waiting_all",
      value: `${t("general_key_words.wait")} - ${t(
        "dashboard_menu.waiting_list_link"
      )}`,
      section: "waiting",
      recordType:t("vehicle_details_form.colorWaitinVehicleList"),
      from:from,
      to:to,
    },
    //   controls links
    {
      id: "control_all",
      value: `${t("general_key_words.control")} - ${t(
        "traffic_control.allControlledVehicle"
      )}`,
      section: "control",
      recordType:t("vehicle_details_form.allControlVehicleList"),
      from:from,
      to:to,
    },
    {
      id: "control_approved",
      value: `${t("general_key_words.control")} - ${t(
        "dash_board_card.approvedControls"
      )}`,
      section: "control",
      recordType:t("vehicle_details_form.approvedControlVehicleList"),
      from:from,
      to:to,
    },
    {
      id: "control_pending",
      value: `${t("general_key_words.control")} - ${t(
        "general_key_words.pending"
      )}`,
      section: "control",
      recordType:t("vehicle_details_form.processingControlVehicleList"),
      from:from,
      to:to,
    },
    {
      id: "control_rejected",
      value: `${t("general_key_words.control")} - ${t(
        "general_key_words.rejected"
      )}`,
      section: "control",
      recordType:t("vehicle_details_form.rejectedControlVehicleList"),
      from:from,
      to:to,
    },
    //   invoices links
    {
      id: "invoices_pending",
      value: `${t("general_key_words.invoice")} - ${t(
        "dash_board_card.pendingInvoice"
      )}`,
      section: "invoice",
      recordType:t("vehicle_details_form.pendingInvoiceList"),
      from:from,
      to:to,
    },
    {
      id: "invoices_generated",
      value: `${t("general_key_words.invoice")} - ${t(
        "invoice.generatedInvoice"
      )}`,
      section: "invoice",
      // recordType:t("vehicle_details_form.generatedGroupInvoiceList"),
      recordType:t("vehicle_details_form.generateInvoiceList"),
      from:from,
      to:to,
    },
    {
      id: "invoices_received",
      value: `${t("general_key_words.invoice")} - ${t(
        "dash_board_card.receivedInvoice"
      )}`,
      section: "invoice",
      // recordType:t("vehicle_details_form.collectedGroupInvoiceList"),
      recordType:t("vehicle_details_form.collectedInvoiceList"),
      from:from,
      to:to,
    },
  ];

  const handleSearch = (e) => {
    const searchObject = {
      type: type,
      fromGre: fromGre,
      toGre: toGre,
      from: from,
      to: to,
      branch: branch,
      recordType:recordType,
    };
    // console.log(searchObject)
    if (type) {
      dispatch(SearchQuery(searchObject, token));
    }
  };

  const handleFromDate = (e) => {
    console.log("Date called")
    // get gregorian date
    const dateObject = new DateObject(e.toDate());
    const gregorianDate = dateObject.format("YYYY-MM-DD");

    // get persian date
    const jalaliDate = jalaliMoment(e.toDate());
    const persianDateString = jalaliDate.locale("fa").format("YYYY-MM-DD");
    setFrom(persianDateString);
    setFromGre(gregorianDate);
    // console.log(persianDateString  + " - " + gregorianDate);
  };
  const handleToDate = (e) => {
    // get gregorian date
    const dateObject = new DateObject(e.toDate());
    const gregorianDate = dateObject.format("YYYY-MM-DD");

    // get persian date
    const jalaliDate = jalaliMoment(e.toDate());
    const persianDateString = jalaliDate.locale("fa").format("YYYY-MM-DD");
    setTo(persianDateString);

    setToGre(gregorianDate);
    // console.log(persianDateString  + " - " + gregorianDate);
  };

  const handleTableType = (section) => {
    // console.log("Section:")
    // console.log(section)
    // show table based on type
    dispatch(setTableType(section));
    // console.log(section.)
  };

  const typeChangeHandler = (e) => {
    if (e.target.value) {
      const value = JSON.parse(e.target.value);
      setType(value.id);
      setRecordType(value.recordType);
      handleTableType(value);
      checkIsSearchButtonDisabled(value, branch); // Pass the updated value
    }
  };

  const branchChangeHandler = (e) => {
    const value = e.target.value;
    // console.log("value:",value)
    setBranch(value);
    checkIsSearchButtonDisabled(type, value); // Pass the updated value
  };

  const checkIsSearchButtonDisabled = (newType, newBranch) => {
    setIsDisabled((prevState) =>
      newType === "" || newBranch === "" ? true : false
    );
  };
  return (
    <div className="row border py-2 rounded-3 mt-2 mx-0 bg-white">
      <div className="col-sm-12 col-md-6 col-lg-2 mb-2 mb-lg-0">
        <div className="d-flex">
          <span className="input-group-text bg-dark text-white border-0 shadow-sm rounded-0">
            {t("general_key_words.from")}
          </span>
          <DatePicker
            name="from"
            id="from"
            calendar={persian}
            locale={persian_fa}
            inputClass="form-control rounded-0"
            containerStyle={{ width: "100%" }}
            value={from}
            onChange={handleFromDate}
          />
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-2 mb-2 mb-lg-0">
        <div className="d-flex">
          <span className="input-group-text bg-dark text-white border-0 shadow-sm rounded-0">
            {t("general_key_words.to")}
          </span>
          <DatePicker
            name="to"
            id="to"
            calendar={persian}
            locale={persian_fa}
            inputClass="form-control rounded-0"
            containerStyle={{ width: "100%" }}
            value={to}
            onChange={handleToDate}
          />
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-3 mb-2 mb-lg-0">
        <div className="input-group ">
          <span className="input-group-text bg-dark text-white border-0 shadow-sm rounded-0">
            {type ? (
              <span>
                <BiCheckDouble />
              </span>
            ) : (
              <span>
                <BsList />
              </span>
            )}
          </span>
          <select
            className="form-select rounded-0"
            onChange={typeChangeHandler}
            aria-label="Default select example"
          >
            <option selected value="">
              {t("general_key_words.choose")}
            </option>
            {SearchTypes.map((item) => (
              <option value={JSON.stringify(item)}>{item.value}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-3 mb-2 mb-lg-0">
        <div className="input-group ">
          <span className="input-group-text bg-dark text-white border-0 shadow-sm rounded-0">
            {t("branch.branchName")}
          </span>
          {user.role === "super" || user.role === "traffic_super" ? (
            <select
              type="select"
              id="branch"
              name="branch"
              onChange={branchChangeHandler}
              className="form-select rounded-0"
            >
              <option key="optionOne" selected value="">
                {t("general_key_words.choose")}
              </option>
              <option key="optionTwo" value="all">
                {t("general_key_words.all")}
              </option>
              {data &&
                data.map((item, index) => (
                  <option value={item.branchName} key={index}>
                    {item.branchName}
                  </option>
                ))}
            </select>
          ) : (
            <select
              type="select"
              id="branch"
              name="branch"
              onChange={branchChangeHandler}
              className="form-select rounded-0"
            >
              <option key="optionOne" selected value="">
                {t("general_key_words.choose")}
              </option>
              {data &&
                data.map(
                  (item, index) =>
                  // (
                    // console.log(item)
                    user.branch === item.id && (
                      <option value={item.branchName} key={index}>
                        {item.branchName}
                        {/* {user.branch} */}
                      </option>
                    )
                )}
            </select>
          )}
          {/* <select
            type="select"
            id="branch"
            name="branch"
            onChange={branchChangeHandler}
            className="form-select rounded-0"
          >
            <option key="optionOne" selected value="">
              {t("general_key_words.choose")}
            </option>
            <option key="optionTwo" value="all">
              {t("general_key_words.all")}
            </option>
            {data &&
              data.map((item, index) => (
                <option value={item.branchName} key={index}>
                  {item.branchName}
                </option>
              ))}
          </select> */}
        </div>
      </div>
      {/*  */}
      <div className="col-sm-12 col-md-12 col-lg-2 mb-2  mb-lg-0">
        <button
          disabled={isDisabled}
          className="btn btn-primary w-100 d-flex align-items-center justify-content-center gap-2"
          onClick={handleSearch}
        >
          {loading ? <DownloadLoading /> : <BsSearch className="bi" />}
          {t("general_key_words.search")}
        </button>
      </div>
    </div>
  );
};

export default SearchPanel;
