import { t } from "i18next";
import React, { useMemo, useState, useRef } from "react";
import { useTable, useSortBy, usePagination } from "react-table"; // Import useSortBy hook

import jsPDF from "jspdf";
import "jspdf-autotable";

import { useDownloadExcel } from "react-export-table-to-excel";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";

import jalaliMoment from "jalali-moment";
import InvoiceOptions from "./InvoiceOptions";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { PrintableData } from "../../redux/InvoicesSection/InvoiceSlice";
import { useNavigate } from "react-router-dom/dist";
import { InvoicePrintCollection } from "../../redux/InvoicesSection/InvoicePrint";
import { BiSolidChevronLeft, BiSolidChevronRight, BiSolidChevronsLeft, BiSolidChevronsRight } from "react-icons/bi";

// import "jspdf-autotable/dist/polyfills.js";
// import "jspdf-autotable/dist/jspdf.plugin.autotable.js";

const ApprovedTable = ({ carsData }) => {
  // console.log("Watin list called:");
  const [filterValue, setFilterValue] = useState(""); // State variable for filtering
  const [selectedItems, setSelectedItems] = useState([]);
  const [checkBox, setCheckBox] = useState(true);
  const token = useSelector((state) => state.authentication.token);

  const dispatch = useDispatch();
  const navigate = useNavigate();




  const data = useMemo(() => {
    if (!carsData || !carsData.data) {
      return [];
    }

    // Apply filtering based on the filterValue
    return carsData.data.filter((row) => {
      return Object.values(row).some((value) =>
        value
          ? value.toString().toLowerCase().includes(filterValue.toLowerCase())
          : false
      );
    });
  }, [carsData, filterValue]);

  const columns = useMemo(
    () => [
      // {
      //   Header: t("vehicle_table.serial_number"),
      //   accessor: "id",
      // },
      {
        Header: t("vehicle_table.serial_number"),
        accessor: (row, index) => index + 1, // Generate sequential numbers
      },
      {
        Header: t("vehicle_table.driver_name"),
        accessor: "driver.firstName",
      },
      {
        Header: t("vehicle_table.driver_father_name"),

        accessor: "driver.fatherName",
      },

      {
        Header: t("vehicle_table.driver_nid"),
        accessor: "driver.nid",
      },
      {
        Header: t("vehicle_table.province"),
        accessor: "driver.province",
      },
      {
        Header: t("vehicle_table.date"),
        accessor: "gre_date",
      },
      {
        Header: t("vehicle_table.car_type"),
        accessor: "driver.carType",
      },
      {
        Header: t("vehicle_details_form.carNumPlat"),
        accessor: "driver.carNumPlat",
        // Enable sorting for this column
        canSort: true,
      },
      {
        Header: "Status",
        accessor: "invoice_status",
        // Enable sorting for this column
        canSort: true,
      },
      {
        Header: "Price(AF)",
        accessor: "price",
        // Enable sorting for this column
        canSort: true,
      },

      {
        Header: t("vehicle_table.options_button"),
        id: "optionsButton", // Add a unique id for this column
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },

    useSortBy, // Enable sorting
    usePagination
  );

  const totalPrice = useMemo(() => {
    if (!data || data.length === 0) {
      return 0;
    }
    return data.reduce((sum, row) => sum + parseInt(row.price, 10), 0);
  }, [data]);

  //downlaod excel sheet:
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: t("excelTable.waitingVehicleTable"),
    sheet: t("excelTable.waitingVehicleSheet"),
  });

  //   const handleExportToExcel = () => {
  //     const exportData = page.map((row) => {
  //       const rowData = {};
  //       columns.forEach((column) => {
  //         // rowData[column.Header] = row.values[column.accessor];
  //         if (column.id !== "optionsButton") { // Exclude the "optionsButton" column
  //             rowData[column.Header] = row.values[column.accessor];
  //           }
  //       });
  //       return rowData;
  //     });
  //     onDownload(exportData, columns);
  //   };
  const handleExportToExcel = () => {
    const exportColumns = columns.filter(
      (column) => column.id !== "optionsButton"
    );
    const exportData = page.map((row) => {
      const rowData = {};
      exportColumns.forEach((column) => {
        rowData[column.Header] = row.values[column.accessor];
      });
      return rowData;
    });
    onDownload(exportData, exportColumns);
  };

  //download to pdf:
  const exportPDF = () => {
    // console.log("PDF");
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3, or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Driver Table";
    const headers = [
      [
        t("Name"),
        t("Father Name"),
        t("NID"),
        t("Phone#"),
        t("Driving Permit"),
        t("Car Num Plat"),
        t("Province"),
      ],
    ];

    const data = page.map((row) => [
      row.original.firstName,
      row.original.fatherName,
      row.original.nid,
      row.original.phone,
      row.original.drivingPermit,
      row.original.carNumPlat,
      row.original.province,
    ]);

    doc.text(title, marginLeft, 40);
    doc.autoTable({
      startY: 50,
      head: headers,
      body: data,
    });

    doc.save("report.pdf");
  };

  const driverCheckHandler = (e) => {
    const id = e.target.id;
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(id)) {
        return prevSelectedItems.filter((item) => item !== id);
      } else {
        return [...prevSelectedItems, id];
      }
    });
  };

  const handleCheckBoxActivate = (e) => {
    setCheckBox(!checkBox);
    setSelectedItems([]);
  };

  const handleTransfer = () => {
    if (selectedItems.length > 0) {
      dispatch(PrintableData(selectedItems));
      dispatch(InvoicePrintCollection(token, selectedItems));
      // navigate(`print`);
    } else toast.error(t("general_key_words.noRecordSelect"));
    setSelectedItems([]);
  };

  return (
    <div>
      {/* Input field for filtering */}
      {/* <input
        type="text"
        className="form-control m-0 rounded-3"
        value={filterValue}
        onChange={(e) => setFilterValue(e.target.value)}
        placeholder={t("general_key_words.search")}
      /> */}

      <div className="row">
        <div className="col-sm-12 col-md-8">
          <input
            type="text"
            className="form-control"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            placeholder={t("general_key_words.search")}
          />
        </div>
        <div className="col-sm-12 col-md-4">
          <div className="bg-white rounded-2 mt-2 mt-lg-0 mb-0 d-flex flex-row align-items-center justify-content-between">
            <div onClick={handleExportToExcel}>
              <FaFileExcel
                style={{ color: "green", cursor: "pointer", fontSize: "25px" }}
              />
            </div>
            <div className="d-flex flex-row align-items-center">
              <span className="mx-2">{t("general_key_words.show")}</span>
              <select
                value={pageSize}
                className="form-select"
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20, 50, 100, 200, 400, 800, 1600].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div className="mx-1 d-flex flex-row gap-2">
              <button
                className={`btn px-3 ${
                  checkBox ? "btn-primary" : "btn-danger"
                }`}
                onClick={handleCheckBoxActivate}
              >
                {checkBox ? "Print" : "Cancel"}
              </button>
              {!checkBox && (
                <button
                  className={`btn px-3 px-lg-5 btn-success`}
                  onClick={handleTransfer}
                >
                  Generate
                </button>
              )}
            </div>
          </div>
          {/* <span onClick={exportPDF}>
      <FaFilePdf style={{ color: "red", cursor: "pointer", fontSize: "25px" }} />
    </span> */}
        </div>
      </div>

      {/* <div className="col-sm-12 col-md-3 col-lg-3">
        <button onClick={handleExportToExcel}>Export to Excel <FaFileExcel /></button>
      </div> */}

      <table
        className="table table-hover"
        id="property-table"
        // ref={tableRef}
        {...getTableProps()}
        border="1"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key="header">
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Display sorting indicators */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.original.id}>
                {row.cells.map((cell, index) => {
                  //   return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  return (
                    <td {...cell.getCellProps()}>
                      {index === row.cells.length - 1 ? (
                        !checkBox ? (
                          <input
                            type="checkbox"
                            className="form-check-input"
                            disabled={checkBox}
                            id={row.original.driver_id}
                            onChange={driverCheckHandler}
                            checked={selectedItems.includes(
                              `${row.original.driver_id}`
                            )}
                          />
                        ) : (
                          <InvoiceOptions record={row.original} />
                        )
                      ) : cell.column.id === "gre_date" ? (
                        // Assuming your date is in the format YYYY-MM-DD
                        // Adjust format as needed
                        jalaliMoment(new Date(cell.value).toISOString())
                          .locale("fa")
                          .format("YYYY-MM-DD")
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {/* Add a new row to display the total price */}
          <tr className="">
            <td className=" text-center" colSpan={columns.length}>
              Total Price: {totalPrice} (AF)
            </td>
          </tr>
        </tbody>
      </table>

      <table
        className="table table-hover d-none"
        id="property-table"
        ref={tableRef}
        {...getTableProps()}
        border="1"
      >
        <caption style={{ textAlign: "center", fontSize: "25px", fontWeight: "bold" }}>
            {t("message.brand")}
        </caption>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key="header">
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Display sorting indicators */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.original.id}>
                {row.cells.map((cell, index) => {
                  //   return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  return (
                    <td {...cell.getCellProps()}>
                      {index === row.cells.length - 1 ? (
                        !checkBox ? (
                          <input
                            type="checkbox"
                            className="form-check-input"
                            disabled={checkBox}
                            id={row.original.driver_id}
                            onChange={driverCheckHandler}
                            checked={selectedItems.includes(
                              `${row.original.driver_id}`
                            )}
                          />
                        ) : (
                          <InvoiceOptions record={row.original} />
                        )
                      ) : cell.column.id === "gre_date" ? (
                        // Assuming your date is in the format YYYY-MM-DD
                        // Adjust format as needed
                        jalaliMoment(new Date(cell.value).toISOString())
                          .locale("fa")
                          .format("YYYY-MM-DD")
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {/* Add a new row to display the total price */}
          <tr className="border">
            <td className="border" colSpan={columns.length}>
              Total Price: {totalPrice} (AF)
            </td>
          </tr>
        </tbody>
      </table>

      {pageCount > 1 && (
        <ul className="pagination bg-white py-2 rounded-3 d-flex flex-row justify-content-between px-2 mt-2">
          <li className="page-item ">
            <button className="btn btn-light p-0 p-1" disabled>
              {t("general_key_words.page")}{" "}
              <strong>
                {pageIndex + 1} / {pageCount}
              </strong>{" "}
            </button>
          </li>
          <div className="d-flex flex-row gap-1">
            <li className="page-item ">
              <button
                className="btn btn-primary py-2 d-flex align-items-center"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <BiSolidChevronsLeft className="fs-6" />
              </button>
            </li>
            <li className="page-item ">
              <button
                className="btn btn-primary p-1 px-2 d-flex align-items-center"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <BiSolidChevronLeft className="fs-6 bi" />
                <span className="fs-6">Previous</span>
              </button>
            </li>
            <li className="page-item ">
              <button
                className="btn btn-primary p-1 px-2 d-flex align-items-center"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <BiSolidChevronRight className="fs-6 bi" />
                <span className="fs-6">Next</span>
              </button>
            </li>
            <li className="page-item ">
              <button
                className="btn btn-primary py-2 d-flex align-items-center"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <BiSolidChevronsRight className="fs-6" />
              </button>
            </li>
          </div>
        </ul>
      )}
    </div>
  );
};

export default ApprovedTable;
