import toast from "react-hot-toast";
import { http } from "../../../Utility/APIRequest"; 

import { t } from "i18next";
import { FetchIDError, FetchIDStart, FetchIDSuccess } from "../slice/branchSlice";
import { HandleHTTPRequestError } from "../../../Utility/HandleHTTPRequestError";

export const FetchBranchById = (token, id) => async (dispatch) => {
  dispatch(FetchIDStart());
  // console.log()
  // http request to backend
  try {
    const response = await http.get(`api/branch/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    // console.log("REsoonse:");
    // console.log(response)
    toast.success(t("toast.data_fetch"));
    dispatch(FetchIDSuccess(response.data.record));
  } catch (error) {
    dispatch(FetchIDError());
    HandleHTTPRequestError(error.response.status, error, dispatch);
  }
};
