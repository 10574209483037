import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import FormDataHelper from "../../Utility/FormDataHelper";
import {
  FetchError,
  HandleProgress,
  UploadError,
  UploadStart,
  UploadSuccess,
} from "./VehicleSlice";
import { useSelector } from "react-redux";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const UploadDriverData = (driverData, token, resetForm) => async (dispatch) => {
  dispatch(UploadStart());
  // http request to backend
  try {
    // const formData = FormDataHelper(driverData);
    const formData = new FormData();
    driverData.images.forEach((file, index) => {
      formData.append(`images[${index}]`, file);
    });

    formData.append("firstName", driverData.firstName);
    formData.append("fatherName", driverData.fatherName);
    formData.append("phone", driverData.phone);
    formData.append("nid", driverData.nid);
    formData.append("province", driverData.province);
    formData.append("regGregorianDate", driverData.regGregorianDate);
    formData.append("regPersianDate", driverData.regPersianDate);
    formData.append("carType", driverData.carType);
    formData.append("maktoob", driverData.maktoob);

    if (driverData.maktoob == "true") {
      formData.append("maktoob_number", driverData.maktoob_number);
      formData.append("carNumPlat", "");
      formData.append("drivingPermit", "");
    } else {
      formData.append("maktoob_number", "");
      formData.append("carNumPlat", driverData.carNumPlat);
      formData.append("drivingPermit", driverData.drivingPermit);
    }
    
    formData.append("paintingStatus", driverData.paintingStatus);
    formData.append("workStatus", driverData.workStatus);
    formData.append("carModel", driverData.carModel);
    formData.append("commissionNum", driverData.commissionNum);

    formData.append("techContNum", driverData.techContNum);
    formData.append("commissionFile", driverData.commissionFile);
    formData.append("techContFile", driverData.techContFile);
    formData.append("tarifa_number", driverData.tarifa_number);
    formData.append("tarifa_file", driverData.tarifa_file);
    formData.append("color_area", driverData.colored);
    formData.append("receipt_number", driverData.receipt_number);

    // console.log(formData);
    const response = await http.post("api/driver", formData, {
      headers: { Authorization: `Bearer ${token}` },
      onUploadProgress: (progressEvent) => {
        const progress = Math.floor(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        dispatch(HandleProgress(progress));
        // console.log(progress);
      },
    });

    // console.log(response);
    toast.success(t("toast.data_upload"));
    dispatch(UploadSuccess());
    resetForm();
  } catch (error) {
    if (error.response.status === 422) {
      const Errors = {
        message: error.message,
        errors: error.response.data.errors,
      };

      dispatch(UploadError(Errors));
    }

    dispatch(FetchError());
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
      toast.error(t("toast.apiOffline"));
    }
  }
};
