import { t } from "i18next";
import React from "react";
import { AiFillCar } from "react-icons/ai";
import {
  BiSolidCarMechanic,
  BiSolidTimer,
} from "react-icons/bi";
import {MdMore } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const TrafficDashCard = ({ data }) => {
  // console.log("Data44444446:");
  // console.log(data);
  // const domain = "/";
  const user = useSelector((state) => state.authentication.user);
  const navigate = useNavigate();
  const handleViewMore = (page) => {
    // const navigateUrl = domain+page;
    // window.location.assign(navigateUrl);
    // added a test comment
    window.open("/" + page, "_blank");
  };

  const handleViewDrivers = (page) => {
    window.open("/vehicle-dashboard", "_blank");
  };

  const visitList = (url) => {
    navigate("/" + url);
  };
  return (
    <>
    {/* {user.role !=="user" &&( */}
    {/* {(user.role ==="super") &&( */}



      <div className="rounded-3 row bg-light m-0 mt-2 py-5">
 
        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
          <div
            className="dash-card-bg border rounded-3 shadow"
            style={{ backgroundPosition: "0px 0px" }}
          >
            <div className="d-flex flex-column p-4">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="fs-5">
                  {t("dash_board_card.registeredVehicle")}
                </div>
                <div>
                  <AiFillCar className="fs-4" />
                </div>
              </div>
              <div className="fs-2 fw-semibold my-2">
                {data && data.drivers > 0 ? data.drivers : "0"}
              </div>

              <div className="">
                  <button
                    className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                      data.waiting > 0 ? "" : "disabled"
                    }`}
                    // onClick={(msg) => handleViewMore("view-list")}
                    onClick={(e) => visitList("view-list")}
                  >
                    {t("dash_board_card.details")}
                    <MdMore className="bi" />
                  </button>
                
              </div>
    
            </div>
          </div>
        </div>



        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
          <div
            className="dash-card-bg border rounded-3 shadow"
            style={{ backgroundPosition: "0px 0px" }}
          >
            <div className="d-flex flex-column p-4">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="fs-5">{t("dash_board_card.colorWaiting")}</div>
                <div>
                  <BiSolidTimer className="fs-4" />
                </div>
              </div>
              <div className="fs-2 fw-semibold my-2">
                {data && data.waiting > 0 ? data.waiting : "0"}
              </div>

              

              <div className="">
                  <button
                    className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                      data.waiting > 0 ? "" : "disabled"
                    }`}
                    // onClick={(msg) => handleViewMore("view-list")}
                    onClick={(e) => visitList("waiting-list")}
                  >
                    {t("dash_board_card.details")}
                    <MdMore className="bi" />
                  </button>
                
              </div>
            
            </div>
          </div>
        </div>

        {/* Pending Controls: */}
        {/* {data && data.controls > 0 && ( */}
        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
          <div
            className="dash-card-bg border rounded-3 shadow"
            style={{ backgroundPosition: "0px 0px" }}
          >
            <div className="d-flex flex-column p-4">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="fs-5">
                  {t("dash_board_card.pendingControls")}
                </div>
                <div>
                  <BiSolidCarMechanic className="fs-4" />
                </div>
              </div>
              <div className="fs-2 fw-semibold my-2">
                {data && data.approvedControls > 0 ? data.approvedControls : "0"}
              </div>

              <div className="">
                  <button
                    className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                      data.approvedControls > 0 ? "" : "disabled"
                    }`}
                    onClick={(e) => visitList("control-list/waiting")}
                  >
                    {t("dash_board_card.details")}
                    <MdMore className="bi" />
                  </button>
                
              </div>
             
            </div>
          </div>
        </div>

        {/* Approved Controls: */}
        {/* {data && data.controls > 0 && ( */}
        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
          <div
            className="dash-card-bg border rounded-3 shadow"
            style={{ backgroundPosition: "0px 0px" }}
          >
            <div className="d-flex flex-column p-4">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="fs-5">
                  {t("dash_board_card.approvedControls")}
                </div>
                <div>
                  <BiSolidCarMechanic className="fs-4" />
                </div>
              </div>
              <div className="fs-2 fw-semibold my-2">
                {data && data.controls > 0 ? data.controls : "0"}
              </div>
              <div className="">
                  <button
                    className={`btn btn-light d-flex flex-row gap-2 align-items-center w-100 justify-content-center bg-transparent ${
                      data.controls > 0 ? "" : "disabled"
                    }`}
                    onClick={(e) => visitList("approved-control-list")}
                  >
                    {t("dash_board_card.details")}
                    <MdMore className="bi" />
                  </button>
                
              </div>

            </div>
          </div>
        </div>
        {/* )} */}


        {/* {data && data.branches && (
          <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div
              className="dash-card-bg border rounded-3 shadow"
              style={{ backgroundPosition: "0px 0px" }}
            >
              <div className="d-flex flex-column p-4">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="fs-5">{t("dash_board_card.branches")}</div>
                  <div>
                    <RiGitBranchFill className="fs-4" />
                  </div>
                </div>
                <div className="fs-2 fw-semibold my-2">
                  {data && data.branches > 0 ? data.branches : "0"}
                </div>
                
              </div>
            </div>
          </div>
        )} */}
         
        {/* Invoices section: */}
        {/* {data && data.pendingInvoiceSum > 0 && (
          <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div
              className="dash-card-bg border rounded-3 shadow"
              style={{ backgroundPosition: "0px 0px" }}
            >
              <div className="d-flex flex-column p-4">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="fs-5">
                    {t("dash_board_card.pendingInvoice")}
                  </div>
                  <div>
                    <MdPendingActions className="fs-4" />
                  </div>
                </div>
                <div className="fs-2 fw-semibold my-2">
                  {data && data.pendingInvoiceSum > 0
                    ? data.pendingInvoiceSum
                    : "0"}
                </div>
                
              </div>
            </div>
          </div>
        )}

        {data && data.generatedInvoiceSum > 0 && (
          <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div
              className="dash-card-bg border rounded-3 shadow"
              style={{ backgroundPosition: "0px 0px" }}
            >
              <div className="d-flex flex-column p-4">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="fs-5">{t("invoice.generatedInvoice")}</div>
                  <div>
                    <RiAiGenerate className="fs-4" />
                  </div>
                </div>
                <div className="fs-2 fw-semibold my-2">
                  {data && data.generatedInvoiceSum > 0
                    ? data.generatedInvoiceSum
                    : "0"}
                </div>
                
              </div>
            </div>
          </div>
        )}

        {data && data.receivedInvoiceSum > 0 && (
          <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
            <div
              className="dash-card-bg border rounded-3 shadow"
              style={{ backgroundPosition: "0px 0px" }}
            >
              <div className="d-flex flex-column p-4">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="fs-5">
                    {t("dash_board_card.receivedInvoice")}
                  </div>
                  <div>
                    <MdAttachMoney className="fs-4" />
                  </div>
                </div>
                <div className="fs-2 fw-semibold my-2">
                  {data && data.receivedInvoiceSum > 0
                    ? data.receivedInvoiceSum
                    : "0"}
                </div>
                
              </div>
            </div>
          </div>
        )} */}

      </div>
    </>
  );
};

export default TrafficDashCard;
