import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../Utility/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import LoadingAnimation from "../../shared/LoadingAnimation";
import NoContentsFound from "../../shared/NoContentsFound";
import {FetchInvoicesList, FetchPendingInvoicesByBranchId } from "../../redux/InvoicesSection/FetchInvoices";

import InvoiceListTable from "./InvoiceListTable";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import GeneratedInvoiceWithVehicleTable from "./GeneratedInvoiceWithItemTable";
export default function GeneratedInvoiceWithVehicles(props) {

  const data = useSelector((state) => state.invoice.data);
  const carsData = useSelector((state) => state.invoice);
  const loading = useSelector((state) => state.invoice.loading);
  // const progress = useSelector((state) => state.vehicle.progress);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);
  const type = props.type;
  const { id } = useParams();

  useEffect(() => {
    if(type==="collected"){
      dispatch(FetchInvoicesList(token, "api/driver/collected_invoices/get"));
    }
    else{
      dispatch(FetchInvoicesList(token, "api/driver/generated_invoices/get"));
    }
    
  }, []);
  return (
    <div>
      <div className="mt-2">
        {/* <FormHeader province={"Kabul"} /> */}
        <PageHeader title={t("invoice.invoiceAndItems")} />
      </div>
      {loading ? (
        <LoadingAnimation />
      ) : data && data.length>0? (
        type==="collected" ?
        <GeneratedInvoiceWithVehicleTable carsData={carsData} recordType={t("vehicle_details_form.collectedInvoiceList")} />
        :
        <GeneratedInvoiceWithVehicleTable carsData={carsData} recordType={t("vehicle_details_form.generateInvoiceList")} />
      ) : (
        <NoContentsFound message={t("general_key_words.dataNotFound")} />
      )}
    </div>
  );
}
