import React from "react";
import { BsChevronDoubleRight } from "react-icons/bs";
const PageHeader = ({ title }) => {
  return (
    <h6 className="mt-2 p-3 bg-white rounded-3 fw-semibold d-flex flex-row align-items-center gap-2">
      <BsChevronDoubleRight className="bi text-primary" /> {title}
    </h6>
  );
};

export default PageHeader;
