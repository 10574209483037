import React from 'react'
import { BiMinus, BiPlus } from 'react-icons/bi'

const TitlePanelGeneral = ({title,isForm,setIsForm,buttonText}) => {
  return (
    <div className="rounded-3 d-flex flex-row justify-content-between bg-light   align-items-center px-3 py-1 my-2">
          <span>{title}</span>
          <button
            className={
              isForm
                ? "btn btn-light border- d-flex px-3 py-1 align-items-center justify-content-center"
                : "btn btn-outline-secondary border-light py-1 d-flex px-3 align-items-center justify-content-center "
            }
            type="button"
            onClick={(e) => setIsForm(!isForm)}
          >
            <span className="">
              {buttonText}
            </span>
            {isForm ? (
              <BiMinus className="ms-2 fs-5" />
            ) : (
              <BiPlus className="ms-2 fs-5" />
            )}
          </button>
        </div>
  )
}

export default TitlePanelGeneral