import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: null,
  item: null,
};

const DeleteSlice = createSlice({
  name: "delete",
  initialState,
  reducers: {
    DeleteStart: (state, action) => {
      state.loading = true;
      state.item = action.payload;
    },
    DeleteSuccess: (state) => {
      state.loading = false;
      state.item = null;
    },
    DeleteError: (state) => {
      state.loading = false;
      state.item = null;
    },
  },
});

export const {DeleteError,DeleteStart,DeleteSuccess} = DeleteSlice.actions;
export default DeleteSlice.reducer;
