import { t } from 'i18next';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const InvoiceNav = () => {
    return (
        <ul className="nav justify-content-start">
          {/* <li className="nav-item">
            <NavLink
            //   to="/control-list/waiting"
            to="/approved_invoice-list/all"
              className={({ isActive }) =>
                isActive ? "nav-link text-primary" : "nav-link text-black-50"
              }
              end
            >
                {t("invoice.allApprovedInvoice")} 
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
            //   to="/control-list/waiting"
            to="/approved_invoice-list"
              className={({ isActive }) =>
                isActive ? "nav-link text-primary" : "nav-link text-black-50"
              }
              end
            >
                {t("invoice.generateNewInvoice")}
            </NavLink>
          </li> */}

          {/* <li className="nav-item">
            <NavLink
            //   to="view-list"
              to="approved_invoice-list/printed"
              className={({ isActive }) =>
                isActive ? "nav-link text-primary" : "nav-link text-black-50"
              }
              end
            >
              {t("invoice.printedInvoice")}
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
            //   to="view-list"
              to="approved_invoice-list/collecting-cash"
              className={({ isActive }) =>
                isActive ? "nav-link text-primary" : "nav-link text-black-50"
              }
              end
            >
              {t("invoice.collectInvoiceCash")}
            </NavLink>
          </li> */}

          {/* <li className="nav-item">
            <NavLink
            //   to="view-list"
              to="approved_invoice-list/collectedCash"
              className={({ isActive }) =>
                isActive ? "nav-link text-primary" : "nav-link text-black-50"
              }
              end
            >
              {t("invoice.collectedInvoices")}
            </NavLink>
          </li> */}
        
    
        </ul>
      );
}

export default InvoiceNav