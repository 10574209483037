import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { t } from "i18next";

// import "chartjs-plugin-datalabels";
// import  "./dist/css/all.min.css";
// import  "./dist/css/adminlte.min.css";

// import  "./plugins/jquery/jquery.min.js";
// import  "./dist/js/adminlte.min.js";
// import "./chart.css";
// import "./amdinLte.css";
// import "./dist/css/adminlte.min.css";
// import "./dist/css/all.min.css";
// import "./plugins/jquery/jquery.min.js";
// import "./dist/js/adminlte.min.js";

const DashChart = ({ data }) => {
  const domain = "http://localhost:3000/";

  const handleViewMore = (page) => {
    const navigateUrl = domain + page;
    window.open(navigateUrl, "_blank");
  };

  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    if (data) {
      // Extract the data you want to display in the bar chart
      const labels = [
        // // t("dash_board_card.registeredVehicle"),
        // t("dash_board_card.pendingInvoice")+" :"+data.pendingInvoiceSum,
        // t("dash_board_card.receivedInvoice")+" :"+data.receivedInvoiceSum,
        // // t("dash_board_card.colorWaiting"),
        // t("invoice.generatedInvoice")+" :"+data.generatedInvoiceSum,
        // t("dash_board_card.approvedControls")+" :"+data.controls,
        // t("dash_board_card.branches"),
        // Add other data labels here

        t("dash_board_card.pendingInvoice"),
        t("dash_board_card.receivedInvoice"),
        t("invoice.generatedInvoice"),
      ];
      const values = [
        // data.drivers,
        data.pendingInvoiceSum,
        data.receivedInvoiceSum,
        data.generatedInvoiceSum,
        // data.controls,
        // data.waiting,
        // data.receivedInvoiceSum,
        // data.branches,
        // Add other data values here
      ];

      const vehicleValues = [
        // data.drivers,
        // data.pendingInvoiceSum,
        // data.receivedInvoiceSum,
        // data.generatedInvoiceSum,
        data.controls,
        data.waiting,
        data.branches,
        // Add other data values here
      ];

      // Calculate the minimum value for the Y-axis
      const minDataValue = Math.min(...values);

      // Destroy the previous chart if it exists
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      // Create a chart using Chart.js
      const ctx = chartRef.current.getContext("2d");

      chartInstanceRef.current = new Chart(ctx, {
        type: "bar",
        // type: "line",
        // type: "polarArea",
        data: {
          labels: labels,
          datasets: [
            {
              // label: t("dash_board_card.invoiceChart"),
              // data: values,
              // backgroundColor: [
              //   "rgba(255, 99, 132, 0.2)",
              //   "rgba(255, 159, 64, 0.2)",
              //   "rgba(255, 205, 86, 0.2)",
              //   "rgba(75, 192, 192, 0.2)",
              //   "rgba(54, 162, 235, 0.2)",
              //   "rgba(153, 102, 255, 0.2)",
              //   "rgba(201, 203, 207, 0.2)",
              // ],
              // borderColor: [
              //   "rgb(255, 99, 132)",
              //   "rgb(255, 159, 64)",
              //   "rgb(255, 205, 86)",
              //   "rgb(75, 192, 192)",
              //   "rgb(54, 162, 235)",
              //   "rgb(153, 102, 255)",
              //   "rgb(201, 203, 207)",
              // ],
              // borderWidth: 1,

                label               : ' ',
                backgroundColor: '#007bff',
                borderColor: '#007bff',
                // backgroundColor     : 'rgba(60,141,188,0.9)',
                // borderColor         : 'rgba(60,141,188,0.8)',
                pointRadius          : false,
                pointColor          : '#3b8bba',
                pointStrokeColor    : 'rgba(60,141,188,1)',
                pointHighlightFill  : '#fff',
                pointHighlightStroke: 'rgba(60,141,188,1)',
                data                : values
              },
              {
                label               : ' ',
                backgroundColor     : 'rgba(210, 214, 222, 1)',
                borderColor         : 'rgba(210, 214, 222, 1)',
                pointRadius         : false,
                pointColor          : 'rgba(210, 214, 222, 1)',
                pointStrokeColor    : '#c1c7d1',
                pointHighlightFill  : '#fff',
                pointHighlightStroke: 'rgba(220,220,220,1)',
                data                : values
              },
            
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              suggestedMin: minDataValue > 5 ? minDataValue - 5 : 0, // Adjust the suggested minimum based on the data
            },
          },
          plugins: {
            tooltip: {
              intersect: false, // Enable tooltips for empty parts of the chart
            },
            datalabels: {
                anchor: "end", // Position of the data labels (end of the bar)
                align: "top",  // Alignment of the data labels (top of the bar)
                font: {
                  size: 25,    // Adjust font size as needed
                  weight: "bold",
                },
                formatter: (value) => value, // Display the actual value as the label
              },
          },
        },
      });
    }
  }, [data]);

  return (
    
        // <canvas
        //     ref={chartRef}
        //     // style={{ width: "200px", height: "150px" }} // Set width and height styles
        //     className="mx-auto chartCustomTwo"
        //     >  
        // </canvas>



<div className="card card-success my-2">
  <div className="card-header">
    <h3 className="card-title text-center"> {t("general_key_words.invoice")}</h3>

    {/* <div className="card-tools">
      <button type="button" className="btn btn-tool" data-card-widget="collapse">
        <i className="fas fa-minus"></i>
      </button>
      <button type="button" className="btn btn-tool" data-card-widget="remove">
        <i className="fas fa-times"></i>
      </button>
    </div> */}
  </div>
  <div className="card-body">
    <div className="chart">
      <canvas 
      ref={chartRef}
      id="barChart" style={{minHeight:"250px", height:"250px", maxHeight:"250px", maxWidth:"100%"}}>

      </canvas>
    </div>
  </div>
</div>

    
  );
};

export default DashChart;
