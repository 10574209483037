import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import { FetchError, FetchStart, FetchSuccess } from "./ControlSlice";
import { t } from "i18next";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";

export const ApprovedControl = (token) => async (dispatch) => {
    dispatch(FetchStart());
    // http request to backend
    try {
      const response = await http.get("api/control/approved/get", {
        headers: { Authorization: `Bearer ${token}` },
      });
      // console.log("Pending Controls:")
      // console.log(response)
      if(response.data.records.length>0){
        toast.success(t("toast.data_fetch"));
      }else{
        toast.success(t("No data found"));
      }
      dispatch(FetchSuccess(response.data.records));
    } catch (error) {
      dispatch(FetchError());
      if (error.response) {
        HandleHTTPRequestError(error.response.status, error, dispatch);
      } else {
        // Handle the case where the API is offline or unreachable
        console.error("API is offline or unreachable.");
          toast.error(t("toast.apiOffline"));
      }
    }
  };

  //get rejected controls:
  // 
  export const FetchRejectedControl = (token) => async (dispatch) => {
    dispatch(FetchStart());
    // http request to backend
    try {
      const response = await http.get("api/control/rejected/get", {
        headers: { Authorization: `Bearer ${token}` },
      });
      // console.log("Rejected Controls:")
      // console.log(response)
      if(response.data.records.length>0){
        toast.success(t("toast.data_fetch"));
      }else{
        toast.success(t("No data found"));
      }
      dispatch(FetchSuccess(response.data.records));
    } catch (error) {
      dispatch(FetchError());
      if (error.response) {
        HandleHTTPRequestError(error.response.status, error, dispatch);
      } else {
        // Handle the case where the API is offline or unreachable
        console.error("API is offline or unreachable.");
          toast.error(t("toast.apiOffline"));
      }
    }
  };