import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import { RejectError, RejectStart, RejectSuccess } from "./InvoiceSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const RejectInvoice = (token, id) => async (dispatch, getState) => {
  dispatch(RejectStart(id));
  // http request to backend
  try {
    const response = await http.post(`api/driver/invoice/reject/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const currentState = getState();
    const updatedData = currentState.invoice.data.filter(
      (record) => record.id !== id
    );

    toast.success(t("toast.data_reject"));
    dispatch(RejectSuccess(updatedData));
  } catch (error) {
    dispatch(RejectError());
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};
