import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchRecordID } from "../../redux/vehicleRegistration/FetchRecordID";
import { useNavigate, useParams } from "react-router-dom";
import LoadingAnimation from "../../shared/LoadingAnimation";
import PageHeader from "../../Utility/PageHeader";
import NoContentsFound from "../../shared/NoContentsFound";
import { useTranslation } from "react-i18next";
import { FaDownload } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";
import { BsFillTrashFill, BsPrinter } from "react-icons/bs";
import Swal from "sweetalert2";
import { DeleteDriverRecord } from "../../redux/vehicleRegistration/DeleteDriverRecord";
import InlineLoadingAnimation from "../../shared/InlineLoadingAnimation";
import { SelectRecord } from "../../redux/vehicleRegistration/VehicleSlice";
import DownloadLoading from "../../shared/DownloadLoading";
import { API_Image_BASE_URL, FILES_BASE_URL } from "../../Utility/Domain";
import { AddToApproveBankSlip } from "../../redux/vehicleRegistration/AddToApproveBankSlip";
import { FcAcceptDatabase } from "react-icons/fc";

const ViewDriver = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.vehicle.loading);
  const record = useSelector((state) => state.vehicle.record);
  const selectedDelete = useSelector((state) => state.vehicle.selectedDelete);
  const token = useSelector((state) => state.authentication.token);
  const downloadLoading = useSelector((state) => state.download.loading);
  const { id } = useParams();
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const user = useSelector((state) => state.authentication.user);
  
  useEffect(() => {
    if (id) {
      dispatch(FetchRecordID(token, id));
      // if (record) {
      //   setImages(JSON.parse(record.images));
      // }
    }
  }, [id]);

  useEffect(() => {
    if (record && record.images) {
      setImages(JSON.parse(record.images));
    }
  }, [record]);

  // console.log("tttttt:");
  // console.log(record);
  const handleEdit = () => {
    // console.log(record.id);
    // window.location.assign(`http://localhost:3000/view-list/edit/${record.id}`)
    // navigate(`edit/${record.id}`);
    navigate(`/view-list/edit/${record.id}`);
  };

  // const handlePrint = () => {
  //   // console.log("Page printed");

  // };

  const NavigateToPrintPage = () => {
    dispatch(SelectRecord(record));
    // window.open('/view-list/view/print');
    navigate("/view/print");
  };

  const handleDelete = () => {
    Swal.fire({
      title: t("general_key_words.areYouSure"),
      text: t("general_key_words.deleteConfirmMsg"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("general_key_words.deleteConfirmButtonText"),
      cancelButtonText: t("general_key_words.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteDriverRecord(token, record.id, "view"));
        navigate("/view-list");
      }
    });
  };

  //download file section:
  // const devUlr = "http://localhost:8000/storage/driver";
  // const proUrl = "https://api.afgsoft.net/public/storage/driver";
  const downloadTechFile = (fileName) => {
    // window.open("https://api.afgsoft.net/public/storage/driver/control_files/pdf_file_1697352607.pdf","blank")
    // window.open(`${proUrl}/pdf_file_1697352607.pdf`, "_blank");
    if (fileName) {
      window.open(`${FILES_BASE_URL}/technical_control/${fileName}`, "_blank");
      // window.close();
      //   const data = {
      //     path : 'app/public/driver/technical_control/',
      //     name: fileName,
      //   };
      //   dispatch(DownloadFile(data, token));
    }
  };
  const downloadCommissionFile = (fileName) => {
    if (fileName) {
      window.open(`${FILES_BASE_URL}/commission/${fileName}`, "_blank");
      //   const data = {
      //     path : 'app/public/storage/driver/commission/',
      //     name: fileName,
      //   };
      //   dispatch(DownloadFile(data, token));
    }
  };

  const downloadTarifaFile = (fileName) => {
    if (fileName) {
      window.open(`${FILES_BASE_URL}/tarifa/${fileName}`, "_blank");
      //   const data = {
      //     path : 'app/public/storage/driver/commission/',
      //     name: fileName,
      //   };
      //   dispatch(DownloadFile(data, token));
    }
  };

  const NavigateToApproveBankSlip = (type) => {
    Swal.fire({
      title: t("general_key_words.areYouSure"),
      text: t("general_key_words.confirmChangeMsg"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("vehicle_table.yesApproveIt"),
      cancelButtonText: t("general_key_words.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(AddToApproveBankSlip(token, record.id, type));
        // dispatch(AddToWaiting(token, record.id, "table"));
      }
    });
  };

  // console.log("Records:")
  // console.log(record)

  return loading ? (
    <LoadingAnimation />
  ) : (
    <div className="">
      {/* <PageHeader title={"Driver Information"} /> */}
      <PageHeader title={t("vehicle_details_form.driverInfo")} />
      {record ? (
        <div className="mt-2 bg-white rounded-3 ">
          {/* start */}
          <div className="row p-3 p-lg-5 pt-lg-3">
            {/* <div className="row p-3 ps-5 pe-5 ps-lg-5"> */}

            {/* <h5 className="col-12">{t("vehicle_details_form.driverInfo")}</h5> */}
            <div className="col-sm-12 col-lg-6">
              <div className="d-flex flex-column my-2">
                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">
                    {t("vehicle_details_form.firstName")}:
                  </span>
                  <span className="mx-3 fw-semibold">{record.firstName}</span>
                </div>
                {/* <div className="d-flex flex-row align-items-center py-2">
                <span className="">Last Name:</span>
                <span className="mx-3 fw-semibold">{record.lastName}</span>
              </div> */}
                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">
                    {t("vehicle_details_form.fatherName")}:
                  </span>
                  <span className="mx-3 fw-semibold">{record.fatherName}</span>
                </div>
                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">{t("vehicle_details_form.phone")}:</span>
                  <span className="mx-3 fw-semibold">{record.phone}</span>
                </div>

                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">
                    {t("general_key_words.registrationDate")}:
                  </span>
                  <span className="mx-3 fw-semibold">
                    {record.regGregorianDate}____{record.regPersianDate}
                  </span>
                </div>

                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">
                    {t("general_key_words.time")}:
                  </span>
                  <span className="mx-3 fw-semibold">
                    {(new Date(record.created_at)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  </span>
                </div>

                {/* my working 
                new Date(record.regGregorianDate)
                */}

                {/* <div className="d-flex flex-row align-items-center py-2">
                  <span className="">
                    {t("vehicle_details_form.carChassis")}:
                  </span>
                  <span className="mx-3 fw-semibold">{record.carChassis}</span>
                </div> */}

                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">
                    {t("vehicle_details_form.commissionNum")}:
                  </span>
                  <span className="mx-3 fw-semibold">
                    {record.commissionNum}
                  </span>
                </div>

                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">
                    {t("vehicle_details_form.techContNum")}:
                  </span>
                  <span className="mx-3 fw-semibold">{record.techContNum}</span>
                </div>
                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">{t("vehicle_table.paintArea")}</span>
                  <span className="mx-3 fw-semibold text-success">
                    {record.color_area === "town"
                      ? t("vehicle_table.town")
                      : t("vehicle_table.suburb")}
                  </span>
                </div>
                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">
                    {t("validation_errors.receipt_number_label")}:
                  </span>
                  <span className="mx-3 fw-semibold">{record.receipt_number ? record.receipt_number : "-"}</span>
                </div>

                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">{t("vehicle_table.bankSlipApprove")}</span>
                  <span className="mx-3 fw-semibold text-success">
                    {record.bank_slip_status === "approved"
                      ? t("vehicle_table.approved")
                      : t("vehicle_table.notApproved")}
                  </span>
                </div>

              </div>
            </div>

            <div className="col-sm-12 col-md-6">
              <div className="d-flex flex-column py-2">
                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">{t("general_key_words.code")}:</span>
                  <span className="mx-3 fw-semibold">{record.id}</span>
                </div>
                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">{t("vehicle_details_form.nid")}:</span>
                  <span className="mx-3 fw-semibold">{record.nid}</span>
                </div>
                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">
                    {t("vehicle_details_form.province")}:
                  </span>
                  <span className="mx-3 fw-semibold">{record.province}</span>
                </div>
                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">{t("vehicle_details_form.carType")}:</span>
                  <span className="mx-3 fw-semibold">{record.carType}</span>
                </div>

                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">
                    {t("vehicle_details_form.carModel")}:
                  </span>
                  <span className="mx-3 fw-semibold">{record.carModel}</span>
                </div>

                {/* <div className="d-flex flex-row align-items-center py-2">
                  <span className="">
                    {t("vehicle_details_form.carNumPlat")}:
                  </span>
                  <span className="mx-3 fw-semibold">{record.carNumPlat}</span>
                </div> */}

             
                <div className="d-flex flex-row align-items-center py-2">
                  <span className="">
                    {t("invoice.tarifaNumber")}:
                  </span>
                  <span className="mx-3 fw-semibold">
                    {record.tarifa_number}
                  </span>
                </div>
                

                {/* {record.carNumPlat && ( */}
                  <div className="d-flex flex-row align-items-center py-2">
                    <span className="">
                      {t("vehicle_details_form.carNumPlat")}:
                    </span>
                    <span className="mx-3 fw-semibold">
                      {record.carNumPlat ? record.carNumPlat : t("vehicle_details_form.na")}
                    </span>
                  </div>
                {/* )} */}
                {/* {record.drivingPermit && ( */}
                  <div className="d-flex flex-row align-items-center py-2">
                    <span className="">
                      {t("vehicle_details_form.drivingPermit")}:
                    </span>
                    <span className="mx-3 fw-semibold">
                      {/* {record.drivingPermit} */}
                      {record.drivingPermit ? record.drivingPermit : t("vehicle_details_form.na")}
                    </span>
                  </div>
                {/* )} */}
                {/* {record.maktoob_number && ( */}
                  <div className="d-flex flex-row align-items-center py-2">
                    <span className="">
                    {t("vehicle_details_form.maktobNumber")}:
                    </span>
                    <span className="mx-3 fw-semibold">
                      {/* {record.maktoob_number} */}
                      {record.maktoob_number ? record.maktoob_number : t("vehicle_details_form.na")}
                    </span>
                  </div>

                  <div className="d-flex flex-row align-items-center py-2">
                    <span className="">
                    {t("vehicle_table.work_status")}:
                    </span>
                    <span className="mx-3 fw-semibold">
                      
                      {
                          record.workStatus === "waiting"
                          ? t("vehicle_details_form.inWaiting")
                          : record.workStatus === "approved"
                          ? t("vehicle_details_form.inContrl")
                          : record.workStatus === "registration"
                          ? t("vehicle_details_form.newRegister")
                          : record.workStatus === "rejected"
                          ? t("vehicle_details_form.rejectControl")
                          : t("vehicle_details_form.complated")
                      }

                    </span>
                  </div>




                {/* )} */}
                {/* <div className="d-flex flex-row align-items-center py-2 ">
                <span className="">Gregorian Date:</span>
                <span className="mx-3 fw-semibold">{record.greDate}</span>
              </div> */}
              </div>
            </div>
            {record &&
                  images.length>0 &&(
                  <>
            <hr className="my-2" />
            <h5 className="col-12 mb-3">{t("vehicle_details_form.image")}</h5>
            <div className="col-sm-12 mb-2">
              <div className="row">
                {
                  images.map((img) => (
                    <div className="col-sm-12 col-md-6 overflow-hidden p-0">
                      <img
                        src={`${API_Image_BASE_URL}storage/driver/images/${img}`}
                        alt="this is an"
                        className="driver-view-image"
                      />
                    </div>
                  ))}
              </div>
            </div>
            
            </>
            )}
             {record && (record.techContFile || 
             record.commissionFile || record.tarifa_file ) &&(
                  <>
            <hr className="my-2" />
            <h5 className="col-12 mb-3">
              {" "}
              {t("vehicle_details_form.driverAndCarFiles")}
            </h5>
            </>
            )}
            <div className="col-sm-12">
              <div className="row">
                {/* <div className="col-sm-12 col-md-6 col-lg-4 text-center  mb-2">
                  <button className="btn btn-primary w-75 d-flex align-items-center justify-content-center gap-2">
                    <FaDownload className="bi" />{" "}
                    {t("vehicle_details_form.nidFile")}
                  </button>
                </div> */}
                {/* <div className="col-sm-12 col-md-6 col-lg-4 text-center mb-2">
                  <button className="btn btn-primary w-75 d-flex align-items-center justify-content-center gap-2">
                    <FaDownload className="bi" />{" "}
                    {t("vehicle_details_form.purchaseDoc")} 
                  </button>
                </div> */}

                {/* <div className="col-sm-12 col-md-6 col-lg-4 text-center mb-2">
                  <button className="btn btn-primary w-75 d-flex align-items-center justify-content-center gap-2">
                    <FaDownload className="bi" />
                    {t("vehicle_details_form.drivingPermitFile")}
                  </button>
                </div> */}
                {record.techContFile && (
                  <div className="col-sm-12 col-md-6 col-lg-4 text-center mb-2">
                    <button
                      className="btn btn-primary w-75 d-flex align-items-center justify-content-center gap-2"
                      onClick={(fileName) =>
                        downloadTechFile(record.techContFile)
                      }
                    >
                      {downloadLoading ? (
                        <DownloadLoading />
                      ) : (
                        <FaDownload className="bi" />
                      )}

                      {t("vehicle_details_form.techContFile")}
                    </button>
                  </div>
                )}

                {record.commissionFile && (
                  <div className="col-sm-12 col-md-6 col-lg-4 text-center mb-2">
                    <button
                      className="btn btn-primary w-75 d-flex align-items-center justify-content-center gap-2"
                      onClick={(fileName) =>
                        downloadCommissionFile(record.commissionFile)
                      }
                    >
                      {downloadLoading ? (
                        <DownloadLoading />
                      ) : (
                        <FaDownload className="bi" />
                      )}
                      {t("vehicle_details_form.commissionFile")}
                    </button>
                  </div>
                )}

                {record.tarifa_file && (
                  <div className="col-sm-12 col-md-6 col-lg-4 text-center mb-2">
                    <button
                      className="btn btn-primary w-75 d-flex align-items-center justify-content-center gap-2"
                      onClick={(fileName) =>
                        // downloadCommissionFile(record.tarifa_file)
                        downloadTarifaFile(record.tarifa_file)
                      }
                    >
                      {downloadLoading ? (
                        <DownloadLoading />
                      ) : (
                        <FaDownload className="bi" />
                      )}
                      {t("invoice.tarifaFile")}
                    </button>
                  </div>
                )}

                {/* {user.role === "super" || user.role === "approver" && record.workStatus==="registration" && record.bank_slip_status==="not_approved" ( */}
                {
                 (user.role === "super" || user.role === "approver") && (record.workStatus==="registration" && record.bank_slip_status==="not_approved") && (
                  <div className="col-sm-12 col-md-6 col-lg-4 text-center mb-2">
                    <button
                      className="btn btn-primary w-75 d-flex align-items-center justify-content-center gap-2"
                      // onClick={NavigateToApproveBankSlip}
                      onClick={()=>{
                        NavigateToApproveBankSlip("view")
                      }}
                    >
                      <FcAcceptDatabase className="bi" />
                     {t("vehicle_table.bankSlipApprove")} 
                    </button>
                  </div>
                )}




                <div className="col-sm-12 col-md-6 col-lg-4 text-center mb-2 d-flex flex-row gap-2">
                  {
                  (record.workStatus === "registration" ||record.workStatus === "rejected") 
                  && (record.bank_slip_status ==="not_approved") && 
                  (user.role !== "approver" && user.staff !=="traffic staff") ? (
                    <>
                      <button
                        className="btn btn-secondary d-flex align-items-center gap-2"
                        onClick={handleEdit}
                      >
                        <AiFillEdit className="bi" />{" "}
                        {t("general_key_words.edit")}
                      </button>
                      {/* <button
                        className="btn btn-danger d-flex align-items-center gap-2"
                        onClick={handleDelete}
                      >
                        {selectedDelete ? (
                          <InlineLoadingAnimation />
                        ) : (
                          <BsFillTrashFill className="bi" />
                        )}
                        {t("general_key_words.delete")}
                      </button> */}
                    </>
                  ) : null}

                  <button
                    className="btn btn-primary d-flex align-items-center gap-2"
                    // onClick={handlePrint}
                    onClick={NavigateToPrintPage}
                  >
                    <BsPrinter className="bi" /> {t("general_key_words.print")}
                  </button>
                </div>

                {/* <div className="d-flex flex-row gap-1 justify-content-end">
                    edit and delete div
                </div> */}
              </div>
            </div>
            {/* end */}
          </div>
        </div>
      ) : (
        <NoContentsFound message={"No data was found on server"} />
      )}
    </div>
  );
};

export default ViewDriver;
