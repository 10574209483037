import React from "react";
import {
  BsChevronDoubleDown,
  BsViewList,
} from "react-icons/bs";
import { t } from "i18next";
import { FcAddDatabase } from "react-icons/fc";
import { AiFillEdit } from "react-icons/ai";
import { FaMinus } from "react-icons/fa";
import InlineLoadingAnimation from "../../shared/InlineLoadingAnimation";
import { useDispatch, useSelector } from "react-redux";
import { RejectWaitingRecord } from "../../redux/WaitingSection/RejectWaitingRecord";
import { AddToControl } from "../../redux/vehicleRegistration/AddToControl";
import { RejectInvoice } from "../../redux/InvoicesSection/RejectInvoice";
const InvoicePrintedOptions = ({ record, section }) => {
  const inlineLoading = useSelector((state) => state.invoice.inlineLoading);
  const selected = useSelector((state) => state.invoice.selected);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authentication.token);

  const handleView = () => {
    window.open(`/approved_invoice-list/printed/${record.invoice_print_id}`, "_blank");
  };



  const handleReject = () => {
     dispatch(RejectInvoice(token, record.id,""));
  };

  return selected === record.id && inlineLoading ? (
    <div className="">
      <InlineLoadingAnimation />
    </div>
  ) : (
    <div className="btn-group">
      <button
        className="btn btn-secondary d-flex align-items-center gap-2 rounded"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <BsChevronDoubleDown />
        {t("general_key_words.choose")}
      </button>
      <ul className="dropdown-menu" style={{ zIndex: 2000 }}>
        <li>
          <a
            className="dropdown-item d-flex align-items-center gap-2 text-muted"
            type="button"
            onClick={handleView}
          >
            <BsViewList className="bi" />
            {t("general_key_words.view")}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default InvoicePrintedOptions;
