import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import {
  FetchError,
  FetchStart,
  FetchSuccess,
  HandleProgress,
} from "./VehicleSlice";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { t } from "i18next";

export const FetchDriverByBranchId = (token, id) => async (dispatch) => {
  dispatch(FetchStart());
  // http request to backend
  try {
    const response = await http.get(`api/drivers/branch/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.success(t("toast.data_fetch"));
    dispatch(FetchSuccess(response.data.records));
    // console.log("response for branch driver id:");
    // console.log(response);
  } catch (error) {
    dispatch(FetchError())
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};

//all controlled driverd by a branch details:
export const FetchControlledDriverByBranchId = (token, id) => async (dispatch) => {
    // console.log("Approved drivers fetch called")
    dispatch(FetchStart());
    // http request to backend
    try {
      const response = await http.get(`api/drivers/controlled/branch/get/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success(t("toast.data_fetch"));
      dispatch(FetchSuccess(response.data.records));
    } catch (error) {
      dispatch(FetchError())
      if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
    }
  };


  //all controlled driverd by a branch details:
export const FetchWaitingDriverByBranchId = (token, id) => async (dispatch) => {
    // console.log("Approved drivers fetch called")
    dispatch(FetchStart());
    // http request to backend
    try {
      const response = await http.get(`api/drivers/waiting/branch/get/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success(t("toast.data_fetch"));
      dispatch(FetchSuccess(response.data.records));
      // console.log("response for branch driver id:");
      // console.log(response);
    } catch (error) {
      dispatch(FetchError())
      if (error.response) {
        HandleHTTPRequestError(error.response.status, error, dispatch);
      } else {
        // Handle the case where the API is offline or unreachable
        console.error("API is offline or unreachable.");
          toast.error(t("toast.apiOffline"));
      }
    }
  };

  //all controlled driverd by a branch details:
// export const FetchPendingInvoicesByBranchId = (token, id) => async (dispatch) => {
//     console.log("Pending invoices")
//     dispatch(FetchStart());
//     // http request to backend
//     try {
//       const response = await http.get(`api/invoice/pending/branch/get/${id}`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       toast.success(t("toast.data_fetch"));
//       dispatch(FetchSuccess(response.data.records));
//       console.log("response for branch driver id:");
//       console.log(response);
//     } catch (error) {
//       dispatch(FetchError())
//       HandleHTTPRequestError(error.response.status,error,dispatch);
//     }
//   };




//

