import toast from "react-hot-toast";
import { http } from "../../Utility/APIRequest";
import { HandleHTTPRequestError } from "../../Utility/HandleHTTPRequestError";
import { SendError, SendStart, SendSuccess } from "./MessageSlice";
import { t } from "i18next";
import { sendSMS } from "../../pages/car-registration/SendSMS";

export const SendMessage = (data, token) => async (dispatch) => {
  // dispatch(sendSMS(data.driver.phone, data.message));
  dispatch(SendStart());
  try {
    const formData = new FormData();
    formData.append("message", data.message);
    formData.append("user_id", data.user_id);
    formData.append("driver_id", data.driver_id);

    const response = await http.post("api/message", formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast.success(t("message.messageSent"));
    await sendSMS(data.driver.phone, data.message);
    dispatch(SendSuccess(data.phone,data.message));
  } catch (error) {
    if (error.response.status === 422) {
      const Errors = {
        message: error.message,
        errors: error.response.data.errors,
      };
      dispatch(SendError());
    }
    if (error.response) {
      HandleHTTPRequestError(error.response.status, error, dispatch);
    } else {
      // Handle the case where the API is offline or unreachable
      console.error("API is offline or unreachable.");
        toast.error(t("toast.apiOffline"));
    }
  }
};
