import { t } from "i18next";
import React from "react";

const DriverGridView = ({ driver }) => {
  return (
    <>
      <h4 className="col-12 fw-semibold">{t("vehicle_details_form.driverInfo")}:</h4>
      <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="d-flex flex-row align-items-center py-2">
          <span className="">{t("vehicle_details_form.firstName")}:</span>
          <span className="mx-3 fw-semibold">{driver.firstName}</span>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="d-flex flex-row align-items-center py-2">
          <span className="">{t("vehicle_details_form.fatherName")}:</span>
          <span className="mx-3 fw-semibold">{driver.fatherName}</span>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="d-flex flex-row align-items-center py-2">
          <span className="">{t("vehicle_details_form.phone")}:</span>
          <span className="mx-3 fw-semibold">{driver.phone}</span>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="d-flex flex-row align-items-center py-2">
          <span className="">{t("vehicle_details_form.nid")}:</span>
          <span className="mx-3 fw-semibold">{driver.nid}</span>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="d-flex flex-row align-items-center py-2">
          <span className="">{t("vehicle_details_form.carType")}:</span>
          <span className="mx-3 fw-semibold">{driver.carType}</span>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="d-flex flex-row align-items-center py-2">
          <span className="">{t("vehicle_details_form.province")}:</span>
          <span className="mx-3 fw-semibold">{driver.province}</span>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="d-flex flex-row align-items-center py-2">
          <span className="">{t("general_key_words.date")}:</span>
          <span className="mx-3 fw-semibold">{driver.regPersianDate}</span>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="d-flex flex-row align-items-center py-2">
          <span className="">{t("vehicle_details_form.carNumPlat")}:</span>
          <span className="mx-3 fw-semibold">{driver.carNumPlat}</span>
        </div>
      </div>
      {/* <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="d-flex flex-row align-items-center py-2">
          <span className="">Car Chassis:</span>
          <span className="mx-3 fw-semibold">{driver.carChassis}</span>
        </div>
      </div> */}
      <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="d-flex flex-row align-items-center py-2">
          <span className="">{t("vehicle_details_form.drivingPermit")}:</span>
          <span className="mx-3 fw-semibold">{driver.drivingPermit}</span>
        </div>
      </div>

      <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="d-flex flex-row align-items-center py-2">
          <span className="">{t("vehicle_details_form.workStatus")}:</span>
          <span className="mx-3 fw-semibold">{driver.workStatus}</span>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="d-flex flex-row align-items-center py-2">
          <span className="">{t("vehicle_details_form.paintingStatus")}:</span>
          <span className="mx-3 fw-semibold">{driver.paintingStatus}</span>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="d-flex flex-row align-items-center py-2">
          <span className="">{t("vehicle_details_form.carModel")}:</span>
          <span className="mx-3 fw-semibold">{driver.carModel}</span>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="d-flex flex-row align-items-center py-2">
          <span className="">{t("invoice.tarifaNumber")}:</span>
          <span className="mx-3 fw-semibold">{driver.tarifa_number}</span>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="d-flex flex-row align-items-center py-2">
          <span className="">{t("vehicle_details_form.techContNum")}:</span>
          <span className="mx-3 fw-semibold">{driver.techContNum}</span>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-4">
        <div className="d-flex flex-row align-items-center py-2">
          <span className="">{t("vehicle_details_form.commissionNum")}:</span>
          <span className="mx-3 fw-semibold">{driver.commissionNum}</span>
        </div>
      </div>
    </>
  );
};

export default DriverGridView;
