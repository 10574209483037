import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  data: null,
  user:null,
  token:null,
};

const SessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    FetchStart: (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    },
    FetchSuccess: (state, action) => {
      // console.log("Action:");
      // console.log(action);
      state.loading = false;
      state.data = action.payload;
      state.error = null;
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    FetchError: (state) => {
      state.loading = false;
      state.data = null;
    },
    ClearSession: (state) => {
      state.data = null;
      state.error = null;
      state.token = null;
      state.user = null;
      state.isAuthenticated = false;
    },
  },
});

export const {
  FetchError,
  FetchStart,
  FetchSuccess,
  ClearSession,
} = SessionSlice.actions;
export default SessionSlice.reducer;
