import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { provinces, provincesDari } from "../Data/provinces";
import { Staff } from "../Data/Staff";
import { Role } from "../Data/AccountRole";
import { SwalMessage } from "../Utility/SwalMessage";
import FormDataHelper from "../Utility/FormDataHelper";
import PostRequest from "../Utility/PostRequest";
import APIFormErrors from "../Utility/APIFormErrors";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { http } from "../Utility/APIRequest";
import { useDispatch, useSelector } from "react-redux";
import { FetchBranchData } from "../redux/branchSection/actions/FetchBranchData";
import InlineLoadingAnimation from "../shared/InlineLoadingAnimation";
import { UpdateAccountCompletely } from "../redux/authentication/UpdateAccountCompletely";
import { UploadError } from "../redux/authentication/AuthenticationSlice";
import { t } from "i18next";
import SelectField from "../Utility/SelectField";
const RegistrationForm = () => {
  const errors = useSelector((state) => state.authentication.APIErrors);
  const navigate = useNavigate();
  const loading = useSelector((state) => state.authentication.loading);
  const token = useSelector((state) => state.authentication.token);
  const data = useSelector((state) => state.branch.data);
  const update = useSelector((state) => state.authentication.update);
  const dispatch = useDispatch();
  // const { setFieldValue } = useFormikContext();
  useEffect(() => {
    dispatch(FetchBranchData(token));
    // console.log(data);
  }, []);

  const initialValues = {
    firstName: update ? update.firstName : "",
    lastName: update ? update.lastName : "",
    email: update ? update.email : "",
    password: "",
    confirmPassword: "",
    staff: update ? update.staff : "",
    province: update ? update.province : "",
    role: update ? update.role : "",
    branch: update ? update.branch : "",
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("validation_errors.firstNameRequired")),
    lastName: Yup.string().required(t("validation_errors.lastNameRequired")),

    email: Yup.string()
      .email(t("login.email_invalid_error"))
      .required(t("validation_errors.emailRequired")),
    password: Yup.string()
      .min(8, t("validation_errors.passWordLength"))
      .required(t("validation_errors.passwordRequired")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("validation_errors.passwordNotMatch"))
      .required(t("validation_errors.confirmPasswordRequired")),
    // address: Yup.string().required("Home full address is required"),
    staff: Yup.string().required(t("validation_errors.selectStaff")),
    // province: Yup.string().required("Select province"),
    // branch: Yup.string().required("Branch is required"),
    role: Yup.string().required(t("validation_errors.selectRole")),
    province: Yup.string().required(t("validation_errors.selectProvince")),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        // add to formdata
        const formData = FormDataHelper(values);

        if (update) {
          formData.append("id", update.id);
          dispatch(UpdateAccountCompletely(formData, token, resetForm));
          setSubmitting(false);
        } else {
          // axios.defaults.withCredentials = true;
          http
            .get(`sanctum/csrf-cookie`)
            .then((res) => {
              http
                .post(`api/auth/register`, formData)
                .then((response) => {
                  if (response.status === 201) {
                    SwalMessage(
                      "User Account",
                      response.data.message,
                      "success"
                    );
                    resetForm();
                  }
                })
                .catch((err) => {
                  if (err.response.status === 422) {
                    toast.error(err.response.data.message);
                    dispatch(UploadError(err.response.data.errors));
                  }
                })
                .finally((response) => {
                  setSubmitting(false);
                });
            })
            .catch((err) => {
              // console.log(err.message);
            })
            .finally((res) => {});
        }
      }}
    >
      {({ isSubmitting, handleReset, values, setFieldValue }) => (
        <Form>
          {errors && <APIFormErrors errors={errors} />}
          <div className="row g-3">
            {/* account names section */}
            <div className="col-sm-6">
              <label htmlFor="firstName" className="form-label">
                {t("setting.firstName")} <span className="text-danger"> *</span>
              </label>
              <Field
                type="text"
                name="firstName"
                id="firstName"
                placeholder={t("place_holder.typeFirstName")}
                className="form-control"
              />
              <ErrorMessage
                name="firstName"
                component="div"
                className="alert alert-danger p-2 my-1"
              />
            </div>
            <div className="col-sm-6">
              <label htmlFor="lastName" className="form-label">
                {t("setting.lastname")} <span className="text-danger"> *</span>
              </label>
              <Field
                type="text"
                name="lastName"
                id="lastName"
                placeholder={t("place_holder.typeLastname")}
                className="form-control"
              />
              <ErrorMessage
                name="lastName"
                component="div"
                className="alert alert-danger p-2 my-1"
              />
            </div>
            {/* <div className="col-12">
              <label htmlFor="userName" className="form-label">
                User_name<span className="text-danger"> *</span>
              </label>
              <div className="input-group has-validation">
                <span className="input-group-text">@</span>
                <Field
                  type="text"
                  name="userName"
                  id="userName"
                  placeholder={"type your user name "}
                  className="form-control"
                />
                <ErrorMessage
                  name="userName"
                  component="div"
                  className="alert alert-danger p-2 my-1"
                />
              </div>
            </div> */}

            {/* account email and contact section*/}
            <div className="col-12">
              <label htmlFor="email" className="form-label">
                {t("setting.email")} <span className="text-danger"> *</span>
              </label>
              <Field
                type="text"
                name="email"
                id="email"
                placeholder={"you@example.com"}
                className="form-control"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="alert alert-danger p-2 my-1"
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <label htmlFor="password" className="form-label">
                {t("setting.password")} <span className="text-danger"> *</span>
              </label>
              <Field
                type="password"
                name="password"
                id="password"
                placeholder={"***************"}
                className="form-control"
              />
              <ErrorMessage
                name="password"
                component="div"
                className="alert alert-danger p-2 my-1"
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <label htmlFor="confirmPassword" className="form-label">
                {t("setting.confirmPassword")}
                <span className="text-danger"> *</span>
              </label>
              <Field
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder={"***************"}
                className="form-control"
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="alert alert-danger p-2 my-1"
              />
            </div>
            <hr className="mx-auto my-4  w-75" />
            {/* <h5 className="text-muted">Personal Address</h5> */}

            {!update && (
              <div className="col-12">
                {loading ? (
                  <InlineLoadingAnimation />
                ) : (
                  <>
                    {" "}
                    <label htmlFor="branch" className="form-label">
                      {t("branch.branchName")}
                    </label>
                    <SelectField id={"branch"} name={"branch"} data={data} province={"province"}/>
                    <ErrorMessage
                      name="branch"
                      component="div"
                      className="alert alert-danger p-2 my-1"
                    />
                  </>
                )}
              </div>
            )}
            {!update && (
              <div className="col-md-5">
                <label htmlFor="province" className="form-label">
                  {t("vehicle_details_form.province")}
                </label>
                <Field
                  as="select"
                  id="province"
                  name="province"
                  className="form-select"
                  disabled="disabled"
                >
                  <option value="">{t("general_key_words.choose")}</option>
                  {provincesDari.map((pro, index) => (
                    <option value={pro} key={index}>
                      {pro}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="province"
                  component="div"
                  className="alert alert-danger p-2 my-1"
                />
              </div>
            )}
            <div className="col-md-4">
              <label htmlFor="staff" className="form-label">
                {t("branch.relatedStaff")}
              </label>
              <Field
                as="select"
                id="staff"
                name="staff"
                className="form-select"
              >
                <option value="">{t("general_key_words.choose")}</option>
                {Staff.map((st, index) => (
                  <option value={st} key={index} className="text-capitalize">
                    {st}
                  </option>
                ))}
              </Field>

              <ErrorMessage
                name="staff"
                component="div"
                className="alert alert-danger p-2 my-1"
              />
            </div>

            <div className="col-md-3">
              <label htmlFor="role" className="form-label">
                {t("general_key_words.role")}
              </label>
              <Field as="select" id="role" name="role" className="form-select">
                <option value="">{t("general_key_words.choose")}</option>
                {Role.map((ro, index) => (
                  <option value={ro} key={index}>
                    {ro}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="role"
                component="div"
                className="alert alert-danger p-2 my-1"
              />
            </div>
            <hr className="mx-auto my-3 w-75" />

            <button
              className="w-100 btn btn-primary "
              disabled={isSubmitting && true}
              type="submit"
            >
              {isSubmitting ? (
                <>
                  <div
                    className="mx-3 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">
                      {t("general_key_words.loading")}
                    </span>
                  </div>
                  <span>{t("general_key_words.processingPleaseWait")}</span>
                </>
              ) : update ? (
                t("setting.updateAccount")
              ) : (
                t("setting.registerAccount")
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RegistrationForm;
