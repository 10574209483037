import React, { useState } from "react";
import { useParams } from "react-router-dom";
import jalaliMoment from "jalali-moment";
import { t } from "i18next";
import StilamHeader from "../../Utility/StilamHeader";
const PrintStilamPage = () => {
  const { id, count, amount } = useParams();


  const [text, setText] = useState('');
  const maxLines = 6; // Adjust this to your desired limit

  const handleTextareaChange = (event) => {
    const lines = event.target.value.split('\n');
    if (lines.length <= maxLines) {
      setText(event.target.value);
    }
  };

  return (
    <div className="bg-white" dir="rtl" style={{minHeight:"100vh"}}>
      <StilamHeader />
      <div className="text-center">
 
          {/* <p >درخواستی حواله تکمیل کار</p> */}
          <h6> {t("stilam.toMsg")} </h6>
          
      </div>
      <div className="px-5 text-end bg-white">
       <h6 className="px-0 py-2"> {t("stilam.salam")} </h6>
       <p className="fw-bold">محترما!</p>
       <p>

                طوریکه ریاست محترم مستحضر اند، شرکت ساختمانی و تجارتی ستاتیک مسول پیشبرد پروژه متذکره می باشد که رنگمالی 
                <span className="fw-bold mx-2 text-decoration-underline">({count})</span>
                
                  عراده تکسی ارسالی آن ریاست محترم مطابق شرطنامه به وجه احسن انجام داده و از جانب نماینده با صلاحیت آنریاست 
                 محترم نیز مورد تایید قرارد گرفته است که ضمیه درخواستی هذا اوراق تکمیلی تاییدی آن مع انوایس نمبر 
                <span className="fw-bold mx-2 text-decoration-underline">({id})</span>
                و فورم های کار تکمیل شده محضر ریاست محترم ارسال است.
                </p>
                <p>
                  بناء از مقام محترم شما احترامانه تقاضا داریم تا حکم حواله <span className="fw-bold mx-2 text-decoration-underline">({amount})</span> افغانی پول این شرکت را بالا مرجع مربوطه عنایت فرموده ممنون سازند.
                 
                </p>
                <div className="text-center py-3">
                <p >والسلام</p>
                <input className="border-0" 
                  style={{ 
                    textAlign: 'center', 
                    width:"30%", 
                    outline: 'none'
                  }} />
                <p></p>
                <input className="border-0" style={{ textAlign: 'center', width:"30%", outline: 'none'  }} />
                
                </div>
                
                
                <div className="py-0">
                
                  <p>ضمایم : </p>
                  <textarea  style={
                    {border:"0px", 
                    width:"100%", 
                    height:"170px", 
                    resize: "none",
                    padding:"10px",
                    outline: 'none'
                  }
                  } 
                  value={text}
                  onChange={handleTextareaChange}
                  />
                </div>
      </div>
    </div>
  );
};

export default PrintStilamPage;
