import { Outlet } from "react-router-dom";
import SideNavigation from "./SideNavigation";
import LayoutHeader from "./LayoutHeader";
import SearchPanel from "./SearchPanel";

function Layout() {
  return (
    <div className="dashboard-body">
      {/* header section of the layout dashboard */}

      <div className="container-fluid">
        <div className="row">
          <SideNavigation />
          <main className="col-md-9 ms-sm-auto col-lg-10">
          <LayoutHeader />
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
}

export default Layout;
