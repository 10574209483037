import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: null,
  data: null,
  inlineLoading: null,
  selected: null,
  messageRecord:null
};

const WaitSlice = createSlice({
  name: "wait",
  initialState,
  reducers: {
    UpdateRecords:(state,action)=>{
      state.data = action.payload;
    },
    FetchStart: (state) => {
      state.loading = true;
      state.data = null;
    },
    FetchSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    FetchError: (state) => {
      state.loading = false;
      state.data = null;
    },
    MoveStart: (state, action) => {
      state.inlineLoading = true;
      state.selected = action.payload;
    },
    MoveSuccess: (state,action) => {
      state.inlineLoading = false;
      state.selected = null;
      state.data =action.payload;
    },
    MoveError: (state) => {
      state.inlineLoading = false;
      state.selected = null;
    },
    SelectMessageRecord:(state,action)=>{
      state.messageRecord = action.payload
    },
    RemoveMessageRecord:(state)=>{
      state.messageRecord= null
    },
  },
});

export const {
  FetchError,
  FetchSuccess,
  FetchStart,
  MoveError,
  MoveStart,
  MoveSuccess,
  RemoveMessageRecord,
  SelectMessageRecord,
  UpdateRecords
} = WaitSlice.actions;
export default WaitSlice.reducer;
